import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  disabledContainer: css`
    cursor: not-allowed;
  `,
  expandedContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
  collapsedContainer: css`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  valueLabel: css`
    margin-left: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
  `,
  inputContent: css`
    width: 100%;
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  readonlyContent: css`
    justify-content: flex-start;
  `,
  expandedImage: css`
    max-height: 100px;
    img {
      max-height: 100px;
    }
  `,
  collapsedImage: css`
    height: 22px;
    img {
      height: 22px;
      width: 22px;
      object-fit: cover;
      margin: 0;
    }
  `
};
