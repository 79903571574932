import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    width: 100%;
  `,
  listContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XXS};
  `,
  title: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  chipContainer: css`
    background-color: ${COLOR.NEUTRAL_5};
    border-radius: ${RADIUS.S};
    padding: ${GAP.S};
  `
};
