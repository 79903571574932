import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  popover: css`
    display: flex;
    width: 100%;
    line-height: inherit;
    & > * {
      line-height: inherit;
    }
  `,
  tooltipContainer: css`
    display: grid;
    grid-gap: ${GAP.XS};
  `,
  value: (color: string) => css`
    padding: ${GAP.XS} ${GAP.S};
    background-color: ${COLOR[color]};
    color: ${FOREGROUND_COLOR[color]};
    border-radius: ${RADIUS.S};
    margin-left: ${GAP.S};
    gap: ${GAP.XS};
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  `,
  tooltipTitle: css`
    display: flex;
    align-items: center;
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-bottom: ${GAP.XXS};
  `,
  orderList: css`
    list-style-type: decimal;
    list-style-position: inside;
  `,
  unorderList: css`
    padding-left: ${GAP.L};
    list-style-type: disc;
    list-style-position: inside;
  `,
  description: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    margin-bottom: ${GAP.XS};
  `,
  itemListItem: css`
    font-size: ${FONT_SIZE.S};
    padding-bottom: ${GAP.XS};
  `,
  itemListItemDescription: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  argTitle: css`
    display: inline-flex;
    align-items: center;
    gap: ${GAP.XS};
  `,
  exampleList: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  examplesTitle: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-bottom: ${GAP.XS};
  `,
  exampleInput: css`
    border-radius: ${RADIUS.M};
    border: 1px ${COLOR.NEUTRAL_3} solid;
    background-color: ${COLOR.BASE_BACKGROUND};
    padding: ${GAP.XXS};
    margin-top: ${GAP.XS};
  `,
  exampleTitleContainer: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `,
  exampleInputTitle: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  exampleInputSubtitle: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
  `
};
