import { css } from '@emotion/react';
import { COLOR, SECONDARY_COLOR } from '@constants';

export const styles = {
  container: css`
    [data-selector='input-action-btn-container'] {
      border: none;
    }
    [data-selector='input-border'] {
      height: 32px;
    }
    [data-selector='children-container'] {
      padding: 0;
      overflow: hidden;
    }
  `,
  containerWithoutValue: (isReadonly: boolean) => css`
    [data-selector='input-border'] {
      ${!isReadonly && `border-style: dashed; background-color: ${COLOR.NEUTRAL_5};`}
    }
  `,
  containerHover: css`
    [data-selector='input-border'] {
      :hover {
        background-color: ${SECONDARY_COLOR.BRAND_1};
        border: 1px solid ${COLOR.BRAND_1};
      }
    }
  `,
  containerUploading: css`
    [data-selector='input-border'] {
      border-style: solid;
      background-color: ${SECONDARY_COLOR.NEUTRAL_6};
      height: 32px;
    }
  `,
  containerDisabled: css`
    [data-selector='input-border'] {
      border-style: solid;
      background-color: ${COLOR.NEUTRAL_9};
    }
  `,
  containerError: css`
    [data-selector='input-border'] {
      border-color: ${COLOR.HC_5};
    }
  `
};
