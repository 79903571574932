import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    margin: calc(${GAP.XXS} * -1);
    position: relative;
  `,
  content: css`
    display: inline-block;
  `,
  dragger: css`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 2;
  `,
  interactive: css`
    cursor: pointer;
  `
};
