import { COLOR, FONT_SIZE, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    border-radius: ${RADIUS.S};
    border: 1px solid ${COLOR.NEUTRAL_4};
    background-color: ${COLOR.NEUTRAL_5};
    padding: ${GAP.L};
    margin: ${GAP.L} 0;
  `,
  title: css`
    font-size: ${FONT_SIZE.M};
    display: flex;
    align-items: center;
  `,
  titleText: css`
    padding-left: ${GAP.S};
    color: ${COLOR.NEUTRAL_1};
  `,
  list: css`
    margin: ${GAP.M} 0 0 0;
    list-style-position: inside;
  `,
  item: css`
    font-size: ${FONT_SIZE.S};
    color: ${COLOR.NEUTRAL_2};
    padding: ${GAP.XXXS} 0;
    font-weight: 500;
  `
};
