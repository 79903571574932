/* istanbul ignore file | @todo */
import { useCallback } from 'react';

import { GeneralModel, actionHooks, swallowError } from '@cyferd/client-engine';

export const useNotificationUtils = () => {
  const onNavigateTo = actionHooks.useAction('dispatchNavigateTo');

  const markItemAsRead = actionHooks?.useOnMarkAsReadNotification(GeneralModel.IGNORED_POINTER_ID);
  const onClickNotificationLink = useCallback((link: Parameters<typeof onNavigateTo>[0], meta?: any) => onNavigateTo(link, meta), [onNavigateTo]);
  const onMarkItemAsRead = useCallback(value => markItemAsRead(value.id, { read: !value.read }).pipe(swallowError()), [markItemAsRead]);

  return { onClickNotificationLink, onMarkItemAsRead };
};
