import { APP_WIDTH, COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  modalContainer: css`
    height: calc(100vh - 160px);
  `,
  container: css`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: ${GAP.L};
    @media (min-width: ${APP_WIDTH.S}px) {
      grid-template-columns: 250px 1fr;
    }
  `,
  nav: (navDivision: boolean) => css`
    display: flex;
    flex-direction: column;
    border-bottom: 1px ${COLOR.NEUTRAL_3} solid;
    padding-bottom: ${GAP.L};
    overflow-y: auto;

    @media (min-width: ${APP_WIDTH.S}px) {
      padding-bottom: none;
      border-bottom: none;
      padding-right: ${GAP.L};
      ${!!navDivision && `border-right: 1px ${COLOR.NEUTRAL_3} solid;`}
    }
  `,
  navItem: (depth: number, isActive: boolean, isCollapsible: boolean) => css`
    min-height: 36px; // chevrons
    font-weight: ${FONT_WEIGHT.NORMAL};
    padding: ${GAP.XS};
    padding-left: calc(${GAP.XS} + calc(${GAP.XL} * ${depth}));
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color ${TRANSITION_SPEED};
    border-radius: ${RADIUS.S};
    color: ${COLOR.NEUTRAL_1};
    :hover {
      background-color: ${COLOR.NEUTRAL_4};
    }
    ${isCollapsible &&
    `
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    `}
    ${!!isActive &&
    `
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      color: ${COLOR.HC_15};
      background-color: ${SECONDARY_COLOR.HC_15}!important;
    `}
  `,
  doc: css`
    display: flex;
    justify-content: center;
    overflow-y: auto;

    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    padding: ${GAP.M};
    height: calc(100% - ${GAP.M});
    border-radius: ${RADIUS.M};
  `,
  smoothScroll: css`
    scroll-behavior: smooth;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.L};
    width: 100%;
    max-width: 900px;
  `,
  title: css`
    font-size: ${FONT_SIZE.XXM};
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  compoPropsTable: css`
    display: grid;
    gap: ${GAP.S};
    grid-template-columns: 270px 1fr;
  `,
  division: css`
    margin: ${GAP.S} 0;
    border-top: 1px ${COLOR.NEUTRAL_4} solid;
  `,
  searchInput: css`
    margin-bottom: ${GAP.S};
  `,
  matches: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XL};
  `,
  match: css`
    cursor: pointer;
  `,
  matchSummary: css`
    line-height: 150%;
    margin-top: ${GAP.S};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  `,
  highlighted: css`
    background-color: ${COLOR.YW_6};
  `
};
