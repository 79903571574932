import { GAP } from '@constants';
import { styles } from './styles';

export interface SpinnerProps {
  size?: string;
  testid?: string;
}

export const Spinner = ({ size = GAP.XL, testid }: SpinnerProps) => {
  return (
    <div css={styles.spinnerContainer}>
      <div data-testid={testid || 'spinner'} css={styles.spinner} style={{ width: size, height: size }} aria-busy="true" aria-live="polite" />
    </div>
  );
};
