import { useCallback } from 'react';
import { EMPTY } from 'rxjs';

export const useOnOpenExternalUrl = (defaultUrl?: string) =>
  useCallback(
    (url?: string) => {
      window.open(typeof url === 'string' ? url : defaultUrl, '_blank');
      return EMPTY;
    },
    [defaultUrl]
  );
