import { COLOR, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  tooltipContainer: css`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${GAP.XXS};
    background-color: ${COLOR.BASE_BACKGROUND};
    padding: ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.M};
  `,
  tooltipLabel: css`
    font-weight: 700;
  `
};
