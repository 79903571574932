import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    [data-selector='file-input'] {
      h5 {
        display: none;
      }
      [draggable='true'] {
        border: 0;
        padding: 0;
      }
      [data-selector='division'] {
        width: 100%;
      }
      [data-selector='input-border'],
      [data-selector='input-action-btn-container'] {
        background-color: ${COLOR.TRANSPARENT};
        border-color: ${COLOR.TRANSPARENT};
      }
      label {
        left: ${GAP.XS};
      }
    }
  `,
  dropzoneContainer: css`
    width: 100%;
  `
};
