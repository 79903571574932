import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsMounted } from '@cyferd/client-engine';

export const useGetElementSize = (ResizeObserverClass: typeof ResizeObserver = ResizeObserver) => {
  const ref = useRef<HTMLDivElement>();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [top, setTop] = useState(0);
  const isMounted = useIsMounted();

  /* istanbul ignore next line | wrap in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded | caused by the drag & drop library */
  const resizeObserver = useMemo(() => {
    return new ResizeObserverClass(entries => {
      window.requestAnimationFrame(() => {
        if (!entries?.length) return;
        /**
         * The hook works for a single element but ResizeObserver can
         * observe multiple elements.
         */
        const contentRect = entries[0]?.contentRect;
        if (contentRect && isMounted.current) {
          const styles = window.getComputedStyle(ref.current);

          const paddingTop = parseFloat(styles.paddingTop);
          const paddingBottom = parseFloat(styles.paddingBottom);
          const paddingLeft = parseFloat(styles.paddingLeft);
          const paddingRight = parseFloat(styles.paddingRight);

          setHeight(contentRect.height + paddingTop + paddingBottom);
          setWidth(contentRect.width + paddingLeft + paddingRight);

          const calculatedTop = Math.max(ref?.current?.getBoundingClientRect()?.top, 0);
          if (calculatedTop) setTop(calculatedTop);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const measuredRef = useCallback(node => {
    if (node !== null) ref.current = node;
  }, []);

  useEffect(() => {
    if (ref.current) resizeObserver.observe(ref.current);
    return () => {
      if (ref.current) resizeObserver.disconnect();
    };
  });

  return { width, height, top, ref: measuredRef };
};
