import { COLOR, FONT_WEIGHT, GAP, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';
import { EVALUATOR_TAB } from '../resources';

/* istanbul ignore next line */
export const getStyles = (editorContainerHeight: string = '370px', tab: EVALUATOR_TAB) => ({
  editorContainer: css`
    display: grid;
    grid-template-columns: minmax(${tab === EVALUATOR_TAB.PLAYGROUND ? 500 : 250}px, 1fr) 10fr;
    height: ${editorContainerHeight};
    border-top: 1px ${COLOR.NEUTRAL_2} solid;
    border-bottom: 1px ${COLOR.NEUTRAL_2} solid;
    transition: all calc(${TRANSITION_SPEED} / 2);
  `,
  toolbarContainer: css`
    display: grid;
    grid-gap: ${GAP.S};
    overflow: hidden;
    grid-template-rows: minmax(0, max-content);
    position: relative;
    overflow: auto;
  `,
  toolbarList: css`
    flex: 1;
    overflow: auto;
  `,
  inputListHeader: css`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    gap: ${GAP.XXS};
    border-bottom: 1px ${COLOR.NEUTRAL_3} solid;
    padding-bottom: ${GAP.XS};

    color: ${COLOR.NEUTRAL_2};
    span {
      color: ${COLOR.NEUTRAL_2}!important;
      fill: ${COLOR.NEUTRAL_2}!important;
    }
  `,
  inputListHeaderOpen: css`
    padding-bottom: ${GAP.XXS};
    margin-bottom: ${GAP.S};
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
  `,
  inputListContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
    padding: ${GAP.M} ${GAP.S};
  `,
  sidebarContainer: css`
    padding: ${GAP.XS} ${GAP.S} 0 0;
    border-right: 1px ${COLOR.NEUTRAL_2} solid;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  `,
  inputListGroup: css`
    padding-left: ${GAP.M};
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `
});
