import type { ViewModel } from '@cyferd/client-engine';
import type { ComponentConfig } from '@models/view';
import { getComponentConfig } from '@models/view';

export type ViewNodeList = { path: string; node: ViewModel.Node; config: ComponentConfig }[];

const getFlatNodeList = (nodeList: ViewModel.Node[], prefix: string = ''): ViewNodeList => {
  return (nodeList || []).reduce((total, node, index) => {
    const path = [prefix, String(index)].filter(Boolean).join('.');
    return [...total, { node, path, config: getComponentConfig(node?.component) }, ...getFlatNodeList(node?.contains, `${path}.contains`)];
  }, []);
};

export const getViewNodeList = (view: ViewModel.View): ViewNodeList => {
  return [
    ...Object.entries(view?.modals || {})
      .map(([key, modal]) =>
        getFlatNodeList([modal]).map(item => ({ ...item, path: [`modals.${key}`, item.path.replace(/^0(\.?)/, '')].filter(Boolean).join('.') }))
      )
      .flat(),
    ...getFlatNodeList(view?.contains, 'contains')
  ];
};
