import { COLOR } from '@constants';
import { styles } from './styles';
import type { Item } from './types';
import { memo, useMemo } from 'react';
import { GeneralModel, isDeepEqual } from '@cyferd/client-engine';

export interface TableCellProps extends Omit<Item, 'id' | 'rowColor'> {
  id?: string;
  rowIndex?: number;
  testid?: string;
  format: GeneralModel.JSONSchemaFormat;
}

export const TableCell = memo(({ id, color, item, rowIndex, format, testid }: TableCellProps) => {
  const hasColor = useMemo(
    () =>
      ![
        GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
        GeneralModel.JSONSchemaFormat.JSON,
        GeneralModel.JSONSchemaFormat.SQL,
        GeneralModel.JSONSchemaFormat.GRAPHQL,
        GeneralModel.JSONSchemaFormat.XML,
        GeneralModel.JSONSchemaFormat.YAML,
        GeneralModel.JSONSchemaFormat.RICH_TEXT,
        GeneralModel.JSONSchemaFormat.ASSOCIATION
      ].includes(format) && !!COLOR[color],
    [color, format]
  );
  const hasColorBar = useMemo(
    () => hasColor && ![GeneralModel.JSONSchemaFormat.RATING, GeneralModel.JSONSchemaFormat.ICON, GeneralModel.JSONSchemaFormat.ICON_IMAGE].includes(format),
    [format, hasColor]
  );
  return (
    <div data-testid="table-cell" css={[styles.cellContainer, !!hasColor && styles.cellContainerPadding]}>
      <div css={[styles.cellContent, !hasColor && styles.cellPadding]} data-testid={`${testid}-cell-${rowIndex}-${id}`}>
        {!!hasColor ? (
          <div data-testid="table-cell-color" css={[styles.firstItemContainer(color, hasColorBar), styles.cellPadding]}>
            {item}
          </div>
        ) : (
          item
        )}
      </div>
    </div>
  );
});

export const tableCellNeedsColor = (value: any) => ![null, undefined, '', [], {}].some(v => isDeepEqual(v, value));
