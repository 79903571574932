import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';

const size = '33px';

export const styles = {
  children: css`
    display: flex;
    align-items: center;
  `,
  inputContainer: css`
    display: flex;
    gap: ${GAP.S};
  `,
  inputWrapper: css`
    flex: 1;
  `,
  input: css`
    border: none;
    width: 100%;
    caret-color: transparent;
    outline: none;
    border-radius: ${RADIUS.S};
  `,
  close: css`
    display: flex;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
    padding: ${GAP.XS} 0 ${GAP.M} 0;
  `,
  groupname: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.NEUTRAL_2};
    margin-bottom: ${GAP.XS};
  `,
  container: css`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  selctedSample: css`
    height: 30px !important;
    width: 100% !important;
    border-radius: ${RADIUS.S}!important;
  `,
  colorSample: css`
    width: ${size};
    height: ${size};
    border-radius: 50%;
    border: 1px solid ${COLOR.NEUTRAL_3}!important;
    position: relative;
    cursor: pointer;
    margin: 4px;
  `,
  active: css`
    outline-color: ${COLOR.BRAND_1};
    outline-width: 2px;
    outline-offset: 2px;
    outline-style: solid;
  `,
  checkmark: css`
    width: ${size};
    height: ${size};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  header: css`
    flex: 1;
  `,
  footer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  `,
  actionListItem: css`
    margin: 0 0 ${GAP.S} ${GAP.S};
  `,
  groupContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XXS};
  `,
  listContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${GAP.XXS};
  `,
  emptyStateContainer: css`
    width: 100%;
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: ${FONT_SIZE.XXM};
    color: ${COLOR.NEUTRAL_1};
    border: 2px dashed ${COLOR.NEUTRAL_2};
    padding: ${GAP.S};
    background-color: ${COLOR.BASE_BACKGROUND};
  `,
  dropdown: css`
    position: relative;
    top: ${GAP.S};
    border-radius: ${RADIUS.S};
    border: 1px solid ${COLOR.NEUTRAL_2};
  `,
  dropdownContent: css`
    margin: ${GAP.M};
  `
};
