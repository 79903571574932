import { memo } from 'react';

import { COLOR, getDataTypeIcon } from '@constants';

import { colorPerType } from '../getFormulaInputType';
import type { FormulaInputRow } from '../resources';
import { styles } from './styles';
import { Icon } from '../../Icon';
import { FormulaTooltip } from '../FormulaTooltip';
import { Translate } from '@cyferd/client-engine';

export interface FormulaOptionProps {
  item: FormulaInputRow;
  avoidOpenOnClick?: boolean;
  avoidTooltip?: boolean;
}

export const FormulaOption = memo(({ item, avoidOpenOnClick, avoidTooltip }: FormulaOptionProps) => {
  const icon = getDataTypeIcon(item.type === 'null' ? item.type : item.output?.format);
  const color = COLOR[colorPerType[item.type]];

  const content = (
    <div data-testid="input-item">
      <div css={styles.item} data-testid="input-item-action">
        <div css={styles.iconContainer}>
          <Icon name={icon} fill={color} />
        </div>
        <p css={styles.label} style={{ color }}>
          <Translate>{item.label}</Translate>
        </p>
      </div>
    </div>
  );

  return (
    <div css={styles.container}>
      {avoidTooltip ? (
        /* istanbul ignore next */
        content
      ) : (
        <FormulaTooltip item={item} avoidWrapping={false} avoidOpenOnClick={!!avoidOpenOnClick}>
          {content}
        </FormulaTooltip>
      )}
    </div>
  );
});
