import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

type StyleMap = { [key: string]: string };

export interface UseStyleListenerOptions {
  elementRef?: RefObject<HTMLElement>;
  className?: string;
  id?: string;
  dataSelector?: {
    attribute?: string;
    value?: string | number | boolean;
  };
  stylesToWatch: string[];
}

const useStyleListener = ({ elementRef, className, id, dataSelector, stylesToWatch }: UseStyleListenerOptions) => {
  const [styleValues, setStyleValues] = useState<StyleMap>({});

  useEffect(() => {
    const { attribute, value } = dataSelector || {};
    const query = value ? `[${attribute}="${value}"]` : `[${attribute}]`;

    const haveStylesChanged = (prevStyles: StyleMap, newStyles: StyleMap): boolean => {
      return stylesToWatch.some(style => prevStyles[style] !== newStyles[style]);
    };

    const element =
      elementRef?.current ||
      (className ? document.querySelector(`.${className}`) : id ? document.querySelector(`#${id}`) : dataSelector ? document.querySelector(query) : null);

    if (!element) return;

    const getStyles = () => {
      const computedStyles = window.getComputedStyle(element as HTMLElement);
      const updatedStyles: StyleMap = {};

      stylesToWatch.forEach(style => {
        updatedStyles[style] = computedStyles.getPropertyValue(style);
      });

      if (haveStylesChanged(styleValues, updatedStyles)) {
        setStyleValues(updatedStyles);
      }
    };

    getStyles();

    const handleInteraction = () => {
      getStyles();
    };

    element.addEventListener('mouseenter', handleInteraction);
    element.addEventListener('mouseleave', handleInteraction);
    element.addEventListener('focus', handleInteraction);
    element.addEventListener('blur', handleInteraction);

    const observer = new MutationObserver(() => {
      getStyles();
    });

    observer.observe(element, {
      attributes: true,
      attributeFilter: ['style', 'class'],
      subtree: false
    });

    return () => {
      element.removeEventListener('mouseenter', handleInteraction);
      element.removeEventListener('mouseleave', handleInteraction);
      element.removeEventListener('focus', handleInteraction);
      element.removeEventListener('blur', handleInteraction);
      observer.disconnect();
    };
  }, [elementRef, className, id, dataSelector, stylesToWatch, styleValues]);

  return styleValues;
};

export default useStyleListener;
