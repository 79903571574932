import type { ComponentProps } from 'react';
import { useCallback, useState } from 'react';

import { noop, useTranslate } from '@cyferd/client-engine';
import { Input } from '@components/elements/Input';

import type { TemplateName } from '../../schemas';
import { DataTypeModal } from '../DataTypeModal';
import { getDataTypeLabel } from '@constants';

export type Props = ComponentProps<typeof Input>;

export const DataTypeInput = ({ onChange, value, ...props }: Props) => {
  const { translate } = useTranslate();
  const [open, setOpen] = useState<boolean>(false);

  const onToggle = useCallback(() => !props.disabled && setOpen(prev => !prev), [props.disabled]);

  return (
    <>
      <div onClick={onToggle} data-testid="data-type-input">
        <Input {...props} value={translate(getDataTypeLabel(value as TemplateName))} onChange={noop} />
      </div>
      <DataTypeModal open={open} onClose={onToggle} onChange={onChange} />
    </>
  );
};
