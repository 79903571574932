// istanbul ignore file
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, catchError, mergeMap, of, takeUntil, tap, throwError } from 'rxjs';
import type { GeneralModel } from '@cyferd/client-engine';
import {
  ApiModel,
  ViewFormationContext,
  ViewModel,
  isDeepEqual,
  ofType,
  swallowError,
  tapOnSuccess,
  useFinalizeWhileMounted,
  usePrevious,
  useUnmountObservable
} from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useEvaluate } from './useEvaluate';

type buildQueryParams = ViewModel.CyTableProps['data'];
const buildQuery = (data: buildQueryParams) => ({
  cursor: {
    ...data.initialCursor,
    collectionId: data.collectionId,
    ...(data.fixedFilter ? { fixedFilter: data.fixedFilter } : {})
  },
  ...(data.fields ? { fields: data.fields } : {}),
  ...(data.select ? { select: data.select } : {}),
  ...(data.omit ? { omit: data.omit } : {}),
  ...(data.omitAssociations ? { omitAssociations: data.omitAssociations } : {})
});

export interface UseDataSourceParams {
  pointer: string;
  data: ViewModel.CyTableProps['data'];
  currentValue: ViewModel.CyTableProps['value'];
}

export interface UseDataSourceResult {
  value: ViewModel.CyTableProps['value'];
  canLoad: boolean;
  isLoading: boolean;
  isFirstLoad: boolean;
  fetch: () => Observable<ApiModel.APIAction>;
  updateCursor: (criteria: GeneralModel.FetchCriteria) => Observable<never>;
}

export const useDataSource = ({ pointer, data, currentValue }: UseDataSourceParams): UseDataSourceResult => {
  const { dataSourceType, ...params } = data || {};
  const finalize = useFinalizeWhileMounted();
  const onDestroy$ = useUnmountObservable();
  const evaluate = useEvaluate();
  const viewFormation = useContext(ViewFormationContext);

  const { onCoreRunFlow, onDataList, onDispatchUseAction } = useCyActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [initialQuery, setInitialQuery] = useState(undefined);
  const [query, setQuery] = useState<ApiModel.FlowPayloadModel.CORE_LIST['query']>(buildQuery(params));
  const previousQuery = usePrevious(query);

  const canLoad = useMemo(() => {
    if (![ViewModel.CyTableDataSourceType.COLLECTION, ViewModel.CyTableDataSourceType.FLOW].includes(dataSourceType)) return false;
    if (dataSourceType === ViewModel.CyTableDataSourceType.FLOW) return !!data.flowId;
    return !!query;
  }, [query, data.flowId, dataSourceType]);

  const fetch = useCallback(() => {
    return of(null).pipe(
      tap(() => setIsLoading(true)),
      mergeMap(() => {
        if (dataSourceType === ViewModel.CyTableDataSourceType.COLLECTION) return onDataList({ query });
        return onCoreRunFlow({ id: data.flowId, input: evaluate(data.flowInput, { query }) });
      }),
      takeUntil(onDestroy$),
      ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
      tapOnSuccess(result => {
        viewFormation.onLocalStateChange(result, pointer);

        // TODO: not working
        if (data.onSuccess) {
          return onDispatchUseAction({ target: data.onSuccess, event: result as any });
        }
      }),
      finalize(() => setIsLoading(false)),
      /** user error */
      catchError(error => {
        // TODO: not working
        if (data.onError) {
          return onDispatchUseAction({ target: data.onError, event: { error } as any });
        }

        return throwError(() => error);
      })
    );
  }, [query, dataSourceType, data, evaluate, finalize, pointer, onDestroy$, onCoreRunFlow, onDataList, viewFormation, onDispatchUseAction]);

  useEffect(() => {
    if (!canLoad) return;
    if (isDeepEqual(previousQuery, query) || isDeepEqual(initialQuery, query)) return;
    if (!initialQuery) setInitialQuery(query);
    fetch().pipe(swallowError()).subscribe();
  }, [query, canLoad, fetch, initialQuery, previousQuery]);

  if (dataSourceType === ViewModel.CyTableDataSourceType.MANUAL) {
    return {
      value: currentValue || data.initialValue,
      canLoad: false,
      isLoading: false,
      isFirstLoad: false,
      fetch: () => EMPTY,
      updateCursor: () => EMPTY
    };
  }

  const updateCursor = (criteria: GeneralModel.FetchCriteria) => {
    setQuery(prevQuery => ({
      ...prevQuery,
      cursor: {
        ...prevQuery.cursor,
        ...criteria
      }
    }));
    return EMPTY;
  };

  return {
    value: currentValue || ({} as any),
    canLoad,
    isLoading,
    isFirstLoad: isLoading && !currentValue,
    fetch,
    updateCursor
  };
};
