import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { ApiModel } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, ofTypeSetData, swallowError, tapOnSuccess, useFinalizeWhileMounted, useUnmountObservable } from '@cyferd/client-engine';

import { CyWrapperContext } from '@components/smart/CyWrapper';
import { COLOR, GAP } from '@constants';
import { styles } from './styles';
import { ToolTip } from '@components/elements/Tooltip';
import { InfoForLabel } from '@components/elements/InfoForLabel';
import { CTA, CTAType } from '@components/elements/CTA';
import { Spinner } from '@components/elements/Spinner';
import { AssociationPending } from '../AssociationPending';
import type { IconKeys } from '@components/elements/Icon';
import { Icon } from '@components/elements/Icon';
import { mergeMap, takeLast, takeUntil, tap } from 'rxjs';
import { SemaphoreContext } from '../../../../smart/CyForm/SemaphoreProvider';
import { InputWrapper } from '@components/elements/InputWrapper';

export interface AssociationKPIProps {
  disabled?: boolean;
  schema: GeneralModel.JSONSchema;
  apiQuery: ApiModel.ApiValue['query'];
  errorMessage?: string;
  meta: ApiModel.ApiRecordAssociationMeta;
  recordMap: Record<string, ApiModel.ApiRecord>;
  disableCreateNew?: boolean;
  disableLink?: boolean;
  disableUnlink?: boolean;
  disabledType?: GeneralModel.DisabledType;
  onChangeMeta: (event: Partial<ApiModel.ApiRecordAssociationMeta>) => void;
  onEdit: ViewModel.TriggerCallback<any>;
  onLink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
  onUnlink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
}
export const AssociationKPI = memo(
  ({
    disabled,
    schema,
    apiQuery,
    errorMessage,
    meta,
    onChangeMeta,
    recordMap,
    onEdit,
    onLink,
    onUnlink,
    disableCreateNew,
    disableLink,
    disableUnlink,
    disabledType
  }: AssociationKPIProps) => {
    const { useListAssociation, useOnFetchEntity } = useContext(CyWrapperContext);
    const { semaphore$, unlock } = useContext(SemaphoreContext);
    const onListAssociated = useListAssociation(apiQuery, schema);
    const onDestroy$ = useUnmountObservable();
    const [isLoading, setIsLoading] = useState(false);
    const [associatedIcon, setAssociatedIcon] = useState<IconKeys>();
    const finalize = useFinalizeWhileMounted();
    const safeDisabled = !!disabled && disabledType === GeneralModel.DisabledType.DISABLED;

    const onFetchEntity = useOnFetchEntity();

    const count = useMemo(() => {
      if ([typeof meta?.totalCount, typeof meta?.add?.length, typeof meta?.remove?.length].every(type => type === 'number'))
        return meta.totalCount + meta.add.length - meta.remove.length;
    }, [meta?.add?.length, meta?.remove?.length, meta?.totalCount]);

    const onFetch = useCallback(
      (fetchCriteria: GeneralModel.FetchCriteria) => {
        setIsLoading(true);
        return semaphore$.pipe(
          takeUntil(onDestroy$),
          mergeMap(() => onListAssociated(fetchCriteria)),
          tapOnSuccess(v => onChangeMeta({ ...meta, totalCount: v.totalCount })),
          finalize(() => {
            setIsLoading(false);
            unlock();
          })
        );
      },
      [finalize, meta, onChangeMeta, onDestroy$, onListAssociated, semaphore$, unlock]
    );

    useEffect(() => {
      if (schema?.metadata?.association?.preventLoad) onFetch({}).subscribe();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (schema.metadata?.association?.cursor?.collectionId) {
        onFetchEntity(schema.metadata?.association?.cursor?.collectionId)
          .pipe(
            takeUntil(onDestroy$),
            ofTypeSetData(),
            takeLast(1),
            tap(({ query }) => setAssociatedIcon(query?.recordImage || /* istanbul ignore next */ '123')),
            swallowError()
          )
          .subscribe();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema.metadata?.association?.cursor?.collectionId]);

    return (
      <InputWrapper
        disabled={!!disabled}
        disabledType={disabledType}
        errorMessage={errorMessage}
        description={schema.description}
        label=""
        unlimitedHeight={true}
      >
        <div css={styles.container} data-testid="association-kpi">
          <ToolTip text={`(${count}) ${schema.title}`}>
            <span css={[styles.title, safeDisabled && styles.disabled]}>
              {!!associatedIcon && <Icon testid="associated-icon" name={associatedIcon} size={GAP.XL} fill={COLOR.NEUTRAL_1} />}
              <h3 css={[styles.titleText, safeDisabled && styles.disabled]}>{schema.title}</h3>
              {isLoading ? (
                <div css={styles.spinnerContainer}>{isLoading && <Spinner />}</div>
              ) : (
                <span css={[styles.counter, safeDisabled && styles.disabled]}>
                  : <span data-testid="kpi-value">{count}</span>
                </span>
              )}
              <InfoForLabel label={schema.title} value={schema?.info} />
            </span>
          </ToolTip>
          {(!disableLink || !disableUnlink || !disableCreateNew) && (
            <div css={styles.toolbar}>
              <div css={styles.toolbarItem}>
                <CTA
                  disabled={disabled}
                  icon="zoom_out_map"
                  tooltip="Open & Edit"
                  onClick={onEdit}
                  type={CTAType.LINK}
                  size={ViewModel.CTASize.LARGE}
                  testid="kpi-cta"
                />
              </div>
            </div>
          )}
        </div>
        {(!!meta?.add?.length || !!meta?.remove?.length) && (
          <div>
            <AssociationPending schema={schema} disabled={disabled} meta={meta} recordMap={recordMap} onCancelLink={onLink} onCancelUnlink={onUnlink} />
          </div>
        )}
      </InputWrapper>
    );
  }
);

AssociationKPI.displayName = 'AssociationKPI';
