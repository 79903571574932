import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, GAP, INPUT_HEIGHT } from '@constants';

export const styles = {
  inputContainer: css`
    display: flex;
    align-items: center;
    min-height: ${INPUT_HEIGHT};
    gap: ${GAP.XS};
    width: 100%;
  `,
  switchElement: css`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${GAP.S};
  `,
  root: css`
    all: unset;
    box-sizing: border-box;
    width: 40px;
    min-width: 40px;
    height: 24px;
    background-color: ${COLOR.BASE_BACKGROUND};
    border-radius: 100px;
    border: 2px solid;
    cursor: pointer;

    &[data-state='checked'] {
      background-color: ${COLOR.BRAND_1};

      &:disabled {
        background-color: ${COLOR.NEUTRAL_3} !important;
        border-color: ${COLOR.NEUTRAL_3} !important;
        cursor: not-allowed;
      }
      .switch-thumb-off {
        display: none;
      }
    }

    span {
      z-index: 1;
      display: block;
      width: 16px;
      height: 16px;
      background-color: ${COLOR.BRAND_1};
      border-radius: 100%;
      transition: transform 100ms;
      transform: translate(3px);
      will-change: transform;

      &[data-state='checked'] {
        transform: translate(19px);
        background-color: ${COLOR.BASE_BACKGROUND};
      }
    }

    :hover {
      border-color: ${COLOR.BRAND_1};
    }

    :focus {
      border-color: ${COLOR.BE_2};
    }
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  flexRowIndeterminate: css`
    justify-content: center;
  `,
  thumbIndeterminate: css`
    transform: translate(0) !important;
  `,
  label: css`
    color: ${COLOR.NEUTRAL_1};
    label {
      color: ${COLOR.NEUTRAL_1};
    }
    display: flex;
    flex-direction: column;

    .label-text {
      display: flex;
      align-items: center;
      gap: ${GAP.XXS};
    }

    span:not([data-selector]='info-icon') {
      font-size: ${FONT_SIZE.XS};
      color: ${COLOR.NEUTRAL_2};
    }
  `,
  labelLeft: css`
    * {
      text-align: end;
    }
  `,
  labelElement: css`
    color: ${COLOR.NEUTRAL_1};
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 3px;
  `
};
