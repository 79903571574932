import { memo, useMemo, useState } from 'react';

import { IconImage } from '@components/elements/Icon/renderIcon';
import { SearchInput } from '@components/elements/SearchInput';
import { ToolTip } from '@components/elements/Tooltip';
import { COLOR, FOREGROUND_COLOR } from '@constants';
import type { ParsedList } from '@cyferd/client-engine';
import { Translate, getClassnames, useTranslate } from '@cyferd/client-engine';

import { SeemlessButton } from '../SeemlessButton';
import { iconSize, styles } from './styles';

const prepareTerm = (term: string) => String(term).toLowerCase().replace(/\.|_/i, ' ');

export interface AdderOption {
  id: string;
  item: Pick<ParsedList['items'][0], 'title' | 'description' | 'image' | 'color'> & { longDescription?: string };
  group: string;
  groupId?: string;
}

export interface AdderProps {
  options: AdderOption[];
  columnWidth?: string;
  onSelect: (id: string) => void;
}

export const Adder = memo(({ options, columnWidth, onSelect }: AdderProps) => {
  const { translate } = useTranslate();
  const [search, setSearch] = useState<string>('');

  const isIncludedInSearch = (termList: string[]) => !!search.trim() && termList.some(i => prepareTerm(i).includes(prepareTerm(search)));

  const list = useMemo(
    () => options.reduce((total, curr) => ({ ...total, [curr.group]: [...(total[curr.group] || []), curr].flat() }), {} as { [key: string]: AdderOption[] }),
    [options]
  );

  return (
    <div data-testid="adder-container">
      <div>
        <div className={styles.searchContainer}>
          <div className={styles.searchInnerContainer}>
            <SearchInput value={search} onChange={setSearch} />
          </div>
        </div>
        <div className={styles.getGroupList(columnWidth)}>
          {Object.entries(list).map(([category, categoryList]) => (
            <div key={category}>
              <p className={styles.groupTitle}>
                <Translate>{category}</Translate>
              </p>
              <div className={styles.listContainer}>
                {categoryList.map((config, i) => {
                  const isActive = isIncludedInSearch([translate(config.item.title), config.id]);
                  return (
                    <SeemlessButton data-testid="adder-item" key={i} className={styles.templateName} onClick={() => onSelect(config.id)}>
                      <ToolTip text={config.item.longDescription}>
                        <div data-testid={`${config.id}-option`} className={getClassnames(styles.buttonContent, isActive && styles.templateNameActive)}>
                          <div className={styles.buttonInnerContainer}>
                            {!!config.item.image && (
                              <div className={styles.iconContainer} style={{ backgroundColor: COLOR[config.item.color] }}>
                                <IconImage title="" icon={config.item.image as any} iconProps={{ fill: FOREGROUND_COLOR[config.item.color], size: iconSize }} />
                              </div>
                            )}
                            <div>
                              <p
                                data-testid={`option-${String(isActive)}`}
                                className={getClassnames(
                                  styles.templateNameText,
                                  isActive && styles.templateNameTextActive,
                                  !!search && !isActive && styles.templateNameTextInactive
                                )}
                              >
                                <Translate>{config.item.title}</Translate>
                              </p>
                              {!!config.item.description && (
                                <p className={getClassnames(styles.description)}>
                                  <Translate>{config.item.description}</Translate>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </ToolTip>
                    </SeemlessButton>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
