import type { PropsWithChildren } from 'react';
import React, { memo, useCallback } from 'react';
import type { SchemaFormBaseProps } from '@cyferd/client-engine';
import { GeneralModel } from '@cyferd/client-engine';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext } from '@hello-pangea/dnd';
import { styles } from '../styles';
import { FieldsetContent } from '@components/elements/Fieldset';

const FieldSizeWrapper = memo(({ fieldSize, maxColumns, children }: PropsWithChildren<{ fieldSize: GeneralModel.FieldSize; maxColumns?: number }>) => {
  if (fieldSize !== GeneralModel.FieldSize.FULL) return <>{children}</>;
  return (
    <div data-testid="field-size-wrapper" data-field-size={fieldSize} css={styles.fieldSizeFull}>
      <FieldsetContent testid="fieldset-size-wrapper" maxColumns={maxColumns}>
        {children}
      </FieldsetContent>
    </div>
  );
});

export const useRenderFieldWrapper = ({
  setDragChange,
  getIds,
  dragChange,
  maxColumns
}: {
  setDragChange: (value: React.SetStateAction<number>) => void;
  getIds: Function;
  dragChange: number;
  maxColumns: number;
}): SchemaFormBaseProps['componentRecord']['renderFieldWrapper'] =>
  useCallback(
    ({ id, children, value, onChange, format, type, schema }) => {
      /* istanbul ignore next line | @todo */ const onDragEnd = (result: DropResult) => {
        if (!result?.destination) return;
        const source = result.source.index;
        const destination = result.destination.index;
        const removed = [...value.slice(0, source), ...value.slice(source + 1, value.length)];
        const delta = destination - source > 0 ? 1 : 0;
        onChange([...removed.slice(0, destination - delta), value[source], ...removed.slice(destination - delta, removed.length)]);
        setDragChange(Date.now());
      };
      return (
        <FieldSizeWrapper fieldSize={schema.metadata?.fieldSize} maxColumns={maxColumns}>
          {(() => {
            if (
              type === 'array' &&
              ![
                GeneralModel.JSONSchemaFormat.NUMERIC_RANGE,
                GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
                GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
                GeneralModel.JSONSchemaFormat.ASSOCIATION
              ].includes(format)
            ) {
              return (
                <DragDropContext key={dragChange} onDragEnd={onDragEnd}>
                  <div {...getIds(id, { nativeHtmlElement: true })} data-format={format} data-id={schema?.$id}>
                    {children}
                  </div>
                </DragDropContext>
              );
            }

            if (type === 'object') return children;
            return (
              <div {...getIds(id, { nativeHtmlElement: true })} data-format={format} data-id={schema?.$id}>
                {children}
              </div>
            );
          })()}
        </FieldSizeWrapper>
      );
    },
    [dragChange, getIds, maxColumns, setDragChange]
  );
