import { FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  outterContainer: css`
    width: 100%;
  `,
  container: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  titleContainer: css`
    display: flex;
  `,
  titleInnerContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
  `,
  title: css`
    flex: 1;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  titleSizeMedium: css`
    font-size: ${FONT_SIZE.XXM};
  `,
  titleSizeLarge: css`
    font-size: ${FONT_SIZE.XL};
  `,
  titleAlignmentLeft: css`
    justify-content: flex-start;
  `,
  titleAlignmentCenter: css`
    justify-content: center;
  `,
  titleAlignmentRight: css`
    justify-content: flex-end;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    align-items: center;
  `,
  actionsPositionLeft: css`
    flex-direction: row-reverse;
  `,
  actionsPositionRight: css`
    flex-direction: row;
  `,
  textWithNoActions: css`
    width: 100%;
  `
};
