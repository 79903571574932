import { useCallback, useMemo, useState } from 'react';
import { IconImagePickerModal } from './components/IconImagePickerModal';

import { styles } from './styles';
import { isIcon } from '@utils';
import { COLOR, FONT_SIZE } from '@constants';
import type { IOptionMenu } from '../OptionMenu';
import { Image } from '../Image';
import type { IconKeys } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import { InputWrapper } from '../InputWrapper';
import { GeneralModel } from '@cyferd/client-engine';

export interface IconImagePickerProps {
  delay?: number;
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  expanded?: boolean;
  iconOnly?: boolean;
  id?: string;
  label: string;
  name?: string;
  onChange: (value: string) => void;
  onError?: () => void;
  optionList?: IOptionMenu['optionList'];
  required?: boolean;
  testid?: string;
  value?: string;
  info?: string;
  color?: GeneralModel.Color.ThemeColor;
  disabledType?: GeneralModel.DisabledType;
}

export const IconImagePicker = ({
  delay = 500,
  description,
  disabled,
  errorMessage,
  expanded = true,
  iconOnly,
  label,
  onChange,
  onError,
  optionList,
  required,
  testid = 'icon-picker',
  value,
  info,
  color,
  disabledType
}: IconImagePickerProps) => {
  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const safeDisabled = !!disabled && !isReadonly;
  const valueIsIcon = useMemo(() => !!value && isIcon(value), [value]);

  const [error, setError] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const onClear = () => onChange(undefined);

  const onImageError = useCallback(() => {
    onError?.();
    setError(true);
  }, [onError]);

  const renderImage = useCallback(() => {
    if (error) {
      return (
        <div data-testid="icon-picker-valueisnoticon">
          <Icon name="broken_image" size={expanded ? '70px' : FONT_SIZE.XXM} data-testid={`${expanded ? 'expanded' : 'collapsed'}-error-icon`} />
        </div>
      );
    }
    return (
      <div data-testid="icon-picker-valueisnoticon">
        <Image alt="selected-icon" onError={onImageError} src={value} />
      </div>
    );
  }, [error, expanded, onImageError, value]);

  const renderExpanded = useCallback(() => {
    if (valueIsIcon) {
      return <Icon name={value as IconKeys} size={FONT_SIZE.XXM} testid="expanded-icon" fill={safeDisabled ? COLOR.NEUTRAL_1_5 : COLOR.NEUTRAL_1} />;
    }
    return (
      <div css={styles.expandedImage} data-testid="expanded-image">
        {renderImage()}
      </div>
    );
  }, [valueIsIcon, renderImage, value, safeDisabled]);

  const renderCollapsed = useCallback(() => {
    if (valueIsIcon) {
      return <Icon name={value as IconKeys} size={FONT_SIZE.XXM} testid="not-expanded-icon" fill={safeDisabled ? COLOR.NEUTRAL_1_5 : COLOR.NEUTRAL_1} />;
    }
    return (
      <div css={styles.collapsedImage} data-testid="collapsed-image">
        {renderImage()}
      </div>
    );
  }, [renderImage, safeDisabled, value, valueIsIcon]);

  return (
    <div data-testid="icon-image-container">
      <InputWrapper
        description={description}
        disabled={!!disabled}
        disabledType={disabledType}
        errorMessage={errorMessage}
        label={label}
        optionList={optionList}
        required={required}
        testid={testid}
        unlimitedHeight={true}
        value={value}
        info={info}
        color={color}
        onClear={!!value && !disabled && onClear}
        showPlaceholderLine={true}
      >
        <div css={[styles.container, !!disabled && styles.disabledContainer]}>
          <div css={styles.inputContent} onClick={!disabled ? toggleModal : undefined} data-testid="input-element">
            {!!value && expanded && (
              <div css={[styles.expandedContainer, isReadonly && /* istanbul ignore next */ styles.readonlyContent]}>{renderExpanded()}</div>
            )}
            {!!value && !expanded && (
              <div css={[styles.collapsedContainer, isReadonly && /* istanbul ignore next */ styles.readonlyContent]}>{renderCollapsed()}</div>
            )}
          </div>
        </div>
      </InputWrapper>
      <IconImagePickerModal
        delay={delay}
        iconOnly={iconOnly}
        label={label}
        open={isOpen}
        onChange={onChange}
        onClose={toggleModal}
        value={value}
        description={description}
      />
    </div>
  );
};
