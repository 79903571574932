import { getClassnames } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { styles } from './styles';

export interface EmptyStateProps {
  message?: string;
  compact?: boolean;
}

export const EmptyState = ({ message = 'No Data', compact }: EmptyStateProps) => {
  const { getTestIdProps } = useTestingHelper('empty-state');
  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <p className={getClassnames(styles.text, compact && styles.compact)}>{message}</p>
    </div>
  );
};
