import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import type { Observable } from 'rxjs';
import type { CtaProps } from '../CTA';

export enum ChipType {
  DEFAULT = 'default',
  DRODPOWN = 'dropdown'
}

export type ChipOption = GeneralModel.InputOption & { onClick: () => void | Observable<ApiModel.APIAction> };

export interface ChipProps {
  id?: string;
  testid?: string;
  type?: ChipType;
  title: string;
  description?: string;
  color?: GeneralModel.Color.ThemeColor;
  icon?: GeneralModel.IconName;
  active: boolean;
  activeValues?: (string | number)[];
  options?: ChipOption[];
  disabled?: boolean;
  disabledType?: GeneralModel.DisabledType;
  showCheck?: boolean;
  compact?: boolean;
  onClick: CtaProps['onClick'];
}
