import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { decodeQueryString } from '@cyferd/client-engine';

export const useUrlBuilder = () => {
  const { search, pathname } = useLocation();

  return useMemo(() => {
    const queryParams = decodeQueryString(search);
    return { pathname: pathname.slice(1), queryParams };
  }, [pathname, search]);
};
