import type { ReactNode } from 'react';
import React, { Children, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import {
  ApiModel,
  ErrorBoundary,
  ViewModel,
  createUUID,
  getParsedActionChildren,
  isObject,
  swallowError,
  useGeneralFetch,
  useHasFiltersApplied,
  useOnEntityChange,
  useParsedCursors,
  useRecordActionsParser,
  useSearchFetch,
  useTranslate
} from '@cyferd/client-engine';

import { CardList } from '@components/elements/CardList';
import { FiltersModal } from '@components/elements/FiltersModal';
import { CONTAINER_WIDTH, TRANS } from '@constants';
import { useGetElementSize } from '@utils';
import { CyText } from '../CyText';
import { CyWrapperContext } from '../CyWrapper';
import { SmartTable } from './SmartTable';
import { styles } from './styles';
import { DisplaySettingsModal } from '@components/elements/DisplaySettingsModal';
import { Grid } from '@components/elements/Grid';
import { QuickFilters } from '@components/elements/QuickFilters';
import { createPortal } from 'react-dom';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { SearchInput } from '@components/elements/SearchInput';
import { Header } from '@components/elements/Header';
import { ProgressBar } from '@components/elements/ProgressBar';
import { Spinner } from '@components/elements/Spinner';
import { List } from '@components/elements/List';
import { Pagination } from '@components/elements/Pagination';
import { BBContainer } from '@components/elements/BBContainer';
import { ModalContainerContext } from '@components/elements/Modal/ModalContainerContext';
import { CyReusableActionEffect } from '../CyEffect';

export interface CyListContextValue {
  controlsPortalRef?: HTMLDivElement;
  controlCTASize?: ViewModel.CTASize;
  renderCustomListType?: (value: ViewModel.CyListProps['value']) => ReactNode;
}

export const CyListContext = createContext<CyListContextValue>({
  controlsPortalRef: undefined,
  controlCTASize: undefined,
  renderCustomListType: undefined
});

export const CyList = ({
  id,
  componentName,
  title,
  value,
  type: externalType,
  actionListChildren,
  headerListChildren,
  initialFetchCriteria,
  fixedFilter,
  paginationHidden,
  searchStringHidden,
  orderHidden,
  advancedFiltersHidden,
  quickFiltersHidden,
  refreshHidden,
  listActionsHidden,
  recordActionsHidden,
  typeSelectorHidden,
  avoidFetch,
  children,
  density,
  onFetch,
  onClickItem,
  onChangeType,
  effectChildren,
  framed = true,
  fitToPage,
  createNewHidden,
  rowColor$
}: ViewModel.CyListProps) => {
  const { translate } = useTranslate();
  const testid = 'cyList';
  const { ref, width } = useGetElementSize();
  const { useFetchCollectionModel, useOnRefresh, renderSchemaForm, useParsers, useAction } = useContext(CyWrapperContext);
  const onFormModal = useAction('dispatchFormModal');
  const [type, setType] = useState<ViewModel.CyListType>(externalType);
  const { parseData } = useParsers();
  const cyListContextValue = useContext(CyListContext);
  const safeType = useMemo(() => (Object.values(ViewModel.CyListType).includes(type) ? type : ViewModel.CyListType.TABLE), [type]);
  const hasFiltersApplied = useHasFiltersApplied(value?.query?.cursor);

  const refreshCompoId = useMemo(createUUID, []);

  const modalContainerContext = useContext(ModalContainerContext);
  const safeFramed = modalContainerContext?.instance ? false : framed;

  const quickFilters = useMemo(
    () => value?.query?.quickFilters?.filter(f => isObject(f) && !parseData(f.hidden)) as GeneralModel.FetchCriteria['quickFilters'],
    [parseData, value?.query?.quickFilters]
  );

  const onInternalChangeType = useCallback(
    (event: ViewModel.CyListType) => {
      if (typeof onChangeType === 'function') onChangeType(event).pipe(swallowError()).subscribe();
      else setType(event);
    },
    [onChangeType]
  );

  const fetchCollectionModel = useFetchCollectionModel();

  const { onGeneralFetch, canFetch, isLoading } = useGeneralFetch({ onFetch, fixedCursor: { fixedFilter }, avoidFetch });
  const { onRefresh, onSearch, onInitialFetch, canSearch, searchString, setSearchString } = useSearchFetch({
    onFetch: onGeneralFetch,
    isLoading,
    value,
    canFetch,
    initialFetchCriteria
  });

  const onCreateNew = useCallback(
    () =>
      onFormModal({
        type: ApiModel.ApiEntity.ENTITY,
        title: TRANS.client.buttons.createNew,
        formType: ViewModel.CyFormType.STEPPER,
        collectionId: value?.query?.cursor?.collectionId,
        reusableActionOnSuccess: refreshCompoId
      }),
    [onFormModal, refreshCompoId, value?.query?.cursor?.collectionId]
  );

  const isFirstLoad = isLoading && !value;

  const safeOnClickItem = isLoading ? undefined : onClickItem;

  const shouldRenderHeader = !!(title || headerListChildren?.length || !typeSelectorHidden || (canFetch && (!searchStringHidden || !advancedFiltersHidden)));
  const recordActions = !recordActionsHidden && value?.query?.recordActions;

  useEffect(() => {
    setType(externalType);
  }, [externalType]);

  useOnRefresh({ id, componentName }, onRefresh);

  const { initialCursor, currentCursor } = useParsedCursors({ initialFetchCriteria, fixedFilter, value });
  useOnEntityChange(initialCursor, currentCursor, onInitialFetch);

  const parsedHeaderListChildren = useMemo(() => getParsedActionChildren(headerListChildren, value), [headerListChildren, value]);
  const listActions = !listActionsHidden && getParsedActionChildren(value?.query?.listActions);
  const parseListActions = useRecordActionsParser(listActions);
  const parsedListActions = useMemo(() => parseListActions({ item: value, index: null }), [parseListActions, value]);
  const completeParsedActionChildren = useMemo(
    () =>
      [
        ...parsedListActions,
        ...parsedHeaderListChildren,
        createNewHidden === false /** hidden on explicit false */ &&
          value?.query?.cursor?.collectionId &&
          ({
            type: ViewModel.CTAType.PRIMARY,
            label: TRANS.client.buttons.createNew,
            icon: 'add',
            important: true,
            onClick: onCreateNew
          } as any)
      ]
        .filter(Boolean)
        .map(item => ({
          testid: `${testid}-header-action-btn-${item.label}`,
          label: item.label,
          type: item.type || CTAType.TERTIARY,
          disabled: isLoading || !!item.disabled,
          onClick: event => item.onClick?.(value, event),
          image: item.icon,
          status: (item as any).status,
          tooltip: item.helperText,
          color: item.color,
          important: item.important,
          size: (item as any).size || ViewModel.CTASize.SMALL
        })),
    [createNewHidden, isLoading, onCreateNew, parsedHeaderListChildren, parsedListActions, value]
  );

  const controls = (
    <div css={styles.controls} data-selector="controls">
      <div css={styles.headerActionList}>
        <OptionMenu maxImportant={4} defaultBtnType={ViewModel.CTAType.LINK} optionList={completeParsedActionChildren} />
      </div>
      <div css={styles.headerSearchContainer}>
        {canSearch && (
          <>
            {!searchStringHidden && (
              <div css={styles.searchInputContainer}>
                <SearchInput
                  testid={`${testid}-search-input`}
                  value={searchString}
                  searchDelay={500}
                  onChange={setSearchString}
                  onClick={onSearch}
                  compact={width <= CONTAINER_WIDTH.XS}
                  ctaProps={cyListContextValue?.controlCTASize ? { size: cyListContextValue?.controlCTASize } : undefined}
                />
              </div>
            )}

            {!advancedFiltersHidden && (
              <div>
                <FiltersModal
                  entity={value?.query}
                  displaySorting={true}
                  fetchCollectionModel={fetchCollectionModel}
                  cursor={value?.query?.cursor}
                  onSubmit={onGeneralFetch}
                  testid={`${testid}-advanced-filters`}
                  renderSchemaForm={renderSchemaForm}
                  disabled={isLoading}
                  ctaProps={cyListContextValue?.controlCTASize ? { size: cyListContextValue?.controlCTASize } : undefined}
                />
              </div>
            )}
          </>
        )}
        {!typeSelectorHidden && (
          <div>
            <DisplaySettingsModal type={safeType} onChangeType={onInternalChangeType} disabled={isLoading} />
          </div>
        )}
        {!refreshHidden && !!canSearch && (
          <div>
            <CTA
              onClick={onRefresh}
              testid={`${testid}-refresh-btn`}
              icon="refresh"
              disabled={isLoading}
              hideLoading={true}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              tooltip={TRANS.client.buttons.refresh}
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <BBContainer framed={safeFramed} fitToPage={fitToPage}>
      <div id={id} css={styles.container} data-testid={testid} ref={ref}>
        <div data-testid="effects">
          {effectChildren}
          <CyReusableActionEffect componentName={refreshCompoId} id={refreshCompoId} onPlay={onRefresh} />
        </div>
        <ErrorBoundary>
          {!!shouldRenderHeader && (
            <div css={[!!value?.list?.length && styles.headerContainer]}>
              <Header
                testid="cyList-header"
                title={title}
                titleControls={
                  !quickFiltersHidden &&
                  !!quickFilters?.length && (
                    <div>
                      <QuickFilters config={quickFilters} cursor={value?.query?.cursor} disabled={isLoading} onFetch={onGeneralFetch} />
                    </div>
                  )
                }
                /** portal needed to add these controls to association titles */
                controls={cyListContextValue?.controlsPortalRef ? (createPortal(controls, cyListContextValue.controlsPortalRef) as any) : controls}
              />
            </div>
          )}
        </ErrorBoundary>
        {!!isLoading && !!value && (
          <div style={{ marginTop: -10, marginBottom: 5 }}>
            <ProgressBar color="BRAND_2" size={5} alt={true} />
          </div>
        )}
        <ErrorBoundary>
          <div css={styles.content}>
            {isFirstLoad && (
              <div css={styles.spinnerContainer}>
                <Spinner testid={`${testid}-spinner`} />
              </div>
            )}
            {!isLoading && !value?.list?.length && (
              <span data-testid={`${testid}-empty-list`}>
                {Children.count(children) ? (
                  children
                ) : (
                  <div css={styles.spinnerContainer} data-testid="empty-state">
                    <CyText
                      content={translate(
                        !hasFiltersApplied ? TRANS.client.emptyStates.cyList : /* istanbul ignore next */ TRANS.client.emptyStates.cyListWithFilters
                      )}
                      titleAlignment={ViewModel.Alignment.CENTER}
                    />
                  </div>
                )}
              </span>
            )}
            {!isFirstLoad &&
              !!value?.list?.length &&
              (() => {
                if (typeof cyListContextValue?.renderCustomListType === 'function') return cyListContextValue.renderCustomListType(value);
                switch (safeType) {
                  case ViewModel.CyListType.LIST:
                    return (
                      <List
                        disabled={isLoading}
                        value={value}
                        actionListChildren={actionListChildren}
                        recordActions={recordActions}
                        density={density}
                        onClickItem={safeOnClickItem}
                        testid={`${testid}-list`}
                      />
                    );
                  case ViewModel.CyListType.CARD:
                    return (
                      <CardList
                        disabled={isLoading}
                        value={value}
                        actionListChildren={actionListChildren}
                        recordActions={recordActions}
                        onClickItem={safeOnClickItem}
                        testid={`${testid}-card`}
                      />
                    );
                  case ViewModel.CyListType.GRID:
                    return (
                      <Grid
                        disabled={isLoading}
                        value={value}
                        actionListChildren={actionListChildren}
                        recordActions={recordActions}
                        density={density}
                        onClickItem={safeOnClickItem}
                        testid={`${testid}-grid`}
                      />
                    );
                  case ViewModel.CyListType.TABLE:
                  default:
                    return (
                      <SmartTable
                        value={value}
                        onFetch={onGeneralFetch}
                        isLoading={isLoading}
                        onClickItem={safeOnClickItem}
                        rowColor={rowColor$}
                        actionListChildren={actionListChildren}
                        recordActions={recordActions}
                        testid={testid}
                        orderHidden={orderHidden}
                      />
                    );
                }
              })()}
          </div>
        </ErrorBoundary>
        <ErrorBoundary>
          {!!canFetch && !paginationHidden && (
            <Pagination
              cursor={value?.query?.cursor}
              count={value?.list?.length || 0}
              onFetch={onGeneralFetch}
              disabled={isLoading}
              testid={`${testid}-pagination`}
            />
          )}
        </ErrorBoundary>
      </div>
    </BBContainer>
  );
};

CyList.displayName = ViewModel.DisplayName.CY_LIST;
