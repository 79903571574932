import { FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
  `,
  item: css`
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${GAP.XS};
    overflow: hidden;
    padding: ${GAP.XS};
  `,
  label: css`
    display: block;
    font-weight: ${FONT_WEIGHT.NORMAL};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
  `
};
