import React, { useCallback, useMemo, useRef } from 'react';

import { GeneralModel } from '@cyferd/client-engine';

import { getStyle } from './styles';
import { findValueClosestIndex } from './utils/findValueClosestIndex';

export interface SliderProps {
  testid?: string;
  multipleOf?: number;
  minimum?: number;
  maximum?: number;
  value?: number | number[];
  range?: boolean;
  disabled?: boolean;
  uniqueItems?: boolean;
  showSteps?: boolean;
  color?: GeneralModel.Color.ThemeColor;
  onChange?: (value: number | number[]) => void;
  disabledType?: GeneralModel.DisabledType;
}

export const Slider = ({
  testid = 'slider',
  minimum = 0,
  maximum = 100,
  multipleOf = 1,
  value,
  onChange,
  range,
  color,
  uniqueItems,
  showSteps,
  disabled,
  disabledType
}: SliderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const { styles, handleSize } = useMemo(() => getStyle({ color, isReadonly }), [color, isReadonly]);

  const values = useMemo(() => {
    if (!value && value !== 0) return range ? [minimum, maximum] : [minimum];
    return Array.isArray(value) ? value : [value];
  }, [value, minimum, maximum, range]);

  const stepDots = useMemo(
    (): number[] =>
      !multipleOf || !showSteps
        ? []
        : [minimum, ...Array.from({ length: (maximum - minimum) / multipleOf }, (_, i) => minimum + (i + 1) * multipleOf), maximum],
    [minimum, maximum, multipleOf, showSteps]
  );

  const getPercentageFromValue = useCallback((value: number) => ((value - minimum) / (maximum - minimum)) * 100, [minimum, maximum]);
  const getValueFromPercentage = useCallback((percentage: number) => (percentage / 100) * (maximum - minimum) + minimum, [minimum, maximum]);
  const getValidValue = useCallback(
    (value: number) => {
      if (value <= minimum) return minimum;
      if (value >= maximum) return maximum;
      const steppedValue = multipleOf ? minimum + Math.round((value - minimum) / multipleOf) * multipleOf : value;
      const limitedValue = Math.max(minimum, Math.min(maximum, isFinite(steppedValue) ? steppedValue : minimum));
      return limitedValue;
    },
    [minimum, maximum, multipleOf]
  );

  const getLimitedPosition = useCallback((position: number) => Math.min(Math.max(position, 0), 100), []);

  const getEventPositionValue = useCallback(
    ({ pageX }: React.MouseEvent) => {
      const { width, left } = containerRef.current.getBoundingClientRect();
      const offsetPercentage = (Math.max(pageX - left, 0) / width) * 100;
      return getValidValue(getValueFromPercentage(offsetPercentage));
    },
    [getValidValue, getValueFromPercentage]
  );

  const getValidValues = useCallback(
    (value: number, valueIndex: number) => {
      if (!range) return value;
      return uniqueItems && values.includes(value) ? values : [...values].map((v, i) => (i === valueIndex ? value : v)).sort((a, b) => a - b);
    },
    [values, range, uniqueItems]
  );

  const onDrag = useCallback(
    (event: React.MouseEvent, valueIndex: number) => {
      event.stopPropagation();
      /* istanbul ignore if */
      if (event.clientX <= 0) return;
      const value = getEventPositionValue(event);
      /* istanbul ignore else */
      if (onChange) onChange(getValidValues(value, valueIndex));
    },
    [getValidValues, getEventPositionValue, onChange]
  );

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation?.();
      const value = getEventPositionValue(event);
      /* istanbul ignore else */
      if (onChange) onChange(getValidValues(value, findValueClosestIndex(value, values)));
    },
    [getValidValues, getEventPositionValue, values, onChange]
  );

  const getHandlePositionStyle = useCallback(
    (value: number, index: number) => {
      const calcStyle = (formula: string) => `calc(${getLimitedPosition(getPercentageFromValue(getValidValue(value)))}% ${formula})`;
      if (!range) return calcStyle('');
      const offsetSize = `${handleSize / 2}px`;
      if (index === values?.length - 1) return calcStyle(`+ ${offsetSize}`);
      if (index === 0) return calcStyle(`- ${offsetSize}`);
      return calcStyle('');
    },
    [range, handleSize, values?.length, getLimitedPosition, getPercentageFromValue, getValidValue]
  );

  return (
    <div css={styles.container} ref={containerRef} onClick={!disabled ? onClick : null} data-testid={`${testid}-container`}>
      <div css={styles.rail}></div>
      <div css={[styles.valuesContainer, range && styles.valuesContainerRange]}>
        <div css={styles.handles}>
          {range ? (
            <div
              css={styles.track}
              style={{
                left: `calc(${getLimitedPosition(getPercentageFromValue(getValidValue(values.at(0))))}% - ${handleSize / 2}px)`,
                width: `calc(${getLimitedPosition(
                  getPercentageFromValue(getValidValue(values.at(-1))) - getPercentageFromValue(getValidValue(values.at(0)))
                )}% + ${handleSize}px)`
              }}
            />
          ) : (
            <div
              css={styles.track}
              style={{
                left: `calc(0% - ${handleSize / 4}px)`,
                width: `calc(${getLimitedPosition(getPercentageFromValue(getValidValue(values.at(0))))}% + ${handleSize / 2}px)`
              }}
            />
          )}
          {values.map((val, i) => (
            <div key={`handle-${i}`} css={[styles.handle, disabled && styles.handleDisabled]} style={{ left: getHandlePositionStyle(val, i) }}>
              <div
                css={styles.handleInner}
                draggable={true}
                role="slider"
                data-testid={`${testid}-handle-${i}`}
                aria-orientation="horizontal"
                aria-valuemin={minimum}
                aria-valuemax={maximum}
                aria-valuenow={val}
                onDrag={!disabled ? event => onDrag(event, i) : null}
              />
            </div>
          ))}
        </div>
        {showSteps && !!stepDots.length && (
          <div css={styles.dots}>
            {stepDots.map((value, i) => (
              <div key={`dot-${i}`} css={styles.dot} style={{ left: `${getPercentageFromValue(value)}%` }} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
