import type { ComponentProps } from 'react';
import React, { useCallback, useState } from 'react';

import { GeneralModel } from '@cyferd/client-engine';

import { SyntaxEditor } from '../SyntaxEditor';

export interface JSONSyntaxEditorProps extends Omit<Partial<ComponentProps<typeof SyntaxEditor>>, 'value' | 'onChange'> {
  value: object;
  disabled?: boolean;
  onChange: (newValue: object) => void;
  testid?: string;
  label: string;
  description?: string;
  required?: boolean;
  allowFileInput?: boolean;
  color?: GeneralModel.Color.ThemeColor;
}

export const JSONSyntaxEditor = (props: JSONSyntaxEditorProps) => {
  const [errorMessage, setErrorMessage] = useState<{ name: string; message: string; location: { column: number } }>(null);

  const onJSONChange = useCallback(
    (strJSON?: string) => {
      try {
        setErrorMessage(null);
        if (strJSON?.trim() === '') props.onChange(null);
        const parsedValue = JSON.parse(strJSON);
        props.onChange(parsedValue);
      } catch (error) {
        setErrorMessage(error as any);
      }
    },
    [props]
  );
  return (
    <SyntaxEditor
      {...props}
      value={String([null, undefined].includes(props.value) ? '' : JSON.stringify(props.value, null, 4))}
      language={GeneralModel.JSONSchemaFormat.JSON}
      onChange={onJSONChange}
      errorMessage={!!errorMessage ? 'Syntax error' : props.errorMessage}
    />
  );
};

JSONSyntaxEditor.displayName = 'JSONSyntaxEditor';
