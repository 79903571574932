import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    display: block;
  `,
  item: css`
    display: inline-block;
    margin-right: ${GAP.M};
    :last-child {
      margin-right: 0 !important;
    }
  `
};
