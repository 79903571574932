import type { PropsWithChildren } from 'react';
import { useContext } from 'react';

import { styles } from './styles';
import type { SerializedStyles } from '@emotion/react';
import { useGetElementSize } from '@utils';
import { ModalContainerContext } from '../Modal/ModalContainerContext';

export type BBContainerProps = PropsWithChildren<{
  framed?: boolean;
  fitToPage?: boolean;
  cssOverload?: SerializedStyles;
  testId?: string;
}>;

export const BBContainer = ({ framed, fitToPage, children, cssOverload, testId = 'bb-container' }: BBContainerProps) => {
  const { ref, top } = useGetElementSize();
  const modalContainerContext = useContext(ModalContainerContext);
  const isInsideModal = !!modalContainerContext?.instance;

  if (!framed && !fitToPage) return children;

  return (
    <div ref={ref} data-testid={testId} css={[!!framed && styles.framed, cssOverload, !!fitToPage && styles.fitToPage(top, isInsideModal)]}>
      {children}
    </div>
  );
};
