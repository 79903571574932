import { Fragment, useMemo } from 'react';
import { getFormulaElementsStyles, styles } from './styles';
import type { FormulaInputRow, InlineFormulaConfig } from '../resources';
import { getFormulaChipList, getInlineFormulaConfig } from '../resources';
import { FormulaInputType } from '../getFormulaInputType';
import { Translate } from '@cyferd/client-engine';

export interface InlineFormulaProps {
  value: any;
  maxDepth?: number;
  maxElements?: number;
  singleLine?: boolean;
  inputList: FormulaInputRow[];
}

export const InlineFormula = ({ value, maxDepth, maxElements, singleLine, inputList }: InlineFormulaProps) => {
  const inlineFormulaConfig = useMemo(() => getInlineFormulaConfig({ value, maxDepth, maxElements, inputList }), [inputList, maxDepth, maxElements, value]);
  if (!inlineFormulaConfig) return null;
  return (
    <div css={styles.container} data-testid="inline-formula">
      {!!singleLine && <div css={styles.ellipsis} />}
      <div css={[singleLine && styles.singleLineContainer]}>
        <div css={[singleLine && styles.singleLineContent]}>
          <InlineFormulaElement singleLine={singleLine} config={inlineFormulaConfig} />
        </div>
      </div>
    </div>
  );
};

const delimiterMap: Record<string, { open: string; close: string }> = {
  [FormulaInputType.FORMULA]: { open: '(', close: ')' },
  [FormulaInputType.OBJECT]: { open: '{', close: '}' },
  [FormulaInputType.ARRAY]: { open: '[', close: ']' }
};

const InlineFormulaElement = ({ config, singleLine }: { config: InlineFormulaConfig; singleLine: boolean }) => {
  const styles = useMemo(() => getFormulaElementsStyles(config, singleLine), [config, singleLine]);
  const valueIsObject = config.formulaInputRow.formulaType === FormulaInputType.OBJECT;
  const valueIsFormula = config.formulaInputRow.formulaType === FormulaInputType.FORMULA;
  const delimiterSet = delimiterMap[config.formulaInputRow.formulaType];
  const formulaChips = useMemo(() => valueIsFormula && getFormulaChipList(config.formulaInputRow.value), [valueIsFormula, config.formulaInputRow.value]);
  return (
    <span data-testid="inline-formula-element">
      <span css={styles.text}>
        <Translate>{config.formulaInputRow.label}</Translate>
      </span>
      {formulaChips?.map?.(chipProps => (
        <span key={chipProps.id} css={styles.chip}>
          <Translate>{chipProps.title}</Translate>
        </span>
      ))}
      {!!config.content.length && <span css={styles.text}>{delimiterSet.open}</span>}

      {config.content.map((c, index) => {
        return (
          <Fragment key={c.key}>
            {valueIsObject && (
              <span css={[styles.text, styles.key]}>
                <Translate>{c.key}</Translate>:{' '}
              </span>
            )}
            <InlineFormulaElement config={c} singleLine={singleLine} />
            {index !== config.content.length - 1 && <span css={[styles.text, styles.comma]}>,</span>}
          </Fragment>
        );
      })}
      {!!config.content.length && <span css={styles.text}>{delimiterSet.close}</span>}
    </span>
  );
};
