import { css } from '@emotion/react';

export const styles = {
  cyList: css`
    height: 100%;
  `,
  hiddenCylist: css`
    height: 0;
    width: 0;
    overflow: hidden;
  `
};
