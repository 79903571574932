import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { Translate } from '@cyferd/client-engine';
import type { Observable } from 'rxjs';
import { ChipList } from '../ChipList';
import { memo, useMemo } from 'react';
import { CHIP_HEIGHT } from '../Chip/styles';
import { styles } from './styles';
import { CompactSwitch } from '../CompactSwitch';
import { DropdownMenu } from '../DropdownMenu';
import { Icon } from '../Icon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { TRANS } from '@constants';
import { useGetElementSize } from '@utils';

export interface QuickFiltersProps {
  config: GeneralModel.FetchCriteria['quickFilters'];
  cursor?: GeneralModel.FetchCriteria;
  disabled?: boolean;
  onFetch?: (criteria: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>;
  testid?: string;
}

export const QuickFilters = memo(({ config, cursor, disabled, onFetch, testid = 'quick-filters' }: QuickFiltersProps) => {
  const quickFilters = useMemo(() => (Array.isArray(cursor?.quickFilters) ? cursor?.quickFilters : []), [cursor?.quickFilters]);
  const { ref: containerRef, width } = useGetElementSize();

  const chipList = useMemo(
    () => (
      <ChipList
        list={config.map(item => {
          const active = quickFilters.some(f => f?.id === item.id);
          return {
            id: item.id,
            title: item.name,
            description: item.description,
            color: item.color as GeneralModel.Color.ThemeColor,
            icon: item.icon as GeneralModel.IconName,
            disabled,
            showCheck: true,
            active,
            onClick: () => onFetch({ ...cursor, quickFilters: active ? quickFilters.filter(f => f?.id !== item.id) : [...quickFilters, item] })
          };
        })}
      />
    ),
    [config, cursor, disabled, onFetch, quickFilters]
  );
  const compact = useMemo(
    () => (
      <DropdownMenu
        horizontalResponsive={false}
        renderTrigger={({ onClick, ref }) => (
          <div ref={ref} css={styles.trigger} onClick={onClick} data-testid="quick-filters-dropdown">
            <div ref={containerRef}>
              <div css={styles.triggerContent}>
                <Icon name="filter_list" />
                <span css={styles.title}>
                  <Translate>{TRANS.client.buttons.quickFilters}</Translate>
                </span>
                {!!quickFilters?.length && <div css={styles.activeCount}>{quickFilters.length}</div>}
                <Icon name="keyboard_arrow_down" />
              </div>
            </div>
          </div>
        )}
      >
        <div css={styles.dropdown} style={{ minWidth: width }}>
          <PreventClickPropagation>{chipList}</PreventClickPropagation>
        </div>
      </DropdownMenu>
    ),
    [chipList, containerRef, quickFilters.length, width]
  );

  return (
    <div data-testid={testid}>
      <CompactSwitch maxHeight={CHIP_HEIGHT} compact={compact} expanded={chipList} />
    </div>
  );
});
