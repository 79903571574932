import React, { useCallback, useContext, useMemo, useState } from 'react';
import { EMPTY, map } from 'rxjs';

import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { ViewModel, actions, tapOnSuccess } from '@cyferd/client-engine';

import { CyList } from '../../../CyList';
import { CyModal } from '../../../CyModal';
import { CyWrapperContext } from '../../../CyWrapper';
import { styles } from './styles';
import { AssociationAdder } from '@components/elements/AssociationInput/components/AssociationAdder';
import { AssociationPending } from '@components/elements/AssociationInput/components/AssociationPending';
import { getAssociationBoundaries } from '@utils';
import { CTAType } from '@components/elements/CTA';
import { Layout } from '@components/elements/Layout';
import { InputErrorMesssage } from '@components/elements/InputErrorMessage';

export type EditionTab = 'linked' | 'unlinked';

export type AssociationModalProps = {
  schema: GeneralModel.JSONSchema;
  apiQuery: ApiModel.ApiValue['query'];
  meta: ApiModel.ApiRecordAssociationMeta;
  recordMap: Record<string, ApiModel.ApiRecord>;
  errorMessage?: string;
  componentName?: string;
  defaultFilter?: GeneralModel.FetchCriteria['filter'];
  fixedFilter?: GeneralModel.FetchCriteria['fixedFilter'];
  record?: ApiModel.ApiRecord;
  initialTab: EditionTab;
  disableLink?: boolean;
  disableUnlink?: boolean;
  onClickItem: ViewModel.CyListProps['onClickItem'];
  onLink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
  onUnlink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
} & Partial<ViewModel.CyModalProps>;

export const AssociationModal = ({
  id,
  schema,
  apiQuery,
  meta,
  disableLink,
  disableUnlink,
  recordMap = {},
  onClickItem,
  onLink,
  onUnlink,
  componentName,
  defaultFilter,
  fixedFilter,
  errorMessage,
  initialTab,
  ...props
}: AssociationModalProps) => {
  const [selectedList, setSelectedList] = useState<ApiModel.ApiValue>(undefined);
  const [availableList, setAvailableList] = useState<ApiModel.ApiValue>(undefined);
  const { canLink, canUnlink } = getAssociationBoundaries(schema, meta);

  const tabList: ViewModel.LayoutTab[] = useMemo(
    () => [{ title: 'linked', displayName: schema?.title }, !disableLink && { title: 'unlinked', displayName: 'Available to add' }].filter(tab => !!tab),
    [disableLink, schema?.title]
  );
  const [tab, setTab] = useState<string>(initialTab);
  const { useListAssociation } = useContext(CyWrapperContext);

  const onListSelected = useListAssociation(apiQuery, schema);
  const onListAvailable = useListAssociation(apiQuery, schema, true);

  const onFetchSelected = useCallback(
    (fetchCriteria: GeneralModel.FetchCriteria) =>
      onListSelected(fetchCriteria).pipe(
        tapOnSuccess(setSelectedList),
        map(v => actions.dispatchSetData({ value: v }))
      ),
    [onListSelected]
  );
  const onFetchAvailable = useCallback(
    (fetchCriteria: GeneralModel.FetchCriteria) =>
      onListAvailable(fetchCriteria).pipe(
        tapOnSuccess(setAvailableList),
        map(v => actions.dispatchSetData({ value: v }))
      ),
    [onListAvailable]
  );
  const initialFetchCriteria = useMemo(
    () =>
      ({
        ...schema?.metadata?.association?.cursor,
        filter: defaultFilter,
        options: { limit: 5, ...schema?.metadata?.association?.cursor?.options }
      }) as GeneralModel.FetchCriteria,
    [defaultFilter, schema?.metadata?.association?.cursor]
  );

  const commonAction = useCallback(
    event =>
      ({
        helperText: 'Go to',
        icon: 'open_in_new',
        type: CTAType.LINK,
        important: true,
        onClick: () => onClickItem(event?.item)
      }) as ViewModel.CyListProps['actionListChildren'][0],
    [onClickItem]
  );

  const selectedActionList = useMemo(
    () =>
      [
        !disableUnlink &&
          (event => {
            const acc = meta?.remove?.find(a => a?.id === event?.item?.id);
            return {
              helperText: acc ? 'Undo' : 'Remove',
              icon: acc ? 'undo' : 'close',
              type: CTAType.LINK,
              important: true,
              onClick: () => onUnlink(acc || { id: event?.item?.id }, event?.item),
              disabled: acc ? !canLink : !canUnlink
            };
          }),
        commonAction
      ] as ViewModel.CyListProps['actionListChildren'],
    [canLink, canUnlink, commonAction, disableUnlink, meta?.remove, onUnlink]
  );

  const availableActionList = useMemo(
    () =>
      [
        event => {
          const acc = meta?.add?.find(a => a?.id === event?.item?.id);
          return {
            helperText: acc ? 'Undo' : 'Add',
            icon: acc ? 'undo' : 'add',
            type: CTAType.LINK,
            important: true,
            onClick: () => onLink({ id: event?.item?.id }, event?.item),
            disabled: acc ? !canUnlink : !canLink
          };
        },
        commonAction
      ] as ViewModel.CyListProps['actionListChildren'],
    [canLink, canUnlink, commonAction, meta?.add, onLink]
  );

  const onClickAvailable = useCallback(
    event => {
      const acc = meta?.add?.find(a => a?.id === event?.id);
      if (acc ? canUnlink : canLink) onLink({ id: event?.id }, event);
      return EMPTY;
    },
    [canLink, canUnlink, meta?.add, onLink]
  );

  return (
    <CyModal {...props} title={schema?.label} id="association-modal" type={ViewModel.ModalType.FULL_SCREEN}>
      <div css={styles.container}>
        <Layout type={ViewModel.LayoutType.TAB} tabList={tabList} activeTab={tab} onChangeTab={setTab as ViewModel.TriggerCallback<any>}>
          {/** selected */}
          <div>
            {!disableLink && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ minWidth: '300px' }}>
                  <AssociationAdder
                    meta={meta}
                    collectionId={schema?.metadata?.association?.cursor?.collectionId}
                    apiQuery={apiQuery}
                    associationKey={schema?.metadata?.association?.cursor?.associationKey}
                    defaultFilter={defaultFilter}
                    fixedFilter={fixedFilter}
                    disabled={!canLink}
                    onLink={onLink}
                  />
                </div>
              </div>
            )}
            <AssociationPending schema={schema} disabled={false} meta={meta} recordMap={recordMap} onCancelLink={onLink} onCancelUnlink={onUnlink} />
            <div css={styles.listContainer}>
              <CyList
                componentName={componentName}
                initialFetchCriteria={initialFetchCriteria}
                value={selectedList}
                onFetch={onFetchSelected}
                type={ViewModel.CyListType.TABLE}
                typeSelectorHidden={true}
                actionListChildren={selectedActionList}
                fixedFilter={fixedFilter}
                density={ViewModel.Density.M}
              />
            </div>
          </div>
          {/** available */}
          {!disableLink && (
            <div>
              <AssociationPending schema={schema} disabled={false} meta={meta} recordMap={recordMap} onCancelLink={onLink} onCancelUnlink={onUnlink} />
              <div css={styles.listContainer}>
                <CyList
                  componentName={componentName}
                  initialFetchCriteria={initialFetchCriteria}
                  value={availableList}
                  onFetch={onFetchAvailable}
                  onClickItem={onClickAvailable}
                  type={ViewModel.CyListType.TABLE}
                  typeSelectorHidden={true}
                  actionListChildren={availableActionList}
                  fixedFilter={fixedFilter}
                  density={ViewModel.Density.M}
                />
              </div>
            </div>
          )}
        </Layout>
        <InputErrorMesssage id={id} message={errorMessage} />
      </div>
    </CyModal>
  );
};
