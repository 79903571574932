import type { ComponentProps, PropsWithChildren } from 'react';
import React, { memo } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { Translate, noop } from '@cyferd/client-engine';

import { InfoForLabel } from '../InfoForLabel';
import { Collapsible } from '../Collapsible';
import type { IOptionMenu } from '../OptionMenu';
import { OptionMenu } from '../OptionMenu';
import { columnBreakpoint, styles } from './styles';
import { IconImage } from '../Icon/renderIcon';
import { FONT_SIZE } from '@constants';

export type FieldsetProps = PropsWithChildren<{
  id?: string;
  title?: string;
  description?: string;
  testid?: string;
  startsCollapsed?: boolean;
  isCollapsible?: boolean;
  isDetailGroup: boolean;
  maxColumns?: number;
  image?: string;
  optionList?: IOptionMenu['optionList'];
  optionMenuOverride?: Partial<ComponentProps<typeof OptionMenu>>;
  empty?: boolean;
  info?: string;
  color?: GeneralModel.Color.ThemeColor;
}>;

export const Fieldset = ({
  id,
  title,
  description,
  children,
  testid,
  startsCollapsed,
  maxColumns,
  isCollapsible,
  isDetailGroup,
  image,
  optionList,
  optionMenuOverride,
  empty,
  info,
  color
}: FieldsetProps) => {
  const safeTitle = isDetailGroup ? String(title) : title;

  return (
    <div css={[isDetailGroup && styles.detailGroupContainer(color)]} data-id={id}>
      <Collapsible
        open={!startsCollapsed}
        renderHeader={(onToggle, toggleButton, isOpen) => {
          return (
            <div css={[(!!title || !!description) && isOpen && !empty && styles.headerContainer]} data-selector={`legend-container${isOpen ? '-open' : ''}`}>
              <div css={styles.titleContainer}>
                <span css={styles.legendContainer}>
                  {!!title && (
                    <legend onClick={isCollapsible || startsCollapsed ? onToggle : noop} css={[styles.legend, isCollapsible && styles.isCollapsible]}>
                      <span>{(isCollapsible || startsCollapsed) && toggleButton}</span>
                      {!!image && (
                        <IconImage
                          title=""
                          icon={image}
                          iconProps={{ size: FONT_SIZE.XM }}
                          imageProps={{ css: { width: 24, height: 24, objectFit: 'cover', borderRadius: '100%' } }}
                        />
                      )}
                      <Translate>{safeTitle}</Translate>
                    </legend>
                  )}
                  <InfoForLabel label={title} value={info} />
                </span>
                <OptionMenu optionList={optionList} {...optionMenuOverride} />
              </div>
              {!!description && (
                <p data-selector="description" css={styles.description}>
                  <Translate>{description}</Translate>
                </p>
              )}
            </div>
          );
        }}
      >
        <FieldsetContent maxColumns={maxColumns} testid={testid}>
          {children}
        </FieldsetContent>
      </Collapsible>
    </div>
  );
};

Fieldset.displayName = 'Fieldset';

export const FieldsetContent = memo(({ maxColumns, testid, children }: Pick<FieldsetProps, 'maxColumns' | 'testid' | 'children'>) => {
  return (
    <fieldset
      css={[
        styles.container,
        !!maxColumns &&
          typeof maxColumns === 'number' && {
            gridTemplateColumns: `repeat(auto-fit, minmax(max(${columnBreakpoint}px, (100% - (${maxColumns} - 1) * 20px) / ${maxColumns}), 1fr))`
          }
      ]}
      data-testid={testid || 'fieldset'}
    >
      {children}
    </fieldset>
  );
});
