import { css } from '@emotion/react';

import { GAP } from '@constants';

export const styles = {
  image: css`
    margin: 0 auto ${GAP.L} auto;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  constrain: css`
    max-width: fit-content;
    margin: auto;
  `,
  ratio: {
    '16/9': css`
      aspect-ratio: 16/9;
    `,
    '9/16': css`
      aspect-ratio: 9/16;
    `,
    '1/1': css`
      aspect-ratio: 1/1;
    `,
    '3/4': css`
      aspect-ratio: 3/4;
    `,
    '4/3': css`
      aspect-ratio: 4/3;
    `
  }
};
