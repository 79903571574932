import { COLOR, GAP, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex: 1;
    align-items: center;
  `,
  form: css`
    display: flex;
    width: 0px;
    overflow: hidden;
    transition: width ${TRANSITION_SPEED};
    align-items: center;
    gap: ${GAP.XS};
    border-width: 1px;
    border-style: solid;
    background-color: ${COLOR.NEUTRAL_9};
    border-color: ${COLOR.NEUTRAL_9};
    border-radius: 80px;
    padding: 0 ${GAP.M};
    opacity: 0;
  `,
  expandedForm: css`
    opacity: 1;
    width: 100%;
  `,
  alternativeForm: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    border-color: ${COLOR.NEUTRAL_3};
  `,
  hasFocus: css`
    border-color: ${COLOR.BRAND_1}!important;
  `,
  searchInput: css`
    width: 100%;
    border: 0;
    height: 28px;

    :focus {
      outline: none;
    }
  `,
  expandContainer: css`
    position: absolute;
  `,
  inputContainer: css`
    width: 100%;
    display: flex;
    align-items: center;
    input {
      color: ${COLOR.NEUTRAL_1};
    }
  `,
  spinnerContainer: css`
    width: 18px;
    height: 18px;
  `
};
