import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, GAP } from '@constants';

export const styles = {
  mainContainer: css`
    display: flex;
    align-items: center;
    margin: ${GAP.M} 0 ${GAP.S} 0;
    justify-content: space-between;
    flex-wrap: wrap;
  `,
  leftContainer: css`
    cursor: pointer;
    color: ${COLOR.NEUTRAL_2};
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
    p {
      font-size: ${FONT_SIZE.XS};
    }
    [data-selector='input-border'] {
      border: 0;
      margin-top: -${GAP.XS};
    }
  `,
  rightContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      &:disabled {
        span {
          fill: ${COLOR.NEUTRAL_3};
          color: ${COLOR.NEUTRAL_3};
        }
      }
    }
  `,
  text: css`
    font-size: ${FONT_SIZE.XS};
    padding: 0 ${GAP.S};
  `,
  hidden: css`
    visibility: hidden;
  `
};
