import { css } from '@emotion/react';

import { COLOR, RADIUS, SHADOW } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';

export const getStyle = ({ color, isReadonly }: { color: GeneralModel.Color.ThemeColor; isReadonly: boolean }) => {
  const safeColor = COLOR[color] ? color : 'BRAND_1';
  const bigHandle = 20;
  const smallHandle = 12;
  const handleSize = isReadonly ? smallHandle : bigHandle;

  const railTrackStyle = css`
    width: 100%;
    height: 6px;
    border-radius: ${RADIUS.S};
    position: absolute;
    top: ${Math.floor(handleSize * 0.4)}px;
  `;

  return {
    handleSize,
    styles: {
      container: css`
        position: relative;
      `,
      valuesContainer: css`
        position: relative;
        width: 100%;
        margin: 0 auto;
        height: ${handleSize}px;
      `,
      valuesContainerRange: css`
        width: calc(100% - ${handleSize}px);
      `,
      rail: css`
        ${railTrackStyle}
        background-color: ${COLOR.NEUTRAL_3_5};
        z-index: 0;
      `,
      railRange: css`
        margin: 0 -${handleSize / 2}px;
      `,
      track: css`
        ${railTrackStyle}
        z-index: 1;
        background-color: ${COLOR[safeColor]};
      `,
      handles: css`
        position: absolute;
        left: 0;
        right: 0;
        height: ${bigHandle}px;
        margin: 0 ${handleSize / 4}px;
      `,
      handle: css`
        transform: translateX(-50%);
        width: ${handleSize}px;
        height: ${handleSize}px;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        outline: 4px solid transparent;
        cursor: grab;
        overflow: hidden;
        box-shadow: ${SHADOW.XS};

        background-color: ${COLOR[safeColor]};

        &:hover {
          outline: 4px solid ${COLOR.NEUTRAL_4};
        }
      `,
      handleDisabled: css`
        cursor: not-allowed;
        &:hover {
          outline: none;
        }
      `,
      handleInner: css`
        width: 100%;
        height: 100%;
        opacity: 0;
      `,
      dots: css`
        margin-left: 4px;
        margin-right: 8px;
        position: absolute;
        left: 0;
        right: 0;
        top: ${Math.floor(handleSize * 0.4)}px;
      `,
      dot: css`
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        background-color: ${COLOR[safeColor]};
      `
    }
  };
};
