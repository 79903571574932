import type { SchemaFormBaseProps } from '@cyferd/client-engine';
import { Children, useCallback } from 'react';

import { styles } from '../styles';

// eslint-disable-next-line no-empty-pattern
export const useRenderArrayContent = ({}: Record<string, any>): SchemaFormBaseProps['componentRecord']['renderArrayContent'] =>
  useCallback(({ children, schema }) => {
    return !!Children.toArray(children)?.length && <div css={!schema?.metadata?.unlimitedHeight && styles.arrayContentLimited}>{children}</div>;
  }, []);
