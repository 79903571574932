import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    min-height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  btnRight: css`
    position: absolute;
    top: 50%;
    right: 30px;
    z-index: 1;
  `,
  btnLeft: css`
    position: absolute;
    top: 50%;
    left: 30px;
    z-index: 1;
  `,
  optionMenu: css`
    padding-top: ${GAP.M};
  `,
  description: css`
    display: flex;
    gap: ${GAP.XXS};
    margin-top: ${GAP.XXS};
    color: ${COLOR.NEUTRAL_1};
  `,
  tooltipTrigger: css`
    display: flex;
    cursor: pointer;
  `,
  tooltipContent: css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
  `
};
