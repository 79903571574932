import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { ChipList } from '@components/elements/ChipList';
import { styles } from './styles';
import { getAssociationBoundaries } from '@utils';

export interface AssociationPendingProps {
  schema: GeneralModel.JSONSchema;
  meta: ApiModel.ApiRecordAssociationMeta;
  recordMap: Record<string, ApiModel.ApiRecord>;
  disabled: boolean;
  disableLink?: boolean;
  disableUnlink?: boolean;
  onCancelLink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
  onCancelUnlink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
}

const getCommonChipProps = (record: ApiModel.ApiRecord) => ({
  title: record?.recordTitle,
  description: record?.recordDescription,
  color: record?.recordColor as GeneralModel.Color.ThemeColor,
  icon: record?.recordImage as GeneralModel.IconName,
  active: true,
  showCheck: false
});

export const AssociationPending = ({
  meta,
  schema,
  recordMap,
  disabled,
  onCancelLink,
  onCancelUnlink,
  disableLink,
  disableUnlink
}: AssociationPendingProps) => {
  const hasAdd = !!meta?.add?.length;
  const hasRemove = !!meta?.remove?.length;

  if (!hasAdd && !hasRemove) return null;

  const { canLink, canUnlink } = getAssociationBoundaries(schema, meta);

  return (
    <div css={styles.container} data-testid="acc-pending">
      {!!hasAdd && (
        <div css={styles.listContainer} data-testid="acc-pending-add">
          <h4 css={styles.title}>Add ({meta.add.length})</h4>
          <div css={styles.chipContainer}>
            <ChipList
              collapsible={true}
              list={meta.add.map(acc => {
                const record = recordMap?.[acc?.id];
                return {
                  id: acc?.id,
                  ...getCommonChipProps(record),
                  title: getCommonChipProps(record).title || (acc as any)?.recordTitle,
                  disabled: disabled || !canUnlink || disableUnlink,
                  onClick: () => onCancelLink(acc, record)
                };
              })}
            />
          </div>
        </div>
      )}
      {!!hasRemove && (
        <div css={styles.listContainer} data-testid="acc-pending-remove">
          <h4 css={styles.title}>Remove ({meta.remove.length})</h4>
          <div css={styles.chipContainer}>
            <ChipList
              collapsible={true}
              list={meta.remove.map(acc => {
                const record = recordMap?.[acc?.id];
                return {
                  id: acc?.id,
                  ...getCommonChipProps(record),
                  color: 'NEUTRAL_3',
                  disabled: disabled || !canLink || disableLink,
                  onClick: () => onCancelUnlink(acc, record)
                };
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};
