import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  footer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${GAP.M};
  `,
  evaluatorContainer: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  fixedHeight: css`
    height: 500px;
    overflow: auto;
  `
};
