/* istanbul ignore file */

import { useCallback } from 'react';

import { actions } from '@cyferd/client-engine';
import { useRequest } from '@utils/useRequest';

export const useAction = <T extends keyof typeof actions>(actionCreatorName: T) => {
  const request = useRequest();

  return useCallback(
    (payload: Parameters<(typeof actions)[typeof actionCreatorName]>[0]) => request(actions[actionCreatorName](payload as any)),
    [actionCreatorName, request]
  );
};
