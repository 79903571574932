import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  contentContainer: css`
    margin: ${GAP.S};
  `,
  footer: css`
    display: flex;
  `
};
