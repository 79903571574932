/* istanbul ignore file */

import { geocode } from 'opencage-api-client';

import type { CyWrapperContextValue } from '@components/smart/CyWrapper/CyWrapper';

import { useOnRefresh } from '../../builder/state-mgmt/useOnRefresh';
import { useNavigateToRecord } from '../../builder/views/shared/SchemaForm/useNavigateToRecord';
import { getRenderSchemaForm } from '../getRenderSchemaForm';
import { useOnOpenEmailClient } from '../useOnOpenEmailClient';
import { useOnOpenExternalUrl } from '../useOnOpenExternalUrl';
import { useParsers } from '../useParsers';
import { useFetchCollectionModel, useListAssociation, useOnCreateAssociatedRecord, useOnDownloadFile, useOnFetchEntity, useOnGetEntity } from './actionHooks';
import { useAction } from './useAction';

export const cyWrapperContextValue: CyWrapperContextValue = {
  useFetchCollectionModel,
  useOnRefresh,
  useParsers,
  useOnOpenEmailClient,
  useOnOpenExternalUrl,
  renderSchemaForm: getRenderSchemaForm({ allowFormula: false }),
  useAction,
  useListAssociation,
  useOnFetchEntity,
  useOnGetEntity,
  useOnCreateAssociatedRecord,
  useOnDownloadFile,
  useNavigateToRecord,
  geocode
};
