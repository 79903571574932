import { css } from '@emotion/css';

export const getStyles = ({ height }: { height: number }) => ({
  container: css`
    [data-selector='form-content'] {
      display: flex;
      flex-direction: column;
      height: calc(${Math.floor(height)}px);
    }
    [data-selector='form-body'] {
      flex: 1;
      overflow: auto;
      
      [data-selector='form-content'] {
        display: block!important;
        height: auto!important;
      }
      [data-selector='form-body'] 
        overflow: initial!important;
      }
    }
  `
});
