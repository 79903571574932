import { COLOR, CONTAINER_WIDTH, FONT_SIZE, FONT_WEIGHT, GAP, INPUT_HEIGHT, RADIUS, SHADOW, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  dropdown: css`
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    display: grid;
    grid-gap: ${GAP.XXS};
    max-height: 275px;
    max-width: ${CONTAINER_WIDTH.M}PX;
    overflow: auto;
  `,
  option: css`
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${GAP.S};
    cursor: pointer;
    padding: ${GAP.S} ${GAP.M};
    transition: all ${TRANSITION_SPEED};
    border-radius: ${RADIUS.S};
    :hover {
      background-color: ${COLOR.NEUTRAL_6};
    }
    font-size: ${FONT_SIZE.S};
    font-size: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
    white-space: nowrap;
  `,
  dialCode: css`
    background-color: ${COLOR.TRANSPARENT};
    align-items: center;
    color: ${COLOR.NEUTRAL_2};
    cursor: pointer;
    width: 78px;
    height: ${INPUT_HEIGHT};
    display: flex;
    justify-content: center;
  `,
  readonlyDialCode: css`
    justify-content: flex-start;
  `,
  countryButton: css`
    display: flex;
    align-items: center;
  `,
  disabled: css`
    color: ${COLOR.NEUTRAL_1_5};
  `
};
