import type { GeneralModel } from '@cyferd/client-engine';
import { isFx, isObject } from '@cyferd/client-engine';

export enum FormulaInputType {
  FORMULA = 'fx',
  REFERENCE = 'reference',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  OBJECT = 'object',
  KEY = 'key',
  NULL = 'null'
}

export const colorPerType: Record<GeneralModel.JSONSchema['type'], GeneralModel.Color.ThemeColor> = {
  string: 'HC_15',
  number: 'HC_10',
  integer: 'HC_10',
  boolean: 'HC_4',
  object: 'HC_7',
  array: 'HC_5',
  null: 'HC_1',
  any: 'HC_6'
};

export const getFormulaInputType = (value: any): FormulaInputType => {
  const rawType = typeof value;
  if (rawType === 'string' && /{{(.*)}}/.test(value)) return FormulaInputType.REFERENCE;
  if ([FormulaInputType.STRING, FormulaInputType.NUMBER, FormulaInputType.BOOLEAN].includes(rawType as FormulaInputType)) return rawType as FormulaInputType;
  if (Array.isArray(value)) return FormulaInputType.ARRAY;
  if (!isObject(value)) return FormulaInputType.NULL;
  if (isFx(value)) return FormulaInputType.FORMULA;
  return FormulaInputType.OBJECT;
};
