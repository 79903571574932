import { css } from '@emotion/react';

import { GAP, RADIUS } from '@constants';

export const styles = {
  button: css`
    cursor: pointer;
    background: transparent;
    border-radius: ${RADIUS.S};
    overflow: hidden;
    img {
      height: 40px;
      width: 72px;
      margin: 0;
      object-position: center;
    }
  `,
  modal: css`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  `,
  image: css`
    width: auto;
    height: auto;
    max-height: 200px;
    max-width: 100%;
    object-fit: cover;
    border-radius: ${RADIUS.M};
    align-self: center;
  `,
  buttonContainer: css`
    align-self: flex-end;
    margin-top: ${GAP.L};
  `
};
