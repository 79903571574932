import type { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useCallback, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, finalize } from 'rxjs';

type UseEditModeParams = {
  editMode: ViewModel.CyTableProps['editMode'];
  fetch?: () => Observable<ApiModel.APIAction>;
  reset: () => void;
  refreshId: string;
};
export const useEditMode = ({ editMode: { enabled }, fetch, reset, refreshId }: UseEditModeParams) => {
  const [editing, setEditing] = useState<boolean>(false);
  const { onDispatchRefresh } = useCyActions();

  const refresh = useCallback(() => {
    if (editing) reset();
    setEditing(false);
    onDispatchRefresh({ componentNameList: [refreshId] });
  }, [editing, refreshId, onDispatchRefresh, reset]);

  const handleStartEditing = useCallback(() => {
    (fetch?.() || EMPTY).pipe(finalize(() => setEditing(prev => !prev))).subscribe();
  }, [fetch]);

  const toggleEditing = useCallback(() => {
    return editing ? refresh() : handleStartEditing();
  }, [handleStartEditing, refresh, editing]);

  const safeEditing = useMemo(() => enabled && editing, [editing, enabled]);

  return { editing: safeEditing, refresh, toggleEditing };
};
