import { css } from '@emotion/react';
import { COLOR, GAP, RADIUS, SHADOW } from '@constants';

export const styles = {
  container: css`
    position: fixed;
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    background-color: ${COLOR.TOP_BACKGROUND};
    z-index: 5;
    padding: ${GAP.M} ${GAP.S} ${GAP.XXXS} ${GAP.S};
    max-height: 270px;
    overflow: auto;
  `,
  optionContainer: css`
    margin-bottom: ${GAP.S};
  `
};
