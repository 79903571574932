import type { ApiModel, SchemaFormContextValue } from '@cyferd/client-engine';
import { GeneralModel, SchemaFormContext, noop, parseSchemaProperty } from '@cyferd/client-engine';
import { RichTextEditor } from '../RichTextEditor';
import type { ComponentProps } from 'react';
import { useContext, useMemo } from 'react';
import { CyWrapperContext } from '@components/smart/CyWrapper';

export type InfoBlockProps = {
  fixedValue: GeneralModel.JSONSchemaMetadata['fixedValue'];
  schema: GeneralModel.JSONSchema;
  path: string[];
} & Pick<ComponentProps<typeof RichTextEditor>, 'id' | 'color' | 'unlimitedHeight'>;

export const InfoBlock = ({ schema, fixedValue, path, id, color, unlimitedHeight }: InfoBlockProps) => {
  const { fullValue } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
  const { useParsers } = useContext(CyWrapperContext);
  const { parseData } = useParsers(schema?.metadata?.fixedValue);

  const value = useMemo(
    () => parseSchemaProperty(fixedValue, { value: undefined, fullItem: fullValue, path: path.join('.'), definition: schema, parseData }),
    [fixedValue, fullValue, path, schema, parseData]
  );

  return (
    <RichTextEditor
      id={id}
      color={color}
      unlimitedHeight={unlimitedHeight}
      disabled={true}
      disabledType={GeneralModel.DisabledType.VIEW_ONLY}
      value={value}
      onChange={noop}
    />
  );
};
