import { Translate } from '@cyferd/client-engine';
import { styles } from './styles';
import { Icon } from '../Icon';
import { COLOR, FONT_SIZE } from '../../../@constants';

export interface ErrorMessageProps {
  id?: string;
  message: string;
  testid?: string;
  showLine?: boolean;
}

export const InputErrorMesssage = ({ message, id, testid, showLine }: ErrorMessageProps) =>
  !!message ? (
    <div id={`${id}-error`} css={[styles.errorMessage, !!showLine && styles.line]} data-selector="error-message">
      <Icon name="error" fill={COLOR.HC_5} outlined={true} size={FONT_SIZE.XS} />
      <div data-testid={testid ? `${testid}-error-message` : 'error-message'}>
        <Translate>{message}</Translate>
      </div>
    </div>
  ) : null;
