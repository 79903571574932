import { COLOR, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';
import { getItemHeight } from '../Layout/styles';
import { ViewModel } from '@cyferd/client-engine';

export const styles = {
  framed: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    padding: ${GAP.M};
    height: 100%;
    border-radius: ${RADIUS.M};
  `,
  fitToPage: (top: number, isInsideModal: boolean) => `
    height: ${getItemHeight(ViewModel.LayoutHeightPreset.REMAINING, isInsideModal ? Math.max(top + 70, 0) : top)};
    overflow: auto;
  `
};
