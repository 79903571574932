import { useCallback, useEffect, useRef, useState } from 'react';

type useDropdownInteractionsProps = {
  autoFocus?: boolean;
  options?: any[];
  onSelect?: (index: number) => void;
  initialHighlightedIndex?: number;
};
export const useDropdownInteractions = ({ autoFocus, options, onSelect, initialHighlightedIndex }: useDropdownInteractionsProps) => {
  const [highlightedIndex, setHighlightedIndex] = useState(initialHighlightedIndex);
  const [isOpen, innerSetOpen] = useState(false);
  const selectRef = useRef(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        setHighlightedIndex(prevIndex => (prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'ArrowUp':
        setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'Enter':
        if (isOpen) {
          event.preventDefault();
          innerSetOpen(false);
          onSelect?.(highlightedIndex);
        } else {
          innerSetOpen(true);
          selectRef.current?.focus();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (autoFocus) {
      innerSetOpen(true);
      selectRef.current?.focus();
    }
  }, [autoFocus]);

  const setOpen = (value?: boolean) => {
    const innerValue = value ?? !isOpen;
    innerSetOpen(innerValue);
    if (innerValue === true) selectRef.current?.focus();
  };

  const handleSelectItem = useCallback(
    (index: number) => {
      innerSetOpen(false);
      onSelect?.(index);
    },
    [onSelect, innerSetOpen]
  );

  return {
    handleKeyDown,
    setOpen,
    selectRef,
    isOpen,
    highlightedIndex,
    handleSelectItem
  };
};
