import { FONT_SIZE, FONT_WEIGHT } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  readOnlyContainer: css`
    cursor: pointer;
    position: relative;
  `,
  header: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  footer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  title: css`
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `
};
