import { GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  formsContainer: css`
    display: flex;
    flex-direction: column;
    gap: calc(${GAP.M} / 2);
  `
};
