import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  inputContainer: css`
    height: auto;
  `,
  valueContainer: css`
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLOR.NEUTRAL_2};
    padding: ${GAP.XS} 0 ${GAP.XXS} 0;
    font-size: ${FONT_SIZE.S};
  `,
  rangeContainer: css`
    display: flex;
    color: ${COLOR.NEUTRAL_1_5};
    font-size: ${FONT_SIZE.XS};
    padding: ${GAP.XXS} 0;
  `,
  rangeLeft: css`
    margin: 0 auto 0 0;
  `,
  rangeRight: css`
    margin: 0 0 0 auto;
  `
};
