import { APP_WIDTH, COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

interface PropsStyle {
  shouldShow: boolean;
  top: number;
  left: number;
  arrowPosition: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right';
  color?: string;
}

export const MAX_WIDTH = '460px';
export const MAX_HEIGHT = '350px';

export const styles = {
  dialog: (props: PropsStyle) => css`
    @media (max-width: ${APP_WIDTH.S}px) {
      width: 100%;
    }
    max-width: ${MAX_WIDTH};
    max-height: ${MAX_HEIGHT};
    position: fixed;
    left: ${props.shouldShow ? props.left : 0}px;
    top: ${props.shouldShow ? props.top : 0}px;
    opacity: ${props.shouldShow ? 1 : 0};
    margin: 0;
    padding: 0;
    border: none;
    z-index: 9999;
    border-radius: 4px;
    box-sizing: border-box;
    text-shadow: none;
    ${props.arrowPosition === 'top-left' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-color: transparent transparent ${COLOR.NEUTRAL_4} transparent;
        position: absolute;
        border-radius: 1px;
        top: -19px;
        left: ${GAP.L};
        width: 0;
      }
      &::after {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-radius: 1px;
        border-color: transparent transparent ${COLOR[props.color] || COLOR.BRAND_1} transparent;
        position: absolute;
        top: -17.3px;
        left: ${GAP.L};
        width: 0;
      }
    `}

    ${props.arrowPosition === 'bottom-left' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-color: ${COLOR.NEUTRAL_4} transparent transparent transparent;
        position: absolute;
        border-radius: 1px;
        bottom: -19px;
        left: ${GAP.L};
        width: 0;
      }
      &::after {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-radius: 1px;
        border-color: ${COLOR[props.color] || COLOR.BRAND_1} transparent transparent transparent;
        position: absolute;
        bottom: -17.3px;
        left: ${GAP.L};
        width: 0;
      }
    `}
  
      ${props.arrowPosition === 'bottom-right' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-color: transparent transparent transparent ${COLOR.NEUTRAL_4};
        position: absolute;
        border-radius: 1px;
        bottom: ${GAP.S};
        right: -19.5px;
        width: 0;
      }
      &::after {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-radius: 1px;
        border-color: transparent transparent transparent ${COLOR[props.color] || COLOR.BRAND_1};
        position: absolute;
        bottom: ${GAP.S};
        right: -18px;
        width: 0;
      }
    `}
  
      ${props.arrowPosition === 'top-right' &&
    css`
      &::before {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-color: transparent transparent transparent ${COLOR.NEUTRAL_4};
        position: absolute;
        border-radius: 1px;
        top: ${GAP.S};
        right: -19.5px;
        width: 0;
      }
      &::after {
        content: '';
        border-style: solid;
        border-width: 10px 10px 10px 10px;
        border-radius: 1px;
        border-color: transparent transparent transparent ${COLOR[props.color] || COLOR.BRAND_1};
        position: absolute;
        top: ${GAP.S};
        right: -18px;
        width: 0;
      }
    `}
  `,
  dialogWrapper: ({ arrowPosition, color }: { arrowPosition: PropsStyle['arrowPosition']; color?: string }) => css`
    max-width: ${MAX_WIDTH};
    max-height: ${MAX_HEIGHT};
    position: relative;
    border-radius: 4px;
    border: 1px solid ${COLOR.NEUTRAL_4};
    background-color: ${COLOR.TOP_BACKGROUND};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    ${arrowPosition === 'top-left' &&
    css`
      padding-top: 6px;
      box-shadow: inset 0 6px 0 0 ${COLOR[color] || COLOR.BRAND_1};
    `}

    ${arrowPosition === 'bottom-left' &&
    css`
      padding-bottom: 6px;
      box-shadow: inset 0 -6px 0 0 ${COLOR[color] || COLOR.BRAND_1};
    `}

    ${arrowPosition === 'bottom-right' &&
    css`
      padding-right: 6px;
      box-shadow: inset -6px 0 0 0 ${COLOR[color] || COLOR.BRAND_1};
    `}
  
    ${arrowPosition === 'top-right' &&
    css`
      padding-right: 6px;
      box-shadow: inset -6px 0 0 0 ${COLOR[color] || COLOR.BRAND_1};
    `}
  `,
  dialogContent: css`
    padding: ${GAP.L};
    overflow-y: auto;
  `,
  close: css`
    margin-left: auto;
    position: absolute;
    top: ${GAP.S};
    right: ${GAP.S};
    z-index: 4;
  `,
  wrapper: css`
    display: inherit;
    line-height: 0;
    & > * {
      line-height: normal;
    }
  `,
  label: css`
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_2};
    font-weight: ${FONT_WEIGHT.BOLD};
    margin-bottom: ${GAP.XXS};
    text-align: left;
    word-break: break-word;
  `
};
