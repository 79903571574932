import { GeneralModel, applyMask } from '@cyferd/client-engine';
import { useParsers } from '@utils';
import { isEditable, typeFormatFallbacks } from '../components/EditTable/constants';

// TODO: fix naming on these functions, and get output type as it's really useful to use in the table

const useGetPropertyBaseProps = () => {
  const { parseSchemaProperty } = useParsers();
  return ({ value, schema, id, fullItem }) => {
    if (!schema) return { value };
    const evaluate = (formula: GeneralModel.EvaluatorFormula) => parseSchemaProperty(formula, { value, definition: schema, fullItem, path: id });

    const { metadata, format, type } = schema;
    const safeFormat = format ?? typeFormatFallbacks[type];

    const disabled: boolean = !!(metadata?.disabled === true || evaluate(metadata?.disabled) || !!metadata?.calculation);
    const disabledType = metadata?.disabledType;
    const icon = evaluate(metadata?.icon);
    const parsedValue = !metadata?.calculation ? value : applyMask(value, metadata?.mask);
    const maskedValue = !metadata?.calculation ? applyMask(value, metadata?.mask) : value;
    const propertyDisplayName = schema.label || schema.title;
    const displayNamePath = [[null, undefined, GeneralModel.JSONSchemaFormat.OBJECT].includes(safeFormat) && propertyDisplayName].filter(Boolean);
    const optionList = schema.metadata?.optionList;
    const editable = isEditable(schema) && !disabled;

    // TODO: These about colors are adapted from parseList. They should be extracted on a common util
    const isArray = Array.isArray(parsedValue) && ![GeneralModel.JSONSchemaFormat.NUMERIC_RANGE].includes(safeFormat);
    const getOption = (v: any) => metadata?.optionList?.find(option => option.value === v);
    const baseFieldColor = !metadata?.color ? undefined : evaluate(metadata?.color);
    const fieldColor = !isArray ? getOption(parsedValue)?.color || baseFieldColor : parsedValue.map((v, i) => getOption(v)?.color || baseFieldColor?.[i]);
    const color = !isArray ? GeneralModel.Color.getColorIfValid(fieldColor) : fieldColor?.map?.(GeneralModel.Color.getColorIfValid);
    const safeColor = evaluate(color);

    const secondaryBaseColor = !metadata?.color ? undefined : evaluate(metadata?.color);
    const secondColor = !isArray
      ? getOption(parsedValue)?.color || secondaryBaseColor
      : parsedValue.map((v, i) => getOption(v)?.color || secondaryBaseColor?.[i]);
    const secondaryColor = !isArray ? GeneralModel.Color.getColorIfValid(secondColor) : secondColor?.map?.(GeneralModel.Color.getColorIfValid);
    const safeSecondaryColor = evaluate(secondaryColor);

    return {
      id,
      disabled,
      disabledType,
      type,
      value: parsedValue,
      maskedValue,
      color: safeColor,
      secondaryColor: safeSecondaryColor,
      optionList,
      icon,
      propertyDisplayName,
      displayNamePath,
      definition: schema,
      format: safeFormat,
      editable
    };
  };
};

export const useGetRecordProperties = () => {
  const getPropertyBaseProps = useGetPropertyBaseProps();
  return ({ record, schemaProperties = {} }) =>
    Object.keys(schemaProperties).reduce(
      (acc, key) => ({
        ...acc,
        [key]: getPropertyBaseProps({
          id: key,
          value: record[key],
          schema: schemaProperties[key],
          fullItem: record
        })
      }),
      {}
    );
};
