import { useCallback } from 'react';
import { EMPTY } from 'rxjs';

export const useOnOpenEmailClient = () =>
  useCallback((email: string) => {
    const href = `mailto:${email}`;
    const link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('target', '_blank');
    link.click();
    return EMPTY;
  }, []);
