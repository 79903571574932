/* istanbul ignore file */

import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { noop } from '@cyferd/client-engine';
import { DragDropContext } from '@hello-pangea/dnd';

import { FormulaTooltipContent } from '@components/elements/Evaluator/FormulaTooltip';

export interface ApiRefFormulasProps {
  fx: FormulaInputRow;
}

export const ApiRefFormulas = ({ fx }: ApiRefFormulasProps) => {
  return (
    <DragDropContext onDragEnd={noop}>
      <FormulaTooltipContent item={fx} />
    </DragDropContext>
  );
};

export const getFxSearchContent = (fx: FormulaInputRow) => {
  return [fx.id, fx.description, fx.label, ...fx.keywords].flat().filter(Boolean).join('<hr/>');
};
