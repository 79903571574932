import { COLOR, GAP, SECONDARY_COLOR } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  conditionsContainer: css`
    margin-top: ${GAP.S};
  `,
  loading: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${GAP.M};
    background: ${COLOR.BASE_BACKGROUND};
    gap: ${GAP.M};
    position: relative;
    border: 1px solid ${SECONDARY_COLOR.BRAND_1};
    border-radius: 4px;
    justify-content: center;
    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 4px;
      background-color: ${COLOR.BRAND_1};
      top: 0;
      left: -1px;
      border-radius: 6px;
    }
    .row {
      display: flex;
      gap: 12px;
      height: 45px;
      align-items: center;
      width: 100%;
      padding-top: 6px;
      margin-top: 12px;
    }
    .buttons {
      display: flex;
      gap: 8px;
      height: 27.59px;
      width: 25%;
    }
  `
};
