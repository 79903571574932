/* istanbul ignore file */
import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import { styles } from './styles';
import type { GeneralModel } from '@cyferd/client-engine';
import { EvaluatorModel, Translate, ViewModel, createUUID, isObject, noop } from '@cyferd/client-engine';
import { COLOR, FOREGROUND_COLOR, TRANS, getBasicDataTypeIcon } from '@constants';
import { FormulaInputType, colorPerType } from '../getFormulaInputType';
import type { FormulaInputRow } from '../resources';
import { Icon } from '@components/elements/Icon';
import { EvaluatorTree } from '../EvaluatorTree';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTAType } from '@components/elements/CTA';
import { actions as uiActions } from '../../../../client-app/state-mgmt/ui/actions';
import { useDispatch } from 'react-redux';
import { ToastStatus } from '@components/elements/Toast';
import { PopoverTrigger } from '@components/elements/Popover';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';

const renderMaxMin = (input: GeneralModel.JSONSchema) => {
  const min = input.minItems ?? input.minimum ?? input.minProperties;
  const max = input.maxItems ?? input.maximum ?? input.maxProperties;
  const isPlural = Math.max(min, max) > 1;
  if (!min && !max) return null;
  return (
    !isNaN(min) && (
      <span css={styles.itemListItemDescription}>
        ({!max && 'minimum '}
        {min}
        {!!max && min !== max && ` to ${max}`} item{!!isPlural && 's'})
      </span>
    )
  );
};

const renderInputItem = (input: GeneralModel.JSONSchema, index: number) => (
  <li key={`${input.$id}${index}`} css={styles.itemListItem} style={{ color: COLOR[colorPerType[input.type]] }}>
    <span css={styles.argTitle}>
      <Icon name={getBasicDataTypeIcon(input.type)} fill={COLOR[colorPerType[input.type]]} />
      <Translate>
        {input.title} ({input.type}) {renderMaxMin(input)}
      </Translate>
    </span>
    {!!input.description && (
      <p css={styles.itemListItemDescription}>
        <Translate>{input.description}</Translate>
      </p>
    )}
    {!!input.metadata?.optionList?.length && (
      <p css={styles.itemListItemDescription}>
        <ul css={styles.unorderList}>
          {input.metadata.optionList.map((o, i) => (
            <li key={i}>
              <Translate>{o.value}</Translate>
            </li>
          ))}
        </ul>
      </p>
    )}
  </li>
);

export type FormulaTooltipProps = PropsWithChildren<{
  item: FormulaInputRow;
  avoidWrapping: boolean;
  avoidOpenOnClick: boolean;
}>;

export const FormulaTooltipContent = ({ item }: Pick<FormulaTooltipProps, 'item'>) => {
  const color = COLOR[colorPerType[item.type]];
  const formulaConfig = EvaluatorModel.formulaMap[item.id] as EvaluatorModel.FormulaConfig;
  return (
    <div>
      <div css={styles.tooltipContainer}>
        <div style={{ color }} css={styles.tooltipTitle}>
          <Translate>{item.label}</Translate>{' '}
          <span css={styles.value(colorPerType[item.type])}>
            <Icon name={getBasicDataTypeIcon(item.type)} fill={FOREGROUND_COLOR[colorPerType[item.type]]} />
            {item.type}
          </span>
        </div>
        {!!item.description && (
          <p css={styles.description}>
            <Translate>{item.description}</Translate>
          </p>
        )}
        {item.formulaType !== FormulaInputType.FORMULA && typeof item.template !== 'object' && (
          <p>
            <span css={styles.tooltipTitle}>Value </span>
            <span>{JSON.stringify(item.template)}</span>
          </p>
        )}
        {isObject(item.input) && (
          <div>
            <p css={styles.tooltipTitle}>Inputs {renderMaxMin(item.input)}</p>
            {Array.isArray(item.input.prefixItems) && (
              <ol css={styles.orderList}>{item.input.prefixItems.filter(isObject).map((i, index) => renderInputItem(i, index))}</ol>
            )}
            {isObject(item.input.items) && !!Object.keys(item.input.items).length && renderInputItem(item.input.items, 0)}
          </div>
        )}
        <Examples formulaConfig={formulaConfig} />
      </div>
    </div>
  );
};

const Examples = ({ formulaConfig }: { formulaConfig: EvaluatorModel.FormulaConfig }) => {
  if (!formulaConfig?.examples?.length) return null;
  return (
    <div>
      <p css={styles.examplesTitle}>Examples</p>
      <div css={styles.exampleList}>
        {formulaConfig.examples.map((example, i) => (
          <Example key={i} example={example} formulaConfig={formulaConfig} />
        ))}
      </div>
    </div>
  );
};

const Example = ({ example, formulaConfig }: { example: EvaluatorModel.FormulaConfig['examples'][0]; formulaConfig: EvaluatorModel.FormulaConfig }) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState<'input' | 'output'>('input');
  const value = mode === 'input' ? { [formulaConfig.key]: example.input } : example.output;
  return (
    <div>
      <div css={styles.exampleTitleContainer}>
        <div>
          <p css={styles.exampleInputTitle}>
            <Translate>{example.name}</Translate>
          </p>
          <p css={styles.exampleInputSubtitle}>
            <Translate>{example.description}</Translate>
          </p>
        </div>
        <PreventClickPropagation>
          <OptionMenu
            optionList={[
              {
                important: true,
                testid: 'swap-mode',
                tooltip: `See ${mode === 'input' ? 'output' : 'input'}`,
                image: 'swap_horiz',
                type: CTAType.LINK,
                size: ViewModel.CTASize.SMALL,
                outlined: true,
                onClick: () => setMode(prev => (prev === 'input' ? 'output' : 'input'))
              },
              {
                important: true,
                testid: 'copy-example',
                tooltip: TRANS.client.buttons.copy,
                image: 'copy_all',
                type: CTAType.LINK,
                size: ViewModel.CTASize.SMALL,
                outlined: true,
                onClick: () => {
                  navigator?.clipboard?.writeText?.(JSON.stringify(value));
                  dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: `Copied` }));
                }
              }
            ]}
          />
        </PreventClickPropagation>
      </div>
      <div css={styles.exampleInput}>
        <EvaluatorTree
          readonly={true}
          value={value}
          inputList={[]}
          activePath={null}
          pathBeingDragged={null}
          height={'0'}
          onChange={noop}
          onChangeActive={noop}
          onKeyChange={noop}
        />
      </div>
    </div>
  );
};

export const FormulaTooltip = ({ item, children, avoidWrapping, avoidOpenOnClick }: FormulaTooltipProps) => {
  if (avoidWrapping) return children;
  return (
    <PopoverTrigger
      timeout={1500}
      avoidOpenOnClick={avoidOpenOnClick}
      containerCss={styles.popover}
      contentCss={styles.popover}
      color={colorPerType[item?.type]}
      value={<FormulaTooltipContent item={item} />}
    >
      {children}
    </PopoverTrigger>
  );
};
