import { useCallback, useRef, useState } from 'react';

const stopPropagation = e => e.stopPropagation();

export const useDragHorizontalScroll = (speed?: number) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [startX, setStartX] = useState(undefined);
  const [scrollLeft, setScrollLeft] = useState(undefined);
  const [xPosition, setXPosition] = useState(undefined);

  const onMouseDown = useCallback(e => {
    /* istanbul ignore else */
    if (ref.current) {
      setMouseIsDown(true);
      setStartX(e.pageX - ref.current.offsetLeft);
      setScrollLeft(ref.current.scrollLeft);
    }
  }, []);

  const onMouseUp = useCallback(() => {
    setMouseIsDown(false);
  }, []);

  const onMouseMove = useCallback(
    e => {
      const element = ref.current;
      /* istanbul ignore else */
      if (element && mouseIsDown) {
        e.preventDefault();
        const x = e.pageX - element.offsetLeft;
        const walk = (x - startX) * (speed || 1);
        const newX = scrollLeft - walk;
        element.scrollLeft = newX;
        setXPosition(newX);
      }
    },
    [mouseIsDown, scrollLeft, speed, startX]
  );
  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    hScrollingActive: mouseIsDown,
    ref,
    stopPropagation,
    xPosition
  };
};
