import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, RADIUS, SHADOW, TRANSITION_SPEED } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { ViewModel } from '@cyferd/client-engine';

const getSize = (density: ViewModel.Density) => {
  switch (density) {
    case ViewModel.Density.S:
      return { content: '38px', height: '80px', width: '95px', icon: '24px', lines: '1', fontSize: FONT_SIZE.S, padding: `${GAP.S} ${GAP.XS} 0 ${GAP.XS}` };
    case ViewModel.Density.L:
      return {
        content: '90px',
        height: '170px',
        width: '170px',
        icon: '50px',
        lines: '2',
        fontSize: FONT_SIZE.S,
        padding: `${GAP.L} ${GAP.S} ${GAP.S} ${GAP.S}`
      };
    case ViewModel.Density.M:
    default:
      return {
        content: '60px',
        height: '138px',
        width: '138px',
        icon: '32px',
        lines: '2',
        fontSize: FONT_SIZE.S,
        padding: `${GAP.L} ${GAP.S} ${GAP.S} ${GAP.S}`
      };
  }
};

export const getStyles = ({ density, color }: { density: ViewModel.Density; color?: GeneralModel.Color.ThemeColor }) => ({
  gridContainer: css`
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fill, ${getSize(density).width});
  `,
  container: css`
    position: relative;
    [data-selector='action-trigger'] {
      transition: opacity ${TRANSITION_SPEED};
      opacity: 0;
    }
    :hover {
      [data-selector='action-trigger'] {
        opacity: 1;
      }
    }
  `,
  actionContainer: css`
    position: absolute;
    top: ${GAP.XS};
    right: ${GAP.XS};
  `,
  gridItem: css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: ${getSize(density).padding};
    border-radius: ${RADIUS.M};
    width: ${getSize(density).width};
    height: ${getSize(density).height};
    transition: all ${TRANSITION_SPEED};
  `,
  cardItemClickable: css`
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
      box-shadow: ${SHADOW.S};
    }
  `,
  title: css`
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    color: ${COLOR.BASE_FOREGROUND};
    font-size: ${getSize(density).fontSize};
    display: -webkit-box;
    -webkit-line-clamp: ${getSize(density).lines};
    -webkit-box-orient: vertical;
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  imageContainer: css`
    background-color: ${COLOR[color] || COLOR.NEUTRAL_5};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${getSize(density).content};
    height: ${getSize(density).content};
    border-radius: 100%;
    overflow: hidden;
    box-shadow: ${SHADOW.S};
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  righButtonContainer: css`
    margin-right: 0;
    margin-left: auto;
  `,
  content: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${GAP.M};
  `,
  bottomContainer: css`
    display: flex;
    width: 100%;
    flex: 1;
  `,
  textContent: css`
    align-self: center;
  `,
  iconProps: { size: getSize(density).icon, fill: FOREGROUND_COLOR[color] || COLOR.BASE_FOREGROUND } as any
});
