import { COLOR } from '@constants';

export const textReg = /^text\//i;
export const syntaxReg = /^(application|text)\/(json|sql|graphql|yaml|xml|html|yml|octet-stream$)/i;

export const isPdf = mime => /^application\/pdf$/i.test(mime);
export const isJson = mime => /^application\/json$/i.test(mime);
export const isXml = mime => /^text\/xml$/i.test(mime);
export const isImage = mime => /^image\//i.test(mime);
export const isVideo = mime => /^video\//i.test(mime);
export const isAudio = mime => /^audio\//i.test(mime);
export const isPlainText = mime => /^text\/plain$/i.test(mime);
export const isText = mime => textReg.test(mime);
export const isCsv = mime => /^text\/csv$/i.test(mime);

/* istanbul ignore next line */
export const getFileIconProps = (mimeType: string) => {
  if (isPdf(mimeType)) return { name: 'picture_as_pdf', color: COLOR.HC_7 };
  if (isJson(mimeType) || isXml(mimeType)) return { name: 'code_blocks', color: COLOR.HC_9 };
  if (isImage(mimeType)) return { name: 'image', color: COLOR.PE_5 };
  if (isVideo(mimeType)) return { name: 'play_circle', color: COLOR.HC_3 };
  if (isAudio(mimeType)) return { name: 'graphic_eq', color: COLOR.BRAND_2 };
  if (isPlainText(mimeType)) return { name: 'title', color: COLOR.HC_15 };
  if (isCsv(mimeType)) return { name: 'csv', color: COLOR.HC_10 };
  return { name: 'draft', color: COLOR.BASE_FOREGROUND };
};
