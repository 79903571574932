import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  mainContainer: css`
    width: 100%;
    label {
      z-index: 1;
    }
  `,
  expandedContainer: css`
    display: flex;
    align-items: flex-start;
    gap: ${GAP.XS};
    width: 100%;
    overflow-x: hidden;
  `,
  container: css`
    width: 100%;
    display: flex;
  `,
  disabledEditor: css`
    filter: contrast(85%);
  `
};
