import { COLOR } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    position: relative;
    display: block;
  `,
  partialIcon: css`
    position: absolute !important;
    z-index: 1;
    left: 0;
    top: 0;
    overflow: hidden;
  `,
  icon: props => css`
    display: inline-block;
    position: relative;
    color: ${props.fill || COLOR.BASE_FOREGROUND};
    font-size: ${props.size || '1em'};
    cursor: ${props.interactive ? 'pointer' : ''};
  `
};
