import { GAP, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${GAP.XS};
  `,
  UNSTABLE_rotation: css`
    transform: rotate(90deg);
  `,
  triggerContainer: css`
    transition: all ${TRANSITION_SPEED};
  `
};
