import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  phoneInputContainer: css`
    display: flex;
    align-items: center;
    position: relative;

    .special-label {
      color: ${COLOR.NEUTRAL_2};
      left: calc(0.75rem - 2px);
    }
    [data-selector='error-message'] {
      display: none;
    }
  `,
  phoneNumber: css`
    margin-left: ${GAP.XXS};
    flex: 1;
  `,
  callButton: css`
    margin-left: 5px;
  `
};
