import { BUILDER_ROUTE, QUERY_PARAM } from '@constants';
/* istanbul ignore file | this is a config */
import { ApiModel } from '@cyferd/client-engine';

export const getLink = (id: string, collectionId?: string): string => {
  switch (collectionId) {
    case ApiModel.ApiEntity.INTEGRATION:
      return `${BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT}${BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${id}`;
    case ApiModel.ApiEntity.ENTITY:
      return `${BUILDER_ROUTE.ENTITY_EDITOR.ROOT}${BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${id}`;
    case ApiModel.ApiEntity.FLOW:
      return `${BUILDER_ROUTE.FLOW_EDITOR.ROOT}${BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${id}`;
    case ApiModel.ApiEntity.TAG:
      return `${BUILDER_ROUTE.TAG_EDITOR.ROOT}${BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${id}`;
    case ApiModel.ApiEntity.USER:
      return `${BUILDER_ROUTE.USER_EDITOR.ROOT}${BUILDER_ROUTE.USER_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${id}`;
    case ApiModel.ApiEntity.VIEW:
      return `${BUILDER_ROUTE.VIEW_EDITOR.ROOT}${BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${id}`;
    default:
      return '';
  }
};
