import { ApiModel } from '@cyferd/client-engine';
import type { User } from '@cyferd/client-engine/dist/types/models/user';
import { parse } from '@cyferd/evaluator';
import { hasFlag } from '@utils';

const prepareCoreOptions = (arr, user) =>
  arr
    .filter(({ hidden, flag }: any) => hidden !== true && (!flag || hasFlag(user, flag)))
    .map((item: any) => ({
      title: item.name,
      description: item.description,
      color: (item as any).color,
      image: (item as any).icon,
      fullItem: { id: item.id },
      raw: { id: item.id },
      list: []
    }));

const coreOptionList = {
  // TODO: add Collection,
  Flow: Object.values(ApiModel.FlowSchemaMap)
  // TODO: add View,
};

export const getCoreOptions = (collectionId: string, formula: any, user: User) => {
  if (!coreOptionList[collectionId]) return [];

  if (!formula) return prepareCoreOptions(coreOptionList[collectionId], user);

  try {
    return prepareCoreOptions(parse(formula).evaluate({ list: coreOptionList[collectionId] }), user);
  } catch (e) {
    return [];
  }
};
