import type { SvgProps } from '../types';

export const Layout_G = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke={fill} />
    <path
      d="M23 36.5L5 36.5C4.17157 36.5 3.5 35.8284 3.5 35L3.5 5C3.5 4.17158 4.17157 3.5 5 3.5L23 3.5C23.8284 3.5 24.5 4.17158 24.5 5L24.5 35C24.5 35.8284 23.8284 36.5 23 36.5Z"
      stroke={fill}
    />
    <path d="M27 5C27 3.89543 27.8954 3 29 3L35 3C36.1046 3 37 3.89543 37 5V35C37 36.1046 36.1046 37 35 37H29C27.8954 37 27 36.1046 27 35L27 5Z" fill={fill} />
  </svg>
);
