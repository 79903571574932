import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  inputContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  formulaContainer: css`
    grid-template-columns: auto;
  `
};
