import type { ComponentProps } from 'react';
import { memo, useCallback, useContext, useMemo } from 'react';

import type { CollectionModel } from '@cyferd/client-engine';
import { ErrorBoundary, GeneralModel, ViewModel, isObject, normalize, recursiveMap } from '@cyferd/client-engine';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';

import { TRANS, TRIGGER_FORMAT } from '@constants';
import { userInputList } from '@models/user';
import { getViewDynamicInputList, viewInputList } from '@models/view';
import type { EditorContextValue } from '../EditorHome';
import { EditorContext } from '../EditorHome';
import { SchemaForm } from '../SchemaForm';
import { getStyles } from './styles';
import type { TabList } from '@components/elements/TabList';

const getListenerSchema = (schema: GeneralModel.JSONSchema): GeneralModel.JSONSchema => {
  const isSuccess = /[a-z]Success$/.test(schema.key);
  const isError = /[a-z]Error$/.test(schema.key);
  return {
    ...schema,
    type: 'array',
    format: GeneralModel.JSONSchemaFormat.ARRAY,
    info: (() => {
      if (isSuccess) {
        return `<p>This action will run when the <span style="color: var(--BRAND_1);"><strong>main action</strong></span> has successfully finished.&nbsp;&nbsp;</p>
        <p>&nbsp;</p>
        <p>The main action is considered finished when it hasn't encountered errors, timeouts, or when the action only affects the UI (dispatch actions).&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Warning:</strong> this action will not run there is no <span style="color: var(--BRAND_1);"><strong>main action</strong></span> or default action.</p>`;
      }
      if (isError) {
        return `This action will run if the <span style="color: var(--BRAND_1);"><strong>main action</strong></span> gets an error or timeout from the server.`;
      }
      return schema.info;
    })(),
    metadata: {
      allowFormula: false,
      ctaConfig: { label: 'Add action' },
      fieldSize: GeneralModel.FieldSize.REGULAR,
      color: (() => {
        if (isSuccess) return 'GN_2';
        if (isError) return 'HC_5';
        return 'BRAND_1';
      })(),
      ...schema?.metadata,
      detailGroupId: schema.key.replace(/(success|error)$/i, '')
    },
    items: { type: 'object', label: 'Actions', format: TRIGGER_FORMAT as any, metadata: { eventType: schema.key, allowFormula: false } }
  };
};

export const isListenerKey = (key: string) => /^on[A-Z]/.test(key);
const isListener = (k: string, props: CollectionModel.Collection['schema']['properties']) =>
  props[k]?.format === 'array' && props[k]?.items.format === ('trigger' as any);

const detailGroupList: CollectionModel.DetailGroup[] = [
  { id: 'basic', name: TRANS.client.viewTabs.basic, image: 'topic' },
  { id: 'tabs', name: TRANS.client.viewTabs.tabs, hidden: { $cyf_not: [{ $cyf_contains: [['F', 'L'], '{{event.item.type}}'] }] }, image: 'topic' },
  { id: 'input', name: TRANS.client.viewTabs.input, image: 'data_object' },
  { id: 'calendarList', name: TRANS.client.viewTabs.calendarList, collapsible: true, startsCollapsed: false, image: 'schedule' },
  { id: 'advanced', name: TRANS.client.viewTabs.advanced, collapsible: true, startsCollapsed: false, image: 'topic' },
  { id: 'headerActions', name: TRANS.client.viewTabs.headerActions, collapsible: true, startsCollapsed: false, image: 'play_arrow' },
  { id: 'mainActions', name: TRANS.client.viewTabs.mainActions, collapsible: true, startsCollapsed: false, image: 'play_arrow' },
  { id: 'itemActions', name: TRANS.client.viewTabs.itemActions, collapsible: true, startsCollapsed: false, image: 'play_arrow' },
  { id: 'navigation', name: TRANS.client.viewTabs.navigation, collapsible: true, startsCollapsed: false, image: 'web_asset' },
  { id: 'page', name: TRANS.client.viewTabs.page, collapsible: true, startsCollapsed: false, image: 'edit_document' }
].map((item, index) => ({ ...item, order: index + 1 }));

export interface AttributesEditorProps {
  model?: {
    schema: GeneralModel.JSONSchema;
    detailGroupList?: CollectionModel.DetailGroup[];
  };
  componentSchema: GeneralModel.JSONSchema;
  node: ViewModel.Node;
  height?: number;
  tabsIOptionMenu?: ComponentProps<typeof TabList>['optionMenuProps'];
  onChange: (event: ViewModel.Node) => void;
}

export const AttributesEditor = ({ model, ...props }: AttributesEditorProps) => {
  if (!model) return <AttributesEditorLegacy {...props} />;
  return <AttributesEditorCompo model={model} {...props} />;
};

const AttributesEditorCompo = memo((props: AttributesEditorProps) => {
  const { model, node, height, tabsIOptionMenu, onChange } = props;
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const completeViewInputList = useMemo(() => [...getViewDynamicInputList(item), ...viewInputList, ...userInputList] as FormulaInputRow[], [item]);
  const styles = useMemo(() => getStyles({ height }), [height]);

  const value = useMemo(() => ({ componentName: node?.name, ...node?.attrs, ...node?.listeners }), [node?.attrs, node?.listeners, node?.name]);

  const onInternalChange = useCallback(
    (event: any) => {
      const { componentName: name, ...rest } = event;
      const { attrs, listeners } = Object.entries(rest).reduce(
        (total, [k, v]) => {
          const section = isListener(k, model.schema.properties) ? 'listeners' : 'attrs';
          return { ...total, [section]: { ...total[section], [k]: v } };
        },
        { attrs: {}, listeners: {} }
      );
      onChange({ ...node, name, attrs, listeners });
    },
    [node, model, onChange]
  );

  return (
    <div className={styles.container}>
      <ErrorBoundary>
        <SchemaForm
          id="attr-editor"
          allowFormula={true}
          autofocusDisabled={true}
          avoidInitialSync={true}
          avoidAlphabeticalSort={true}
          shouldValidate={false}
          wrapDetailGroups={true}
          schema={model.schema}
          detailGroupList={model.detailGroupList}
          type={ViewModel.CyFormType.TABS}
          value={value}
          onChange={onInternalChange}
          inputList={completeViewInputList}
          tabsIOptionMenu={tabsIOptionMenu}
        />
      </ErrorBoundary>
    </div>
  );
});

const AttributesEditorLegacy = memo(({ node, componentSchema, height, tabsIOptionMenu, onChange }: AttributesEditorProps) => {
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const styles = useMemo(() => getStyles({ height }), [height]);

  const ignoredPropertyReg = useMemo(() => /(^(children|prepend|append)$)/, []);

  const completeViewInputList = useMemo(() => [...getViewDynamicInputList(item), ...viewInputList, ...userInputList] as FormulaInputRow[], [item]);

  const detailGroupListWithActions = useMemo(
    () => [
      ...detailGroupList,
      ...Object.values(componentSchema.properties)
        .filter(({ key }) => isListenerKey(key))
        .filter(({ key }) => !/(success|error)$/i.test(key))
        .map(({ key, label }, i) => ({ id: key, name: label, image: 'play_circle', order: detailGroupList.length + i }) as CollectionModel.DetailGroup)
    ],
    [componentSchema.properties]
  );

  const modifiedSchema = useMemo(() => {
    const attrs = {
      ...recursiveMap(Object.fromEntries(Object.entries(componentSchema?.properties).filter(([key]) => !isListenerKey(key))), (item, path) => {
        const key = path[path.length - 1];
        if (!isObject(item) || !path.length) return item;
        if (/^attrs$/.test(key)) return { ...item, metadata: { ...item.metadata, fieldSize: GeneralModel.FieldSize.FULL } };
        if (/^listeners$/.test(key))
          return {
            ...item,
            metadata: { ...item.metadata, fieldSize: GeneralModel.FieldSize.FULL },
            properties: Object.fromEntries(Object.entries(item.properties).map(([key, item]) => [key, getListenerSchema(item)]))
          };
        return item;
      })
    };

    const listeners = Object.fromEntries(
      Object.entries(componentSchema?.properties)
        .filter(([key]) => isListenerKey(key))
        .map(([key, item]) => [key, getListenerSchema(item)])
    );
    return normalize.schema(
      {
        type: 'object',
        properties: {
          ...attrs,
          ...listeners,
          componentName: {
            type: 'string',
            label: 'Component name',
            description: 'Warning: changing this name might affect functionality you have set up in your view',
            info: `<p>This name helps you identify the component in the view builder, and its also used for:</p>
            <p></p>
            <ul>
            <li>Targeting this component when using refresh actions</li>
            <li>The default pointer where this component's value is stored while the view is running</li>
            </ul>
            <p></p>
            <p>As a result, changing this name might affect functionality you have set up in your view</p>            
            `,
            metadata: {
              allowFormula: false,
              hidden: node?.component === ViewModel.DisplayName.VIEW_HEADER || node?.component === ViewModel.DisplayName.GLOBAL_HEADER,
              detailGroupId: 'basic',
              detailOrder: 0
            }
          }
        }
      },
      { avoidAlphabeticalSort: true, validDetailGroupIdList: detailGroupListWithActions.map(({ id }) => id) }
    );
  }, [componentSchema?.properties, detailGroupListWithActions, node?.component]);

  const value = useMemo(() => ({ componentName: node?.name, ...node?.attrs, ...node?.listeners }), [node?.attrs, node?.listeners, node?.name]);

  const onInternalChange = useCallback(
    (event: any) => {
      const { componentName: name, ...rest } = event;
      const { attrs, listeners } = Object.entries(rest).reduce(
        (total, [k, v]) => {
          const section = isListenerKey(k) ? 'listeners' : 'attrs';
          return { ...total, [section]: { ...total[section], [k]: v } };
        },
        { attrs: {}, listeners: {} }
      );
      onChange({ ...node, name, attrs, listeners });
    },
    [node, onChange]
  );

  return (
    <div className={styles.container}>
      <ErrorBoundary>
        <SchemaForm
          id="attr-editor"
          wrapDetailGroups={true}
          schema={modifiedSchema}
          autofocusDisabled={true}
          type={ViewModel.CyFormType.TABS}
          ignoredPropertyReg={ignoredPropertyReg}
          value={value}
          onChange={onInternalChange}
          allowFormula={true}
          shouldValidate={false}
          avoidInitialSync={true}
          avoidAlphabeticalSort={true}
          inputList={completeViewInputList}
          detailGroupList={detailGroupListWithActions}
          tabsIOptionMenu={tabsIOptionMenu}
        />
      </ErrorBoundary>
    </div>
  );
});
