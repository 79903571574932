import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';

import * as RadixToolTip from '@radix-ui/react-tooltip';

import { styles } from './styles';
import { Translate } from '@cyferd/client-engine';

export type IToolTipProps = PropsWithChildren<{
  disabled?: boolean;
  side?: 'top' | 'bottom' | 'left' | 'right';
  text: string | ReactNode;
  testid?: string;
}>;

export const ToolTip = ({ disabled, text, children, side = 'bottom', testid = 'tooltip' }: IToolTipProps) =>
  !text || disabled ? (
    <>{children}</>
  ) : (
    <RadixToolTip.Provider>
      <RadixToolTip.Root>
        <RadixToolTip.Trigger asChild={true} data-testid={`${testid}-trigger`}>
          <span css={styles.childrenContainer}>{children}</span>
        </RadixToolTip.Trigger>
        <RadixToolTip.Content data-testid={`${testid}-content`} side={side} sideOffset={side === 'left' || side === 'right' ? -5 : 5}>
          <div css={styles.tooltipContainer}>
            <Translate>{text}</Translate>
            <RadixToolTip.Arrow css={styles.contentArrow} />
          </div>
        </RadixToolTip.Content>
      </RadixToolTip.Root>
    </RadixToolTip.Provider>
  );

ToolTip.displayName = 'ToolTip';
