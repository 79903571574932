import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  label: css`
    padding: ${GAP.XXS};
    color: ${COLOR.BRAND_1};
    display: inline-block;
  `,
  input: css`
    &&::-webkit-file-upload-button {
      visibility: hidden;
      user-select: none;
    }
    display: none;
    user-select: none;
  `
};
