import { DropdownMenuType } from '@components/elements/DropdownMenu';

export interface Dimensions {
  width: number;
  height: number;
}

export interface DimensionsWithPositions extends Partial<Dimensions> {
  x: number;
  y: number;
}

export interface Params {
  triggerDimensions: DimensionsWithPositions;
  menuDimensions: Dimensions;
  clientDimensions: Dimensions;
  horizontalPadding?: number;
  verticalPadding?: number;
  type?: DropdownMenuType;
}

export const getElementMenuPosition = ({
  triggerDimensions,
  menuDimensions,
  clientDimensions,
  horizontalPadding = 0,
  verticalPadding = 0,
  type = DropdownMenuType.OPTION_MENU
}: Params) => {
  const isOptionMenu = type === DropdownMenuType.OPTION_MENU;
  const { x, y, width: triggerWidth, height: triggerHeight } = triggerDimensions;
  const { height, width } = menuDimensions;
  const { width: clientWidth, height: clientHeight } = clientDimensions;

  const down = clientHeight - y;
  const up = y;
  const neededHeight = height + (isOptionMenu ? triggerHeight : 0) + verticalPadding;
  const spareHeightDown = down - neededHeight;
  const spareHeightUp = up - neededHeight;

  const spareWidth = clientWidth - (x + width + (isOptionMenu ? triggerWidth : 0) + horizontalPadding);

  const shouldRenderBottom = spareHeightDown > 0 || spareHeightDown > spareHeightUp; // down by default else where it has more space
  const shouldRenderRight = spareWidth > 0;

  return {
    style: isOptionMenu
      ? {
          top: y + (shouldRenderBottom ? triggerHeight + verticalPadding : (height + verticalPadding) * -1) /* istanbul ignore next */ || 0,
          left: x + (shouldRenderRight ? horizontalPadding : triggerWidth - width - horizontalPadding) /* istanbul ignore next */ || 0
        }
      : {
          top: (shouldRenderBottom ? y + verticalPadding : y - height - verticalPadding) /* istanbul ignore next */ || 0,
          left: (shouldRenderRight ? x + horizontalPadding : x - width - horizontalPadding) /* istanbul ignore next */ || 0
        },
    shouldRenderBottom,
    shouldRenderRight
  };
};
