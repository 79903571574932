import { getItemHeight } from '@components/elements/Layout/styles';
import { GAP } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    overflow: hidden;
  `,
  searchContainer: css`
    display: flex;
    gap: ${GAP.XS};
    align-items: center;
    justify-content: space-between;
    padding: 0 ${GAP.S};
  `,
  content: (height: number) => css`
    overflow: auto;
    max-height: ${getItemHeight(ViewModel.LayoutHeightPreset.REMAINING, height)};
    padding-right: ${GAP.M};
  `
};
