import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, createUUID, mergeTruthy, noop, normalize, recursiveMap, replaceDeep } from '@cyferd/client-engine';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';

import { ENV, GENERAL } from '@constants';
import { getEntityFullInputList } from '@models/entity';
import { SchemaForm } from '../SchemaForm';
import { CollectionSelectionModal } from './components/CollectionSelectionModal';
import { SchemaCreator } from './components/SchemaCreator';
import { groupDetailGroupList, groupSchema } from './schemas';
import { styles } from './styles';
import { ExampleEditor } from '../../EntityEditor/components/ExampleEditor/ExampleEditor';
import { CollectionBuilderEmptyState } from '@components/elements/CollectionBuilderEmptyState';
import { NGModal } from '@components/elements/NGModal';

export interface SchemaEditorProps {
  value: CollectionModel.Collection;
  allowApiFormats: boolean;
  allowNg: boolean;
  activePropertyId?: string;
  onChange: (event: CollectionModel.Collection) => void;
  onActivePropertyIdChange?: (event: string) => void;
}

export const SchemaEditor = ({ value, activePropertyId, allowNg, allowApiFormats, onChange, onActivePropertyIdChange }: SchemaEditorProps) => {
  const [isCollectionModalOpen, setCollectionModalOpen] = useState<boolean>(false);
  const detailRef = useRef<HTMLDivElement>();
  const exampleRef = useRef<HTMLDivElement>();
  const [tempDetailGroupId, setTempDetailGroupId] = useState<string>();
  const safeDetailGroupList = useMemo(() => value?.detailGroupList || [], [value?.detailGroupList]);
  const inputList: FormulaInputRow[] = useMemo(() => getEntityFullInputList(value?.schema), [value?.schema]);
  const [internalActivePropertyId, onInternalActivePropertyIdChange] = useState<string>(() => Object.keys(value?.schema?.properties || {})[0]);
  const activeId = onActivePropertyIdChange ? activePropertyId : internalActivePropertyId;
  const activeDetailGroup = safeDetailGroupList?.find(group => group.id === activeId);
  const [size, setSize] = useState<number>(1);
  const [showNgModal, setShowNgModal] = useState<boolean>(false);

  const onToggleNgModal = useCallback(() => setShowNgModal(p => !p), []);

  const onToggleCollectionModal = useCallback((tmpId?: string) => {
    setTempDetailGroupId(tmpId);
    setCollectionModalOpen(prev => !prev);
  }, []);

  const onScrollTop = useCallback(() => detailRef?.current?.scrollTo?.(0, 0), []);

  const onActiveChange = useCallback(
    (event: string) => {
      (onActivePropertyIdChange || onInternalActivePropertyIdChange)(event);
      onScrollTop();
    },
    [onActivePropertyIdChange, onScrollTop]
  );

  const onChangeEntitySchema = useCallback(
    (schema: GeneralModel.JSONSchema) => {
      const cleanSchema = recursiveMap(schema, (item: any, path: string[]) => {
        if (
          !['minProperties', 'pattern', 'maxLength', 'minLength', 'multipleOf', 'maximum', 'minimum', 'maxItems', 'minItems', 'default'].includes(
            path[path.length - 1]
          )
        ) {
          return item;
        }
        return item === null ? /* istanbul ignore next */ undefined : item;
      });
      onChange({ ...value, schema: cleanSchema });
    },
    [value, onChange]
  );

  const onDetailGroupListChange = useCallback(
    (detailGroupList: CollectionModel.Collection['detailGroupList']) => onChange(normalize.collection({ ...value, detailGroupList })),
    [value, onChange]
  );

  const onDetailGroupChange = useCallback(
    (payload: CollectionModel.DetailGroup) => {
      const index = safeDetailGroupList.findIndex(g => g.id === payload.id);
      const detailGroupList = replaceDeep(safeDetailGroupList, payload, String(index));
      onDetailGroupListChange(detailGroupList);
    },
    [onDetailGroupListChange, safeDetailGroupList]
  );

  const onRemoveDetailGroup = useCallback(
    (groupId: string) => {
      onChange(normalize.collection({ ...value, detailGroupList: safeDetailGroupList.filter(g => g.id !== groupId) }));
    },
    [value, safeDetailGroupList, onChange]
  );

  const onAddDetailGroup = useCallback(() => {
    const newGroup: CollectionModel.DetailGroup = { id: createUUID(), name: 'New group' };
    onDetailGroupListChange([newGroup, ...safeDetailGroupList].map((g, i) => ({ ...g, order: i + 1 })));
    onActiveChange(newGroup.id);
  }, [onDetailGroupListChange, safeDetailGroupList, onActiveChange]);

  const onAddAssociation = useCallback(
    (collection: CollectionModel.Collection) => {
      const associationKey = `${createUUID()}_child`;
      const association = normalize.schema(
        {
          title: `${collection.name}`,
          $id: associationKey,
          type: 'array',
          format: GeneralModel.JSONSchemaFormat.ASSOCIATION,
          metadata: {
            subtype: GeneralModel.JSONSchemaSubtype.ASSOCIATION_LIST,
            detailGroupId: tempDetailGroupId,
            association: {
              min: 0,
              max: -1,
              cursor: { collectionId: collection.id, associationKey, filter: {}, options: {}, quickFilters: [], excluded: false }
            }
          },
          items: {}
        },
        { validDetailGroupIdList: safeDetailGroupList.map(({ id }) => id) }
      ) as GeneralModel.JSONSchema;
      onChangeEntitySchema(mergeTruthy(value.schema, { properties: { [associationKey]: association } }));
      onActiveChange(associationKey);
    },
    [value?.schema, onChangeEntitySchema, safeDetailGroupList, onActiveChange, tempDetailGroupId]
  );

  const onToggleSize = useCallback(() => setSize(p => (p >= 3 ? 1 : p + 1)), []);

  const getSizeIcon = useCallback((size: number) => (size === 3 ? 'close_fullscreen' : 'open_in_full'), []);

  useEffect(() => {
    /* istanbul ignore next line */
    setTimeout(() => exampleRef?.current?.querySelector(`[data-id="${activeId}"]`)?.scrollIntoView?.({ behavior: 'smooth' }), ENV.INPUT_DEBOUNCE_TIME);
  }, [activeId]);

  if (!value) return null;

  return (
    <>
      <div css={[styles.grid, styles.size(size)]} data-testid="schema-editor">
        <div css={[styles.gridCol, styles.sidebarContainer]} data-testid={`size-${size}`}>
          <div css={styles.sidebarOuterContainer}>
            <SchemaCreator
              allowApiFormats={allowApiFormats}
              properties={value.schema}
              detailGroupList={safeDetailGroupList}
              onChange={onChangeEntitySchema}
              onChangeParent={noop}
              activePropertyId={activeId}
              setActivePropertyId={onActiveChange}
              onChangeDetailGroupList={onDetailGroupListChange}
              onAddDetailGroup={onAddDetailGroup}
              onRemoveDetailGroup={onRemoveDetailGroup}
              onAddAssociation={onToggleCollectionModal}
              inputList={inputList}
            />
          </div>
        </div>
        <div css={[styles.gridCol, styles.content(size)]} ref={detailRef}>
          <div id={GENERAL.PROPERTY_DETAIL_ID} />
          {!activeId && (
            <div css={styles.emptyStateContainer}>
              <CollectionBuilderEmptyState allowNg={!value?.createdAt && allowNg} isEdition={!!value?.createdAt} onClick={onToggleNgModal} />
            </div>
          )}
          {!!activeDetailGroup && (
            <SchemaForm
              schema={groupSchema}
              value={activeDetailGroup}
              onChange={onDetailGroupChange}
              inputList={inputList}
              detailGroupList={groupDetailGroupList}
              wrapDetailGroups={true}
            />
          )}
        </div>
        <div css={[styles.gridCol, styles.preview]} ref={exampleRef}>
          <ExampleEditor collection={value} onCollectionChange={onChange} onToggleSize={onToggleSize} toggleIcon={getSizeIcon(size)} />
        </div>
      </div>

      {!!isCollectionModalOpen && <CollectionSelectionModal onClose={onToggleCollectionModal} onChange={onAddAssociation} />}
      {!!showNgModal && <NGModal value={value} onChange={onChange} onClose={onToggleNgModal} />}
    </>
  );
};
