import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { actions, isDeepEqual, swallowError, useDispatch, usePrevious } from '@cyferd/client-engine';

import { processLocation } from './useUrl';
import { shouldChangeLocationSync } from '@constants';

export const useNavigation = () => {
  const location = useLocation();
  const url = processLocation(location);
  const prevUrl = usePrevious(url);
  const prevLocation = usePrevious(location);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDeepEqual(url, prevUrl) || shouldChangeLocationSync(location, prevLocation)) return;

    dispatch(actions.coreMoveTo({ id: url.pathname, input: url.queryParams }))
      .pipe(swallowError())
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, dispatch]);
};
