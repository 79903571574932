import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

const styleCorrection = css`
  padding: 0;
  p,
  ul,
  ol {
    white-space: pre-wrap !important;
  }

  * {
    word-break: break-word;
  }

  .ql-indent-1 {
    padding-left: ${GAP.XXL};
  }
  .ql-indent-2 {
    padding-left: calc(${GAP.XXL} * 2);
  }
  .ql-indent-3 {
    padding-left: calc(${GAP.XXL} * 3);
  }
  .ql-indent-4 {
    padding-left: calc(${GAP.XXL} * 4);
  }
  .ql-indent-5 {
    padding-left: calc(${GAP.XXL} * 5);
  }

  span,
  em,
  strong,
  div {
    margin: 0 !important;
    white-space: pre-wrap !important;
  }
  p,
  span,
  em,
  strong,
  li {
    line-height: 1.5em;
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  }
  h1 {
    font-size: ${FONT_SIZE.L};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
  h2 {
    font-size: ${FONT_SIZE.XXM};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
  h3 {
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
  h4 {
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
  h5 {
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
  pre {
    background-color: ${COLOR.NEUTRAL_4};
    color: ${FOREGROUND_COLOR.NEUTRAL_4};
  }
  strong,
  b {
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;

export const styles = {
  quillContainer: css`
    flex: 1;
    min-height: 22px;
    .ql-toolbar {
      display: none;
    }
  `,
  containerColor: css`
    padding-left: ${GAP.XS};
  `,
  isFocused: css`
    .ql-toolbar {
      border: none;
      display: block;
    }
  `,
  modalOpen: css`
    visibility: hidden;
  `,
  container: ({ unlimitedHeight }: { unlimitedHeight: boolean }) => css`
    .ql-toolbar {
      display: none;
    }

    .ql-container {
      overflow-y: auto;
      ${unlimitedHeight ? '' : 'max-height: 375px;'}
      border: none;
    }
    .ql-editor {
      ${styleCorrection};
    }

    .ql-formats:not(:last-child) {
      margin-right: ${GAP.S}!important;
      padding-right: ${GAP.S}!important;
      border-right: 1px ${COLOR.NEUTRAL_3} solid;
    }

    .ql-formats > button {
      position: relative;

      &:hover::after,
      &:active::after,
      &:focus::after {
        background: ${COLOR.BASE_BACKGROUND};
        color: ${COLOR.BASE_FOREGROUND};
        padding: 0.5em;
        border-radius: 0.4em;
        border: 1px solid ${COLOR.BASE_FOREGROUND};
        top: -120%;
        left: -10px;
        z-index: 999999;
        position: absolute;
        font-size: 12px;
        white-space: nowrap;
      }
    }

    .ql-formats > span {
      position: relative;

      &:hover::after,
      &:active::after,
      &:focus::after {
        background: ${COLOR.BASE_BACKGROUND};
        color: ${COLOR.BASE_FOREGROUND};
        padding: 0.5em;
        border-radius: 0.4em;
        border: 1px solid ${COLOR.BASE_FOREGROUND};
        top: -120%;
        left: -10px;
        z-index: 999999;
        position: absolute;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    .ql-color {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Text color';
      }
    }

    .ql-background {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Background color';
      }
    }

    .ql-header {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Heading';
      }
    }

    .ql-code-block {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Code block';
      }
    }

    .ql-indent[value='-1'] {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Indent -1';
      }
    }

    .ql-indent[value='+1'] {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Indent +1';
      }
    }

    .ql-script[value='sub'] {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Subscript';
      }
    }

    .ql-script[value='super'] {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Superscript';
      }
    }

    .ql-size {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Font size';
      }
    }

    .ql-font {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Font';
      }
    }

    .ql-align {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Alignment';
      }
    }

    .ql-clean {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Clean format';
      }
    }

    .ql-bold {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Bold';
      }
    }

    .ql-italic {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Italic';
      }
    }

    .ql-underline {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Underline';
      }
    }
    .ql-strike {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Strikeout';
      }
    }
    .ql-link {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Hyperlink';
      }
    }
    .ql-blockquote {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Quote';
      }
    }
    .ql-list[value='bullet'] {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Bulleted List';
        top: -200%;
      }
    }
    .ql-list[value='ordered'] {
      &:hover::after,
      &:active::after,
      &:focus::after {
        content: 'Numbered List';
        top: -200%;
      }
    }
  `,
  darkModeToolbar: css`
    .ql-stroke {
      stroke: ${COLOR.BASE_FOREGROUND};
      fill: none;
    }
    .ql-fill {
      fill: ${COLOR.BASE_FOREGROUND};
      stroke: none;
    }
    .ql-fill.ql-stroke {
      stroke: ${COLOR.BASE_FOREGROUND};
      fill: none;
    }
    .ql-picker {
      color: ${COLOR.BASE_FOREGROUND};
    }
    .ql-picker-options {
      color: ${COLOR.BASE_BACKGROUND};
    }
  `,
  modalEditorContainer: css`
    .ql-toolbar {
      border: none;
      display: block;
    }
    .ql-container {
      max-height: 70vh;
    }
  `,
  disabledModalContainer: css`
    .ql-toolbar {
      display: none;
    }
  `,
  readonlyEditor: css`
    width: 100%;

    .ql-hidden {
      display: none;
    }

    .ql-editor {
      padding: 0;
      ${styleCorrection};
    }
    .ql-editor > * {
      white-space: pre-wrap;
    }
  `
};
