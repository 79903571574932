import { ViewModel, getParsedActionChildren } from '@cyferd/client-engine';
import React, { memo, useMemo } from 'react';
import { styles } from './styles';
import { GAP } from '@constants';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTAType } from '@components/elements/CTA';

export const CyAction = memo(({ id, size, alignment, moreOptionsType, actionListChildren, effectChildren }: ViewModel.CyActionProps) => {
  const parsedActions = useMemo(() => getParsedActionChildren(actionListChildren), [actionListChildren]);
  const aligmentStyle = useMemo(
    () => ({
      flexWrap: 'wrap' as 'wrap',
      gap: `${GAP.M} ${GAP.S}`,
      ...(() => {
        switch (alignment) {
          case ViewModel.Alignment.LEFT:
            return { justifyContent: 'flex-start' };
          case ViewModel.Alignment.CENTER:
            return { justifyContent: 'center' };
          case ViewModel.Alignment.RIGHT:
          default:
            return { justifyContent: 'flex-end' };
        }
      })()
    }),
    [alignment]
  );

  if (!parsedActions?.length) return null;

  return (
    <div id={id} data-testid={id} css={styles.container}>
      <div data-testid="effects">{effectChildren}</div>
      <OptionMenu
        defaultBtnSize={size}
        defaultBtnType={moreOptionsType}
        importantContainerStyle={aligmentStyle}
        optionList={parsedActions.map(actionItem => ({
          label: actionItem.label,
          image: actionItem.icon,
          disabled: !!actionItem.disabled,
          onClick: actionItem.onClick && (event => actionItem.onClick(null, { metaKey: event?.metaKey })),
          color: actionItem.color,
          tooltip: actionItem.helperText,
          type: actionItem.type || CTAType.PRIMARY,
          important: actionItem.important,
          size
        }))}
      />
    </div>
  );
});

CyAction.displayName = ViewModel.DisplayName.CY_ACTION;
