import { logger } from './logger';

export const handleClipboardPaste = async (callback: (event: ClipboardEvent) => void): Promise<void> => {
  try {
    const text: string = await navigator.clipboard.readText();
    const pasteData: DataTransfer = new DataTransfer();
    pasteData.setData('text/plain', text);
    callback(
      new ClipboardEvent('paste', {
        clipboardData: pasteData
      })
    );
  } catch (error) {
    logger.error('Clipboard paste', error);
  }
};
