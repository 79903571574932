import type { SvgProps } from '../types';

export const Layout_K = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="39.5" y="39.5" width="39" height="39" rx="3.5" transform="rotate(-180 39.5 39.5)" stroke={fill} />
    <path
      d="M6 4.5L34 4.50001C34.8284 4.50001 35.5 5.17158 35.5 6.00001L35.5 34C35.5 34.8284 34.8284 35.5 34 35.5L5.99999 35.5C5.17157 35.5 4.5 34.8284 4.5 34L4.5 6C4.5 5.17157 5.17157 4.5 6 4.5Z"
      stroke={fill}
    />
  </svg>
);
