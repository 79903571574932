import { useCallback } from 'react';

export const useTestingHelper = (componentId: string) => {
  const getTestIdProps = useCallback(
    (id: string) => {
      return { 'data-testid': `${componentId}-${id}` };
    },
    [componentId]
  );
  return { getTestIdProps };
};
