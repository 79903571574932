import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { getFormulaInputRowItem } from '@components/elements/Evaluator/resources';
import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, createUUID, getFlatSchemaProps, isUUID, prepareTermForReg } from '@cyferd/client-engine';

import { userInputList } from './user';

export const getNewEntity = (): CollectionModel.Collection => ({
  schema: {
    type: 'object',
    required: [],
    properties: {
      id: {
        $id: 'id',
        key: 'id',
        title: 'ID',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.TEXT,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: { $cyf_exists: ['{{event.item.createdAt}}'] },
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 1,
          disabledType: GeneralModel.DisabledType.VIEW_ONLY
        }
      },
      recordTitle: {
        $id: 'recordTitle',
        key: 'recordTitle',
        title: 'Record title',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.TEXT,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: false,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 2
        }
      },
      recordDescription: {
        $id: 'recordDescription',
        key: 'recordDescription',
        title: 'Record description',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.MULTILINE,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: false,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 3
        }
      },
      recordImage: {
        $id: 'recordImage',
        key: 'recordImage',
        title: 'Record image',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: false,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 4
        }
      },
      recordColor: {
        $id: 'recordColor',
        key: 'recordColor',
        title: 'Record color',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.COLOR,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: false,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 5
        }
      },
      createdAt: {
        $id: 'createdAt',
        key: 'createdAt',
        title: 'Created at',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: true,
          disabledType: GeneralModel.DisabledType.VIEW_ONLY,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 6
        }
      },
      createdBy: {
        $id: 'createdBy',
        key: 'createdBy',
        title: 'Created by',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.TEXT,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: true,
          disabledType: GeneralModel.DisabledType.VIEW_ONLY,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 7
        }
      },
      updatedAt: {
        $id: 'updatedAt',
        key: 'updatedAt',
        title: 'Updated at',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: true,
          disabledType: GeneralModel.DisabledType.VIEW_ONLY,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 8
        }
      },
      updatedBy: {
        $id: 'updatedBy',
        key: 'updatedBy',
        title: 'Updated by',
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.TEXT,
        metadata: {
          core: true,
          hidden: false,
          detailGroupId: 'system',
          disabled: true,
          disabledType: GeneralModel.DisabledType.VIEW_ONLY,
          detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER,
          detailOrder: 9
        }
      }
    },
    additionalProperties: true
  },
  detailGroupList: [
    {
      id: 'system',
      name: 'System',
      order: 1,
      collapsible: true,
      startsCollapsed: true,
      hidden: true
    }
  ],
  globalSearchable: true,
  examples: [],
  name: 'New collection',
  id: createUUID()
});

export const entityInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'entity', base: 'event' }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'entity', base: 'event', path: 'item', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'entity', base: 'event', path: 'value' }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'entity', base: 'event', path: 'definition', format: GeneralModel.JSONSchemaFormat.OBJECT }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'entity', base: 'event', path: 'index', format: GeneralModel.JSONSchemaFormat.NUMBER }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'current',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    description: "Reference to the current field's data."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'current.key',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    description: "Reference to the current field's key. It would be a numeric string if parent is an array."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'current.path',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    description: "Reference to the current field's path starting from the root object."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'current.value',
    description: "Reference to the current field's value."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'parent',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    description: "Reference to the parent field's data."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'parent.key',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    description: "Reference to the parent field's key. It would be a numeric string if parent is an array."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'parent.path',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    description: "Reference to the parent field's path starting from the root object."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: 'parent.value',
    description: "Reference to the current field's value. It would contain the current field's value inside"
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: '$0',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    description: "Reference to the current field's data."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: '$1',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    description: "Reference to the parent field's data."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: '$2',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    description: "Reference to the current field's data 2 levels before."
  }),
  getFormulaInputRowItem({
    groupName: 'Relative reference',
    prefix: 'event',
    base: 'event',
    path: '$3',
    format: GeneralModel.JSONSchemaFormat.OBJECT,
    description: "Reference to the current field's data 3 levels before."
  })
];

export const getFieldLabelForEvaluatorRef = (props: ReturnType<typeof getFlatSchemaProps>[0]) =>
  props.displayNamePath
    .map(p => p.split('_'))
    .flat()
    .filter(p => !isUUID(p))
    .join(' ') || props.definition.title;

export const getEntityFullInputList = (schema: GeneralModel.JSONSchema) => [
  ...getFlatSchemaProps(schema, { includeArrays: true })
    .filter(p => p.path && p.path !== schema?.$id)
    .reduce(
      (total, p) =>
        [
          ...total,
          getFormulaInputRowItem({
            groupName: 'Record',
            prefix: 'entity',
            base: 'event',
            path: `item.${p.path.replace(new RegExp(`^(${prepareTermForReg(schema?.$id)}).`), '')}`,
            label: getFieldLabelForEvaluatorRef(p),
            description: p.definition.description,
            format: p.definition.format
          }),
          ...(p.definition.format !== GeneralModel.JSONSchemaFormat.ASSOCIATION
            ? []
            : [
                getFormulaInputRowItem({
                  groupName: 'Record',
                  prefix: 'entity',
                  base: 'event',
                  path: `item.${p.path.replace(new RegExp(`^(${prepareTermForReg(schema?.$id)}).`), '')}.0.recordTitle`,
                  label: `${getFieldLabelForEvaluatorRef(p)} 0 Record title`,
                  format: GeneralModel.JSONSchemaFormat.TEXT
                }),
                getFormulaInputRowItem({
                  groupName: 'Record',
                  prefix: 'entity',
                  base: 'event',
                  path: `item.$$${p.path.replace(new RegExp(`^(${prepareTermForReg(schema?.$id)}).`), '')}.totalCount`,
                  label: `${getFieldLabelForEvaluatorRef(p)}: Total count`,
                  format: GeneralModel.JSONSchemaFormat.NUMBER
                }),
                getFormulaInputRowItem({
                  groupName: 'Association pending',
                  prefix: 'entity',
                  base: 'event',
                  path: `item.$$${p.path.replace(new RegExp(`^(${prepareTermForReg(schema?.$id)}).`), '')}.add`,
                  label: `${getFieldLabelForEvaluatorRef(p)}: Add`,
                  format: GeneralModel.JSONSchemaFormat.ARRAY
                }),
                getFormulaInputRowItem({
                  groupName: 'Association pending',
                  prefix: 'entity',
                  base: 'event',
                  path: `item.$$${p.path.replace(new RegExp(`^(${prepareTermForReg(schema?.$id)}).`), '')}.remove`,
                  label: `${getFieldLabelForEvaluatorRef(p)}: Remove`,
                  format: GeneralModel.JSONSchemaFormat.ARRAY
                })
              ])
        ].sort((a, b) => a.label.localeCompare(b.label)),
      []
    ),
  ...entityInputList,
  ...userInputList
];
