import { CTAType } from '@components/elements/CTA/types';
import { DATA_TYPE_FORMAT, TRANS } from '@constants';
import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, capitalize, createUUID } from '@cyferd/client-engine';

export const creatorDetailGroupMap = {
  general: { id: createUUID(), image: 'page_info', name: TRANS.client.schemaEditor.groups.titles.advancedConfig, order: 1 } as CollectionModel.DetailGroup,
  association: { id: createUUID(), image: 'share', name: TRANS.client.schemaEditor.groups.titles.associationSettings, order: 2 } as CollectionModel.DetailGroup,
  actions: { id: createUUID(), image: 'bolt', name: TRANS.client.schemaEditor.groups.titles.actions, order: 3 } as CollectionModel.DetailGroup,
  appearance: { id: createUUID(), image: 'palette', name: TRANS.client.schemaEditor.groups.titles.advancedFormatting, order: 4 } as CollectionModel.DetailGroup,
  validation: { id: createUUID(), image: 'rule', name: TRANS.client.schemaEditor.groups.titles.validation, order: 5 } as CollectionModel.DetailGroup,
  options: { id: createUUID(), image: 'list_alt', name: TRANS.client.schemaEditor.groups.titles.options, order: 6 } as CollectionModel.DetailGroup
};

export const creatorDetailGroupList = Object.values(creatorDetailGroupMap);

export const schemaFieldList = [
  'title',
  'label',
  'type',
  'description',
  'info',
  'metadata',
  'detailPriority',
  'minProperties',
  'color',
  'pattern',
  'calculation',
  'subtype',
  'mask',
  'booleanLabelTrue',
  'booleanLabelFalse',
  'hidden',
  'allowFileInput',
  'allowedFileTypes',
  'default',
  'format',
  'maxLength',
  'minLength',
  'multipleOf',
  'maximum',
  'minimum',
  'optionList',
  'maxItems',
  'minItems',
  'uniqueItems',
  'disabled',
  'icon',
  'shouldCleanHidden',
  'invalid',
  'secondaryColor',
  'fieldSize',
  'ctaConfig',
  'startsCollapsed',
  'expanded',
  'iconImage',
  'suggestionList',
  'unlimitedHeight',
  'disabledType',
  'isCollapsible',
  'collapsible'
] as const;

export const getFieldConfig = (fieldName: (typeof schemaFieldList)[number]): GeneralModel.JSONSchema =>
  (
    ({
      label: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.label,
        type: 'string',
        metadata: { hidden: true, disabled: true, calculation: '{{event.item.title}}' }
      },
      title: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.title,
        type: 'string'
      },
      type: { $id: createUUID(), label: TRANS.client.schemaEditor.fields.titles.type, type: 'string', metadata: { hidden: true } },
      description: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.description,
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.TEXT,
        info: TRANS.client.schemaEditor.fields.descriptions.description,
        metadata: { detailGroupId: creatorDetailGroupMap.general.id, detailOrder: 1 }
      },
      info: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.info,
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.RICH_TEXT,
        info: TRANS.client.schemaEditor.fields.info.info,
        metadata: { detailGroupId: creatorDetailGroupMap.general.id, detailOrder: 2 }
      },
      metadata: {
        $id: createUUID(),
        type: 'object',
        label: ' ',
        properties: {}
      },
      minProperties: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.minProperties,
        type: 'number',
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      format: {
        $id: createUUID(),
        type: 'any',
        format: DATA_TYPE_FORMAT,
        label: TRANS.client.schemaEditor.fields.titles.format,
        description: TRANS.client.schemaEditor.fields.descriptions.format,
        metadata: {
          disabled: { $cyf_or: ['{{event.item.metadata.core}}', { $cyf_eq: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.ASSOCIATION] }] }
        }
      },
      pattern: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.pattern,
        type: 'string',
        info: TRANS.client.schemaEditor.fields.descriptions.pattern,
        metadata: {
          detailGroupId: creatorDetailGroupMap.validation.id,
          suggestionList: [
            { label: 'Credit card', value: '^(?:d{4}-){3}d{4}$|^d{16}$' },
            { label: 'IPv4', value: '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$' },
            { label: 'Basic password', value: '^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$' },
            { label: 'YYYY-MM-DD', value: '^d{4}-d{2}-d{2}$' },
            { label: 'URL', value: '^(https?://)?([da-z.-]+).([a-z.]{2,6})([/w.-]*)*/?$' },
            { label: 'Email', value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$' }
          ]
        }
      },
      booleanLabelTrue: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.booleanLabelTrue,
        info: TRANS.client.schemaEditor.fields.descriptions.booleanLabelTrue,
        type: 'string',
        metadata: { detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      booleanLabelFalse: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.booleanLabelFalse,
        info: TRANS.client.schemaEditor.fields.descriptions.booleanLabelFalse,
        type: 'string',
        metadata: { detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      color: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.color,
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.COLOR,
        info: TRANS.client.schemaEditor.fields.descriptions.color,
        metadata: { allowFormula: true, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      fieldSize: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.fieldSize.title,
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        info: TRANS.client.schemaEditor.fields.descriptions.fieldSize.description,
        metadata: {
          detailGroupId: creatorDetailGroupMap.appearance.id,
          optionList: [
            {
              value: 'regular',
              label: TRANS.client.schemaEditor.fields.titles.fieldSize.regular,
              description: TRANS.client.schemaEditor.fields.descriptions.fieldSize.regular,
              image: 'space_bar'
            },
            {
              value: 'full',
              label: TRANS.client.schemaEditor.fields.titles.fieldSize.full,
              description: TRANS.client.schemaEditor.fields.descriptions.fieldSize.full,
              image: 'width'
            }
          ]
        }
      },
      secondaryColor: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.secondaryColor,
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.COLOR,
        info: TRANS.client.schemaEditor.fields.descriptions.secondaryColor,
        metadata: { allowFormula: true, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      calculation: {
        $id: createUUID(),
        type: 'any',
        label: TRANS.client.schemaEditor.fields.titles.calculation,
        format: GeneralModel.JSONSchemaFormat.EVALUATION,
        metadata: { detailGroupId: creatorDetailGroupMap.general.id }
      },
      subtype: {
        $id: createUUID(),
        type: 'string',
        label: TRANS.client.schemaEditor.fields.titles.subtype,
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        metadata: { detailGroupId: creatorDetailGroupMap.general.id }
      },
      invalid: {
        $id: createUUID(),
        type: 'any',
        label: TRANS.client.schemaEditor.fields.titles.invalid,
        info: TRANS.client.schemaEditor.fields.descriptions.invalid,
        format: GeneralModel.JSONSchemaFormat.EVALUATION,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      detailPriority: {
        $id: createUUID(),
        type: 'string',
        info: TRANS.client.schemaEditor.fields.descriptions.detailPriority.description,
        label: TRANS.client.schemaEditor.fields.titles.detailPriority.title,
        default: GeneralModel.JSONSchemaPropertyPriority.IMPORTANT,
        metadata: {
          detailGroupId: creatorDetailGroupMap.general.id,
          optionList: [
            {
              value: GeneralModel.JSONSchemaPropertyPriority.IMPORTANT,
              label: TRANS.client.schemaEditor.fields.titles.detailPriority.important,
              description: TRANS.client.schemaEditor.fields.descriptions.detailPriority.important
            },
            {
              value: GeneralModel.JSONSchemaPropertyPriority.OTHER,
              label: TRANS.client.schemaEditor.fields.titles.detailPriority.other,
              description: TRANS.client.schemaEditor.fields.descriptions.detailPriority.other
            }
          ]
        }
      },
      mask: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.mask,
        type: 'string',
        info: TRANS.client.schemaEditor.fields.descriptions.mask.description,
        metadata: {
          detailGroupId: creatorDetailGroupMap.appearance.id,
          suggestionList: [
            {
              value: '[<1000]0;[<1000000]0,"K";#,##0,,"M"',
              label: 'Auto',
              info: TRANS.client.schemaEditor.fields.descriptions.mask.mask_1,
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '###,##0',
              label: '123,456',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '###,##0.0',
              label: '123,456.7',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '###,##0.00',
              label: '123,456.78',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '##0,"K"',
              label: '123K',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '0%',
              info: TRANS.client.schemaEditor.fields.descriptions.mask.mask_2,
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '# ?/?',
              label: '3/4',
              info: TRANS.client.schemaEditor.fields.descriptions.mask.mask_3,
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '[<1000000]0," KB";[<1000000000]0,," MB";0,,," GB"',
              label: 'File sizes',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.NUMBER] }
            },
            {
              value: '[<1000]"$ "0;[<1000000]"$ "0,"K";"$ "#,##0,,"M"',
              label: '$ Auto',
              info: TRANS.client.schemaEditor.fields.descriptions.mask.mask_4,
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.CURRENCY] }
            },
            {
              value: '"$ "###,##0.00',
              label: '$ 123.45',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.CURRENCY] }
            },
            {
              value: '[<1000]"£ "0;[<1000000]"£ "0,"K";"£ "#,##0,,"M"',
              label: '£ Auto',
              info: TRANS.client.schemaEditor.fields.descriptions.mask.mask_5,
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.CURRENCY] }
            },
            {
              value: '"£ "###,##0.00',
              label: '£ 123.45',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.CURRENCY] }
            },
            {
              value: '[<1000]0" €";[<1000000]0,"K €";#,##0,,"M €"',
              label: '€ Auto',
              info: TRANS.client.schemaEditor.fields.descriptions.mask.mask_6,
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.CURRENCY] }
            },
            {
              value: '###,##0.00" €"',
              label: '123.45 €',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.CURRENCY] }
            },
            {
              value: 'D MMM YYYY',
              label: '1 Jan 2001',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.DATE] }
            },
            {
              value: 'D MMMM YYYY',
              label: '1 January 2001',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.DATE] }
            },
            {
              value: 'DD/MM/YY',
              label: '01/01/01',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.DATE] }
            },
            {
              value: 'DD-MM-YYYY',
              label: '01-01-2001',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.DATE_TIME_U] }
            },
            {
              value: 'DD-MM-YYYY HH:mm',
              label: '01-01-2001 13:00',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.DATE_TIME_U] }
            },
            {
              value: 'DD-MM-YYYY hh:mm AM/PM',
              label: '01-01-2001 01:00 PM',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.DATE_TIME_U] }
            },
            {
              value: 'HH:mm',
              label: '13:00',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.TIME] }
            },
            {
              value: 'hh:mm AM/PM',
              label: '01:00 PM',
              hidden: { $cyf_ne: ['{{event.item.format}}', GeneralModel.JSONSchemaFormat.TIME] }
            }
          ]
        }
      },
      hidden: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.hidden,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        info: TRANS.client.schemaEditor.fields.descriptions.hidden,
        default: false,
        metadata: { allowFormula: true, detailGroupId: creatorDetailGroupMap.general.id }
      },
      icon: {
        $id: createUUID(),
        type: 'string',
        label: TRANS.client.schemaEditor.fields.titles.icon,
        format: GeneralModel.JSONSchemaFormat.ICON,
        info: TRANS.client.schemaEditor.fields.descriptions.icon,
        metadata: { allowFormula: true, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      iconImage: {
        $id: createUUID(),
        type: 'string',
        label: TRANS.client.schemaEditor.fields.titles.iconImage,
        format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
        info: TRANS.client.schemaEditor.fields.descriptions.iconImage,
        metadata: { allowFormula: true, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      default: {
        $id: 'default',
        label: TRANS.client.schemaEditor.fields.titles.default,
        type: 'boolean',
        info: TRANS.client.schemaEditor.fields.descriptions.default,
        metadata: { detailGroupId: creatorDetailGroupMap.general.id, allowFormula: false }
      },
      maxLength: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.maxLength,
        type: 'number',
        minimum: 0,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      minLength: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.minLength,
        type: 'number',
        minimum: 0,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      multipleOf: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.multipleOf,
        type: 'number',
        minimum: Number.EPSILON,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      maximum: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.maximum,
        type: 'number',
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      minimum: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.minimum,
        type: 'number',
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      uniqueItems: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.uniqueItems,
        info: TRANS.client.schemaEditor.fields.descriptions.uniqueItems,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: false,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      unlimitedHeight: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.unlimitedHeight,
        info: TRANS.client.schemaEditor.fields.descriptions.unlimitedHeight,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: false,
        metadata: { detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      disabledType: {
        $id: createUUID(),
        type: 'string',
        label: TRANS.client.schemaEditor.fields.titles.disabledType.title,
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        default: GeneralModel.DisabledType.VIEW_ONLY,
        metadata: {
          detailGroupId: creatorDetailGroupMap.general.id,
          optionList: [
            {
              value: GeneralModel.DisabledType.VIEW_ONLY,
              label: TRANS.client.schemaEditor.fields.titles.disabledType.readonly,
              description: TRANS.client.schemaEditor.fields.descriptions.disabledType.readonly
            },
            {
              value: GeneralModel.DisabledType.DISABLED,
              label: TRANS.client.schemaEditor.fields.titles.disabledType.disabled,
              description: TRANS.client.schemaEditor.fields.descriptions.disabledType.disabled
            }
          ]
        }
      },
      maxItems: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.maxItems,
        type: 'number',
        minimum: 0,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      minItems: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.minItems,
        type: 'number',
        minimum: 0,
        metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
      },
      optionList: {
        $id: createUUID(),
        type: 'array',
        label: TRANS.client.schemaEditor.fields.titles.optionList.title,
        info: TRANS.client.schemaEditor.fields.descriptions.optionList.description,
        metadata: { detailGroupId: creatorDetailGroupMap.options.id, ctaConfig: { label: TRANS.client.schemaEditor.fields.titles.optionList.cta } },
        items: {
          $id: createUUID(),
          label: TRANS.client.schemaEditor.fields.titles.option,
          type: 'object',
          required: ['value'],
          uniqueItems: true,
          metadata: { color: '{{event.value.color}}' },
          properties: {
            value: { $id: createUUID(), label: TRANS.client.fields.titles.value, type: 'string' },
            label: { $id: createUUID(), label: TRANS.client.fields.titles.label, type: 'string' },
            description: { $id: createUUID(), label: TRANS.client.fields.titles.description, type: 'string' },
            image: { $id: createUUID(), type: 'string', label: TRANS.client.fields.titles.image, format: GeneralModel.JSONSchemaFormat.ICON_IMAGE },
            color: {
              $id: createUUID(),
              type: 'string',
              label: TRANS.client.fields.titles.color,
              format: GeneralModel.JSONSchemaFormat.COLOR,
              info: TRANS.client.schemaEditor.fields.descriptions.optionList.color
            },
            hidden: {
              $id: createUUID(),
              type: 'boolean',
              label: TRANS.client.schemaEditor.fields.titles.hidden,
              format: GeneralModel.JSONSchemaFormat.CHECKBOX,
              info: TRANS.client.schemaEditor.fields.descriptions.optionList.hidden,
              default: false,
              metadata: { allowFormula: true }
            }
          }
        }
      },
      suggestionList: {
        $id: createUUID(),
        type: 'array',
        label: TRANS.client.schemaEditor.fields.titles.suggestionList.title,
        info: TRANS.client.schemaEditor.fields.descriptions.suggestionList,
        metadata: {
          detailGroupId: creatorDetailGroupMap.options.id,
          fieldSize: GeneralModel.FieldSize.FULL,
          ctaConfig: { label: TRANS.client.schemaEditor.fields.titles.suggestionList.cta }
        },
        items: {
          $id: createUUID(),
          type: 'object',
          required: ['value'],
          uniqueItems: true,
          label: ' ',
          properties: {
            value: { $id: createUUID(), label: TRANS.client.fields.titles.value, type: 'string' },
            label: { $id: createUUID(), label: TRANS.client.fields.titles.title, type: 'string' },
            description: { $id: createUUID(), label: TRANS.client.fields.titles.description, type: 'string' },
            image: { $id: createUUID(), type: 'string', label: TRANS.client.fields.titles.image, format: GeneralModel.JSONSchemaFormat.ICON_IMAGE },
            color: {
              $id: createUUID(),
              type: 'string',
              label: TRANS.client.fields.titles.color,
              format: GeneralModel.JSONSchemaFormat.COLOR,
              info: TRANS.client.schemaEditor.fields.descriptions.optionList.color
            },
            hidden: {
              $id: createUUID(),
              type: 'boolean',
              label: TRANS.client.schemaEditor.fields.titles.hidden,
              format: GeneralModel.JSONSchemaFormat.CHECKBOX,
              info: TRANS.client.schemaEditor.fields.descriptions.optionList.hidden,
              metadata: { allowFormula: true }
            }
          }
        }
      },
      disabled: {
        $id: createUUID(),
        label: TRANS.client.schemaEditor.fields.titles.disabled,
        type: 'boolean',
        info: TRANS.client.schemaEditor.fields.descriptions.disabled,
        metadata: { allowFormula: true, detailGroupId: creatorDetailGroupMap.general.id, detailOrder: 0 },
        default: false,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX
      },
      shouldCleanHidden: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.shouldCleanHidden,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        info: TRANS.client.schemaEditor.fields.descriptions.shouldCleanHidden,
        default: false,
        metadata: { detailGroupId: creatorDetailGroupMap.general.id }
      },
      allowFileInput: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.allowFileInput,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        info: TRANS.client.schemaEditor.fields.descriptions.allowFileInput,
        default: false,
        metadata: { allowFormula: false, detailGroupId: creatorDetailGroupMap.general.id }
      },
      startsCollapsed: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.startsCollapsed,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: false,
        metadata: { allowFormula: false, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      isCollapsible: {
        type: 'boolean',
        label: TRANS.client.fields.titles.collapsible,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: true,
        metadata: { detailGroupId: creatorDetailGroupMap.appearance.id, allowFormula: true }
      },
      collapsible: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.fields.titles.collapsible,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: false,
        metadata: { allowFormula: false, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      expanded: {
        $id: createUUID(),
        type: 'boolean',
        label: TRANS.client.schemaEditor.fields.titles.expanded,
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: true,
        metadata: { allowFormula: false, detailGroupId: creatorDetailGroupMap.appearance.id }
      },
      ctaConfig: {
        $id: createUUID(),
        type: 'object',
        label: TRANS.client.schemaEditor.fields.titles.ctaConfig.ctaConfig,
        format: GeneralModel.JSONSchemaFormat.OBJECT,
        metadata: {
          allowFormula: false,
          detailGroupId: creatorDetailGroupMap.appearance.id,
          subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
          fieldSize: GeneralModel.FieldSize.FULL
        },
        properties: {
          label: { label: TRANS.client.schemaEditor.fields.titles.ctaConfig.label, type: 'string', format: GeneralModel.JSONSchemaFormat.TEXT },
          image: { label: TRANS.client.schemaEditor.fields.titles.ctaConfig.image, type: 'string', format: GeneralModel.JSONSchemaFormat.ICON },
          color: { label: TRANS.client.schemaEditor.fields.titles.ctaConfig.color, type: 'string', format: GeneralModel.JSONSchemaFormat.COLOR },
          type: {
            label: TRANS.client.schemaEditor.fields.titles.ctaConfig.type,
            type: 'string',
            format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
            metadata: {
              optionList: Object.values(CTAType)
                .filter(t => t !== CTAType.SEEMLESS)
                .map(t => ({ value: t, label: capitalize(t.split('-').join(' ')) }))
            }
          }
        }
      }
    }) as Record<string, GeneralModel.JSONSchema>
  )[fieldName];

export const genericSchema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['type', 'format', 'label'],
  properties: {
    label: getFieldConfig('label'),
    title: getFieldConfig('title'),
    format: getFieldConfig('format')
  }
};

export const baseConfigSchema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['type', 'format', 'label'],
  properties: {
    description: getFieldConfig('description'),
    info: getFieldConfig('info'),
    metadata: {
      ...getFieldConfig('metadata'),
      properties: { detailPriority: getFieldConfig('detailPriority'), hidden: getFieldConfig('hidden') }
    }
  }
};

export const schemaFieldMap = Object.fromEntries(schemaFieldList.map(i => [i, getFieldConfig(i)])) as Record<
  (typeof schemaFieldList)[number],
  GeneralModel.JSONSchema
>;
