import { UserModel } from '@cyferd/client-engine';
import { TRANS } from './translations';

export const CLIENT_APP_ROUTE = {
  VIEW_EDITOR: {
    ROOT: '/views'
  },
  ENTITY_EDITOR: {
    ROOT: '/data'
  },
  FLOW_EDITOR: {
    ROOT: '/flows'
  },
  INTEGRATION_EDITOR: {
    ROOT: '/integrations'
  },
  USER_EDITOR: {
    ROOT: '/users'
  },
  TAG_EDITOR: {
    ROOT: '/tags'
  },
  DEVICE_MANAGER: {
    ROOT: '/devices'
  },
  PLATFORM_SETTINGS: {
    ROOT: '/settings'
  }
};

export const SECTION_NAME = {
  APPS: TRANS.client.nav.builder.apps,
  USERS: TRANS.client.nav.builder.users,
  DATA: TRANS.client.nav.builder.data,
  VIEWS: TRANS.client.nav.builder.views,
  FLOWS: TRANS.client.nav.builder.flows,
  INTEGRATIONS: TRANS.client.nav.builder.integrations,
  DEVICE_MANAGER: TRANS.client.nav.builder.devices,
  PLATFORM_SETTINGS: TRANS.client.nav.builder.platform,
  INVITATIONS: TRANS.client.nav.builder.invitations,
  SANDBOX: TRANS.client.nav.builder.sandbox,
  GUIDE: TRANS.client.nav.builder.guide
};

export const LIST_ROUTE = '/list';

export const BUILDER_ROUTE = {
  VIEW_EDITOR: {
    ROOT: '/builder/views',
    CHILDREN: {
      LIST: LIST_ROUTE,
      LOGS: '/logs',
      GENERAL: '/general',
      VIEW: '/layout',
      TAG_BOND: '/applications',
      ENTITY_BOND: '/collections',
      PREVIEW: '/preview',
      VARS: '/vars'
    }
  },
  ENTITY_EDITOR: {
    ROOT: '/builder/data',
    CHILDREN: {
      LIST: LIST_ROUTE,
      GENERAL: '/general',
      SCHEMA: '/definition',
      BOND: '/applications',
      DEFAULT: '/default-value',
      VIEW: '/views',
      TRIGGER: '/triggers',
      EXAMPLES: '/examples',
      ASSOCIATIONS: '/associations',
      SETTINGS: '/settings',
      FILTERS: '/filters'
    }
  },
  FLOW_EDITOR: {
    ROOT: '/builder/flows',
    CHILDREN: {
      LIST: LIST_ROUTE,
      GENERAL: '/general',
      STEPS: '/steps',
      LOGS: '/logs',
      TAG_BOND: '/applications',
      ENTITY_BOND: '/collections',
      PREVIEW: '/preview'
    }
  },
  INTEGRATION_EDITOR: {
    ROOT: '/builder/integrations',
    CHILDREN: {
      LIST: LIST_ROUTE,
      GENERAL: '/general',
      TAG_BOND: '/applications',
      LOGS: '/logs'
    }
  },
  USER_EDITOR: {
    ROOT: '/builder/users',
    CHILDREN: {
      LIST: LIST_ROUTE,
      GENERAL: '/general',
      BOND: '/applications'
    }
  },
  INVITATION_EDITOR: {
    ROOT: '/builder/invitations',
    CHILDREN: {
      LIST: LIST_ROUTE,
      GENERAL: '/general'
    }
  },
  TAG_EDITOR: {
    ROOT: '/builder/tags',
    CHILDREN: {
      LIST: LIST_ROUTE,
      GENERAL: '/general',
      ASSOCIATION_BOND: '/association',
      DATA_BOND: '/data',
      FLOW_BOND: '/flow',
      USER_BOND: '/user',
      VIEW_BOND: '/view',
      VIEW_SETTINGS: '/view-settings',
      INTEGRATION_BOND: '/integration'
    }
  },
  DEVICE_MANAGER: {
    ROOT: '/builder/devices',
    CHILDREN: {
      LIST: LIST_ROUTE
    }
  },
  PLATFORM_SETTINGS: {
    ROOT: '/builder/settings',
    CHILDREN: {
      MOBILE: '/mobile'
    }
  },
  GUIDE: {
    ROOT: '/builder/guide',
    CHILDREN: {}
  }
};

export enum QUERY_PARAM {
  RECORD_ID = 'id',
  JSON_OUTPUT = 'dev-view',
  ACTIVE_NODE_ID = 'node',
  ACTIVE_PROPERTY_ID = 'model-property',
  GUIDE_SECTION = 'section'
}

export enum BUILDER_ICON {
  APPS = 'apps',
  DATA = 'database',
  FLOWS = 'autoplay',
  VIEWS = 'view_quilt',
  INTEGRATIONS = 'power',
  DEVICE_MANAGER = 'devices',
  INVITATIONS = 'group_add',
  SANDBOX = 'design_services',
  USERS = 'person',
  PLATFORM_SETTINGS = 'domain',
  DRAFT = 'stylus_note',
  HELP = 'help'
}

export const BUILDER_DESCRIPTION = {
  APPS: TRANS.client.navSubtitle.apps,
  DATA: TRANS.client.navSubtitle.data,
  FLOWS: TRANS.client.navSubtitle.flows,
  VIEWS: TRANS.client.navSubtitle.views,
  INTEGRATIONS: TRANS.client.navSubtitle.integrations,
  DEVICE_MANAGER: TRANS.client.navSubtitle.devices,
  INVITATIONS: TRANS.client.navSubtitle.invitations,
  USERS: TRANS.client.navSubtitle.users,
  PLATFORM_SETTINGS: TRANS.client.navSubtitle.platform
};

export const routePermissionMap = {
  [BUILDER_ROUTE.ENTITY_EDITOR.ROOT]: [UserModel.UserRole.ENTITY_ADMIN],
  [BUILDER_ROUTE.TAG_EDITOR.ROOT]: [UserModel.UserRole.ENTITY_ADMIN],
  [BUILDER_ROUTE.VIEW_EDITOR.ROOT]: [UserModel.UserRole.DEVELOPER],
  [BUILDER_ROUTE.FLOW_EDITOR.ROOT]: [UserModel.UserRole.DEVELOPER],
  [BUILDER_ROUTE.USER_EDITOR.ROOT]: [UserModel.UserRole.USER_ADMIN],
  [BUILDER_ROUTE.INVITATION_EDITOR.ROOT]: [UserModel.UserRole.USER_ADMIN],
  [BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT]: [UserModel.UserRole.INTEGRATION_ADMIN],
  [BUILDER_ROUTE.DEVICE_MANAGER.ROOT]: [UserModel.UserRole.USER_ADMIN],
  [BUILDER_ROUTE.GUIDE.ROOT]: []
};

export const getIsDynamicPage = (pathname: string) => !/^(builder)/.test(pathname);
