import { useMemo } from 'react';

import type { CollectionModel, ViewModel, useParsers } from '@cyferd/client-engine';
import { isDeepEqual, listToMap, mergeTruthy, normalize, useOlderReference } from '@cyferd/client-engine';

interface UseFormModelArgs extends Pick<ViewModel.CyFormProps, 'value' | 'model'> {
  ungroupedKey: string;
  parseDetailGroupList: ReturnType<typeof useParsers>['parseDetailGroupList'];
  avoidAlphabeticalSort: boolean;
}

export const useFormModel = ({ value, model: modelOverride, ungroupedKey, avoidAlphabeticalSort, parseDetailGroupList }: UseFormModelArgs) => {
  const modelWithOverrides = useMemo(() => {
    return normalize.collection(mergeTruthy(value?.query || modelOverride, modelOverride) as CollectionModel.Collection, { avoidAlphabeticalSort });
  }, [avoidAlphabeticalSort, modelOverride, value?.query]);

  const { groupListId, parsedDetailGroupMap } = useMemo(
    () =>
      parseDetailGroupList({
        schema: modelWithOverrides?.schema,
        detailGroupMap: listToMap(modelWithOverrides?.detailGroupList || []),
        ungroupedKey,
        value: value?.record,
        fullValue: value?.record,
        path: ''
      }),
    [parseDetailGroupList, modelWithOverrides?.schema, modelWithOverrides?.detailGroupList, ungroupedKey, value?.record]
  );

  const newModel: CollectionModel.Collection = useMemo(
    () => ({ ...modelWithOverrides, detailGroupList: Object.values(parsedDetailGroupMap || /* istanbul ignore next */ {}) }),
    [modelWithOverrides, parsedDetailGroupMap]
  );

  const shouldUseOldModel = useOlderReference();
  const model = shouldUseOldModel(newModel, (o, n) => !isDeepEqual(o, n));

  return { model, groupListId, parsedDetailGroupMap };
};
