import { css } from '@emotion/react';

import { GAP } from '@constants';

export const styles = {
  cardContainer: css`
    list-style: none;
    margin: ${GAP.XXS} 0;
    display: grid;
    grid-column-gap: ${GAP.S};
    grid-row-gap: ${GAP.M};
    grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
    grid-auto-rows: min-content;
  `
};
