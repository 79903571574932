import { memo, useCallback } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CTA, CTAType } from '../../CTA';
import { styles } from './styles';
import { FONT_SIZE } from '@constants';
import { ViewModel } from '@cyferd/client-engine';

interface HandleZoomProps {
  children: React.ReactNode;
  offsideContent?: React.ReactNode;
  testid?: string;
}

export const FileViewerZoom = memo(({ children, offsideContent, testid = 'file-viewer-zoom' }: HandleZoomProps) => {
  const handleZoom = useCallback((zoomHandler: () => void) => zoomHandler(), []);
  return (
    <TransformWrapper initialScale={1} centerOnInit={true}>
      {({ zoomIn, zoomOut, resetTransform }) => {
        return (
          <div css={styles.wrapper}>
            <div css={styles.controls} data-testid={testid}>
              <div onDoubleClick={() => handleZoom(resetTransform)}>
                <CTA
                  testid={`${testid}-out`}
                  color="BRAND_1"
                  icon={{ name: 'zoom_out', size: FONT_SIZE.L }}
                  size={ViewModel.CTASize.XL}
                  onClick={() => handleZoom(zoomOut)}
                  type={CTAType.LINK}
                />
              </div>
              <CTA
                testid={`${testid}-in`}
                color="BRAND_1"
                icon={{ name: 'zoom_in', size: FONT_SIZE.L }}
                size={ViewModel.CTASize.XL}
                onClick={() => handleZoom(zoomIn)}
                type={CTAType.LINK}
              />
            </div>
            {offsideContent}
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%'
              }}
              contentStyle={{
                width: '100%',
                height: '100%',
                cursor: 'move'
              }}
            >
              {children}
            </TransformComponent>
          </div>
        );
      }}
    </TransformWrapper>
  );
});
