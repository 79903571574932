import { useContext } from 'react';
import { CyWrapperContext } from '@components/smart/CyWrapper';

export const useCyActions = () => {
  const { useAction } = useContext(CyWrapperContext);

  const onAgentResponse = useAction('agentResponse');
  const onBuiltinParseCsv = useAction('builtinParseCsv');
  const onBuiltinParseCollection = useAction('builtinParseCollection');
  const onBuiltinValidation = useAction('builtinValidation');
  const onBuiltinFormat = useAction('builtinFormat');
  const onBuiltinHydra = useAction('builtinHydra');
  const onBuiltinPick = useAction('builtinPick');
  const onBuiltinWait = useAction('builtinWait');
  const onConnectRest = useAction('connectRest');
  const onCoreGet = useAction('coreGet');
  const onCoreList = useAction('coreList');
  const onCoreCreate = useAction('coreCreate');
  const onCoreUpdate = useAction('coreUpdate');
  const onCoreUpsert = useAction('coreUpsert');
  const onCoreDelete = useAction('coreDelete');
  const onCoreDescribe = useAction('coreDescribe');
  const onCoreListIter = useAction('coreListIter');
  const onCoreGetCore = useAction('coreGetCore');
  const onCoreListCore = useAction('coreListCore');
  const onCoreCallAction = useAction('coreCallAction');
  const onCoreFetchFile = useAction('coreFetchFile');
  const onCoreSearch = useAction('coreSearch');
  const onCoreGetView = useAction('coreGetView');
  const onCoreMoveTo = useAction('coreMoveTo');
  const onCoreRunFlow = useAction('coreRunFlow');
  const onCoreSendNotifications = useAction('coreSendNotifications');
  const onCoreSetupAgent = useAction('coreSetupAgent');
  const onCoreShortLink = useAction('coreShortLink');
  const onCoreDownloadData = useAction('coreDownloadData');
  const onCoreSendInvitations = useAction('coreSendInvitations');
  const onDataGet = useAction('dataGet');
  const onDataList = useAction('dataList');
  const onDataCreate = useAction('dataCreate');
  const onDataUpdate = useAction('dataUpdate');
  const onDataUpsert = useAction('dataUpsert');
  const onDataDelete = useAction('dataDelete');
  const onDataDescribe = useAction('dataDescribe');
  const onDataCount = useAction('dataCount');
  const onDataBulk = useAction('dataBulk');
  const onDataCreateBulk = useAction('dataCreateBulk');
  const onDataUpdateBulk = useAction('dataUpdateBulk');
  const onDataUpsertBulk = useAction('dataUpsertBulk');
  const onDataDeleteBulk = useAction('dataDeleteBulk');
  const onDataUpdateMany = useAction('dataUpdateMany');
  const onDataDeleteMany = useAction('dataDeleteMany');
  const onDataGetFileUrl = useAction('dataGetFileUrl');
  const onDataAdminMove = useAction('dataAdminMove');
  const onDataAdminTruncate = useAction('dataAdminTruncate');
  const onDataAdminUpdate = useAction('dataAdminUpdate');
  const onDataAdminSetRemove = useAction('dataAdminSetRemove');
  const onDataAdminInsert = useAction('dataAdminInsert');
  const onDataAdminUpsert = useAction('dataAdminUpsert');
  const onDataAdminCount = useAction('dataAdminCount');
  const onDataInternalCreate = useAction('dataInternalCreate');
  const onDataInternalGet = useAction('dataInternalGet');
  const onDataInternalList = useAction('dataInternalList');
  const onDataInternalUpdate = useAction('dataInternalUpdate');
  const onDataInternalUpdateBulk = useAction('dataInternalUpdateBulk');
  const onDispatchMessage = useAction('dispatchMessage');
  const onDispatchNotification = useAction('dispatchNotification');
  const onDispatchNotificationRead = useAction('dispatchNotificationRead');
  const onDispatchSetData = useAction('dispatchSetData');
  const onDispatchError = useAction('dispatchError');
  const onDispatchResult = useAction('dispatchResult');
  const onDispatchNavigateTo = useAction('dispatchNavigateTo');
  const onDispatchNavigateReplace = useAction('dispatchNavigateReplace');
  const onDispatchNavigateBack = useAction('dispatchNavigateBack');
  const onDispatchNavigateForward = useAction('dispatchNavigateForward');
  const onDispatchSetUser = useAction('dispatchSetUser');
  const onDispatchSetView = useAction('dispatchSetView');
  const onDispatchSetCurrentFavorite = useAction('dispatchSetCurrentFavorite');
  const onDispatchToggleModal = useAction('dispatchToggleModal');
  const onDispatchDownloadFile = useAction('dispatchDownloadFile');
  const onDispatchRefresh = useAction('dispatchRefresh');
  const onDispatchUseAction = useAction('dispatchUseAction');
  const onDispatchFormModal = useAction('dispatchFormModal');
  const onEmailSend = useAction('emailSend');
  const onFileGetUrl = useAction('fileGetUrl');
  const onFileRead = useAction('fileRead');
  const onFileSave = useAction('fileSave');
  const onFileParseCsv = useAction('fileParseCsv');
  const onFileParseXlsx = useAction('fileParseXlsx');
  const onFileParseDocx = useAction('fileParseDocx');
  const onFileParsePdf = useAction('fileParsePdf');
  const onFileDocxFromTemplate = useAction('fileDocxFromTemplate');
  const onFileDocxToHtml = useAction('fileDocxToHtml');
  const onFlowAbort = useAction('flowAbort');
  const onFlowConsolidate = useAction('flowConsolidate');
  const onFlowRun = useAction('flowRun');
  const onFlowThrow = useAction('flowThrow');
  const onIntegrationGet = useAction('integrationGet');
  const onIntegrationList = useAction('integrationList');
  const onIntegrationCreate = useAction('integrationCreate');
  const onIntegrationUpdate = useAction('integrationUpdate');
  const onIntegrationUpsert = useAction('integrationUpsert');
  const onIntegrationDelete = useAction('integrationDelete');
  const onIntegrationCreateBulk = useAction('integrationCreateBulk');
  const onIntegrationUpsertBulk = useAction('integrationUpsertBulk');
  const onIntegrationTest = useAction('integrationTest');
  const onIntegrationLoop = useAction('integrationLoop');
  const onIntegrationRun = useAction('integrationRun');
  const onLoopTimes = useAction('loopTimes');
  const onLoopList = useAction('loopList');
  const onMobilePush = useAction('mobilePush');
  const onMobilePushBulk = useAction('mobilePushBulk');
  const onNgCollectionNarrative = useAction('ngCollectionNarrative');
  const onNgGenerateSchema = useAction('ngGenerateSchema');
  const onNgListCollections = useAction('ngListCollections');
  const onNgListExamples = useAction('ngListExamples');
  const onNgRaw = useAction('ngRaw');
  const onNgTrainModel = useAction('ngTrainModel');
  const onNgPredict = useAction('ngPredict');
  const onNgExtractFromFile = useAction('ngExtractFromFile');
  const onNotificationSend = useAction('notificationSend');
  const onNotificationMarkAsRead = useAction('notificationMarkAsRead');
  const onSearchRecords = useAction('searchRecords');
  const onTransformHtmlToPdf = useAction('transformHtmlToPdf');
  const onTransformHtmlToText = useAction('transformHtmlToText');
  const onTransformHtmlToImage = useAction('transformHtmlToImage');
  const onTransformPdfToImage = useAction('transformPdfToImage');
  const onTransformJsonToXml = useAction('transformJsonToXml');
  const onTransformXmlToJson = useAction('transformXmlToJson');
  const onTransformNavigateToQr = useAction('transformNavigateToQr');

  return {
    onAgentResponse,
    onBuiltinParseCsv,
    onBuiltinParseCollection,
    onBuiltinValidation,
    onBuiltinFormat,
    onBuiltinHydra,
    onBuiltinPick,
    onBuiltinWait,
    onConnectRest,
    onCoreGet,
    onCoreList,
    onCoreCreate,
    onCoreUpdate,
    onCoreUpsert,
    onCoreDelete,
    onCoreDescribe,
    onCoreListIter,
    onCoreGetCore,
    onCoreListCore,
    onCoreCallAction,
    onCoreFetchFile,
    onCoreSearch,
    onCoreGetView,
    onCoreMoveTo,
    onCoreRunFlow,
    onCoreSendNotifications,
    onCoreSetupAgent,
    onCoreShortLink,
    onCoreDownloadData,
    onCoreSendInvitations,
    onDataGet,
    onDataList,
    onDataCreate,
    onDataUpdate,
    onDataUpsert,
    onDataDelete,
    onDataDescribe,
    onDataCount,
    onDataBulk,
    onDataCreateBulk,
    onDataUpdateBulk,
    onDataUpsertBulk,
    onDataDeleteBulk,
    onDataUpdateMany,
    onDataDeleteMany,
    onDataGetFileUrl,
    onDataAdminMove,
    onDataAdminTruncate,
    onDataAdminUpdate,
    onDataAdminSetRemove,
    onDataAdminInsert,
    onDataAdminUpsert,
    onDataAdminCount,
    onDataInternalCreate,
    onDataInternalGet,
    onDataInternalList,
    onDataInternalUpdate,
    onDataInternalUpdateBulk,
    onDispatchMessage,
    onDispatchNotification,
    onDispatchNotificationRead,
    onDispatchSetData,
    onDispatchError,
    onDispatchResult,
    onDispatchNavigateTo,
    onDispatchNavigateReplace,
    onDispatchNavigateBack,
    onDispatchNavigateForward,
    onDispatchSetUser,
    onDispatchSetView,
    onDispatchSetCurrentFavorite,
    onDispatchToggleModal,
    onDispatchDownloadFile,
    onDispatchRefresh,
    onDispatchUseAction,
    onDispatchFormModal,
    onEmailSend,
    onFileGetUrl,
    onFileRead,
    onFileSave,
    onFileParseCsv,
    onFileParseXlsx,
    onFileParseDocx,
    onFileParsePdf,
    onFileDocxFromTemplate,
    onFileDocxToHtml,
    onFlowAbort,
    onFlowConsolidate,
    onFlowRun,
    onFlowThrow,
    onIntegrationGet,
    onIntegrationList,
    onIntegrationCreate,
    onIntegrationUpdate,
    onIntegrationUpsert,
    onIntegrationDelete,
    onIntegrationCreateBulk,
    onIntegrationUpsertBulk,
    onIntegrationTest,
    onIntegrationLoop,
    onIntegrationRun,
    onLoopTimes,
    onLoopList,
    onMobilePush,
    onMobilePushBulk,
    onNgCollectionNarrative,
    onNgGenerateSchema,
    onNgListCollections,
    onNgListExamples,
    onNgRaw,
    onNgTrainModel,
    onNgPredict,
    onNgExtractFromFile,
    onNotificationSend,
    onNotificationMarkAsRead,
    onSearchRecords,
    onTransformHtmlToPdf,
    onTransformHtmlToText,
    onTransformHtmlToImage,
    onTransformPdfToImage,
    onTransformJsonToXml,
    onTransformXmlToJson,
    onTransformNavigateToQr
  };
};

export default useCyActions;
