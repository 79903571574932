import type { PropsWithChildren } from 'react';
import { Children } from 'react';

import { styles } from './styles';

export type Props = PropsWithChildren<any>;

export const ItemList = ({ children }: Props) => {
  return (
    <div className={styles.container}>
      {Children.toArray(children).map((item, index) => (
        <div key={index} className={styles.item}>
          {item}
        </div>
      ))}
    </div>
  );
};
