import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, HC_COLOR, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

export const defaultTabColor = HC_COLOR.HC_15;

export const styles = {
  container: css`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${GAP.S};
  `,
  tabsContainer: css`
    margin-bottom: ${GAP.M};
    width: 100%;
    max-width: 100%;
    overflow-x: auto;

    display: flex;
    flex-wrap: nowrap;

    position: relative;
  `,
  tabsBorder: css`
    border-bottom: 1px solid ${COLOR.NEUTRAL_3};
  `,
  formTabsContainer: css`
    gap: ${GAP.XXS};
    margin-bottom: 0;
  `,
  btnContainer: css`
    flex: 0 0 auto;
    .cta-spinner-container {
      margin-right: -${GAP.L};
      padding-right: ${GAP.S};
    }
  `,
  activeBtnContainer: css`
    position: relative;
    z-index: 1;
  `,
  borderBottom: css`
    position: absolute;
    bottom: 0px;
    background-color: ${COLOR.NEUTRAL_3};
    height: ${GAP.XXXS};
    width: 100%;
  `,
  actions: css`
    padding: ${GAP.XS} ${GAP.S} 0 0;
  `,
  // LAYOUT TAB
  tabContainer: css`
    position: relative;
  `,
  tabContainerHover: css`
    :hover {
      > div {
        opacity: 0.8;
      }
      > span {
        > div,
        span {
          opacity: 0.8;
        }
      }
    }
  `,
  tab: css`
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${GAP.XS};
    align-items: center;

    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.S};
    span {
      color: ${COLOR.NEUTRAL_1};
    }

    padding: 0 ${GAP.L};
    height: 33px;
  `,
  disabledTab: css`
    span {
      color: ${COLOR.NEUTRAL_2};
    }
    cursor: not-allowed;
  `,
  activeTab: (color?: GeneralModel.Color.ThemeColor) => css`
    span {
      color: ${HC_COLOR[color] || defaultTabColor};
    }
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  disabledActiveBar: css`
    background-color: ${COLOR.NEUTRAL_2};
    cursor: not-allowed;
  `,
  activeBarColor: (color?: GeneralModel.Color.ThemeColor) => css`
    background-color: ${HC_COLOR[color] || defaultTabColor};
  `,
  activeBar: css`
    height: ${GAP.XS};
    width: 100%;
    border-radius: ${GAP.XS};
    transition: all ${TRANSITION_SPEED};
    position: absolute;
    bottom: 0;
  `,
  // FORM TAB
  formTab: css`
    height: 34px;
    padding: 0 ${GAP.M};
    border: 1px solid ${COLOR.NEUTRAL_4};
    border-top-left-radius: ${GAP.XS};
    border-top-right-radius: ${GAP.XS};
    border-bottom: none;

    background-color: ${SECONDARY_COLOR.NEUTRAL_6};
  `,
  formDisabledTab: css`
    background-color: ${COLOR.NEUTRAL_9};
  `,
  formActiveTab: css`
    border-color: ${COLOR.NEUTRAL_3};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
  `,
  formActiveBar: css`
    position: absolute;
    height: ${GAP.XS};
    width: 100%;
    border-top-left-radius: ${GAP.XS};
    border-top-right-radius: ${GAP.XS};
  `
};
