/* istanbul ignore file */

import { useMemo } from 'react';
import { styles } from '../styles';
import { FieldsetContent } from '@components/elements/Fieldset';
import { RichText } from '@components/elements/RichTextEditor';
import { getComponentConfig } from '@models/view';
import type { GeneralModel, ViewModel } from '@cyferd/client-engine';
import { ErrorBoundary, getComponentSchema, isObject, useTranslate } from '@cyferd/client-engine';
import { isListenerKey } from '../../../../builder/views/shared/AttributesEditor/AttributesEditor';
import { COLOR } from '@constants';
import { getPrettyTitlePath } from '../utils';

export interface ApiRefComponentsProps {
  component: ViewModel.DisplayName;
}

export const ApiRefComponents = ({ component }: ApiRefComponentsProps) => {
  const item = useMemo(() => getComponentConfig(component), [component]);
  const schema = useMemo(() => getComponentSchema(component), [component]);
  const allowedChildren = item.allowedChildren.map(i => getComponentConfig(i));
  const { translate } = useTranslate();

  return (
    <div>
      <FieldsetContent maxColumns={1}>
        <ErrorBoundary>
          <Props properties={schema.properties} />
        </ErrorBoundary>
        {!!allowedChildren.length && (
          <FieldsetContent maxColumns={1}>
            <div css={styles.division} />
            <RichText
              value={`<h5>It can contain</h5><ul>${allowedChildren.map(({ label }) => `<li>${translate(label)}</li>`).join('')}</ul>
            `}
            />
          </FieldsetContent>
        )}
      </FieldsetContent>
    </div>
  );
};

const Props = ({ properties, labelPath = [] }: { properties: Record<string, GeneralModel.JSONSchema>; labelPath?: string[] }) => {
  const { translate } = useTranslate();
  return Object.values(properties)
    .filter(prop => !['id', 'componentName', 'children'].includes(prop.key))
    .sort((a, b) => {
      if (isListenerKey(a.key)) return 1;
      if (isListenerKey(b.key)) return -1;
      return 0;
    })
    .map((prop, i) => {
      const isListener = isListenerKey(prop.key);
      const newLabelPath = [...labelPath, prop.label].map(l => l?.trim?.()).filter(Boolean);

      return (
        <div key={`${prop.$id}-${i}`}>
          {!!i && <div css={styles.division} />}
          {!!prop.key && (
            <div css={styles.compoPropsTable}>
              <RichText
                value={translate(`
  <h5>${prop.metadata?.hidden === true ? '⚠ UNSTABLE ' : ''}${getPrettyTitlePath(newLabelPath)}</h5>
  <p><strong style="color: ${COLOR.HC_2}">key:</strong> ${prop.key}</p>
  <p><strong style="color: ${COLOR.HC_2}">type:</strong> ${isListener ? 'action' : prop.type}</p>
  ${['string', 'number', 'boolean'].includes(typeof prop.default) ? `<p><strong style="color: ${COLOR.HC_2}">default:</strong> ${prop.default}</p>` : ''}
  ${
    !prop.metadata?.optionList?.length
      ? ''
      : `<p><strong style="color: ${COLOR.HC_2}">options:</strong></p>
  <ul>${prop.metadata.optionList.map(o => `<li><strong>${o.label}: </strong> ${o.value}</li>`).join('')}</ul>
  `
  }
  `)}
              />
              <RichText
                value={translate(`
  ${prop.description ? `<p>${prop.description}</p><p><br/></p>` : ''}
  ${prop.info ? `<p>${prop.info}</p>` : ''}
  `)}
              />
            </div>
          )}
          {prop.type === 'object' && !isListener && !!Object.keys(prop.properties || {}).length && (
            <>
              {!!prop.key && <div css={styles.division} />}
              <Props properties={prop.properties} labelPath={newLabelPath} />
            </>
          )}
          {prop.type === 'array' && !!Object.keys(prop.items?.properties || {}).length && (
            <>
              {!!prop.key && <div css={styles.division} />}
              <Props properties={prop.items.properties} labelPath={newLabelPath} />
            </>
          )}
        </div>
      );
    });
};

const getPropSearchContent = (prop: GeneralModel.JSONSchema): string[] => {
  if (!isObject(prop)) return [];
  return [
    prop.key,
    prop.label,
    prop.description,
    prop.info,
    prop.metadata?.optionList?.map?.(o => `${o.label}: ${o.value} ${o.description || ''}`),
    ...Object.values(prop.properties || {})
      .map(getPropSearchContent)
      .flat(),
    ...getPropSearchContent(prop.items)
  ].flat();
};

export const getComponentSearchContent = (component: ViewModel.DisplayName) => {
  const item = getComponentConfig(component);
  const schema = getComponentSchema(component);
  return [component, item.category, item.displayName, item.label, ...Object.values(schema.properties).map(getPropSearchContent)]
    .flat()
    .filter(Boolean)
    .join('<hr/>');
};
