import type { BaseFieldProps, SchemaFormBaseProps } from '@cyferd/client-engine';
import { GeneralModel } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { getLabel } from '@utils';
import type { IOptionListItem } from '@components/elements/OptionMenu';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { shouldAllowFormula } from '@components/elements/Evaluator/resources';
import { InlineDropdown } from '@components/elements/InlineDropdown';
import { SelectDropdown } from '@components/elements/SelectDropdown';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper';

export const useRenderOptionList = ({
  getIds,
  getOptionMenu,
  getErrorMessage,
  allowFormula,
  inputList
}: {
  getIds: Function;
  allowFormula: boolean;
  inputList: FormulaInputRow[];
  getOptionMenu: (event: BaseFieldProps) => IOptionListItem[];
  getErrorMessage: (hasError: boolean, error: string) => string;
}): SchemaFormBaseProps['componentRecord']['renderOptionList'] =>
  useCallback(
    props => {
      const { id, format, displayNamePath, value, onChange, optionList, required, disabled, disabledType, hasError, error, description, schema } = props;
      const commonComponentProps = {
        ...getIds(id),
        name: getLabel(displayNamePath),
        label: getLabel(displayNamePath),
        options: optionList,
        description,
        required,
        allowEmpty: !required,
        disabled,
        errorMessage: getErrorMessage(hasError, error),
        info: schema.info,
        disabledType: disabledType || schema.metadata?.disabledType
      };
      const actualInputList = [...(schema.metadata?.inputList || []), ...(inputList || [])];
      return (
        <EvaluatorWrapper
          label={getLabel(displayNamePath)}
          allowFormula={shouldAllowFormula(allowFormula, schema.metadata?.allowFormula)}
          value={value}
          disabled={disabled}
          description={description}
          required={required}
          onChange={onChange}
          inputList={actualInputList}
          format={format}
          openModalOnFocus={schema?.metadata?.openModalOnFocus}
          info={schema?.info}
          disabledType={disabledType || schema?.metadata?.disabledType}
          optionList={getOptionMenu(props)}
        >
          {fixedProps => {
            const innerCommonComponentProps = {
              value: fixedProps.value,
              optionList: [...fixedProps.evaluatorOptionList, ...getOptionMenu(props)],
              onChange: fixedProps.onChange
            };
            return [GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST].includes(format) ? (
              <InlineDropdown {...commonComponentProps} {...innerCommonComponentProps} />
            ) : (
              <SelectDropdown {...commonComponentProps} {...innerCommonComponentProps} />
            );
          }}
        </EvaluatorWrapper>
      );
    },
    [allowFormula, getErrorMessage, getIds, getOptionMenu, inputList]
  );
