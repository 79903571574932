import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SHADOW, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  cardItem: css`
    box-shadow: ${SHADOW.S};
    border-radius: ${RADIUS.S};
    color: ${COLOR.NEUTRAL_1};
    width: 100%;
    overflow: hidden;
  `,
  innerContainer: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    border: 1px solid ${COLOR.BASE_BACKGROUND};
    transition: all ${TRANSITION_SPEED};
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    align-items: stretch;
    height: 90px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      border-radius: ${RADIUS.S};
      width: ${GAP.XS};
    }
  `,
  borderColor: props => css`
    &::before {
      background-color: ${props.backgroundColor};
    }
  `,
  cardItemClickable: css`
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
      box-shadow: ${SHADOW.M};
      top: -1px;
      transition: all ${TRANSITION_SPEED};
    }
  `,
  title: css`
    word-break: break-word;
    overflow: hidden;
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.S};
    line-height: ${FONT_SIZE.M};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  subtitle: css`
    padding-top: ${GAP.XXS};
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    > span {
      -webkit-line-clamp: 2;
      word-break: break-word;
    }
  `,
  imageContainer: css`
    width: 100%;
    max-width: 90px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    img {
      object-fit: cover;
      height: 100%;
    }
  `,
  iconContainer: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    background-color: ${COLOR.NEUTRAL_5};
    span {
      font-size: ${FONT_SIZE.XXXL};
    }
  `,
  iconContent: css`
    min-height: 32px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  image: css`
    width: 100%;
  `,
  content: css`
    display: grid;
    grid-template-columns: 1fr auto;
    padding: ${GAP.M};
    width: 100%;
    height: 100%;
  `,
  extraPadding: css`
    padding-left: ${GAP.L};
  `,
  leftContent: css`
    display: flex;
    width: 100%;
    flex: 1;
  `,
  rightContent: css`
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
  `,
  textContent: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    align-self: center;
  `
};
