import { css } from '@emotion/react';

import { COLOR, GAP } from '@constants';

export const styles = {
  tooltipContainer: css`
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.S};
    color: ${COLOR.BASE_FOREGROUND};
    border: 1px solid ${COLOR.NEUTRAL_4};
    max-width: 300px;
    word-break: break-word;
  `,
  contentArrow: css`
    fill: ${COLOR.TOP_BACKGROUND};
  `,
  childrenContainer: css`
    overflow: hidden;
  `
};
