import { css } from '@emotion/react';

export const styles = {
  flag: css`
    width: 16px;
    height: 11px;
  `,
  ad: css`
    background-position: -16px 0;
  `,
  ae: css`
    background-position: -32px 0;
  `,
  af: css`
    background-position: -48px 0;
  `,
  ag: css`
    background-position: -64px 0;
  `,
  ai: css`
    background-position: -80px 0;
  `,
  al: css`
    background-position: -96px 0;
  `,
  am: css`
    background-position: -112px 0;
  `,
  ao: css`
    background-position: -128px 0;
  `,
  ar: css`
    background-position: -144px 0;
  `,
  as: css`
    background-position: -160px 0;
  `,
  at: css`
    background-position: -176px 0;
  `,
  au: css`
    background-position: -192px 0;
  `,
  aw: css`
    background-position: -208px 0;
  `,
  az: css`
    background-position: -224px 0;
  `,
  ba: css`
    background-position: -240px 0;
  `,
  bb: css`
    background-position: 0 -11px;
  `,
  bd: css`
    background-position: -16px -11px;
  `,
  be: css`
    background-position: -32px -11px;
  `,
  bf: css`
    background-position: -48px -11px;
  `,
  bg: css`
    background-position: -64px -11px;
  `,
  bh: css`
    background-position: -80px -11px;
  `,
  bi: css`
    background-position: -96px -11px;
  `,
  bj: css`
    background-position: -112px -11px;
  `,
  bm: css`
    background-position: -128px -11px;
  `,
  bn: css`
    background-position: -144px -11px;
  `,
  bo: css`
    background-position: -160px -11px;
  `,
  br: css`
    background-position: -176px -11px;
  `,
  bs: css`
    background-position: -192px -11px;
  `,
  bt: css`
    background-position: -208px -11px;
  `,
  bw: css`
    background-position: -224px -11px;
  `,
  by: css`
    background-position: -240px -11px;
  `,
  bz: css`
    background-position: 0 -22px;
  `,
  ca: css`
    background-position: -16px -22px;
  `,
  cd: css`
    background-position: -32px -22px;
  `,
  cf: css`
    background-position: -48px -22px;
  `,
  cg: css`
    background-position: -64px -22px;
  `,
  ch: css`
    background-position: -80px -22px;
  `,
  ci: css`
    background-position: -96px -22px;
  `,
  ck: css`
    background-position: -112px -22px;
  `,
  cl: css`
    background-position: -128px -22px;
  `,
  cm: css`
    background-position: -144px -22px;
  `,
  cn: css`
    background-position: -160px -22px;
  `,
  co: css`
    background-position: -176px -22px;
  `,
  cr: css`
    background-position: -192px -22px;
  `,
  cu: css`
    background-position: -208px -22px;
  `,
  cv: css`
    background-position: -224px -22px;
  `,
  cw: css`
    background-position: -240px -22px;
  `,
  cy: css`
    background-position: 0 -33px;
  `,
  cz: css`
    background-position: -16px -33px;
  `,
  de: css`
    background-position: -32px -33px;
  `,
  dj: css`
    background-position: -48px -33px;
  `,
  dk: css`
    background-position: -64px -33px;
  `,
  dm: css`
    background-position: -80px -33px;
  `,
  do: css`
    background-position: -96px -33px;
  `,
  dz: css`
    background-position: -112px -33px;
  `,
  ec: css`
    background-position: -128px -33px;
  `,
  ee: css`
    background-position: -144px -33px;
  `,
  eg: css`
    background-position: -160px -33px;
  `,
  er: css`
    background-position: -176px -33px;
  `,
  es: css`
    background-position: -192px -33px;
  `,
  et: css`
    background-position: -208px -33px;
  `,
  fi: css`
    background-position: -224px -33px;
  `,
  fj: css`
    background-position: -240px -33px;
  `,
  fk: css`
    background-position: 0 -44px;
  `,
  fm: css`
    background-position: -16px -44px;
  `,
  fo: css`
    background-position: -32px -44px;
  `,
  fr: css`
    background-position: -48px -44px;
  `,
  bl: css`
    background-position: -48px -44px;
  `,
  mf: css`
    background-position: -48px -44px;
  `,
  ga: css`
    background-position: -64px -44px;
  `,
  gb: css`
    background-position: -80px -44px;
  `,
  gd: css`
    background-position: -96px -44px;
  `,
  ge: css`
    background-position: -112px -44px;
  `,
  gf: css`
    background-position: -128px -44px;
  `,
  gh: css`
    background-position: -144px -44px;
  `,
  gi: css`
    background-position: -160px -44px;
  `,
  gl: css`
    background-position: -176px -44px;
  `,
  gm: css`
    background-position: -192px -44px;
  `,
  gn: css`
    background-position: -208px -44px;
  `,
  gp: css`
    background-position: -224px -44px;
  `,
  gq: css`
    background-position: -240px -44px;
  `,
  gr: css`
    background-position: 0 -55px;
  `,
  gt: css`
    background-position: -16px -55px;
  `,
  gu: css`
    background-position: -32px -55px;
  `,
  gw: css`
    background-position: -48px -55px;
  `,
  gy: css`
    background-position: -64px -55px;
  `,
  hk: css`
    background-position: -80px -55px;
  `,
  hn: css`
    background-position: -96px -55px;
  `,
  hr: css`
    background-position: -112px -55px;
  `,
  ht: css`
    background-position: -128px -55px;
  `,
  hu: css`
    background-position: -144px -55px;
  `,
  id: css`
    background-position: -160px -55px;
  `,
  ie: css`
    background-position: -176px -55px;
  `,
  il: css`
    background-position: -192px -55px;
  `,
  in: css`
    background-position: -208px -55px;
  `,
  io: css`
    background-position: -224px -55px;
  `,
  iq: css`
    background-position: -240px -55px;
  `,
  ir: css`
    background-position: 0 -66px;
  `,
  is: css`
    background-position: -16px -66px;
  `,
  it: css`
    background-position: -32px -66px;
  `,
  je: css`
    background-position: -144px -154px;
  `,
  jm: css`
    background-position: -48px -66px;
  `,
  jo: css`
    background-position: -64px -66px;
  `,
  jp: css`
    background-position: -80px -66px;
  `,
  ke: css`
    background-position: -96px -66px;
  `,
  kg: css`
    background-position: -112px -66px;
  `,
  kh: css`
    background-position: -128px -66px;
  `,
  ki: css`
    background-position: -144px -66px;
  `,
  xk: css`
    background-position: -128px -154px;
  `,
  km: css`
    background-position: -160px -66px;
  `,
  kn: css`
    background-position: -176px -66px;
  `,
  kp: css`
    background-position: -192px -66px;
  `,
  kr: css`
    background-position: -208px -66px;
  `,
  kw: css`
    background-position: -224px -66px;
  `,
  ky: css`
    background-position: -240px -66px;
  `,
  kz: css`
    background-position: 0 -77px;
  `,
  la: css`
    background-position: -16px -77px;
  `,
  lb: css`
    background-position: -32px -77px;
  `,
  lc: css`
    background-position: -48px -77px;
  `,
  li: css`
    background-position: -64px -77px;
  `,
  lk: css`
    background-position: -80px -77px;
  `,
  lr: css`
    background-position: -96px -77px;
  `,
  ls: css`
    background-position: -112px -77px;
  `,
  lt: css`
    background-position: -128px -77px;
  `,
  lu: css`
    background-position: -144px -77px;
  `,
  lv: css`
    background-position: -160px -77px;
  `,
  ly: css`
    background-position: -176px -77px;
  `,
  ma: css`
    background-position: -192px -77px;
  `,
  mc: css`
    background-position: -208px -77px;
  `,
  md: css`
    background-position: -224px -77px;
  `,
  me: css`
    background-position: -112px -154px;
    height: 12px;
  `,
  mg: css`
    background-position: 0 -88px;
  `,
  mh: css`
    background-position: -16px -88px;
  `,
  mk: css`
    background-position: -32px -88px;
  `,
  ml: css`
    background-position: -48px -88px;
  `,
  mm: css`
    background-position: -64px -88px;
  `,
  mn: css`
    background-position: -80px -88px;
  `,
  mo: css`
    background-position: -96px -88px;
  `,
  mp: css`
    background-position: -112px -88px;
  `,
  mq: css`
    background-position: -128px -88px;
  `,
  mr: css`
    background-position: -144px -88px;
  `,
  ms: css`
    background-position: -160px -88px;
  `,
  mt: css`
    background-position: -176px -88px;
  `,
  mu: css`
    background-position: -192px -88px;
  `,
  mv: css`
    background-position: -208px -88px;
  `,
  mw: css`
    background-position: -224px -88px;
  `,
  mx: css`
    background-position: -240px -88px;
  `,
  my: css`
    background-position: 0 -99px;
  `,
  mz: css`
    background-position: -16px -99px;
  `,
  na: css`
    background-position: -32px -99px;
  `,
  nc: css`
    background-position: -48px -99px;
  `,
  ne: css`
    background-position: -64px -99px;
  `,
  nf: css`
    background-position: -80px -99px;
  `,
  ng: css`
    background-position: -96px -99px;
  `,
  ni: css`
    background-position: -112px -99px;
  `,
  nl: css`
    background-position: -128px -99px;
  `,
  bq: css`
    background-position: -128px -99px;
  `,
  no: css`
    background-position: -144px -99px;
  `,
  np: css`
    background-position: -160px -99px;
  `,
  nr: css`
    background-position: -176px -99px;
  `,
  nu: css`
    background-position: -192px -99px;
  `,
  nz: css`
    background-position: -208px -99px;
  `,
  om: css`
    background-position: -224px -99px;
  `,
  pa: css`
    background-position: -240px -99px;
  `,
  pe: css`
    background-position: 0 -110px;
  `,
  pf: css`
    background-position: -16px -110px;
  `,
  pg: css`
    background-position: -32px -110px;
  `,
  ph: css`
    background-position: -48px -110px;
  `,
  pk: css`
    background-position: -64px -110px;
  `,
  pl: css`
    background-position: -80px -110px;
  `,
  pm: css`
    background-position: -96px -110px;
  `,
  pr: css`
    background-position: -112px -110px;
  `,
  ps: css`
    background-position: -128px -110px;
  `,
  pt: css`
    background-position: -144px -110px;
  `,
  pw: css`
    background-position: -160px -110px;
  `,
  py: css`
    background-position: -176px -110px;
  `,
  qa: css`
    background-position: -192px -110px;
  `,
  re: css`
    background-position: -208px -110px;
  `,
  ro: css`
    background-position: -224px -110px;
  `,
  rs: css`
    background-position: -240px -110px;
  `,
  ru: css`
    background-position: 0 -121px;
  `,
  rw: css`
    background-position: -16px -121px;
  `,
  sa: css`
    background-position: -32px -121px;
  `,
  sb: css`
    background-position: -48px -121px;
  `,
  sc: css`
    background-position: -64px -121px;
  `,
  sd: css`
    background-position: -80px -121px;
  `,
  se: css`
    background-position: -96px -121px;
  `,
  sg: css`
    background-position: -112px -121px;
  `,
  sh: css`
    background-position: -128px -121px;
  `,
  si: css`
    background-position: -144px -121px;
  `,
  sk: css`
    background-position: -160px -121px;
  `,
  sl: css`
    background-position: -176px -121px;
  `,
  sm: css`
    background-position: -192px -121px;
  `,
  sn: css`
    background-position: -208px -121px;
  `,
  so: css`
    background-position: -224px -121px;
  `,
  sr: css`
    background-position: -240px -121px;
  `,
  ss: css`
    background-position: 0 -132px;
  `,
  st: css`
    background-position: -16px -132px;
  `,
  sv: css`
    background-position: -32px -132px;
  `,
  sx: css`
    background-position: -48px -132px;
  `,
  sy: css`
    background-position: -64px -132px;
  `,
  sz: css`
    background-position: -80px -132px;
  `,
  tc: css`
    background-position: -96px -132px;
  `,
  td: css`
    background-position: -112px -132px;
  `,
  tg: css`
    background-position: -128px -132px;
  `,
  th: css`
    background-position: -144px -132px;
  `,
  tj: css`
    background-position: -160px -132px;
  `,
  tk: css`
    background-position: -176px -132px;
  `,
  tl: css`
    background-position: -192px -132px;
  `,
  tm: css`
    background-position: -208px -132px;
  `,
  tn: css`
    background-position: -224px -132px;
  `,
  to: css`
    background-position: -240px -132px;
  `,
  tr: css`
    background-position: 0 -143px;
  `,
  tt: css`
    background-position: -16px -143px;
  `,
  tv: css`
    background-position: -32px -143px;
  `,
  tw: css`
    background-position: -48px -143px;
  `,
  tz: css`
    background-position: -64px -143px;
  `,
  ua: css`
    background-position: -80px -143px;
  `,
  ug: css`
    background-position: -96px -143px;
  `,
  us: css`
    background-position: -112px -143px;
  `,
  uy: css`
    background-position: -128px -143px;
  `,
  uz: css`
    background-position: -144px -143px;
  `,
  va: css`
    background-position: -160px -143px;
  `,
  vc: css`
    background-position: -176px -143px;
  `,
  ve: css`
    background-position: -192px -143px;
  `,
  vg: css`
    background-position: -208px -143px;
  `,
  vi: css`
    background-position: -224px -143px;
  `,
  vn: css`
    background-position: -240px -143px;
  `,
  vu: css`
    background-position: 0 -154px;
  `,
  wf: css`
    background-position: -16px -154px;
  `,
  ws: css`
    background-position: -32px -154px;
  `,
  ye: css`
    background-position: -48px -154px;
  `,
  za: css`
    background-position: -64px -154px;
  `,
  zm: css`
    background-position: -80px -154px;
  `,
  zw: css`
    background-position: -96px -154px;
  `
};
