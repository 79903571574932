import { Translate, ViewModel } from '@cyferd/client-engine';

import { CTA, CTAType, CustomWplCTAType } from '../../CTA';
import { FONT_SIZE } from '@constants';
import { ToolTip } from '../../Tooltip';
import { useMemo, useState } from 'react';
import { IconImage } from '../../Icon/renderIcon';
import { styles } from './styles';
import { styles as tabListStyles } from '../TabList/styles';
import type { TabListProps } from '../TabList';
import { TabListType } from '../TabList';
import { useSelectNonDisabledTab } from '../useSelectFirstNonDisabledTab';

export const VerticalTabList = ({ tabList, activeTab, tabColor, onChangeTab, startsCollapsed = false, type = TabListType.LAYOUT }: TabListProps) => {
  const [expanded, setExpanded] = useState<boolean>(startsCollapsed);
  const onToggle = () => setExpanded(p => !p);
  const list = useMemo(() => (tabList || []).filter(tab => !tab.hidden), [tabList]);
  const isFormType = type === TabListType.FORM;
  useSelectNonDisabledTab({ activeTab, tabList: list, onChangeTab });

  return (
    list?.length > 0 && (
      <div css={[styles.container, !isFormType && styles.containerBorder, isFormType && styles.formContainer, expanded && styles.expandedContainer]}>
        <div css={[styles.expandBtn]} onClick={onToggle} data-testid="vertical-tabs-expand-btn">
          <CTA
            size={ViewModel.CTASize.MEDIUM}
            type={CTAType.LINK}
            color="BRAND_1"
            tooltip={expanded ? 'Collapse' : 'Expand'}
            icon={expanded ? 'chevron_left' : 'chevron_right'}
          />
        </div>
        {list.map((tab, index) => {
          const isActive = activeTab === tab.title;
          const hasLabel = !!(tab.displayName || tab.title);
          return (
            <div id={tab.title} role="tab" key={index} css={[tabListStyles.btnContainer, styles.btnContainer, !!isActive && styles.activeTab]}>
              <ToolTip text={tab.displayName || tab.title}>
                <CTA
                  type={CustomWplCTAType.SEEMLESS}
                  disabled={!!tab.disabled}
                  onClick={() => onChangeTab(tab.title)}
                  testid={`tab-${index + 1}`}
                  hideLoading={true}
                >
                  <div css={[tabListStyles.tabContainer, !tab.disabled && tabListStyles.tabContainerHover]}>
                    {!!isFormType && (
                      <div
                        css={[
                          styles.formActivebar,
                          !!isActive && tabListStyles.activeBarColor(tabColor),
                          !!tab.disabled && !!isActive && /* istanbul ignore next */ tabListStyles.disabledActiveBar
                        ]}
                      />
                    )}
                    <span
                      css={[
                        tabListStyles.tab,
                        styles.verticalTab,
                        expanded && styles.tabExpanded,
                        !!tab.disabled && tabListStyles.disabledTab,
                        !!isActive && tabListStyles.activeTab(tabColor),
                        !!isFormType && styles.verticalFormTab,
                        !!isFormType && !!isActive && /* istanbul ignore next */ tabListStyles.formActiveTab
                      ]}
                    >
                      {tab.icon ? (
                        <IconImage title="" icon={tab.icon} imageProps={{ size: FONT_SIZE.L }} iconProps={{ size: FONT_SIZE.L, css: styles.iconContainer }} />
                      ) : (
                        <div css={[styles.iconContainer, expanded && styles.iconContainerHidden]}>
                          <span
                            css={[
                              styles.circle,
                              !!isActive && styles.activeCircle(tabColor),
                              !!tab.disabled && /* istanbul ignore next */ styles.disabledCircle
                            ]}
                          />
                        </div>
                      )}
                      {!!expanded && hasLabel && (
                        <span css={styles.tabLabel}>
                          <Translate>{tab.displayName || tab.title}</Translate>
                        </span>
                      )}
                      {!isFormType && (
                        <div
                          css={[
                            styles.activeBar,
                            !!isActive && tabListStyles.activeBarColor(tabColor),
                            !!tab.disabled && !!isActive && /* istanbul ignore next */ tabListStyles.disabledActiveBar
                          ]}
                        />
                      )}
                    </span>
                  </div>
                </CTA>
              </ToolTip>
            </div>
          );
        })}
        {isFormType && <div css={styles.borderRight} />}
      </div>
    )
  );
};
