import { createContext, useContext, useState } from 'react';
import { styles } from './styles';
import type { GeneralModel } from '@cyferd/client-engine';
import { Evaluator, noop } from '@cyferd/client-engine';
import { JSONSyntaxEditor } from '@components/elements/JSONSyntaxEditor';
import { CTA, CTAType } from '@components/elements/CTA';
import { logger, useEvaluationPayload } from '@utils';
import { ENV } from '@constants';

/* istanbul ignore next line */
const parseData = (formula: any, body?: any) => {
  try {
    return Evaluator.parse(formula).evaluate(body);
  } catch (error) {
    logger.warn(ENV.APP_LOGGER_HEADER, 'Error evaluating', { formula, body, error });
    return { errorMessage: (error as any)?.message, errorStack: (error as any)?.stack, error };
  }
};

export interface FormulaTesterContainerContextValue {
  payload: any;
}

export const FormulaTesterContainerContext = createContext<FormulaTesterContainerContextValue>({ payload: undefined });

export interface FormulaTesterProps {
  formula: any;
  height: string;
}

export const FormulaTester = ({ formula, height }: FormulaTesterProps) => {
  const [output, setOutput] = useState<any>();
  const context = useContext(FormulaTesterContainerContext);
  const originalPayload = useEvaluationPayload();
  const [evaluationPayload, setEvaluationPayload] = useState<GeneralModel.EvaluatorFormula>(() => ({ event: context?.payload, ...originalPayload }));

  const onRun = () => {
    setOutput(parseData(formula, evaluationPayload));
  };

  return (
    <div data-testid="formula-tester" css={styles.container}>
      <div css={styles.json}>
        <JSONSyntaxEditor
          testid="input"
          expanded={true}
          height={`calc(calc(${height} - 140px) * 0.65)`}
          label="Event"
          onChange={setEvaluationPayload}
          value={evaluationPayload}
        />
      </div>
      <div css={styles.json}>
        <JSONSyntaxEditor
          testid="output"
          expanded={true}
          height={`calc(calc(${height} - 140px) * 0.35)`}
          label="Output"
          onChange={noop}
          value={output}
          disabled={true}
        />
      </div>
      <CTA testid="output-panel-run" type={CTAType.SECONDARY} label="Try" onClick={onRun} />
    </div>
  );
};

FormulaTester.displayName = 'FormulaTester';
