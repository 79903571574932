import type { ComponentProps, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';

import type { AssociationFilterOutput, parseSchemaForFilters } from '@cyferd/client-engine';
import { FilterModel, GeneralModel, createUUID, isEmptyObject, isObject, mergeTruthy } from '@cyferd/client-engine';
import { getLabel } from '@utils';

import type { BaseForm } from '../../../../smart/CyForm/components/BaseForm';
import type { SelectDropdownProps } from '../../../SelectDropdown';
import { SelectDropdown } from '../../../SelectDropdown';
import { styles } from '../../styles';
import { TRANS } from '@constants';

const getInputTypeSchema = (columnFormat: GeneralModel.JSONSchemaFormat, operator: FilterModel.Operator): GeneralModel.JSONSchema => {
  const format = FilterModel.inputFormatPerOperatorMap[operator]?.includes(columnFormat) ? columnFormat : FilterModel.inputFormatPerOperatorMap[operator]?.[0];
  const optionList = [
    GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
    GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
    GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST
  ].includes(format)
    ? undefined
    : null;

  return {
    $id: createUUID(),
    type: 'object',
    default: {},
    required: [],
    properties: {
      value: {
        $id: 'value',
        description: '',
        type: GeneralModel.formatToTypeMap[format] || 'string',
        format,
        label: FilterModel.opLabelMap[operator] || 'Value',
        enum: optionList,
        metadata: { disabled: false, hidden: false, calculation: null, mask: null, optionList }
      }
    },
    additionalProperties: true
  };
};

export interface FilterRowProps {
  testid?: string;
  value: FilterModel.FilterRowValue | FilterModel.FilterRowAssociatedValue;
  propOptionList: ReturnType<typeof parseSchemaForFilters>['propOptionList'];
  flatPropDefinitionMap: Record<string, GeneralModel.JSONSchema>;
  associationFilterList: AssociationFilterOutput[];
  onChange: (value: FilterModel.FilterRowValue) => void;
  renderSchemaForm?: (props: ComponentProps<typeof BaseForm>) => ReactNode;
  disabled?: boolean;
  allowFormula?: boolean;
}

const nextValueFormat = (operator: string): boolean | undefined => {
  switch (operator) {
    case '$notExists':
      return false;
    case '$exists':
      return true;
    default:
      return undefined;
  }
};

enum MatchesType {
  ANY = 'any',
  RECORD = 'record',
  FIELDS = 'fields'
}

const getMatchType = (operator: string, value: any) => {
  if (!['$notMatches', '$matches'].includes(operator) || !isObject(value)) return undefined;
  if (!value || isEmptyObject(value)) return MatchesType.ANY;
  if (Object.keys(value).includes('filter')) return MatchesType.FIELDS;
  return MatchesType.RECORD;
};

const getCurrentValueForMatches = (
  associationSelected: AssociationFilterOutput,
  value: FilterRowProps['value'],
  operator: string,
  matchType: MatchesType,
  associationKey: string
) => {
  if (associationSelected) {
    if (matchType === MatchesType.FIELDS)
      return value?.[associationSelected.associationSchema.metadata?.association?.cursor?.associationKey]?.[operator]?.filter?.[associationKey];
    if (matchType === MatchesType.RECORD) return value?.[associationSelected.associationSchema.metadata?.association?.cursor?.associationKey]?.[operator]?.id;
  }
  return undefined;
};

const getAssociationKey = (matchType: MatchesType | undefined, operator: string, value: any, fieldKey: string | number) => {
  const targetObj = matchType !== undefined && matchType !== MatchesType.FIELDS ? value?.[fieldKey]?.[operator] : value?.[fieldKey]?.[operator]?.filter;

  return Object.keys(targetObj ?? {})[0];
};

export const FilterRow = ({
  value,
  testid,
  propOptionList,
  flatPropDefinitionMap,
  associationFilterList,
  onChange,
  renderSchemaForm,
  disabled: disabledExternal = false,
  allowFormula
}: FilterRowProps) => {
  const internalTestid: string = `${testid}-filter-row`;
  const [valueFieldKey]: string[] = Object.keys(value);
  const options: SelectDropdownProps['options'] = useMemo(
    () => [
      ...propOptionList.map(({ displayNamePath, value }) => ({ label: getLabel(displayNamePath), value })),
      ...associationFilterList.map(({ associationSchema }) => ({
        label: associationSchema.title,
        value: associationSchema.metadata?.association?.cursor?.associationKey
      }))
    ],
    [associationFilterList, propOptionList]
  );
  const fieldKey = valueFieldKey || options[0]?.value;

  const associationSelected =
    fieldKey && associationFilterList.find(({ associationSchema }) => associationSchema.metadata?.association?.cursor?.associationKey === fieldKey);
  const [operator] = Object.keys(value[fieldKey] || []);
  const typeOperatorOptions = (() => {
    if (associationSelected) return ['$matches', '$notMatches'] as FilterModel.Operator[];
    return FilterModel.operatorListPerFormat[flatPropDefinitionMap[fieldKey]?.format]
      ? [...FilterModel.operatorListPerFormat[flatPropDefinitionMap[fieldKey].format as GeneralModel.JSONSchemaFormat], '$notExists']
      : [];
  })().map(op => ({
    label: op === '$notExists' ? "Doesn't exist" : FilterModel.opLabelMap[op],
    value: op
  }));
  const fieldValue = value?.[fieldKey]?.[operator];
  const matchType = getMatchType(operator, fieldValue);
  const associationKey = getAssociationKey(matchType, operator, value, fieldKey);
  const associationGetFieldValue = getCurrentValueForMatches(associationSelected, value, operator, matchType, associationKey);
  const associationCollectionId = associationSelected?.associationSchema?.metadata?.association?.cursor?.collectionId;
  const disabled = (!!associationKey && !associationSelected) || disabledExternal;

  const columnSchema = (
    flatPropDefinitionMap[fieldKey]?.type !== 'array' ? flatPropDefinitionMap[fieldKey] : flatPropDefinitionMap[fieldKey]?.items
  ) as GeneralModel.JSONSchema;
  const columnFormat: GeneralModel.JSONSchemaFormat = columnSchema?.format as GeneralModel.JSONSchemaFormat;

  const onColumnChange = (col: string) => onChange({ [col]: {} });

  const onOperatorChange = useCallback(
    (op: string) => {
      onChange({ [fieldKey]: { [op === '$notExists' ? '$exists' : op]: nextValueFormat(op) } });
    },
    [fieldKey, onChange]
  );

  /* istanbul ignore next line | @todo */
  const onAssociationKeyChange = (k: string) => onChange({ [fieldKey]: { [operator]: { filter: { [k]: undefined } } } });

  const onMatchTypesChange = (key: MatchesType) => {
    switch (key) {
      case MatchesType.RECORD:
        return onChange({ [fieldKey]: { [operator]: { id: undefined } } });

      case MatchesType.FIELDS:
        const innerKey = (associationSelected?.propOptionList || /* istanbul ignore next | @todo */ [])[0]?.value;
        return onChange({ [fieldKey]: { [operator]: { filter: { [innerKey]: undefined } } } });

      default:
        return onChange({ [fieldKey]: { [operator]: {} } });
    }
  };

  const onValueChange = useCallback(
    (v: any) => {
      const valueObj = associationSelected ? (matchType === MatchesType.RECORD ? { id: v } : { filter: { [associationKey]: v?.value } }) : v?.value;
      onChange({ [fieldKey]: { [operator]: valueObj } });
    },
    [onChange, fieldKey, operator, associationSelected, associationKey, matchType]
  );

  const inputSchema: GeneralModel.JSONSchema = useMemo(() => {
    if (associationSelected) {
      if (matchType === MatchesType.FIELDS) {
        return mergeTruthy(
          { properties: { value: associationSelected.flatPropDefinitionMap[associationKey] } },
          mergeTruthy(getInputTypeSchema(associationSelected.flatPropDefinitionMap[associationKey]?.format, '$eq' as FilterModel.Operator), {
            properties: { value: { label: TRANS.client.fields.titles.value } }
          }),
          arg => arg !== undefined
        );
      }
      if (matchType === MatchesType.RECORD) {
        const fetchCriteria = {
          collectionId: associationCollectionId
        } as GeneralModel.FetchCriteria;
        return {
          metadata: {
            fetchCriteria,
            collectionId: associationCollectionId,
            associationKey: associationSelected?.associationSchema?.metadata?.association?.cursor?.associationKey,
            associationId: associationSelected?.associationSchema?.metadata?.association?.cursor?.associationId
          },
          format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
          label: TRANS.client.fields.titles.value
        };
      }
    }

    const columnSchemaWithHiddenOverride: GeneralModel.JSONSchema = {
      ...columnSchema,
      default: undefined,
      metadata: {
        ...columnSchema?.metadata,
        optionList: columnSchema?.metadata?.optionList?.map(option => ({ ...option, hidden: option?.hidden === true }))
      }
    };

    return mergeTruthy(
      { properties: { value: columnSchemaWithHiddenOverride } },
      getInputTypeSchema(columnFormat, operator as FilterModel.Operator),
      arg => arg !== undefined
    );
  }, [associationSelected, associationKey, columnFormat, operator, columnSchema, matchType, associationCollectionId]);

  const getComponentRecord: ComponentProps<typeof BaseForm>['getComponentRecord'] = useCallback(
    r => ({
      ...r,
      renderFieldWrapper: ({ children }) => <>{children}</>,
      renderGroupWrapper: ({ children }) => <>{children}</>,
      renderGroupContentWrapper: ({ children }) => <>{children}</>,
      renderGroupLabel: () => null
    }),
    []
  );

  const isExistsOperator = ['$exists', '$notExists'].includes(operator);
  const isMatchOperator = ['$matches', '$notMatches'].includes(operator);
  const renderValue = associationSelected || associationKey ? associationGetFieldValue : fieldValue;

  return (
    <div css={styles.grid} data-testid={internalTestid}>
      <div>
        <SelectDropdown
          allowEmpty={true}
          testid={`${internalTestid}-column-selector`}
          label="Field"
          options={options}
          onChange={onColumnChange}
          value={fieldKey}
          disabled={disabled}
        />
      </div>
      <div>
        <SelectDropdown
          allowEmpty={true}
          key={operator}
          testid={`${testid}-operator-selector`}
          label="Operator"
          value={renderValue === false && operator === '$exists' ? '$notExists' : operator}
          options={typeOperatorOptions}
          onChange={onOperatorChange}
          disabled={disabled}
        />
      </div>
      {!!(associationKey || associationSelected) && (
        <>
          {isMatchOperator && (
            <div>
              <SelectDropdown
                allowEmpty={true}
                key={operator}
                testid={`${testid}-association-match-type-selector`}
                label="Match type"
                value={matchType}
                options={[
                  { label: 'Any', value: 'any' },
                  { label: 'Record', value: 'record' },
                  { label: 'Fields', value: 'fields' }
                ]}
                onChange={onMatchTypesChange}
                disabled={disabled}
              />
            </div>
          )}
          {matchType === MatchesType.FIELDS && (
            <div>
              <SelectDropdown
                allowEmpty={true}
                key={operator}
                testid={`${testid}-association-key-selector`}
                label="Association field"
                value={associationKey}
                options={(associationSelected?.propOptionList || /* istanbul ignore next | @todo */ []).map(({ displayNamePath, value }) => ({
                  label: getLabel(displayNamePath),
                  value
                }))}
                onChange={onAssociationKeyChange}
                disabled={disabled || !operator}
              />
            </div>
          )}
        </>
      )}
      <div
        key={operator}
        data-testid={`${testid}-value-form`}
        css={styles.existsOperator({ withOutOperator: isExistsOperator, isMatchAny: matchType === MatchesType.ANY })}
      >
        {renderSchemaForm({
          id: `${testid}-value-form`,
          onChange: onValueChange,
          schema: inputSchema,
          value: matchType === MatchesType.RECORD ? renderValue : { value: renderValue },
          getComponentRecord,
          disabled: disabled || (associationSelected ? !associationKey : !operator),
          avoidInitialSync: matchType !== MatchesType.RECORD,
          shouldValidate: false,
          allowFormula: allowFormula
        })}
      </div>
    </div>
  );
};
