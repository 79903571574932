export const CONTROL_KEYS = [
  'Shift',
  'Control',
  'Alt',
  'Meta',
  'Escape',
  'Tab',
  'CapsLock',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Enter',
  'Backspace',
  'Delete',
  'Home',
  'End',
  'PageUp',
  'PageDown',
  'Insert'
];

export const isControlKey = (key: string) => CONTROL_KEYS.includes(key);
