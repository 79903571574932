import { ViewModel, getComponentSchema } from '@cyferd/client-engine';
import { memo, useMemo } from 'react';

import { OptionMenu } from '../OptionMenu';
import { TRANS } from '@constants';

export interface DisplaySettingsModalProps {
  type: ViewModel.CyListType;
  disabled?: boolean;
  onChangeType: (type: ViewModel.CyListType) => void;
}

export const DisplaySettingsModal = memo(({ type, disabled, onChangeType }: DisplaySettingsModalProps) => {
  const icon = useMemo(() => getComponentSchema(ViewModel.DisplayName.CY_LIST).properties.type.metadata.optionList.find(o => o.value === type)?.image, [type]);
  return (
    <OptionMenu
      defaultBtnIcon={icon as any}
      defaultTooltip={TRANS.client.buttons.listType}
      defaultBtnType={ViewModel.CTAType.LINK}
      defaultBtnTestid="open-display-settings"
      defaultDisabled={disabled}
      optionList={getComponentSchema(ViewModel.DisplayName.CY_LIST).properties.type.metadata.optionList.map((option: any) => ({
        onClick: () => onChangeType(option.value),
        label: option.label,
        image: option.image,
        testid: `${option.label}-btn`,
        color: option.value === type ? 'BRAND_1' : undefined
      }))}
    />
  );
});
