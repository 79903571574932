import type { SvgProps } from '../types';

export const Fx = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="2 2 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.76686 11.8337V10.8337H7.71686L9.60019 8.66699L7.71686 6.50033H6.51686L5.4502 12.1003C5.3502 12.667 5.15286 13.0974 4.8582 13.3917C4.56397 13.6863 4.18353 13.8337 3.71686 13.8337C3.27242 13.8337 2.90864 13.7141 2.62553 13.475C2.34197 13.2363 2.2002 12.917 2.2002 12.517C2.2002 12.217 2.28086 11.9754 2.4422 11.7923C2.60308 11.6088 2.8002 11.517 3.03353 11.517C3.26686 11.517 3.4642 11.5974 3.62553 11.7583C3.78642 11.9197 3.86686 12.1114 3.86686 12.3337C3.86686 12.4225 3.84753 12.5003 3.80886 12.567C3.76975 12.6337 3.72242 12.6892 3.66686 12.7337C3.68908 12.7448 3.71975 12.7588 3.75886 12.7757C3.79753 12.7921 3.83908 12.8003 3.88353 12.8003C4.02797 12.8003 4.14753 12.7365 4.2422 12.609C4.33642 12.481 4.41131 12.2837 4.46686 12.017L5.5002 6.50033H3.56686V5.50033H5.68353L6.03353 3.68366C6.11131 3.20588 6.30019 2.83366 6.60019 2.56699C6.90019 2.30033 7.27242 2.16699 7.71686 2.16699C8.15019 2.16699 8.50864 2.29477 8.79219 2.55033C9.07531 2.80588 9.21686 3.1281 9.21686 3.51699C9.21686 3.80588 9.13642 4.0421 8.97553 4.22566C8.8142 4.40877 8.61686 4.50033 8.38353 4.50033C8.15019 4.50033 7.95308 4.42255 7.79219 4.26699C7.63086 4.11144 7.55019 3.91699 7.55019 3.68366C7.55019 3.59477 7.56975 3.51699 7.60886 3.45033C7.64753 3.38366 7.69464 3.3281 7.7502 3.28366C7.73908 3.26144 7.70864 3.24188 7.65886 3.22499C7.60864 3.20855 7.56131 3.20033 7.51686 3.20033C7.38353 3.21144 7.27531 3.27255 7.19219 3.38366C7.10864 3.49477 7.04464 3.65588 7.0002 3.86699L6.71686 5.50033H9.76686V6.50033H9.03353L10.2669 7.90033L11.5002 6.50033H10.7669V5.50033H13.7669V6.50033H12.8335L10.9335 8.66699L12.8335 10.8337H13.7669V11.8337H10.7669V10.8337H11.5002L10.2669 9.41699L9.03353 10.8337H9.76686V11.8337H6.76686Z"
      fill={fill}
    />
  </svg>
);
