import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, INPUT_HEIGHT, RADIUS, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
    max-width: 100%;
    overflow: auto;
    display: grid;
    grid-auto-columns: 250px;
    grid-auto-flow: column;
    grid-gap: ${GAP.M};
  `,
  column: css`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  grabbingCursor: css`
    cursor: grabbing;
  `,
  columnDraggingOver: css`
    background-color: ${COLOR.NEUTRAL_9};
  `,
  columnTitle: css`
    background-color: ${COLOR.NEUTRAL_5};
    padding: 0 ${GAP.S};
    min-height: calc(${INPUT_HEIGHT} - 2px);
    border-top-left-radius: ${RADIUS.S};
    border-top-right-radius: ${RADIUS.S};
    color: ${FOREGROUND_COLOR.NEUTRAL_5};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
    font-size: ${FONT_SIZE.S};
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    small {
      word-break: keep-all;
    }
  `,
  columnTitleText: css`
    display: flex;
    align-items: center;
    color: ${FOREGROUND_COLOR.NEUTRAL_5};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    gap: ${GAP.S};
  `,
  listContainer: css`
    flex: 1;
    background-color: ${COLOR.NEUTRAL_5};
    padding: ${GAP.S} ${GAP.S} ${GAP.M} ${GAP.S};
    border-bottom-left-radius: ${RADIUS.S};
    border-bottom-right-radius: ${RADIUS.S};
    transition: all ${TRANSITION_SPEED};
  `,
  list: css`
    display: grid;
    grid-gap: ${GAP.S};
    grid-template-columns: 100%;
  `,
  disabledList: css`
    opacity: 0.7;
  `
};
