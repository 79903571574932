import { useCallback, useMemo } from 'react';

import type { FormComponentRecord } from '@cyferd/client-engine';

import { useRenderArrayContent } from './useRenderArrayContent';
import { useRenderArrayItem } from './useRenderArrayItem';
import { useRenderBoolean } from './useRenderBoolean';
import { useRenderDefault } from './useRenderDefault';
import { useRenderFieldWrapper } from './useRenderFieldWrapper';
import { useRenderGroupContentWrapper } from './useRenderGroupContentWrapper';
import { useRenderInputWrapper } from './useRenderInputWrapper';
import { useRenderNumber } from './useRenderNumber';
import { useRenderOptionList } from './useRenderOptionList';
import { useRenderText } from './useRenderText';

type UseComponentRecordArgs = Parameters<typeof useRenderFieldWrapper>[0] &
  Parameters<typeof useRenderInputWrapper>[0] &
  Parameters<typeof useRenderGroupContentWrapper>[0] &
  Parameters<typeof useRenderArrayItem>[0] &
  Parameters<typeof useRenderOptionList>[0] &
  Parameters<typeof useRenderText>[0] &
  Parameters<typeof useRenderNumber>[0] &
  Parameters<typeof useRenderBoolean>[0] &
  Parameters<typeof useRenderDefault>[0];

export const useComponentRecord = ({
  id,
  getIds,
  ungroupedKey,
  asStepper,
  currentStepId,
  wrapDetailGroups,
  apiQuery,
  allowFormula,
  inputList,
  maxColumns,
  dragChange,
  addDefaults,
  setDragChange,
  getOptionMenu,
  getErrorMessage,
  onGetFileRequest,
  onDownloadFile,
  getComponentRecord
}: UseComponentRecordArgs): FormComponentRecord => {
  const renderGroupWrapper = useCallback(({ children }) => children, []);
  const renderGroupLabel = useCallback(() => null, []);
  const renderLabel = useCallback(() => null, []);
  const renderFieldError = useCallback(() => null, []);
  const renderArrayContent = useRenderArrayContent({});
  const renderAddArrayItemButton = useCallback(() => null, []);

  const renderFieldWrapper = useRenderFieldWrapper({ getIds, dragChange, maxColumns, setDragChange });
  const renderInputWrapper = useRenderInputWrapper({ getIds, maxColumns, allowFormula, inputList, addDefaults, getOptionMenu });
  const renderGroupContentWrapper = useRenderGroupContentWrapper({ ungroupedKey, asStepper, currentStepId, wrapDetailGroups, id, maxColumns });
  const renderArrayItem = useRenderArrayItem({ getIds, maxColumns });
  const renderOptionList = useRenderOptionList({ getIds, allowFormula, inputList, getOptionMenu, getErrorMessage });
  const renderText = useRenderText({ getIds, allowFormula, inputList, getOptionMenu, getErrorMessage });
  const renderNumber = useRenderNumber({ getIds, allowFormula, inputList, getOptionMenu, getErrorMessage });
  const renderBoolean = useRenderBoolean({ getIds, allowFormula, inputList, getOptionMenu, getErrorMessage });
  const renderDefault = useRenderDefault({ getIds, apiQuery, allowFormula, inputList, getOptionMenu, onGetFileRequest, onDownloadFile, getComponentRecord });

  return useMemo(
    () =>
      ({
        renderGroupWrapper,
        renderGroupLabel,
        renderLabel,
        renderFieldWrapper,
        renderInputWrapper,
        renderGroupContentWrapper,
        renderFieldError,
        renderArrayContent,
        renderArrayItem,
        renderAddArrayItemButton,
        renderOptionList,
        renderText,
        renderNumber,
        renderBoolean,
        renderDefault
      }) as FormComponentRecord,
    [
      renderGroupWrapper,
      renderGroupLabel,
      renderLabel,
      renderFieldWrapper,
      renderInputWrapper,
      renderGroupContentWrapper,
      renderFieldError,
      renderArrayContent,
      renderArrayItem,
      renderAddArrayItemButton,
      renderOptionList,
      renderText,
      renderNumber,
      renderBoolean,
      renderDefault
    ]
  );
};
