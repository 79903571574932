import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';

import { GeneralModel, ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import type { IOptionListItem } from '../OptionMenu';
import { CTA, CTAType } from '../CTA';
import { InputWrapper } from '../InputWrapper';
import { Modal } from '../Modal';
import { THEME } from '@constants';
import { UIContext } from '@components/providers/UIprovider';
import { useOutsideClick } from '@utils/useOutsideClick';

Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/font'), true);
Quill.register(Quill.import('attributors/style/size'), true);

export type RichTextEditorProps = {
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange: (editorState: string) => void;
  optionList?: IOptionListItem[];
  required?: boolean;
  id?: string;
  value: string;
  info?: string;
  color?: GeneralModel.Color.ThemeColor;
  unlimitedHeight?: boolean;
  disabledType?: GeneralModel.DisabledType;
};

export const RichTextEditor: FC<RichTextEditorProps> = ({
  description,
  disabled,
  errorMessage,
  label,
  optionList,
  required,
  id,
  value,
  onChange,
  info,
  color,
  unlimitedHeight,
  disabledType
}) => {
  const { state: themeState } = useContext(UIContext);
  const testid = id || 'rich-text-editor';
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const richTextEditorRef = useRef<ReactQuill>();
  const onToggleModal = useCallback(() => setModalOpen(p => !p), []);

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);
  const mainRef = useOutsideClick(onBlur, 'mousedown');
  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const internalOptionList = useMemo(
    () =>
      [
        ...(optionList || []),
        !unlimitedHeight &&
          (!isReadonly || !!value) && {
            tooltip: 'Full screen',
            image: 'open_in_full',
            onClick: onToggleModal,
            type: CTAType.LINK,
            important: !optionList?.length,
            size: ViewModel.CTASize.MEDIUM
          }
      ].filter(Boolean) as IOptionListItem[],
    [isReadonly, onToggleModal, optionList, unlimitedHeight, value]
  );

  const colors = useMemo(() => GeneralModel.Color.clientColorGroupList.flat().map(color => `var(--${color})`), []);

  const modules = useMemo(
    () => ({
      toolbar: [
        [
          { header: [1, 2, 3, 4, 5, false] },
          'code-block',
          'blockquote',
          { align: [] },
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
          'bold',
          'italic',
          'underline',
          'strike',
          { color: colors },
          { background: colors },
          'clean'
        ]
      ]
    }),
    [colors]
  );

  const onInternalChange = useCallback(
    (event: string) => {
      if (disabled) return;
      const dummy = document.createElement('p');
      dummy.innerHTML = event;
      onChange(!dummy.textContent?.trim() ? null : event);
    },
    [disabled, onChange]
  );

  const handleOnInternalChange = useCallback(
    (event: string) => {
      if (disabled) return;
      /* istanbul ignore else */
      if (!modalOpen) return onInternalChange(event);
    },
    [disabled, modalOpen, onInternalChange]
  );

  useEffect(() => {
    if (modalOpen && richTextEditorRef.current) {
      richTextEditorRef.current?.focus?.();
      onBlur();
    }
  }, [modalOpen, onBlur]);

  return (
    <div ref={mainRef} css={[styles.container({ unlimitedHeight }), /* istanbul ignore next */ themeState.themeKey === THEME.DARK && styles.darkModeToolbar]}>
      <InputWrapper
        color={color}
        id={testid}
        optionList={internalOptionList}
        description={description}
        disabled={!!disabled}
        errorMessage={errorMessage}
        unlimitedHeight={true}
        showPlaceholderLine={true}
        label={label}
        required={required}
        testid={testid}
        value={value}
        info={info}
        disabledType={disabledType}
      >
        <div
          css={[
            styles.quillContainer,
            /* istanbul ignore next */ isFocused && !disabled && styles.isFocused,
            modalOpen && styles.modalOpen,
            !!color && styles.containerColor
          ]}
        >
          <ReactQuill theme="snow" onFocus={onFocus} value={value} onChange={handleOnInternalChange} readOnly={!!disabled} modules={modules} />
        </div>
      </InputWrapper>
      {!!modalOpen && (
        <Modal
          title={label}
          description={description}
          open={true}
          type={ViewModel.ModalType.REGULAR}
          onClose={onToggleModal}
          footer={<CTA type={CTAType.PRIMARY} label="OK" onClick={onToggleModal} />}
        >
          <div css={[styles.modalEditorContainer, !!disabled && styles.disabledModalContainer]}>
            <ReactQuill ref={richTextEditorRef} theme="snow" value={value} onChange={onInternalChange} readOnly={!!disabled} modules={modules} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export interface RichTextProps {
  value: string;
}

export const RichText = ({ value }: RichTextProps) => {
  if (typeof value !== 'string') return null;
  return (
    <div css={styles.readonlyEditor}>
      <ReactQuill theme="bubble" value={value} readOnly={true} />
    </div>
  );
};
