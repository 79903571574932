import { Observable } from 'rxjs';
import { Translate, swallowError, useTranslate } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR, HC_COLOR } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { ToolTip } from '@components/elements/Tooltip';
import { styles } from './styles';
import { OptionMenuDropdownType } from './types';
import type { IOptionListItem } from '../OptionMenu';

export interface OptionMenuDropdownProps {
  optionList?: IOptionListItem[];
  type?: OptionMenuDropdownType;
}

export const OptionMenuDropdown = ({ optionList, type = OptionMenuDropdownType.REGULAR }: OptionMenuDropdownProps) => {
  const { translate } = useTranslate();
  const isRegularType = type === OptionMenuDropdownType.REGULAR;
  return (
    <div css={styles.dropdown}>
      {optionList.map((option, index) => {
        const color = option.disabled ? COLOR.NEUTRAL_3 : HC_COLOR[option.color || 'NEUTRAL_1'];
        return (
          <ToolTip key={[option.label, option.image, index].join()} text={option.tooltip}>
            <div
              onMouseEnter={option.onMouseEnter || undefined}
              onMouseLeave={option.onMouseLeave || undefined}
              data-testid={option.testid || 'menu-option-btn'}
              css={[styles.option, option.disabled && styles.disabled]}
              onClick={event => {
                if (typeof option.onClick !== 'function' || option.disabled) return;
                const obs$ = option.onClick(event);
                if (obs$ instanceof Observable) obs$.pipe(swallowError()).subscribe();
              }}
            >
              <div
                css={[styles.iconContainer, !isRegularType && styles.appsIconContainer]}
                style={isRegularType ? undefined : { backgroundColor: COLOR[option.color || 'BRAND_1'] }}
              >
                <IconImage
                  icon={option.image}
                  title={translate(option.label)}
                  iconProps={{
                    size: isRegularType ? FONT_SIZE.M : FONT_SIZE.XXM,
                    fill: isRegularType ? color : FOREGROUND_COLOR[option.color || 'BRAND_1'],
                    outlined: option.outlined
                  }}
                  imageProps={{ size: isRegularType ? FONT_SIZE.M : FONT_SIZE.XXM, css: { objectFit: 'cover' } }}
                />
                {option.dot && <div data-testid="unread-flag" css={styles.dot} />}
              </div>
              <span css={[styles.title, !isRegularType && styles.appsTitle]} style={isRegularType ? { color } : undefined}>
                <Translate>{option.label || option.tooltip}</Translate>
              </span>
            </div>
          </ToolTip>
        );
      })}
    </div>
  );
};
