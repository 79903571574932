import { useEffect, useState } from 'react';
import { Subject, takeUntil, tap } from 'rxjs';

import type { CollectionModel } from '@cyferd/client-engine';
import { actions, normalize, ofTypeSetData } from '@cyferd/client-engine';
import { useRequest } from './useRequest';

export const useGetCollectionDefinition = (collectionId?: string) => {
  const request = useRequest();
  const [query, setQuery] = useState<CollectionModel.Collection>();

  useEffect(() => {
    const sync$ = new Subject<void>();
    if (collectionId) {
      request(actions.coreGet({ query: { cursor: { collectionId } } }))
        .pipe(
          takeUntil(sync$),
          ofTypeSetData(),
          tap((v: any) => setQuery(normalize.collection(v.query)))
        )
        .subscribe();
    }
    return () => sync$.next();
  }, [collectionId, request]);

  return query;
};
