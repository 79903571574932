/* istanbul ignore file */
import type { ApiModel } from '@cyferd/client-engine';
import { useTriggerParser } from '@cyferd/client-engine';
import { useCallback } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, catchError, throwError } from 'rxjs';

export const useRequest = () => {
  const onCreateTrigger = useTriggerParser();
  return useCallback(
    (action: ApiModel.APIAction, reThrow?: boolean) =>
      onCreateTrigger({ label: action.type, ...action }, { value: null, item: null, parameters: null }).pipe(
        catchError(error => {
          return reThrow ? throwError(() => error) : EMPTY;
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ) as (action: ApiModel.APIAction, reThrow?: boolean) => Observable<ApiModel.APIAction>;
};
