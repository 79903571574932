import { COLOR, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  header: css`
    display: flex;
    align-items: flex-start;
    gap: ${GAP.XS};
  `,
  titleContainer: css`
    flex: 1;
  `,
  controls: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  propRow: css`
    display: grid;
    grid-template-columns: 130px 1fr 1fr;
    gap: 0 ${GAP.M};
  `,
  tableHeader: css`
    background-color: ${COLOR.NEUTRAL_5};
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
    padding: ${GAP.S} 0;
    gap: 0;
  `,
  propTitle: css`
    padding: 0 ${GAP.S};
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  propCellBase: css`
    padding: ${GAP.XXS} ${GAP.S} ${GAP.S} 0;
    border-right: 1px solid ${COLOR.NEUTRAL_4};
    display: flex;
  `,
  bodyRow: css`
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
  `,
  propCellCenter: css`
    display: flex;
    align-items: center;
  `,
  propCellLabel: css`
    background-color: ${COLOR.NEUTRAL_5};
  `,
  hidden: css`
    display: none;
  `
};
