import { COLOR, GAP, INPUT_HEIGHT, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  inputContainer: css`
    position: relative;
  `,
  spinnerContainer: css`
    width: 100%;
    height: calc(${INPUT_HEIGHT} - 2px);
    padding: 0 ${GAP.S};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  `,
  dropdownContainer: css`
    position: fixed;
    background-color: ${COLOR.TOP_BACKGROUND};
    z-index: 10;
    padding: ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    display: grid;
    grid-gap: ${GAP.XXS};
  `,
  options: css`
    display: grid;
    grid-gap: ${GAP.XXS};
    overflow: auto;
  `,
  hidden: css`
    display: none;
  `,
  loadMoreContainer: css`
    margin: ${GAP.S} ${GAP.S};
  `
};
