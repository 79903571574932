import React, { memo, useMemo } from 'react';
import { ViewModel, getParsedActionChildren } from '@cyferd/client-engine';
import { styles } from './styles';
import { CyAction } from '../CyAction';
import { RichText } from '@components/elements/RichTextEditor';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { COLOR, FONT_SIZE } from '@constants';
import { BBContainer } from '@components/elements/BBContainer';

const isValidText = (txt: string | number | any) => ['string', 'number'].includes(typeof txt) && !!String(txt)?.trim?.();

export const CyText = memo(
  ({
    id,
    title,
    titleSize,
    titleAlignment,
    icon,
    iconColor,
    content,
    actionsPosition,
    actionsAlignment,
    actionsSize,
    actionListChildren,
    effectChildren,
    framed,
    fitToPage,
    moreOptionsType
  }: ViewModel.CyTextProps) => {
    const titleSizeStyle = useMemo(() => {
      switch (titleSize) {
        case ViewModel.CyTextTitleSize.L:
          return styles.titleSizeLarge;
        case ViewModel.CyTextTitleSize.M:
        default:
          return styles.titleSizeMedium;
      }
    }, [titleSize]);
    const titleAlignmentStyle = useMemo(() => {
      switch (titleAlignment) {
        case ViewModel.Alignment.CENTER:
          return styles.titleAlignmentCenter;
        case ViewModel.Alignment.RIGHT:
          return styles.titleAlignmentRight;
        case ViewModel.Alignment.LEFT:
        default:
          return styles.titleAlignmentLeft;
      }
    }, [titleAlignment]);

    const iconSizeStyle = useMemo(() => {
      switch (titleSize) {
        case ViewModel.CyTextTitleSize.L:
          return FONT_SIZE.XL;
        case ViewModel.CyTextTitleSize.M:
        default:
          return FONT_SIZE.XXM;
      }
    }, [titleSize]);

    const actionsPositionStyle = useMemo(() => {
      switch (actionsPosition) {
        case ViewModel.Alignment.LEFT:
          return styles.actionsPositionLeft;
        case ViewModel.Alignment.RIGHT:
          return styles.actionsPositionRight;
        case ViewModel.Alignment.CENTER:
        default:
          return null;
      }
    }, [actionsPosition]);

    const hasValidTitle = useMemo(() => isValidText(title), [title]);
    const hasValidContent = useMemo(() => isValidText(content), [content]);

    return (
      <BBContainer framed={framed} fitToPage={fitToPage}>
        <div id={id} data-testid={id} css={styles.outterContainer}>
          <div data-testid="effects">{effectChildren}</div>
          <div css={styles.container}>
            {(hasValidTitle || !!icon) && (
              <div css={[styles.titleContainer, titleAlignmentStyle]}>
                <div css={styles.titleInnerContainer}>
                  {!!icon && <IconImage icon={icon} title="" iconProps={{ fill: COLOR[iconColor], size: iconSizeStyle }} />}
                  {hasValidTitle && <h3 css={[styles.title, titleSizeStyle]}>{title}</h3>}
                </div>
              </div>
            )}
            <div css={[styles.content, actionsPositionStyle]}>
              {hasValidContent && (
                <div css={!getParsedActionChildren(actionListChildren)?.length && styles.textWithNoActions}>
                  <RichText value={content} />
                </div>
              )}

              <CyAction actionListChildren={actionListChildren} size={actionsSize} alignment={actionsAlignment} moreOptionsType={moreOptionsType} />
            </div>
          </div>
        </div>
      </BBContainer>
    );
  }
);

CyText.displayName = ViewModel.DisplayName.CY_TEXT;
