import { CONTAINER_WIDTH, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  headerContainer: css`
    width: 100%;
    z-index: 5;
    justify-content: center;
    max-width: ${CONTAINER_WIDTH.MAX}px;
  `,
  headerInnerContainer: css`
    max-width: ${CONTAINER_WIDTH.MAX}px;
    padding: 0 ${GAP.S};
    width: 100%;
  `
};
