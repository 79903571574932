import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  dpContainer: (readonly: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    position: relative;
    padding: ${!readonly ? `${GAP.XS} ${GAP.S}` : '0'};
  `,
  pressableContainer: css`
    cursor: pointer;
  `,
  dpSubtitleContainer: css`
    font-size: ${FONT_SIZE.S};
    color: ${COLOR.NEUTRAL_2};
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${GAP.S};
    width: 100%;
  `,
  dpSubtitleText: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    span {
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      text-decoration: underline;
      color: ${COLOR.BRAND_1};
    }
  `,
  dpSubtitleTextDisabled: css`
    color: ${COLOR.NEUTRAL_1_5};
    span {
      color: ${COLOR.NEUTRAL_3};
    }
  `,
  input: css`
    display: none;
  `,
  fileContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  fileContainerSeparator: css`
    margin-bottom: ${GAP.XS};
  `,
  fileInfoContainer: css`
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
  `,
  fileIndicatorContainer: css`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${GAP.XS};
  `,
  fileSpinnerContainer: css`
    position: absolute;
  `,
  fileTitle: css`
    padding-right: ${GAP.XXS};
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  `,
  fileActionContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
  `,
  disabledItemTitle: css`
    color: ${COLOR.NEUTRAL_1_5};
  `
};
