import { ViewModel } from '@cyferd/client-engine';
import { CTA } from '../CTA';
import { Icon } from '../Icon';
import { styles } from './styles';
import { COLOR, FONT_SIZE } from '@constants';

export interface CollectionBuilderEmptyStateProps {
  isEdition?: boolean;
  allowNg: boolean;
  onClick: () => void;
}

export const CollectionBuilderEmptyState = ({ isEdition, allowNg, onClick }: CollectionBuilderEmptyStateProps) => {
  return (
    <div css={styles.container}>
      <p css={styles.title(isEdition)}>{isEdition ? 'No field selected' : 'Start adding content to your collection'}</p>
      {!isEdition && (
        <div css={styles.content}>
          <p css={styles.subtitle}>
            Use <Icon name="add_circle" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.XM} /> to create manually
          </p>
          {!!allowNg && (
            <>
              <div css={styles.orContainer}>
                <p css={styles.or}>or</p>
                <span css={styles.orDivision} />
              </div>
              <CTA testid="ng-btn" icon="auto_awesome" color="HC_3" type={ViewModel.CTAType.TERTIARY} label="Smart generate" onClick={onClick} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

CollectionBuilderEmptyState.displayName = 'CollectionBuilderEmptyState';
