import type { SvgProps } from '../types';

export const CyferdIcon = ({ size, title, ...props }: SvgProps) => {
  return (
    <svg width={size || 55} height={size || 60} viewBox="0 0 55 60" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{title || 'Cyferd Icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5673 0.257507L54.3728 15.3712L45.6911 20.1517L54.3705 25.1377L45.7331 29.824L54.3609 34.5968L45.7339 39.5527L54.3575 44.3558L27.5674 59.7458L0.350006 44.7485V14.9771L27.5673 0.257507ZM44.4088 38.8147L51.7139 34.6182L44.3772 30.5596L36.8853 34.6244L44.4088 38.8147ZM35.5319 35.3586L43.0937 39.5702L27.5662 48.4903L11.524 38.9652L18.738 34.9929L27.564 39.6817L35.5319 35.3586ZM35.5402 33.8752L43.0275 29.8129L35.6037 25.7061L28.1962 29.7849L35.5402 33.8752ZM26.8527 30.5247L34.1868 34.6095L27.5575 38.2062L20.0987 34.2437L26.8527 30.5247ZM26.8563 29.0386L34.2592 24.9624L27.5545 21.2534L20.043 25.2439L26.8563 29.0386ZM18.6756 25.9703L25.5129 29.7784L18.7269 33.5149L11.6005 29.729L18.6756 25.9703ZM18.6903 24.4904L27.5671 19.7747L35.6051 24.2213L43.0395 20.1277L27.5618 11.2362L11.5319 20.5035L18.6903 24.4904ZM44.372 19.3939L27.5598 9.7358L8.89806 20.5246L17.3229 25.2169L8.82951 29.729L17.3663 34.2642L8.90601 38.9227L27.5554 49.9957L44.4187 40.3083L51.7173 44.3733L27.5541 58.2542L1.65001 43.9805V15.752L27.5542 1.74253L51.702 15.3578L44.372 19.3939ZM44.3585 20.8854L36.9496 24.965L44.3833 29.0773L51.7043 25.1053L44.3585 20.8854Z"
      />
    </svg>
  );
};
