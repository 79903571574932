import type { SvgProps } from '../types';

export const FileIcon = ({ size, fill, ...props }: SvgProps) => {
  return (
    <svg width={size || 20} height={size || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.334 12.333H7c-.446 0-.667.223-.667.5 0 .278.221.5.667.5h6.334c.445 0 .666-.222.666-.5 0-.277-.22-.5-.666-.5ZM13.334 10H7c-.446 0-.667.222-.667.5s.221.5.667.5h6.334c.445 0 .666-.222.666-.5s-.22-.5-.666-.5ZM13.334 14.667H7c-.446 0-.667.222-.667.5 0 .277.221.5.667.5h6.334c.445 0 .666-.223.666-.5 0-.278-.22-.5-.666-.5Z"
        fill={fill || 'var(--NEUTRAL_2)'}
      />
      <path
        d="M4.5 19.625h11.125c.875 0 1.563-.719 1.563-1.563V7.47c0-.438-.188-.844-.5-1.125l-5.844-5.5A1.561 1.561 0 0 0 9.75.406H4.437c-.875 0-1.562.719-1.562 1.563v16.093c.063.875.75 1.563 1.625 1.563ZM15.313 6.531h-4.22c-.25 0-.468-.187-.468-.437V2.125l4.688 4.406ZM4.03 2a.48.48 0 0 1 .469-.469h5v4.563c0 .843.719 1.531 1.563 1.531h5.03v10.438a.48.48 0 0 1-.468.468H4.5a.48.48 0 0 1-.469-.468V2Z"
        fill={fill || 'var(--NEUTRAL_2)'}
      />
    </svg>
  );
};
