import { useCallback } from 'react';

import { BUILDER_ROUTE, ENV, QUERY_PARAM } from '@constants';
/* istanbul ignore file */
import { ApiModel } from '@cyferd/client-engine';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';

export const useNavigateToRecord = () => {
  const navigateTo = useOnOpenExternalUrl();
  return useCallback(
    (collectionId: string, recordId: string) => {
      switch (collectionId) {
        case ApiModel.ApiEntity.TAG:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.TAG_EDITOR.ROOT}${BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        case ApiModel.ApiEntity.ENTITY:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.ENTITY_EDITOR.ROOT}${BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        case ApiModel.ApiEntity.VIEW:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.VIEW_EDITOR.ROOT}${BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        case ApiModel.ApiEntity.FLOW:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.FLOW_EDITOR.ROOT}${BUILDER_ROUTE.FLOW_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        case ApiModel.ApiEntity.USER:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.USER_EDITOR.ROOT}${BUILDER_ROUTE.USER_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        case ApiModel.ApiEntity.INVITATION:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.INVITATION_EDITOR.ROOT}${BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        case ApiModel.ApiEntity.INTEGRATION:
          return navigateTo(
            `${ENV.PUBLIC_URL}${BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT}${BUILDER_ROUTE.INTEGRATION_EDITOR.CHILDREN.GENERAL}?${QUERY_PARAM.RECORD_ID}=${recordId}`
          );
        default:
          return navigateTo(`${ENV.CLIENT_APP_URL}/DETAIL?collectionId=${collectionId}&id=${recordId}`);
      }
    },
    [navigateTo]
  );
};
