import type { SvgProps } from '../types';
import { THEME } from '@constants';

export const NG_color = ({ size = 24, fill: _fill, theme = THEME.LIGHT, ...props }: SvgProps) => {
  const colorA = theme === THEME.LIGHT ? '#D63FF8' : '#CD6DDA';
  const colorB = theme === THEME.LIGHT ? '#F3BAFD' : '#EAB7F3';
  return (
    <svg id="Layer_1" data-name="Layer_1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        fill={colorA}
        clipRule="evenodd"
        d="M30.567 11.4714L28.2535 9.94388L28.5846 8.70819L31.3519 8.54209L32.8794 6.22861L34.1151 6.55971L34.2812 9.32699L36.5947 10.8545L36.2636 12.0902L33.4963 12.2563L31.9688 14.5697L30.7331 14.2386L30.567 11.4714Z"
      />
      <path
        fillRule="evenodd"
        fill={colorA}
        clipRule="evenodd"
        d="M23.3857 18.6999L20.5456 16.8246L20.9521 15.3076L24.3493 15.1037L26.2245 12.2636L27.7415 12.6701L27.9455 16.0673L30.7856 17.9425L30.3791 19.4595L26.9819 19.6634L25.1067 22.5036L23.5897 22.0971L23.3857 18.6999Z"
      />
      <path
        fill={colorB}
        d="M25.3896 2.66324C25.2912 2.54393 25.1698 2.44195 25.036 2.3643C21.4731 0.296792 21.1359 -0.153139 16.9324 0.520767C12.6497 1.20736 11.0895 4.96649 8.19942 8.20071C5.30934 11.4349 1.26564 13.947 1.06316 18.2796C0.860681 22.6123 2.15631 26.8825 4.73217 30.3722C6.94463 33.3695 10.32 34.9711 13.8031 35.4342C14.9405 35.5854 16.0801 35.7528 17.2184 35.8966C20.7227 36.3392 23.8991 35.3266 27.4074 34.0742C31.3496 32.2653 34.9351 31.3988 36.8531 27.5085C38.3684 24.4348 38.5031 23.0966 38.0166 20.5846C37.7575 19.2471 36.5169 18.3347 35.1566 18.4101V18.4101C33.4272 18.5059 32.3585 20.1963 31.9003 21.8666C31.7398 22.4516 31.499 23.0848 31.2349 23.9998C31.1499 24.2942 31.0428 24.5825 30.9093 24.8583C29.7194 27.3153 28.3006 30.4419 25.2407 30.9322C22.0062 31.4506 19.5899 31.6296 16.8854 30.9322C14.1808 30.2348 10.5476 28.9139 8.88888 26.6668C7.23018 24.4196 6.53627 20.5678 6.66665 17.7779C6.79704 14.9879 8.99511 13.1435 10.8562 11.0608C12.7172 8.97819 13.9571 6.03853 16.7149 5.5964C18.032 5.38524 20.5607 5.65129 23.1066 6.13903C24.5249 6.41073 26.3089 3.77693 25.3896 2.66324V2.66324Z"
      />
      <path
        fill={colorA}
        d="M28.6801 3.28457C28.6541 2.83743 28.4548 2.41003 28.0789 2.1665C25.0537 0.206565 18.4152 -0.114768 14.957 1.21709C11.2656 2.63875 7.81372 3.65909 5.59219 6.99114C3.37066 10.3232 0.578019 15.5094 0.650424 19.5158C0.722829 23.5223 2.9536 26.8625 5.29309 30.0572C6.97196 32.3498 9.12456 34.4584 11.5881 35.9091C13.4883 37.0281 15.3792 38.2273 17.3321 39.2516C20.1255 40.7169 22.5512 40.1192 25.3759 38.4757C26.5712 37.7803 27.8255 37.1842 29.0612 36.5636C31.9593 35.108 34.4051 32.7161 36.3591 30.0612C38.4271 27.2513 37.6793 24.3399 37.5285 20.9843C37.4916 20.1614 36.8243 19.4839 36.0007 19.4965V19.4965C35.2759 19.5076 34.537 18.1914 34.2866 18.8716C33.8152 20.152 33.1897 26.0813 31.8545 27.8954C30.0664 30.3251 26.9867 36.6043 24.1591 37.5414C21.3315 38.4785 20.3508 37.6461 17.5326 36.7431C14.7143 35.8401 12.133 29.4143 10.3695 27.0061C9.52004 25.8461 7.78278 24.4565 6.20408 22.9903C3.40549 20.3909 0.959519 16.0879 2.60445 12.6408C3.16675 11.4624 3.99157 10.3302 4.84487 9.38073C6.41616 7.6323 8.25611 5.72076 10.5912 5.44969C12.1863 5.26451 14.0132 5.4368 15.5823 4.8325C17.7992 3.97868 23.068 4.47623 26.564 5.10114C27.6804 5.3007 28.7457 4.41679 28.6801 3.28457V3.28457Z"
      />
    </svg>
  );
};
