import { EMPTY_CONTAINER_HEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  mainStepperContainer: css`
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: ${GAP.S};
  `,
  fullHeight: css`
    height: 100%;
  `,
  mainContainer: css`
    width: 100%;
    overflow-x: auto;
  `,
  formContent: css`
    flex: 1;
    overflow: auto;
  `,
  stepperContent: css`
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  actionList: css`
    [data-selector='option-menu-container'] {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  `,
  stepper: {
    wrapper: css`
      padding: ${GAP.M};
    `,
    mainView: css`
      display: flex;
      flex-direction: column;
    `,
    stepperControlsContainer: css`
      display: flex;
      justify-content: space-between;
    `,
    rightControlsContainer: css`
      display: flex;
      margin-left: auto;
      align-items: center;
      gap: ${GAP.S};
    `
  },
  formContainer: css`
    display: grid;
    grid-gap: ${GAP.M};
    grid-template-columns: 100%;
  `,
  inputsContainerStyles: css`
    gap: ${GAP.M};
  `,
  controlsContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${GAP.XS};
  `,
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `,
  expand: css`
    flex: 1;
  `,
  formBody: css`
    display: flex;
  `
};
