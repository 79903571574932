import type { SvgProps } from '../types';

export const Layout_E = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="39.5" y="39.5" width="39" height="39" rx="3.5" transform="rotate(-180 39.5 39.5)" stroke={fill} />
    <path
      d="M3 5C3 3.89543 3.89543 3 5 3L11 3C12.1046 3 13 3.89543 13 5L13 35C13 36.1046 12.1046 37 11 37L5 37C3.89543 37 2.99999 36.1046 3 35L3 5Z"
      fill={fill}
    />
    <path
      d="M15 5C15 3.89543 15.8954 3 17 3L23 3C24.1046 3 25 3.89543 25 5L25 35C25 36.1046 24.1046 37 23 37L17 37C15.8954 37 15 36.1046 15 35L15 5Z"
      fill={fill}
    />
    <path
      d="M27 5C27 3.89543 27.8954 3 29 3L35 3C36.1046 3 37 3.89543 37 5L37 35C37 36.1046 36.1046 37 35 37L29 37C27.8954 37 27 36.1046 27 35L27 5Z"
      fill={fill}
    />
  </svg>
);
