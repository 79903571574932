import { forwardRef } from 'react';
import type { GeneralModel } from '@cyferd/client-engine';
import { styles } from './styles';
import { getElementMenuPosition, useDropdownDimensions } from '@utils';
import { CheckboxMenu } from '../CheckboxMenu';

export interface IMultiOptionDropdownMenu {
  testid?: string;
  optionList: GeneralModel.JSONSchemaMetadata['optionList'];
  onSelect: (value: GeneralModel.InputOption) => void;
  onSelectAll: () => void;
  selectedOptions?: (string | number)[];
}

export const MultiOptionDropdownMenu = forwardRef<HTMLDivElement, IMultiOptionDropdownMenu>(
  ({ testid = 'multi-option-dropdown-items', optionList, onSelect, onSelectAll, selectedOptions }: IMultiOptionDropdownMenu, ref) => {
    const { dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions(ref as React.MutableRefObject<HTMLDivElement>);
    const {
      style: { top }
    } = getElementMenuPosition({ triggerDimensions, menuDimensions, clientDimensions, verticalPadding: 2 });

    return (
      <div style={{ width: triggerDimensions.width, top }} css={styles.menuContainer}>
        <CheckboxMenu
          testid={testid}
          optionList={optionList}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          selectedOptions={selectedOptions}
          ref={dropdownRef}
        />
      </div>
    );
  }
);
