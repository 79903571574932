import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { hexToRgba } from '@cyferd/client-engine';
import { css } from '@emotion/css';

export const styles = {
  detailContainer: css`
    overflow: hidden;
  `,
  typeCombo: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${GAP.S};
  `,
  addChildContainer: css`
    padding: ${GAP.S};
    margin: ${GAP.M} 0;
    border: 2px dashed ${COLOR.BRAND_1};
    background-color: ${hexToRgba(COLOR.NEUTRAL_5, 0.5)};
    display: flex;
    justify-content: center;
  `,
  addBtn: css`
    background-color: ${COLOR.BRAND_1};
    color: ${COLOR.BASE_BACKGROUND};
    min-width: 50%;
  `,
  input: css`
    width: 50%;
    margin-top: ${GAP.S};
  `,
  groupLabel: css`
    color: ${COLOR.NEUTRAL_2};
    padding: ${GAP.S} 0;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: ${FONT_SIZE.S};
  `,
  groupMenu: css`
    display: grid;
    grid-gap: ${GAP.XS};
    grid-auto-flow: column;
  `,
  propContainer: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.M};
  `,
  isDraggingOver: css`
    background-color: ${hexToRgba(COLOR.BRAND_1, 0.1)};
    border-radius: ${RADIUS.S};
  `,
  itemId: css`
    margin-right: ${GAP.S};
  `,
  removeBtn: css`
    font-size: ${FONT_SIZE.S}!important;
    font-weight: ${FONT_WEIGHT.BOLD}!important;
    background-color: ${COLOR.BASE_FOREGROUND}!important;
    width: 32px !important;
    height: 32px !important;
  `,
  header: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `
};
