import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  tooltip: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  tooltipTitle: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `,
  tooltipDescription: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `
};
