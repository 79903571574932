import type { GeneralModel } from '@cyferd/client-engine';
import { isObject } from '@cyferd/client-engine';

export const hasFilters = (fetchCriteria?: GeneralModel.FetchCriteria) => {
  if (fetchCriteria?.options?.skip) return true;
  const filter = fetchCriteria?.filter;
  if (isObject(filter) && Object.keys(filter).length) return true;
  const quickFilters = fetchCriteria?.quickFilters;
  if (Array.isArray(quickFilters) && quickFilters.length) return true;
  if (fetchCriteria?.searchString) return true;
  return false;
};
