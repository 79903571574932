import { COLOR, FONT_SIZE, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    margin-bottom: ${GAP.M};
  `,
  info: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${GAP.S};
  `,
  textContainer: css`
    max-width: 75%;
  `,
  iconTitleContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
  `,
  title: css`
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_1};
    font-weight: bold;
  `,
  description: css`
    margin-top: ${GAP.XXS};
    color: ${COLOR.NEUTRAL_2};
  `,
  counter: css`
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_2};
    text-align: right;
  `,
  progressBar: css`
    width: 100%;
    height: 8px;
    background-color: ${COLOR.NEUTRAL_4};
    border-radius: ${RADIUS.S};
    overflow: hidden;
  `,
  progressBarFill: css`
    display: block;
    height: 100%;
    background-color: ${COLOR.BRAND_1};
    border-radius: ${RADIUS.S};
    transition: width 500ms ease-in-out;
  `
};
