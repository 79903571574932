import { COLOR, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  grid: css`
    display: grid;
    grid-gap: ${GAP.S};
    grid-template-rows: minmax(0, max-content);
    margin-bottom: ${GAP.M};
    overflow: hidden;
    flex: 1;
    transition: grid-template-columns ${TRANSITION_SPEED};
  `,
  size: (size: number) => {
    const gridTemplateColumnsMap = {
      3: '280px 0 auto',
      2: '280px 365px auto',
      1: '280px 52% auto'
    };

    return css`
      grid-template-columns: ${gridTemplateColumnsMap[size] || gridTemplateColumnsMap[1]};
    `;
  },
  sidebarOuterContainer: css`
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  `,
  sidebarContainer: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.M};
    padding: ${GAP.M} 0;
  `,
  gridCol: css`
    overflow: auto;
    flex: 1;
    scroll-behavior: smooth;
  `,
  emptyStateContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,
  advFormContent: css`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: ${GAP.S};
  `,
  content: (size: number) => css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    padding: ${GAP.M};
    height: 100%;
    border-radius: ${RADIUS.M};
    ${size === 3 && `opacity: 0;`}
  `,
  preview: css`
    border: 3px ${SECONDARY_COLOR.BRAND_1} dashed;
    border-radius: ${RADIUS.S};
  `
};
