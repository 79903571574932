import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
    align-items: flex-end;
  `,
  json: css`
    width: 100%;
  `
};
