import { type PropsWithChildren, useMemo } from 'react';
import { getStyles } from './styles';

export type SidebarProps = PropsWithChildren<{
  open: boolean;
  wide?: boolean;
  verticalOffset?: number;
  verticalOffsetDirection?: 'top' | 'bottom';
}>;

const SideBarLeft = ({ open, children, verticalOffset = 0, wide }: SidebarProps) => {
  const styles = useMemo(() => getStyles({ verticalOffset }), [verticalOffset]);
  return (
    <div css={[styles.paneLeft, !!open && styles.paneOpenLeft, open && wide && styles.wideLeft]} data-testid="paneLeft">
      {children}
    </div>
  );
};

export const Sidebar = ({ open, wide, children, verticalOffset = 0, verticalOffsetDirection }: SidebarProps) => {
  const styles = useMemo(() => getStyles({ verticalOffset, verticalOffsetDirection }), [verticalOffset, verticalOffsetDirection]);
  return (
    <div css={[styles.pane, !!open && styles.paneOpen, !!open && !!wide && styles.wide]} data-testid="pane">
      {children}
    </div>
  );
};

Sidebar.FromLeft = SideBarLeft;
