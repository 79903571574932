import { css } from '@emotion/react';
import { COLOR, FONT_SIZE, GAP } from '@constants';

export const styles = {
  errorMessage: css`
    color: ${COLOR.HC_5}!important;
    margin: ${GAP.XS} 0 0 0 !important;
    font-size: ${FONT_SIZE.XS};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${GAP.XXS};
  `,
  line: css`
    padding-top: ${GAP.XS};
    border-top: 1px solid ${COLOR.HC_5};
  `
};
