import React, { memo, useCallback, useId, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { isObject } from '@cyferd/client-engine';

import { TRANS } from '@constants';
import type { ICTAType } from '../CTA';
import { CTA, CTAType } from '../CTA';
import { DropdownMenu, DropdownMenuType } from '../DropdownMenu';
import { styles as dropdownMenuStyles } from '../DropdownMenu/styles';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { styles } from './styles';
import type { IOptionMenu } from './types';
import { OptionMenuDropdown } from '../OptionMenuDropdown';

const DefaultBtn = ({
  onClick,
  isOpen,
  defaultBtnType = CTAType.ACTION_SECONDARY as ICTAType,
  defaultBtnSize,
  defaultBtnIcon,
  defaultBtnTestid = 'actions-btn',
  defaultTooltip = TRANS.client.buttons.actions,
  defaultOutlined = false,
  defaultBtnColor,
  defaultDisabled,
  triggerRef
}) => (
  <PreventClickPropagation>
    <div ref={triggerRef} css={[styles.triggerContainer, isOpen && !defaultBtnIcon && styles.UNSTABLE_rotation]}>
      <CTA
        testid={defaultBtnTestid}
        type={defaultBtnType}
        size={defaultBtnSize}
        color={defaultBtnColor}
        icon={defaultBtnIcon ?? 'MoreVert'}
        tooltip={defaultTooltip}
        outlined={defaultOutlined}
        disabled={defaultDisabled}
        onClick={onClick}
      />
    </div>
  </PreventClickPropagation>
);

export const OptionMenu = memo(
  ({
    renderButton,
    optionList,
    horizontal,
    vertical,
    containerId,
    maxImportant,
    defaultBtnType,
    defaultBtnSize,
    defaultBtnIcon,
    defaultBtnColor,
    defaultBtnTestid,
    defaultTooltip,
    defaultOutlined,
    defaultDisabled,
    importantContainerStyle,
    dropdownType = DropdownMenuType.OPTION_MENU
  }: IOptionMenu) => {
    const defaultRenderBtn = useCallback(
      ({ onClick, isOpen, ref }) => (
        <DefaultBtn
          onClick={onClick}
          isOpen={isOpen}
          defaultBtnType={defaultBtnType}
          defaultBtnSize={defaultBtnSize}
          defaultBtnIcon={defaultBtnIcon}
          defaultBtnTestid={defaultBtnTestid}
          defaultTooltip={defaultTooltip}
          defaultBtnColor={defaultBtnColor}
          defaultOutlined={defaultOutlined}
          defaultDisabled={defaultDisabled}
          triggerRef={ref}
        />
      ),
      [defaultBtnColor, defaultBtnIcon, defaultBtnSize, defaultBtnTestid, defaultBtnType, defaultDisabled, defaultOutlined, defaultTooltip]
    );
    const innerContainerId = useId();
    const [container, setContainer] = useState<HTMLElement>();

    useLayoutEffect(() => {
      setContainer(document.getElementById(containerId || innerContainerId));
    }, [containerId, innerContainerId]);

    if (!optionList?.length) return null;

    const { importantList, regularList } = optionList.filter(isObject).reduce(
      (total, curr) => {
        const importantLimitReached = typeof maxImportant === 'number' && total.importantList.length >= maxImportant;
        if (
          !importantLimitReached &&
          (curr.important ||
            curr.optionList?.length ||
            (optionList?.length === 1 &&
              ([CTAType.ACTION, CTAType.ACTION_SECONDARY, CTAType.ACTION_TERTIARY].includes(curr.type as any) || (curr.type === CTAType.LINK && !curr.label))))
        ) {
          return { ...total, importantList: [...total.importantList, curr] };
        }

        return { ...total, regularList: [...total.regularList, curr] };
      },
      { importantList: [], regularList: [] } as Record<string, typeof optionList>
    );

    if (!importantList.length && !regularList.length) return null;

    return (
      <>
        <div id={innerContainerId} />
        <div css={styles.container} style={importantContainerStyle} data-selector="option-menu-container">
          {importantList.map((option, index) => (
            <div key={[option.label, option.image, index].join()}>
              {option.optionList?.length ? (
                <OptionMenu
                  defaultBtnType={option.type}
                  defaultBtnSize={option.size}
                  defaultBtnIcon={option.image}
                  defaultBtnTestid={option.testid}
                  defaultTooltip={option.tooltip}
                  defaultBtnColor={option.color}
                  defaultOutlined={option.outlined}
                  defaultDisabled={option.disabled}
                  optionList={option.optionList}
                />
              ) : (
                <CTA
                  label={option?.label}
                  icon={option?.image}
                  tooltip={option?.tooltip}
                  type={option?.type || CTAType.LINK}
                  size={option?.size}
                  onClick={option?.onClick}
                  disabled={!!option?.disabled}
                  testid={option?.testid}
                  status={option?.status}
                  color={option?.color}
                  outlined={option?.outlined}
                  allowAutofocus={option?.allowAutofocus}
                />
              )}
            </div>
          ))}
          {!!regularList.length && (
            <DropdownMenu
              type={dropdownType}
              renderTrigger={renderButton || defaultRenderBtn}
              horizontal={horizontal}
              vertical={vertical || 2}
              renderMenuWrapper={
                (({ css, onClick, children, style, ref }) => {
                  return (
                    !!container &&
                    createPortal(
                      <div css={dropdownMenuStyles.menuContainer}>
                        <div style={style} css={css} onClick={onClick} ref={ref}>
                          {children}
                        </div>
                      </div>,
                      container
                    )
                  );
                }) as any
              }
            >
              <OptionMenuDropdown optionList={regularList} />
            </DropdownMenu>
          )}
        </div>
      </>
    );
  }
);
