import { COLOR } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  infoWrapper: css`
    display: inline-flex;
    background: ${COLOR.BASE_BACKGROUND};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `
};
