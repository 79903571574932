import { ENV } from '@constants';
import { encodeQueryString } from '@cyferd/client-engine';

export interface getNavigateToArgs {
  id: string;
  root: string;
  path: string;
  search: string;
  key: string;
  push: (args: { pathname: string; search: string }) => void;
}

export const getNavigateToTab = (config: getNavigateToArgs) => (event?: any) => {
  const search = encodeQueryString({ [config.key]: config.id });

  if (event?.metaKey) window.open(`${ENV.PUBLIC_URL}${config.root}${config.path}${search}`, '_blank');
  // eslint-disable-next-line no-restricted-syntax
  else config.push({ pathname: `${config.root}${config.path}`, search });
};
