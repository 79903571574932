import { COLOR, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    padding: ${GAP.S} 0;
    display: flex;
    flex-direction: column;
    gap: ${GAP.XL};
  `,
  formContainer: css`
    border-top: 1px solid ${COLOR.NEUTRAL_3};
    padding-top: ${GAP.XL};
    display: grid;
    grid-gap: ${GAP.M};
    grid-template-columns: 100%;
  `,
  dropdownContainer: css`
    position: fixed;
    background-color: ${COLOR.BASE_BACKGROUND};
    z-index: 10;
    padding: ${GAP.M} ${GAP.S} ${GAP.XXXS} ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    display: grid;
    grid-gap: ${GAP.XXS};
    max-height: 40vh;
    overflow: auto;
  `
};
