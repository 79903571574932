import { useRef } from 'react';
import { useGetElementSize } from './useGetElementSize';

export const useDropdownDimensions = (outerMainRef?: React.MutableRefObject<HTMLDivElement>) => {
  const internalMainRef = useRef<HTMLDivElement>();
  const mainRef = outerMainRef ? outerMainRef : internalMainRef;
  const { ref: dropdownRef, width, height } = useGetElementSize();
  const { x, y, width: triggerWidth, height: triggerHeight } = mainRef?.current?.getBoundingClientRect() || { x: 0, y: 0 };
  const { clientWidth, clientHeight } = document.documentElement;
  const triggerDimensions = { x, y, width: triggerWidth, height: triggerHeight };
  const menuDimensions = { width, height };
  const clientDimensions = { width: clientWidth, height: clientHeight };
  return {
    mainRef: internalMainRef,
    dropdownRef,
    triggerDimensions,
    menuDimensions,
    clientDimensions
  };
};
