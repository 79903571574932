import { css } from '@emotion/react';
import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, HC_COLOR, HC_SECONDARY_COLOR } from '@constants';

export const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    background-color: ${HC_SECONDARY_COLOR.HC_15};
    align-items: center;
    padding: ${GAP.M} ${GAP.S};
    border-radius: ${GAP.XS};
    margin-bottom: ${GAP.S};
  `,
  content: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
  `,
  message: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    display: inline;
  `,
  linkBtn: css`
    color: ${HC_COLOR.HC_15};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    display: inline;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  `
};
