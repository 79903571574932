import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  description: css`
    width: 100%;
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-height: ${FONT_SIZE.M}; // patch to avoid vertical overflow on large screens
  `,
  padding: css`
    padding: ${GAP.XXS} 0 0 0;
  `,
  active: css`
    color: ${COLOR.BRAND_1};
  `
};
