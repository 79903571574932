import type { ComponentProps } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';

import type { ApiModel, BaseFieldProps, FormComponentRecord, SchemaFormBaseProps } from '@cyferd/client-engine';
import { GeneralModel, SchemaFormBase } from '@cyferd/client-engine';

import type { IOptionListItem } from '@components/elements/OptionMenu';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';
import { useComponentRecord } from './componentRecord/useComponentRecord';
import { ArrayItemContext } from './componentRecord/useRenderArrayItem';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';

const emptyList = [];

export interface BaseFormProps extends Omit<SchemaFormBaseProps, 'componentRecord' | 'useParsers'> {
  allowFormula?: boolean;
  inputList?: FormulaInputRow[];
  id?: string;
  componentRecord?: void;
  getComponentRecord?: (base: FormComponentRecord) => FormComponentRecord;
  apiQuery?: ApiModel.ApiValue['query'];
  ungroupedKey?: string;
  currentStepId?: string;
  asStepper?: boolean;
  wrapDetailGroups?: boolean;
  shouldValidate?: boolean;
  unsuportedSpecialFormatList?: SchemaFormBaseProps['supportedSpecialFormatList'];
  getOptionMenu?: (event: BaseFieldProps) => IOptionListItem[];
  onGetFileRequest?: (file: File) => Observable<any>;
  onDownloadFile?: (fileId: string) => Observable<ApiModel.APIAction>;
  maxColumns?: number;
}

/* istanbul ignore next line */
const defaultFileFn = () => EMPTY;

const getErrorMessage = (hasError: boolean, error: string) => (hasError && !!error ? error : undefined);

const defaultOptionMenu: IOptionListItem[] = [];

export const BaseForm = ({
  id,
  getComponentRecord = c => c,
  apiQuery,
  ungroupedKey,
  currentStepId,
  asStepper,
  onGetFileRequest = defaultFileFn,
  onDownloadFile = defaultFileFn,
  supportedSpecialFormatList = [],
  unsuportedSpecialFormatList = [],
  getOptionMenu = () => defaultOptionMenu,
  wrapDetailGroups = true,
  allowFormula,
  inputList,
  maxColumns,
  ...schemaFormBaseProps
}: BaseFormProps) => {
  const { useParsers } = useContext(CyWrapperContext);
  const [dragChange, setDragChange] = useState<number>();
  const getIds = useCallback(
    (elementId: string, options?: { nativeHtmlElement?: boolean; prefix?: string }) => {
      const customId = options?.prefix ? /* istanbul ignore next */ `${id}-${elementId}-${options.prefix}` : `${id}-${elementId}`;
      return options?.nativeHtmlElement
        ? { id: customId, 'data-testid': elementId || /* istanbul ignore next */ undefined }
        : { id: customId, testid: elementId || /* istanbul ignore next */ undefined };
    },
    [id]
  );

  const specialFormatListExtended = useMemo(
    () =>
      Array.from(
        new Set([
          GeneralModel.JSONSchemaFormat.FILE,
          GeneralModel.JSONSchemaFormat.ADDRESS,
          GeneralModel.JSONSchemaFormat.FILE_LIST,
          GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
          GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
          GeneralModel.JSONSchemaFormat.SQL,
          GeneralModel.JSONSchemaFormat.YAML,
          GeneralModel.JSONSchemaFormat.XML,
          GeneralModel.JSONSchemaFormat.GRAPHQL,
          GeneralModel.JSONSchemaFormat.JSON,
          GeneralModel.JSONSchemaFormat.RATING,
          GeneralModel.JSONSchemaFormat.RANGE,
          GeneralModel.JSONSchemaFormat.NUMERIC_RANGE,
          GeneralModel.JSONSchemaFormat.PHONE_NUMBER,
          GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
          GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
          GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
          GeneralModel.JSONSchemaFormat.ASSOCIATION,
          GeneralModel.JSONSchemaFormat.ICON,
          GeneralModel.JSONSchemaFormat.ICON_IMAGE,
          GeneralModel.JSONSchemaFormat.COLOR,
          GeneralModel.JSONSchemaFormat.EVALUATION,
          GeneralModel.JSONSchemaFormat.RICH_TEXT,
          GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST,
          GeneralModel.JSONSchemaFormat.SEARCH,
          GeneralModel.JSONSchemaFormat.COLLECTION_LITE,
          GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
          GeneralModel.JSONSchemaFormat.INFO_BLOCK,
          ...supportedSpecialFormatList
        ])
      ).filter(f => !unsuportedSpecialFormatList.includes(f)),
    [supportedSpecialFormatList, unsuportedSpecialFormatList]
  );

  const baseComponentRecord = useComponentRecord({
    id,
    ungroupedKey,
    asStepper,
    currentStepId,
    wrapDetailGroups,
    apiQuery,
    allowFormula,
    inputList,
    maxColumns,
    dragChange,
    addDefaults: schemaFormBaseProps.addDefaults,
    getComponentRecord,
    setDragChange,
    getIds,
    getOptionMenu,
    getErrorMessage,
    onGetFileRequest,
    onDownloadFile
  });

  const formComponentRecord = useMemo(() => getComponentRecord(baseComponentRecord), [baseComponentRecord, getComponentRecord]);

  const formProps: ComponentProps<typeof SchemaFormBase> = {
    useParsers,
    ...schemaFormBaseProps,
    supportedSpecialFormatList: specialFormatListExtended,
    componentRecord: formComponentRecord
  };

  return (
    <ArrayItemContext.Provider value={{ optionList: emptyList }}>
      <SchemaFormBase {...formProps} />
    </ArrayItemContext.Provider>
  );
};
