import type { ComponentProps } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';

import type { ApiModel, CollectionModel, GeneralModel } from '@cyferd/client-engine';
import { ViewModel, swallowError } from '@cyferd/client-engine';

import { styles as cyModalStyles } from '../../smart/CyModal/styles';
import type { FilterRow } from '../AdvancedFilter/components/FilterRow';
import { CTA, CTAType } from '../CTA';
import { Filters } from '../Filters';
import { Modal } from '../Modal';
import { styles } from './styles';
import type { SortByValue } from '../SortBy';
import { SortBy } from '../SortBy';
import { TRANS } from '@constants';

export interface FiltersModalProps {
  testid?: string;
  entity: CollectionModel.Collection;
  cursor: GeneralModel.FetchCriteria;
  disabled?: boolean;
  displaySorting?: boolean;
  ctaProps?: Partial<ComponentProps<typeof CTA>>;
  fetchCollectionModel: (collectionId: string) => Observable<CollectionModel.Collection>;
  onSubmit: (cursor: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>;
  renderSchemaForm?: ComponentProps<typeof FilterRow>['renderSchemaForm'];
}

export const FiltersModal = ({
  testid = 'filters-modal',
  entity,
  cursor,
  disabled,
  ctaProps,
  fetchCollectionModel,
  onSubmit,
  renderSchemaForm,
  displaySorting = false
}: FiltersModalProps) => {
  const [state, setState] = useState(cursor?.filter);
  const [sortBy, setSortBy] = useState<SortByValue>({ orderBy: cursor?.options?.orderBy, descending: cursor?.options?.descending });
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setOpen(p => !p);
    setState(cursor?.filter);
  }, [cursor?.filter]);

  const onInternalSubmit = () => {
    onSubmit({ ...cursor, filter: state, options: { ...cursor?.options, ...sortBy, skip: 0 } })
      .pipe(swallowError())
      .subscribe();
    setOpen(false);
  };

  const onClear = () => {
    onSubmit({ ...cursor, filter: {}, options: displaySorting ? { ...cursor?.options, orderBy: undefined, descending: undefined } : cursor?.options })
      .pipe(swallowError())
      .subscribe();
    setOpen(false);
  };

  useEffect(() => {
    setState(cursor?.filter);
    setSortBy({ orderBy: cursor?.options?.orderBy, descending: cursor?.options?.descending });
  }, [cursor]);

  const hasAdvancedFilters: boolean = useMemo(() => cursor?.filter?.$and?.length > 0 || cursor?.filter?.$or?.length > 0, [cursor?.filter]);

  return (
    <>
      <div css={styles.toggleContainer} style={{ position: 'relative' }} data-selector="adv-filters-btn">
        <CTA
          onClick={toggleModal}
          testid={`${testid}-toggle-advanced-filters`}
          icon="filter_alt"
          disabled={disabled}
          type={CTAType.LINK}
          size={ViewModel.CTASize.LARGE}
          tooltip={TRANS.client.buttons.filters}
          {...ctaProps}
        />
        {hasAdvancedFilters && <div data-testid="advanced-filters-active-dot" css={styles.dot} />}
      </div>
      {!!isOpen && (
        <Modal
          type={ViewModel.ModalType.REGULAR}
          open={isOpen}
          testid={testid}
          title={TRANS.client.buttons.filters}
          onClose={toggleModal}
          footer={
            <div css={cyModalStyles.actionListContainer}>
              <div>
                <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={toggleModal} testid={`${testid}-cancel-btn`} />
              </div>
              <div>
                <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.clearAll} onClick={onClear} testid={`${testid}-clear-btn`} />
              </div>
              <div>
                <CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.apply} onClick={onInternalSubmit} testid={`${testid}-accept-btn`} />
              </div>
            </div>
          }
        >
          {!!displaySorting && <SortBy entity={entity} value={sortBy} onChange={setSortBy} />}
          <Filters
            displayLabel={!!displaySorting}
            entity={entity}
            fetchCollectionModel={fetchCollectionModel}
            value={state}
            onChange={setState}
            renderSchemaForm={renderSchemaForm}
            enableAssociations={true}
            disabled={disabled}
            delegateOptionsToValueInput={true}
          />
        </Modal>
      )}
    </>
  );
};
