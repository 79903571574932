import { useEffect, useRef, useState } from 'react';

export const useSafeState = (initialState: any) => {
  const isMountedRef = useRef(false);
  const [state, setState] = useState(() => (typeof initialState === 'function' ? initialState() : initialState));

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const safeSetState = (newState: any) => {
    if (isMountedRef.current) {
      setState(typeof newState === 'function' ? newState() : newState);
    }
  };

  return [state, safeSetState];
};
