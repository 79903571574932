import type { ReactNode } from 'react';
import { styles } from './styles';
import { useGetElementSize } from '@utils';

export interface CompactSwitchProps {
  expanded: ReactNode;
  compact: ReactNode;
  maxHeight: number;
}

/** This component renders expanded version at all times to be able to measure it */
export const CompactSwitch = ({ compact, expanded, maxHeight }: CompactSwitchProps) => {
  const { height, ref } = useGetElementSize();

  const isCompact = !!height && height > maxHeight;

  return (
    <>
      {!!height && !!isCompact && compact}
      <div css={[styles.compactContainer(maxHeight), (isCompact || !height) && styles.hidden]}>
        <div ref={ref}>{expanded}</div>
      </div>
    </>
  );
};
