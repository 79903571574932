import { useCallback, useEffect, useMemo, useState } from 'react';

import type { CollectionModel, GeneralModel } from '@cyferd/client-engine';
import { ViewModel, isObject, keepKeyList, normalize } from '@cyferd/client-engine';

import { getNewEntity } from '@models/entity';
import { styles } from './styles';
import type { IOptionMenu } from '@components/elements/OptionMenu';
import { OptionMenu } from '@components/elements/OptionMenu';
import { Modal } from '@components/elements/Modal';
import { CTAType } from '@components/elements/CTA';
import { FormulaPreview } from '@components/elements/Evaluator/FormulaPreview';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { TRANS } from '@constants';
import { SchemaEditor } from '../../../builder/views/shared/SchemaEditor';

export interface CollectionLiteProps {
  id?: string;
  testid?: string;
  description?: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  optionList?: IOptionMenu['optionList'];
  errorMessage?: string;
  color?: GeneralModel.Color.ThemeColor;
  value: CollectionModel.Collection;
  info?: string;
  inputList?: FormulaInputRow[];
  disabledType?: GeneralModel.DisabledType;
  onChange: (event: CollectionModel.Collection) => void;
}

const safeNormalize = (value: CollectionModel.Collection) => {
  try {
    return normalize.collection(value);
  } catch {
    return value;
  }
};

export const CollectionLite = ({
  id,
  testid,
  description,
  disabled,
  label,
  required,
  optionList,
  errorMessage,
  color,
  value,
  info,
  inputList,
  disabledType,
  onChange
}: CollectionLiteProps) => {
  const [internalValue, setInternalValue] = useState<CollectionModel.Collection>(safeNormalize(value));
  const [open, setOpen] = useState<boolean>(false);
  const onToggle = useCallback(() => setOpen(p => !p), []);

  const initialValue: CollectionModel.Collection = useMemo(() => {
    const base = keepKeyList<CollectionModel.Collection>(getNewEntity(), ['id', 'schema']);
    return safeNormalize({ ...base, detailGroupList: [], schema: { ...base.schema, properties: {} } });
  }, []);

  const safeValue = useMemo(() => {
    if (!isObject(internalValue?.schema?.properties) || !Array.isArray(internalValue?.detailGroupList)) return initialValue;
    else return internalValue;
  }, [initialValue, internalValue]);

  const fieldCount = useMemo(() => Object.keys(isObject(value?.schema?.properties) ? value?.schema?.properties : {}).length, [value?.schema?.properties]);

  const onApply = useCallback(() => {
    onChange(internalValue);
    onToggle();
  }, [internalValue, onChange, onToggle]);

  const onCancel = useCallback(() => {
    setInternalValue(safeNormalize(value));
    onToggle();
  }, [onToggle, value]);

  useEffect(() => {
    setInternalValue(safeNormalize(value));
  }, [value]);

  return (
    <div>
      <FormulaPreview
        formula={value}
        inputList={inputList}
        id={id}
        testid={testid}
        description={description}
        label={label}
        value={fieldCount ? [`${fieldCount} field`, fieldCount > 1 && 's'].filter(Boolean).join('') : ''}
        required={required}
        optionList={optionList}
        errorMessage={errorMessage}
        color={color}
        onChange={onChange}
        disabled={disabled}
        onFocus={onToggle}
        autoFocus={false}
        info={info}
        disabledType={disabledType}
      />

      {!!open && (
        <Modal
          open={true}
          title={label}
          description={description}
          type={ViewModel.ModalType.FULL_SCREEN}
          footer={
            <OptionMenu
              optionList={[
                { important: true, testid: 'collection-lite-cancel', label: TRANS.client.buttons.cancel, type: CTAType.SECONDARY, onClick: onCancel },
                {
                  important: true,
                  testid: 'collection-lite-apply',
                  disabled: disabled,
                  label: TRANS.client.buttons.apply,
                  type: CTAType.PRIMARY,
                  onClick: onApply
                }
              ]}
            />
          }
        >
          <div data-testid="collection-lite-editor" className={styles.container}>
            <SchemaEditor allowApiFormats={false} allowNg={false} value={safeValue} onChange={setInternalValue} />
          </div>
        </Modal>
      )}
    </div>
  );
};
