import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  mainContainer: css`
    cursor: pointer;
  `,
  inputValue: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: ${GAP.M};
  `,
  chipsContainer: css`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    gap: ${GAP.S};
  `,
  chipContainer: css`
    display: flex;
    align-items: center;
  `,
  expandIconContainer: css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `,
  menuContainer: css`
    position: fixed;
    z-index: 10;
  `
};
