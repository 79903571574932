import React from 'react';
import { styles } from './styles';

export interface SkeletonProps {
  children: React.ReactNode;
  loading?: boolean;
  content?: React.ReactNode;
}

export interface SkeletonElementBaseProps {
  /**
   * The size of the element in pixels.
   */
  size?: number;
  /**
   * The animation delay in seconds.
   */
  delay?: number;
  children?: React.ReactNode;
  round?: boolean;
}

export interface AvatarProps extends Omit<SkeletonElementBaseProps, 'round' | 'children'> {}

export interface ContentProps extends Omit<SkeletonElementBaseProps, 'size' | 'children'> {}

const SkeletonElement: React.FC<ContentProps> = ({ delay, round }) => (
  <div css={[styles.content, round && styles.round]} style={delay ? { animationDelay: `${delay}s` } : {}} />
);

const SkeletonElementAvatar: React.FC<AvatarProps> = ({ size, delay }) => (
  <div css={styles.avatar({ size })} style={delay ? { animationDelay: `${delay}s` } : {}} />
);

/** @todo: we can add default content if content is missing */
const Skeleton: React.FC<SkeletonProps> & {
  Avatar: React.FC<AvatarProps>;
  Content: React.FC<ContentProps>;
} = props => {
  const { children, loading, content } = props;
  if (loading || !('loading' in props)) {
    return <div css={styles.wrapper}>{content}</div>;
  }
  return <>{children}</>;
};

Skeleton.Avatar = SkeletonElementAvatar;
Skeleton.Content = SkeletonElement;

export default Skeleton;
