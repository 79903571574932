import { MAX_WIDTH } from '@components/elements/Popover/styles';
import { COLOR, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  content: css`
    width: 100%;
  `,
  popoverContainer: css`
    width: calc(${MAX_WIDTH} - (${GAP.L} * 2));
    margin-top: -${GAP.S};
  `,
  popoverContent: css`
    background-color: ${COLOR.BASE_BACKGROUND};
    padding: ${GAP.XS} ${GAP.S};
    border-radius: ${RADIUS.S};
  `,
  inlineFormula: css`
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: ${GAP.XS};
  `
};
