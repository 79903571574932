import { memo, useId } from 'react';

import { COLOR, SECONDARY_COLOR } from '@constants';
import { styles } from './styles';
import { TableCell } from './TableCell';
import type { Item } from './types';
import { TableHead } from './TableHead';

export interface TableProps {
  head: Item[];
  body: Item[][];
  testid?: string;
  lastColumnSticky?: boolean;
}

export const Table = memo(({ head, body, testid = 'table', lastColumnSticky }: TableProps) => {
  const id = useId();
  return (
    <div css={styles.wrapper} data-testid={testid}>
      <table css={styles.table}>
        <TableHead id={id} testid={testid} head={head} lastColumnSticky={lastColumnSticky} />
        <tbody>
          {body.map((tr, rowIndex) => {
            const onClickItem = tr[0]?.onClickItem;
            const mainColor = COLOR[tr[0]?.rowColor];
            const secondaryColor = SECONDARY_COLOR[tr[0]?.rowBgColor];
            return (
              <tr
                data-testid="table-row"
                key={`tr-${rowIndex}`}
                css={[styles.row, lastColumnSticky && styles.lastColumnSticky(id), styles.rowColor(secondaryColor), onClickItem && styles.clickableRow]}
                onClick={onClickItem}
              >
                <td css={styles.recordColor(mainColor)} />
                {tr.map(td => {
                  return (
                    <td data-selector={id} key={td.id} css={styles.cellTd}>
                      <TableCell {...td} format={td.format} testid={testid} rowIndex={rowIndex} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

Table.displayName = 'Table';
