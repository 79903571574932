import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/css';

export const styles = {
  container: css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
  text: css`
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: ${FONT_SIZE.XM};
    color: ${COLOR.BRAND_1};
    border: 2px dashed ${COLOR.BRAND_1};
    padding: ${GAP.L};
  `,
  compact: css`
    font-size: ${FONT_SIZE.M};
    padding: ${GAP.M} ${GAP.L};
  `
};
