import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { ErrorBoundary, GeneralModel, ViewModel, createUUID, mergeTruthy, normalize, useDebounce, useTranslate } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { EntityEditorContainer } from '../../../EntityEditorContainer';
import type { TemplateName } from '../../schemas';
import { getTypeDefaultProps } from '../../schemas';
import { DataTypeModal } from '../DataTypeModal';
import type { SchemaCreatorContentProps as SchemaCreatorContentProps } from '../SchemaCreatorContent';
import { SchemaCreatorContent } from '../SchemaCreatorContent';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { ENV, getDataTypeIcon, getDataTypeLabel } from '@constants';

export type Props = {
  onAddDetailGroup: () => void;
  screenTop?: number;
} & Omit<SchemaCreatorContentProps, 'hiddenItems' | 'searchCriteria' | 'onAddFieldInGroup' | 'onAddInfoBlock'>;

export const SchemaCreator = memo(({ screenTop, onAddDetailGroup, onAddAssociation, ...props }: Props) => {
  const { translate } = useTranslate();
  const { getTestIdProps } = useTestingHelper('schema-creator');
  const [search, setSearch] = useState<string>('');
  const [typeModalOpen, setTypeModalOpen] = useState<boolean>(false);
  const [tempDetailGroupId, setTempDetailGroupId] = useState<string>();
  const ref = useRef<HTMLDivElement>();

  const toggleTypeModal = useCallback(() => setTypeModalOpen(prev => !prev), []);

  const onAddItem = useCallback(
    (format: TemplateName, groupId?: string) => {
      const type = GeneralModel.formatToTypeMap[format];
      const newProp = mergeTruthy(
        {
          title: translate(getDataTypeLabel(format)),
          type,
          format,
          $id: createUUID(),
          metadata: { detailGroupId: groupId || tempDetailGroupId }
        },
        getTypeDefaultProps(type, format)
      );

      props.onChange(
        normalize.schema(
          { ...props.properties, properties: { ...props.properties.properties, [newProp.$id]: newProp } },
          { validDetailGroupIdList: props.detailGroupList.map(({ id }) => id), avoidAlphabeticalSort: true }
        )
      );
      props.setActivePropertyId(newProp.$id);
    },
    [props, tempDetailGroupId, translate]
  );

  const onAddInfoBlock = useCallback((tmpId?: string) => onAddItem(GeneralModel.JSONSchemaFormat.INFO_BLOCK, tmpId), [onAddItem]);

  const debouncedSearch = useDebounce(search, ENV.INPUT_DEBOUNCE_TIME);
  useEffect(() => {
    ref?.current?.querySelectorAll?.('[data-field-highlighted="true"]')?.[0]?.scrollIntoView?.({ behavior: 'smooth' });
  }, [debouncedSearch]);

  const toggleSchemaItemOpen = (tmpId?: string) => {
    setTempDetailGroupId(tmpId);
    setTypeModalOpen((prev: boolean) => !prev);
  };

  return (
    <div {...getTestIdProps('container')} ref={ref}>
      <EntityEditorContainer
        testid="schema-creator-header"
        search={search}
        screenTop={screenTop}
        setSearch={setSearch}
        customAddButton={
          !props.interactionHidden && (
            <OptionMenu
              renderButton={({ onClick, ref }) => (
                <div ref={ref}>
                  <CTA
                    type={CTAType.ACTION}
                    icon="add"
                    size={ViewModel.CTASize.SMALL}
                    onClick={onClick}
                    tooltip="Add new"
                    testid="schema-creator-header-add-btn"
                  />
                </div>
              )}
              optionList={[
                { testid: 'add-group-btn', image: 'folder', label: 'Add new group', onClick: onAddDetailGroup },
                { testid: 'add-field-btn', image: 'note_add', label: 'Add new field', onClick: toggleSchemaItemOpen },
                !!props.allowApiFormats && { testid: 'add-association-btn', image: 'share', label: 'Add new association', onClick: onAddAssociation },
                {
                  testid: 'add-info-btn',
                  image: getDataTypeIcon(GeneralModel.JSONSchemaFormat.INFO_BLOCK),
                  label: 'Add new text block',
                  onClick: () => onAddInfoBlock()
                }
              ]}
            />
          )
        }
      >
        <ErrorBoundary>
          <SchemaCreatorContent
            {...props}
            searchCriteria={search}
            onAddFieldInGroup={toggleSchemaItemOpen}
            onAddAssociation={onAddAssociation}
            onAddInfoBlock={onAddInfoBlock}
          />
        </ErrorBoundary>
      </EntityEditorContainer>
      <DataTypeModal open={typeModalOpen} onChange={onAddItem} onClose={toggleTypeModal} />
    </div>
  );
});
