import type { ApiModel, GeneralModel } from '@cyferd/client-engine';

export const getAssociationBoundaries = (schema: GeneralModel.JSONSchema, meta: ApiModel.ApiRecordAssociationMeta) => {
  const total = meta?.totalCount + meta?.add?.length - meta?.remove?.length;
  const min = schema?.metadata?.association?.min;
  const max = schema?.metadata?.association?.max;
  if (typeof total !== 'number' || isNaN(total))
    return {
      canUnlink: true,
      canLink: true
    };
  return {
    canLink: typeof max === 'number' ? max === -1 || total < max : true,
    canUnlink: typeof min === 'number' ? total > min : true
  };
};
