import { COLOR, FONT_SIZE, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  division: css`
    margin: ${GAP.S} 0;
  `,
  arrayItemStyles: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${GAP.S};
  `,
  arrayItemContainer: (isReadonly: boolean) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    ${!isReadonly && `margin-left: -${GAP.XS};`}
  `,
  containerDisabled: css`
    background-color: ${COLOR.NEUTRAL_9};
    border: none;
    box-shadow: none;
    cursor: not-allowed;
  `,
  arrayContentLimited: css`
    overflow-y: auto;
    max-height: 400px;
  `,
  description: css`
    display: block;
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XS};
  `,
  inputDescription: css`
    padding-top: ${GAP.XXS};
  `,
  maskedValueContainer: css`
    margin-top: ${GAP.S};
    color: ${COLOR.NEUTRAL_1};
  `,
  fieldSizeFull: css`
    grid-column: 1 / -1;
  `,
  arrayContent: css`
    display: flex;
    flex-direction: column;
    margin-top: ${GAP.XXS};
  `,
  dragIcon: css`
    line-height: 0;
  `,
  isDraggingOver: css`
    border-top: 1px solid ${COLOR.BRAND_1};
    padding: ${GAP.XS} 0;
  `
};
