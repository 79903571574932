import { Translate } from '@cyferd/client-engine';
import { styles } from './styles';

export const TableHead = ({ id, testid, head, lastColumnSticky }) => (
  <thead css={styles.header}>
    <tr css={[styles.row, lastColumnSticky && styles.lastColumnSticky(id)]}>
      <th css={[styles.recordColorTh, styles.cellTh]} />
      {head.map((cell, index) => (
        <th
          data-selector={id}
          key={cell.id}
          css={styles.cellTh}
          scope="col"
          title={typeof cell.item === 'string' ? cell.item : undefined}
          data-testid={`${testid}-column-head-${cell.id}`}
          style={!index ? { paddingLeft: 0 } : undefined}
        >
          <div css={styles.headItem}>
            <Translate>{cell.item}</Translate>
          </div>
        </th>
      ))}
    </tr>
  </thead>
);
