import { useCallback, useEffect, useState } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { removeKeyList } from '@cyferd/client-engine';

import { ItemList } from '@components/elements/ItemList';
import { styles } from './styles';
import { CTA, CTAType } from '@components/elements/CTA';
import { Modal } from '@components/elements/Modal';
import { Input } from '@components/elements/Input';
import { TRANS } from '@constants';

export interface EditIdModalProps {
  parent: GeneralModel.JSONSchema;
  onChangeParent: (parent: GeneralModel.JSONSchema) => void;
  itemName: string;
  open: boolean;
  onClose: () => void;
  setActivePropertyId: (id: string, _?) => void;
}

export const EditIdModal = ({ itemName, parent, onChangeParent, open, onClose, setActivePropertyId }: EditIdModalProps) => {
  const [newId, setNewId] = useState(itemName);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (open === true) setNewId(itemName);
  }, [open, setNewId, itemName]);

  const onAcceptModal = useCallback(() => {
    onChangeParent({
      ...parent,
      properties: { ...removeKeyList(parent.properties, [itemName]), [newId]: { ...parent.properties[itemName], $id: newId, key: newId } },
      required: !parent.required || parent.required.indexOf(itemName) === -1 ? parent.required : [...parent.required.filter(item => item !== itemName), newId]
    });
    setActivePropertyId(newId);
    onClose();
  }, [onChangeParent, onClose, itemName, newId, parent, setActivePropertyId]);

  const onNewIdChange = useCallback(
    (val: string) => {
      setNewId(val);
      const trimmedVal = val?.trim();
      const errorMessage = (() => {
        if (trimmedVal && parent.properties[trimmedVal] && trimmedVal !== itemName) return 'Duplicated ID';
        if (!trimmedVal) return 'Required field';
        return '';
      })();
      setErrorMessage(errorMessage);
    },
    [setNewId, setErrorMessage, parent, itemName]
  );

  return (
    <Modal
      open={open}
      title="Change item ID"
      onClose={onClose}
      footer={
        <ItemList>
          <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={onClose} testid="edit-id-modal-cancel-btn" />
          <CTA
            type={CTAType.PRIMARY}
            label={TRANS.client.buttons.apply}
            onClick={onAcceptModal}
            disabled={!!errorMessage || newId === itemName || !newId}
            testid="edit-id-modal-accept-btn"
          />
        </ItemList>
      }
    >
      <div className={styles.contentContainer}>
        <Input
          id="edit-id-modal-input"
          name="id-input"
          label="ID"
          value={newId}
          onChange={onNewIdChange}
          errorMessage={errorMessage}
          testid="edit-id-modal-input"
        />
      </div>
    </Modal>
  );
};
