// istanbul ignore file
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import type { ViewModel } from '@cyferd/client-engine';
import { createUUID, isDeepEqual, usePrevious } from '@cyferd/client-engine';
import { clearEmptyObjects, getDiffs, useParsers } from '@utils';
import { useGetRecordProperties } from './useGetRecordPropertiesProps';
import { getFormErrors } from '@components/smart/CyForm/getFormErrors';
import { rowId$, rowIndex$ } from '../components/EditTable/constants';

type RecordsMap = Record<string, Record<string, any>>;

type IReducerValues = RecordsMap;
const initialReducerValues = {};
const reducer = (state: IReducerValues, action: any): IReducerValues => {
  switch (action.type) {
    case 'SET':
      return clearEmptyObjects({ ...state, ...action.payload });
    case 'RESET':
      return initialReducerValues;
    default:
      return state;
  }
};

type UseBulkEditParams = {
  value?: ViewModel.CyTableProps['value'];
};
export const useBulkEdit = ({ value }: UseBulkEditParams) => {
  // TODO: Create useBulkEditParsers to have all parsers in one place(?)
  const { parseRecord, parseData } = useParsers();
  const getRecordProperties = useGetRecordProperties();

  const previousValueList = usePrevious(value?.list);
  const previousValueQuery = usePrevious(value?.query);

  // If value.list changes while editing there might be issues
  const list = useMemo(() => (value?.list || []).map((item, index) => ({ ...item, [rowId$]: item.id ?? createUUID(), [rowIndex$]: index })), [value?.list]);
  const listMap = useMemo(() => (list || []).reduce((acc, { [rowId$]: rowId, ...item }) => ({ ...acc, [rowId]: item }), {}), [list]);

  const [diffs, diffsDispatch] = useReducer(reducer, initialReducerValues);
  const [properties, propertiesDispatch] = useReducer(reducer, initialReducerValues);
  const [errors, errorsDispatch] = useReducer(reducer, initialReducerValues);

  const change = useCallback(
    (rows: Record<string, any>, skipDiffs = false) => {
      const changedRows = Object.entries(rows).reduce(
        (acc, [rowId, { [rowIndex$]: index, ...item }]) => {
          const record: Record<string, any> = parseRecord({
            value: { ...listMap[rowId], ...(skipDiffs ? {} : diffs[rowId]), ...item },
            entity: value?.query,
            event: { index }
          }).output;
          const { formErrors } = getFormErrors({
            value: { record, query: value?.query, totalCount: 1 },
            model: value?.query,
            parseData,
            shouldValidate: true
          });
          return {
            ...acc,
            errors: { ...acc.errors, [rowId]: formErrors },
            diffs: { ...acc.diffs, [rowId]: getDiffs(listMap[rowId], record) },
            props: { ...acc.props, [rowId]: getRecordProperties({ record, schemaProperties: value?.query?.schema?.properties }) }
          };
        },
        { diffs: {}, props: {}, errors: {} }
      );
      diffsDispatch({ type: 'SET', payload: changedRows.diffs });
      propertiesDispatch({ type: 'SET', payload: changedRows.props });
      errorsDispatch({ type: 'SET', payload: changedRows.errors });
    },
    [listMap, getRecordProperties, parseRecord, value?.query, parseData, diffs]
  );

  const reset = useCallback(() => {
    diffsDispatch({ type: 'RESET' });
    propertiesDispatch({ type: 'RESET' });
    errorsDispatch({ type: 'RESET' });
    // need to do skipDiffs because callback has not been recalculated yet
    change(listMap, true);
  }, [listMap, change]);

  useEffect(() => {
    if (!isDeepEqual(previousValueList, value?.list) || !isDeepEqual(previousValueQuery, value?.query)) {
      reset();
    }
  }, [previousValueList, previousValueQuery, value?.list, value?.query, reset]);

  // TODO: callback on demand instead?
  const editedRecords = useMemo(
    () => Object.entries(diffs).reduce((acc, [rowId, diff]) => ({ ...acc, [rowId]: { ...listMap[rowId], ...diff } }), {}),
    [diffs, listMap]
  );

  const changeCount = useMemo(() => Object.values(diffs).reduce((acc, item) => acc + Object.keys(item).length, 0), [diffs]);

  return {
    list,
    diffs,
    properties,
    change,
    changeCount,
    editedRecords,
    errors,
    reset
  };
};

export const EditBulkContext = createContext<{
  editedRecords: Record<string, any>;
  change: (rows: Record<string, any>, skipDiffs?: boolean) => void;
  list: ReturnType<typeof useBulkEdit>['list'];
}>({
  editedRecords: {},
  change: () => {},
  list: []
});

export const useBulkEditContext = () => useContext(EditBulkContext);
