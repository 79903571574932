import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  main: css`
    [data-selector='controls'] {
      gap: 0;
    }
    [data-selector='subtitle-container'] {
      align-items: flex-start;
    }
  `,
  toolbar: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${GAP.XS};
    align-items: flex-end;
  `,
  controlsContainer: css`
    max-width: 200px;
  `,
  listHeader: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: ${GAP.XS};
    padding-top: ${GAP.S};
  `
};
