import { COLOR, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  actionColumnTitle: css`
    visibility: hidden;
  `,
  actionsContainer: css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `,
  columnHeaderButtonStyles: css`
    cursor: default;
    background: none;
    display: flex;
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.NORMAL};
    width: 100%;
    justify-content: flex-start;
    gap: ${GAP.XXS};
    align-items: center;
  `,
  clickable: css`
    cursor: pointer;
  `,
  nonClickable: css`
    cursor: not-allowed;
  `,
  titleContainerStyles: css`
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
  `
};
