import type { SvgProps } from '../types';

export const Layout_F = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.75" y="0.75" width="38.5" height="38.5" rx="3.25" stroke={fill} strokeWidth="1.5" />
    <line x1="14" y1="11.25" x2="40" y2="11.25" stroke={fill} strokeWidth="1.5" />
    <line x1="13.25" y1="12" x2="13.25" stroke={fill} strokeWidth="1.5" />
    <line x1="26.25" y1="12" x2="26.25" stroke={fill} strokeWidth="1.5" />
  </svg>
);
