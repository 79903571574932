import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, INPUT_HEIGHT, RADIUS, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    border-radius: ${RADIUS.S};
    overflow: hidden;
    min-height: calc(${INPUT_HEIGHT} - 2px);
    padding: ${GAP.XXS} ${GAP.S};
    transition: all ${TRANSITION_SPEED};
    background-color: ${COLOR.TRANSPARENT};
    :hover {
      background-color: ${COLOR.NEUTRAL_6};
    }
  `,
  containerLeftRoundCorners: css`
    border-radius: ${GAP.XXS} ${RADIUS.S} ${RADIUS.S} ${GAP.XXS};
  `,
  containerHighlighted: css`
    background-color: ${COLOR.NEUTRAL_5};
    :hover {
      background-color: ${COLOR.NEUTRAL_6};
    }
  `,
  containerDisabled: css`
    :hover {
      background-color: ${COLOR.TRANSPARENT};
    }
  `,
  infoContainer: css`
    display: flex;
    align-items: center;
  `,
  imageContainer: css`
    margin-right: ${GAP.M};
  `,
  textContainer: css`
    display: grid;
    text-align: left;
  `,
  title: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
  `,
  highlightedTitle: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.BASE_FOREGROUND};
  `,
  description: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
  `,
  highlightedDescription: css`
    color: ${COLOR.NEUTRAL_1};
  `,
  truncate: css`
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  colorBar: css`
    width: ${RADIUS.S};
    border-radius: ${RADIUS.S};
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  `,
  closeContainer: css`
    line-height: 0;
  `
};
