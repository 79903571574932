import type { PropsWithChildren } from 'react';

import type { SerializedStyles } from '@emotion/react';

import { styles } from './styles';

export const PreventClickPropagation = ({ children, containerCss }: PropsWithChildren<{ containerCss?: SerializedStyles }>) => (
  <span css={[styles.inlineDiv, containerCss]} onClick={e => e.stopPropagation()}>
    {children}
  </span>
);
