import { FONT_SIZE, GAP, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    img {
      max-height: 50vh;
    }
  `,
  center: css`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  `,
  zoomContainer: css`
    width: 100%;
    height: 100%;
    position: absolute;
  `,
  video: css`
    max-height: 50vh;
  `,
  textContainer: css`
    > div {
      width: 100%;
    }
  `,
  csvContainer: css`
    width: 100%;
    height: calc(90vh - 120px);
  `,
  pdfContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `,
  pdfControls: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  pdfContent: css`
    flex: 1;
    box-shadow: ${SHADOW.S};
  `,
  pageCount: css`
    font-size: ${FONT_SIZE.XS};
  `,
  controls: css`
    align-self: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  `,
  wrapper: css`
    width: 100%;
    height: 100%;
  `
};
