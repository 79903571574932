import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { takeUntil, tap } from 'rxjs';

import type { CollectionModel, FilterModel, SchemaFormContextValue, ApiModel } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, ofTypeSetData, useUnmountObservable, SchemaFormContext, swallowError } from '@cyferd/client-engine';

import { TRANS } from '@constants';
import { isValidCollectionId } from '@utils';
import { CTA, CTAType } from '../CTA';
import { styles } from './styles';
import { FormulaPreview } from '../Evaluator/FormulaPreview';
import type { FormulaInputRow } from '../Evaluator/resources';
import { Filters } from '../Filters';
import type { IconKeys } from '../Icon';
import { InputWrapper } from '../InputWrapper';
import { Modal } from '../Modal';
import type { IOptionMenu } from '../OptionMenu';
import { CyWrapperContext } from '../../smart/CyWrapper';

export interface CollectionFilterProps {
  collectionId?: string;
  entity?: CollectionModel.Collection;
  value?: GeneralModel.FetchCriteria['filter'];
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  label?: string;
  required?: boolean;
  testid?: string;
  icon?: string;
  optionList?: IOptionMenu['optionList'];
  onChange: (filter: FilterModel.AdvancedFilterValue) => void;
  placeholder?: string;
  color?: GeneralModel.Color.ThemeColor;
  info?: string;
  path?: string[];
  inputList?: FormulaInputRow[];
  allowFormula?: boolean;
  expanded?: boolean;
}

export const CollectionFilter = memo(
  ({
    value,
    description,
    disabled,
    errorMessage,
    label,
    testid = 'collection-filter',
    optionList,
    icon,
    onChange,
    entity,
    collectionId,
    id,
    placeholder,
    required,
    color,
    info,
    path,
    inputList,
    allowFormula,
    expanded,
    ...rest
  }: CollectionFilterProps) => {
    const { useFetchCollectionModel, renderSchemaForm, useAction } = useContext(CyWrapperContext);
    const { fullValue, useParsers } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
    const onDestroy$ = useUnmountObservable();
    const onCoreDescribe = useAction('coreDescribe');
    const fetchCollectionModel = useFetchCollectionModel();
    const [isOpen, setOpen] = useState(false);
    const [state, setState] = useState(value);
    const [internalEntity, setInternalEntity] = useState<CollectionModel.Collection>(undefined);
    const { parseSchemaProperty } = useParsers({ collectionId });
    const parsedCollectionId = useMemo(() => {
      const parsed = parseSchemaProperty(collectionId, { fullItem: fullValue, path: path?.join?.('.'), definition: null, value: collectionId });
      return !!parsed && typeof parsed === 'string' ? parsed : undefined;
    }, [collectionId, fullValue, parseSchemaProperty, path]);

    const onInternalChange = useCallback(
      (nextState: GeneralModel.FetchCriteria['filter']) => {
        /* istanbul ignore else */
        if (!disabled) setState(nextState);
      },
      [disabled]
    );

    const onInternalSubmit = useCallback(() => {
      if (disabled) return;
      onChange(state);
      setOpen(false);
    }, [onChange, state, disabled]);

    const onImmediateChange = useCallback(
      (nextState: GeneralModel.FetchCriteria['filter']) => {
        /* istanbul ignore else */
        if (!disabled) onChange(nextState);
      },
      [onChange, disabled]
    );

    const onOpen = useCallback(() => {
      if (entity || internalEntity) setOpen(true);
    }, [entity, internalEntity]);

    const onClose = useCallback(() => setOpen(false), []);
    const onCancel = useCallback(() => {
      if (value !== state) setState(value);
      setOpen(false);
    }, [value, state]);

    useEffect(() => {
      if (entity || !isValidCollectionId(parsedCollectionId)) return;
      onCoreDescribe({ query: { cursor: { collectionId: parsedCollectionId } }, pointer: GeneralModel.IGNORED_POINTER_ID })
        .pipe(
          takeUntil(onDestroy$),
          ofTypeSetData(),
          tap(value => value?.query && setInternalEntity(value.query)),
          swallowError()
        )
        .subscribe();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedCollectionId, entity]);

    /* istanbul ignore next */
    useEffect(() => {
      if (value !== state) setState(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const inputValue = useMemo(() => (value ? { value: JSON.stringify(value) } : {}), [value]);

    const safeEntity = entity || internalEntity;

    if (expanded) {
      return (
        <InputWrapper
          {...rest}
          {...inputValue}
          unlimitedHeight={true}
          unframed={false}
          label={label}
          onChange={onChange}
          optionList={optionList}
          description={description}
          errorMessage={errorMessage}
          required={required}
          disabled={disabled}
          color={color}
          info={info}
        >
          <Filters
            entity={safeEntity}
            fetchCollectionModel={fetchCollectionModel}
            value={state}
            onChange={onImmediateChange}
            renderSchemaForm={renderSchemaForm}
            enableAssociations={true}
            delegateOptionsToValueInput={true}
            disabled={disabled}
            allowFormula={allowFormula}
          />
        </InputWrapper>
      );
    }

    return (
      <>
        <div data-testid={testid} id={id} onClick={onOpen}>
          <FormulaPreview
            formula={value}
            inputList={inputList}
            {...rest}
            {...inputValue}
            label={label}
            onChange={onChange}
            onFocus={onOpen}
            optionList={optionList}
            description={description}
            errorMessage={errorMessage}
            placeholder={placeholder}
            required={required}
            readOnly={true}
            disabled={disabled}
            color={color}
            info={info}
          />
        </div>
        {!!isOpen && (
          <Modal
            type={ViewModel.ModalType.REGULAR}
            open={true}
            testid={`${testid}-modal`}
            title={label}
            description={description}
            icon={icon as IconKeys}
            onClose={onClose}
            footer={
              <div css={styles.actionListContainer}>
                <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={onCancel} testid={`${testid}-cancel-btn`} />
                <CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.apply} onClick={onInternalSubmit} testid={`${testid}-accept-btn`} />
              </div>
            }
          >
            <Filters
              entity={safeEntity}
              fetchCollectionModel={fetchCollectionModel}
              value={state}
              onChange={onInternalChange}
              renderSchemaForm={renderSchemaForm}
              enableAssociations={true}
              delegateOptionsToValueInput={true}
              disabled={disabled}
              allowFormula={allowFormula}
            />
          </Modal>
        )}
      </>
    );
  }
);

CollectionFilter.displayName = 'CollectionFilter';
