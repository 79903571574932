import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { BUILDER_ROUTE, routePermissionMap } from '@constants';
import { ClientEngineContext } from '@cyferd/client-engine';

export const useCanAccessRoute = () => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const { pathname } = useLocation();

  return useCallback(
    (path?: string) => {
      switch (path) {
        case BUILDER_ROUTE.PLATFORM_SETTINGS.ROOT:
          return Boolean(user?.isTenantAdmin);
        default:
          const roleList = user?.roles || /* istanbul ignore next */ [];
          const currentPath = `/${pathname.split('/')[1]}`;
          const config = routePermissionMap[path ?? currentPath];

          return !!Array.isArray(config) && config.every(role => roleList.includes(role));
      }
    },
    [pathname, user?.isTenantAdmin, user?.roles]
  );
};
