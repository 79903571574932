import type { ComponentProps } from 'react';
import { useContext, useMemo } from 'react';
import { SelectDropdown } from '../SelectDropdown';
import { ApiModel, ClientEngineContext } from '@cyferd/client-engine';
import { DOC_ID, useNavigateToDoc } from '../Docs/resources';
import type { IOptionListItem } from '../OptionMenu';
import { hasFlag } from '@utils';

export interface ActionDropdownProps extends Omit<ComponentProps<typeof SelectDropdown>, 'options'> {}

const availableActions = Object.values(ApiModel.FlowSchemaMap).filter(actionConfig => actionConfig.hidden !== true);

export const ActionDropdown = ({ optionList, value, ...props }: ActionDropdownProps) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const onNavigateToDoc = useNavigateToDoc();

  const completeOptionList = useMemo(
    () => [
      ...(optionList || []),
      {
        disabled: !value,
        image: 'help',
        important: true,
        testid: `action-help-btn`,
        tooltip: 'Help',
        onClick: () => onNavigateToDoc(`${DOC_ID.API_ACTIONS}-${value}`)
      } as IOptionListItem
    ],
    [onNavigateToDoc, optionList, value]
  );

  const actionTypeOptionList = useMemo(
    () =>
      availableActions
        .filter(({ flag }: any) => !flag || hasFlag(user, flag))
        .map(action => ({
          label: action.name,
          value: action.name,
          description: action.description
        })),
    [user]
  );

  return <SelectDropdown {...props} value={value} optionList={completeOptionList} options={actionTypeOptionList} />;
};
