import { COLOR, EMPTY_CONTAINER_HEIGHT, FONT_SIZE, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
  `,
  headerContainer: css`
    margin-bottom: ${GAP.M};
  `,
  content: css`
    flex: 1;
    overflow: auto;
    margin-top: ${GAP.XS};
  `,
  headerActionList: css`
    [data-selector='option-menu-container'] {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  `,
  headerSearchContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: ${GAP.XS};
  `,
  searchInputContainer: css`
    > div {
      align-items: flex-end;
    }
  `,
  controls: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  listChildren: css`
    margin-top: ${GAP.S};
    flex-wrap: wrap;
  `,
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `,
  titleContainer: css`
    h1 {
      color: ${COLOR.NEUTRAL_1};
      font-size: ${FONT_SIZE.XXM};
    }
    display: flex;
    gap: ${GAP.M};
    align-items: center;
  `
};
