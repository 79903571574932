import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';

import type { StorageInterface } from '@cyferd/client-engine';
import { GeneralModel, useRecentColors, useTranslate } from '@cyferd/client-engine';

import { InputWrapper } from '../InputWrapper/InputWrapper';
import { COLOR, SECONDARY_COLOR, TRANS } from '@constants';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { styles } from './styles';
import type { IOptionMenu } from '../OptionMenu';
import { CTA, CTAType } from '../CTA';
import { Modal } from '../Modal';
import { ToolTip } from '../Tooltip';

const LocalStorageAdapter: StorageInterface = {
  getItem: async (key: string) => {
    return localStorage.getItem(key);
  },
  setItem: async (key: string, value: string) => {
    localStorage.setItem(key, value);
  }
};

export interface ColorInputProps {
  id?: string;
  testid?: string;
  name?: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: GeneralModel.Color.ThemeColor) => void;
  errorMessage?: string;
  placeholder?: string;
  required?: boolean;
  optionList?: IOptionMenu['optionList'];
  info?: string;
  disabledType?: GeneralModel.DisabledType;
}

export const ColorInput = ({
  id,
  label,
  testid = 'color-input',
  name,
  description,
  disabled,
  value,
  onChange,
  errorMessage,
  placeholder = TRANS.client.placeholder.pickColor,
  required,
  optionList,
  info,
  disabledType
}: ColorInputProps) => {
  const { translate } = useTranslate();
  const generatedId = useId();
  const internalId = id || generatedId;
  const [isOpen, setOpen] = useState<boolean>(false);
  const currentTheme = useCurrentTheme();
  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);

  const safeValue = useMemo(() => (!COLOR[value] ? null : value), [value]);

  const { recentColors, addRecentColor } = useRecentColors(LocalStorageAdapter);
  const [selectedColor, setSelectedColor] = useState<GeneralModel.Color.ThemeColor>(safeValue as GeneralModel.Color.ThemeColor);

  const toggleModal = useCallback(() => {
    setOpen(p => !p);
  }, [setOpen]);

  const onSelect = useCallback(() => {
    onChange(selectedColor);
    if (selectedColor) addRecentColor(selectedColor);
    toggleModal();
  }, [addRecentColor, onChange, selectedColor, toggleModal]);

  const clearSelection = useCallback(
    e => {
      e?.stopPropagation?.();
      onChange(null);
    },
    [onChange]
  );

  const [focused, setFocused] = useState<boolean>(false);
  const onBlur = useCallback(() => setFocused(false), []);
  const onFocus = useCallback(() => setFocused(true), []);

  useEffect(() => {
    if (isOpen) setSelectedColor(safeValue as GeneralModel.Color.ThemeColor);
  }, [isOpen, setSelectedColor, safeValue]);

  return (
    <div>
      <div css={styles.inputContainer}>
        <div css={styles.inputWrapper}>
          <InputWrapper
            id={id}
            testid={testid}
            value={safeValue}
            label={label}
            required={required}
            errorMessage={errorMessage}
            disabled={disabled}
            unframed={isReadonly}
            description={!isReadonly && description}
            focused={focused}
            info={info}
            optionList={optionList}
            onClear={!!safeValue && !required && !disabled && clearSelection}
            disabledType={disabledType}
            showPlaceholderLine={true}
          >
            <div css={styles.children}>
              <input
                id={internalId}
                name={name}
                aria-invalid={JSON.stringify(!!errorMessage) as 'true' | 'false'}
                data-focusable={false}
                aria-errormessage={errorMessage ? `${id}-error` : undefined}
                css={styles.input}
                style={{
                  background: safeValue ? `linear-Gradient(90deg, ${COLOR[safeValue]} 0 50%, ${SECONDARY_COLOR[safeValue]} 50% 100%)` : 'none',
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  border: safeValue ? `1px solid ${COLOR.NEUTRAL_3}` : 'none'
                }}
                data-testid={testid}
                disabled={!!disabled}
                placeholder={!safeValue && !isReadonly ? translate(placeholder) : undefined}
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={toggleModal}
                required={required}
              />
            </div>
          </InputWrapper>
        </div>
      </div>
      <Modal
        title={label}
        description={description}
        icon="palette"
        open={isOpen}
        onClose={toggleModal}
        footer={
          <div css={styles.footer}>
            <div css={styles.actionListItem}>
              <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={toggleModal} />
            </div>
            <div css={styles.actionListItem}>
              <CTA
                type={CTAType.PRIMARY}
                label={TRANS.client.buttons.select}
                onClick={onSelect}
                disabled={!!required && !selectedColor}
                testid={`${testid}-modal-select-btn`}
              />
            </div>
          </div>
        }
      >
        <div css={styles.content}>
          {(
            [
              !!recentColors.length && [translate(TRANS.client.inputCompo.color.recent), [recentColors]],
              [translate(TRANS.client.inputCompo.color.palette), [!required && ['UNDEFINED'], ...GeneralModel.Color.clientColorGroupList].filter(Boolean)]
            ] as [string, GeneralModel.Color.ThemeColor[][]][]
          )
            .filter(Boolean)
            .map(([groupName, colorList]) => (
              <div key={groupName}>
                <h2 css={styles.groupname}>{groupName}</h2>
                <div key={groupName} css={styles.groupContainer}>
                  {colorList.map((list, rowIndex) => (
                    <div key={rowIndex} css={styles.listContainer}>
                      {list.map((color, colorIndex) => (
                        <ToolTip key={color} text={{ UNDEFINED: { label: 'Clear value' }, ...currentTheme }[color]?.label}>
                          <div
                            data-testid={`color-${color}${groupName === 'Recent' ? `-recent-${colorIndex + 1}` : ''}`}
                            style={{
                              background:
                                (color as any) !== 'UNDEFINED'
                                  ? `linear-Gradient(-45deg, ${SECONDARY_COLOR[color]} 0 50%, ${COLOR[color]} 50% 100%)`
                                  : ` linear-Gradient(-45deg, transparent 0 49%, ${COLOR.HC_5} 49% 51%, transparent 51%)`
                            }}
                            css={[styles.colorSample, (color === selectedColor || (selectedColor === null && (color as any) === 'UNDEFINED')) && styles.active]}
                            onClick={() => setSelectedColor(['UNDEFINED', selectedColor].includes(color) && !required ? null : color)}
                          />
                        </ToolTip>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};
