import { APP_WIDTH, COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  wrapper: props => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${GAP.M};
    background: ${props.evenColor ? COLOR.BASE_BACKGROUND : COLOR.NEUTRAL_5};
    gap: ${GAP.M};
    position: relative;
    border: 1px solid ${COLOR.NEUTRAL_4};
    border-radius: 4px;
    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 4px;
      background-color: ${COLOR[props.color]};
      top: 0;
      left: -1px;
      border-radius: 6px;
    }
  `,
  labelContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
  `,
  label: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_1};
  `,
  header: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0px;
    [data-selector='FilterRow']:not(:first-of-type) {
      margin-top: ${GAP.S};
    }
  `,
  flexRow: css`
    display: flex;
    width: 100%;
    min-width: 0px;
    margin-top: ${GAP.M};
  `,
  grid: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
    & > div {
      width: 100%;
    }
    @media (min-width: ${APP_WIDTH.S}px) {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      grid-column-gap: ${GAP.M};
      grid-row-gap: ${GAP.M};
      align-items: center;
    }
  `,
  columnConditionContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  logicOperatorContainer: css`
    width: auto;
  `,
  addConditionContainer: css`
    margin-top: ${GAP.M};
    display: flex;
    gap: ${GAP.M};
  `,
  even: css`
    background-color: ${COLOR.BASE_FOREGROUND};
  `,
  actionContainer: css`
    margin-left: ${GAP.XS};
    margin-top: ${GAP.XS};
  `,
  existsOperator: props => css`
    ${props.isMatchAny &&
    `[data-testid='value-container'] {
      display: none;
    }`}
    label {
      display: ${props.withOutOperator ? 'none' : 'initial'};
    }
  `
};
