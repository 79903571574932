import React, { useMemo } from 'react';

import type { CollectionModel, GeneralModel } from '@cyferd/client-engine';
import { ViewModel, getParsedActionChildren, useRecordActionsParser } from '@cyferd/client-engine';

import { COLOR, SECONDARY_COLOR } from '@constants';
import { CTAType } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { ToolTip } from '../Tooltip';
import { styles } from './styles';
import { TooltipInfo } from '../TooltipInfo';

export interface BaseCardProps {
  id?: string;
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  actionListChildren?: ViewModel.CyListProps['actionListChildren'];
  recordActions?: CollectionModel.Collection['recordActions'];
  fullItem?: any;
  testid?: string;
  color?: GeneralModel.Color.ThemeColor;
  index: number;
  disabled?: boolean;
  onClick?: (event?: any) => void;
}

export const BaseCard = ({
  id,
  title,
  subtitle,
  imageUrl,
  actionListChildren,
  recordActions,
  disabled,
  onClick,
  testid = 'BaseCard',
  fullItem,
  color,
  index
}: BaseCardProps) => {
  const isClickable = typeof onClick === 'function';
  const parsedActionChildren = useMemo(() => getParsedActionChildren(actionListChildren, { item: fullItem, index }), [actionListChildren, fullItem, index]);
  const parseRecordActions = useRecordActionsParser(recordActions);
  const parsedRecordActions = useMemo(() => parseRecordActions({ item: fullItem, index }), [fullItem, index, parseRecordActions]);

  const completeParsedActionChildren = useMemo(() => [...parsedRecordActions, ...parsedActionChildren], [parsedActionChildren, parsedRecordActions]);

  const renderActionListChildren = (itemId: string) => {
    if (!completeParsedActionChildren?.length) return null;
    return (
      <PreventClickPropagation>
        <OptionMenu
          defaultBtnType={ViewModel.CTAType.LINK}
          defaultBtnTestid={`${testid}-${itemId}-toggle-more-actions`}
          optionList={completeParsedActionChildren.map((action, actionIndex) => ({
            as: CTAType.LINK,
            label: action.label,
            testid: `${testid}-${itemId}-action-btn-${actionIndex}`,
            image: action.icon,
            disabled: disabled || !!action.disabled,
            onClick: action.onClick && (event => action.onClick(fullItem, { index, metaKey: event?.metaKey })),
            status: (action as any).status,
            color: action.color,
            tooltip: action.helperText,
            important: false
          }))}
        />
      </PreventClickPropagation>
    );
  };

  return (
    <div css={[styles.cardItem]} key={id} data-testid={`${testid}-card-btn`} onClick={onClick}>
      <ToolTip text={<TooltipInfo title={title} description={subtitle} />}>
        <div css={[styles.innerContainer, isClickable && styles.cardItemClickable, COLOR[color] && styles.borderColor({ backgroundColor: COLOR[color] })]}>
          {!!imageUrl && (
            <div css={styles.imageContainer}>
              <div css={styles.iconContainer} style={{ backgroundColor: SECONDARY_COLOR[color] }}>
                <div css={styles.iconContent}>
                  <IconImage icon={imageUrl} title={title} iconProps={{ size: '2em', fill: COLOR.NEUTRAL_1 }} imageProps={{ css: styles.image }} />
                </div>
              </div>
            </div>
          )}
          <div css={[styles.content, !imageUrl && styles.extraPadding]}>
            <div css={styles.leftContent}>
              <div css={styles.textContent}>
                <div css={styles.title}>{title}</div>
                {!!subtitle && (
                  <div css={styles.subtitle}>
                    <span>{subtitle}</span>
                  </div>
                )}
              </div>
            </div>
            <div css={styles.rightContent}>{renderActionListChildren(id)}</div>
          </div>
        </div>
      </ToolTip>
    </div>
  );
};

BaseCard.displayName = 'BaseCard';
