import { css } from '@emotion/react';

export const styles = {
  compactContainer: (height: number) => css`
    ${typeof height === 'number' && `max-height: ${height}px;`}
    overflow: hidden;
  `,
  hidden: css`
    height: 0;
  `
};
