import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  header: css`
    display: flex;
    align-items: flex-start;
    gap: ${GAP.L};
  `,
  title: css`
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.NEUTRAL_1};
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
  `,
  controls: css`
    margin-inline-start: auto;
    display: flex;
  `,
  titleContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
    align-self: center;
    flex: 1;
  `
};
