import { GeneralModel } from '@cyferd/client-engine';

export const CyTableEditableFormats = [
  GeneralModel.JSONSchemaFormat.TEXT,
  GeneralModel.JSONSchemaFormat.DATE,
  GeneralModel.JSONSchemaFormat.EMAIL,
  GeneralModel.JSONSchemaFormat.CURRENCY,
  GeneralModel.JSONSchemaFormat.NUMBER,
  GeneralModel.JSONSchemaFormat.CHECKBOX,
  GeneralModel.JSONSchemaFormat.SWITCH,
  GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
  GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
  GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP
];

export const CyTableEditableTypes = ['string', 'number', 'boolean'];

export const isEditable = schema => {
  if (!schema) return;
  if (schema.format) {
    return (
      // for associations check only for dropdown subtypes
      CyTableEditableFormats.includes(schema.format) || (schema.format === GeneralModel.JSONSchemaFormat.ASSOCIATION && schema.metadata?.subtype === 'dropdown')
    );
  }
  return CyTableEditableTypes.includes(schema.type);
};

export const typeFormatFallbacks = {
  string: GeneralModel.JSONSchemaFormat.TEXT,
  number: GeneralModel.JSONSchemaFormat.NUMBER,
  boolean: GeneralModel.JSONSchemaFormat.CHECKBOX
};

export const rowId$ = Symbol('rowId');
export const rowIndex$ = Symbol('rowIndex');
