import React, { memo, useCallback, useMemo } from 'react';

import { GeneralModel } from '@cyferd/client-engine';

import { Flag } from '../Flag';
import { styles } from './styles';
import type { InputChangeSection } from '../../utils/types';
import { DropdownMenu } from '@components/elements/DropdownMenu';
import { CTA, CustomWplCTAType } from '@components/elements/CTA';
import { InputWrapper } from '@components/elements/InputWrapper';

export type Props = {
  onChange: (value: string, section: InputChangeSection) => void;
  preferredCountries?: string[];
  value: string;
  disabled?: boolean;
  hasError?: boolean;
  disabledType?: GeneralModel.DisabledType;
};

export const FlagPicker = memo(({ onChange, preferredCountries, value, disabled, hasError, disabledType }: Props) => {
  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const safeDisabled = !isReadonly && !!disabled;

  const selected = useMemo(() => GeneralModel.countryMap?.[value], [value]);

  const orderedCountries = useMemo(() => {
    if (preferredCountries?.length > 0) return [...GeneralModel.countryList].sort((_, b) => (preferredCountries.includes(b.code) ? 1 : -1));
    return GeneralModel.countryList;
  }, [preferredCountries]);

  const onSelect = useCallback((country: GeneralModel.Country) => onChange(country.dialCode, 'prefix'), [onChange]);

  const renderTrigger = useCallback(
    ({ onClick, ref, tabIndex }) => {
      return (
        <button
          css={[styles.dialCode, isReadonly && /* istanbul ignore next */ styles.readonlyDialCode]}
          onClick={onClick}
          ref={ref}
          data-testid="dial-code-button"
          tabIndex={tabIndex}
          disabled={disabled}
        >
          <Flag code={selected?.code} />
          <p css={!!safeDisabled && styles.disabled}>{selected?.dialCode}</p>
        </button>
      );
    },
    [disabled, isReadonly, safeDisabled, selected?.code, selected?.dialCode]
  );

  return (
    <InputWrapper
      testid="flag-picker"
      disabled={!!disabled}
      disabledType={disabledType}
      showPlaceholderLine={true}
      value={value}
      errorMessage={!!hasError && ' '}
      label="Country"
    >
      <DropdownMenu renderTrigger={renderTrigger} data-testid="apps-dropdown">
        <div css={styles.dropdown}>
          {[!!value && { name: 'None', dialCode: '', code: '' }, ...orderedCountries].filter(Boolean).map(country => (
            <CTA type={CustomWplCTAType.SEEMLESS} key={country.name} onClick={() => onSelect(country)}>
              <div css={[styles.option, styles.countryButton]} data-testid="country-button">
                <Flag code={country.code} />
                <p>{`${country.name} ${country.dialCode}`}</p>
              </div>
            </CTA>
          ))}
        </div>
      </DropdownMenu>
    </InputWrapper>
  );
});
