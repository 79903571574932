import { css, keyframes } from '@emotion/react';

import { COLOR } from '@constants';

const spin = keyframes`
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
`;

const show = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const styles = {
  spinnerContainer: css`
    animation: ${show} 1s forwards;
  `,
  spinner: css`
    border-radius: 50%;

    /* 0.5px's are needed to avoid hard-stopping */
    mask: radial-gradient(farthest-side, transparent calc(100% - 2px - 0.5px), ${COLOR.BASE_FOREGROUND} calc(100% - 2px + 0.5px));

    /* we're using two half linear-gradient which is masked by the radial-gradient */
    background:
      linear-gradient(to top, ${COLOR.BRAND_1}, ${COLOR.NEUTRAL_4}) 100% 0/50% 100% no-repeat,
      linear-gradient(${COLOR.NEUTRAL_4} 50%, ${COLOR.BASE_BACKGROUND} 95%) 0 0/50% 100% no-repeat;
    animation: ${spin} 1s linear infinite;
    transition: background 0.5s ease-in-out;
  `
};
