import { isDeepEqual } from '@cyferd/client-engine';

export const getDiffs = (baseObj, compareObj) => {
  const baseObjKeys = Object.keys(baseObj);
  const compareObjKeys = Object.keys(compareObj);

  const keys = [...new Set([...baseObjKeys, ...compareObjKeys])];

  const baseObjDiffs = keys.reduce((acc, key) => {
    if (compareObj[key] !== undefined && !isDeepEqual(baseObj[key], compareObj[key], true)) {
      return { ...acc, [key]: compareObj[key] };
    }
    return acc;
  }, {});

  return baseObjDiffs;
};
