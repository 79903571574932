import { CLOSED_MENU_WIDTH, COLOR, GAP, GLOBAL_HEADER_HEIGHT, MENU_WIDTH, SHADOW, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const getStyles = ({ verticalOffset, verticalOffsetDirection }: { verticalOffset: number; verticalOffsetDirection?: 'top' | 'bottom' }) => ({
  pane: css`
    position: fixed;
    top: calc(${GLOBAL_HEADER_HEIGHT} + ${verticalOffset}px);
    right: -${MENU_WIDTH};
    z-index: 10;
    width: ${MENU_WIDTH};
    max-width: 100%;
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.L};
    box-shadow: ${SHADOW.S};
    transition: right calc(${TRANSITION_SPEED} / 2);
    height: calc(100% - (${GLOBAL_HEADER_HEIGHT} + ${verticalOffset || 0}px));
    top: ${verticalOffsetDirection === 'top' ? `calc(${GLOBAL_HEADER_HEIGHT} + ${verticalOffset || 0}px)` : `calc(${GLOBAL_HEADER_HEIGHT})`};
    bottom: ${verticalOffsetDirection === 'bottom' ? `calc(${GLOBAL_HEADER_HEIGHT} + ${verticalOffset || 0}px)` : 'auto'};
  `,
  wide: css`
    width: 750px;
  `,
  paneOpen: css`
    right: 0;
  `,
  paneLeft: css`
    position: fixed;
    top: calc(${GLOBAL_HEADER_HEIGHT} + ${verticalOffset}px);
    left: -${MENU_WIDTH};
    z-index: 10;
    width: ${MENU_WIDTH};
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.L};
    box-shadow: ${SHADOW.S};
    height: calc(100% - ${GLOBAL_HEADER_HEIGHT});
  `,
  paneOpenLeft: css`
    left: ${CLOSED_MENU_WIDTH};
    width: calc(100vw - ${CLOSED_MENU_WIDTH});
  `,
  wideLeft: css`
    left: ${MENU_WIDTH};
    width: calc(100vw - ${MENU_WIDTH});
  `
});
