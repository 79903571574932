import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';
import { CHIP_HEIGHT } from '../Chip/styles';

export const styles = {
  dropdown: css`
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    max-height: 275px;
    overflow: auto;
    [data-selector='chip-list'] {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  trigger: css`
    border: 1px solid ${COLOR.NEUTRAL_3};
    border-radius: ${RADIUS.L};
    display: inline-block;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    cursor: pointer;
  `,
  title: css`
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  triggerContent: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    height: ${CHIP_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${GAP.XS};
    padding: 0 ${GAP.S};
  `,
  activeCount: css`
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    height: 18px;
    min-width: 18px;
    color: ${FOREGROUND_COLOR.BRAND_1};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.BRAND_1};
  `
};
