import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';

import type { ApiModel, SchemaFormContextValue } from '@cyferd/client-engine';
import { ErrorBoundary, GeneralModel, SchemaFormContext, ViewModel } from '@cyferd/client-engine';

import type { EditionTab } from '../../smart/CyForm/components/AssociationModal';
import { AssociationModal } from '../../smart/CyForm/components/AssociationModal';
import { CreateAssociationModal } from '../../smart/CyForm/components/CreateAssociationModal';
import { CyWrapperContext } from '../../smart/CyWrapper';
import { AssociationDropdown } from './components/AssociationDropdown';
import { AssociationKPI } from './components/AssociationKPI';
import { AssociationListBase } from './components/AssociationListBase';
import { getAssociationBoundaries } from '@utils';
import { TRANS } from '@constants';
import { useBulkEditContext } from '@components/smart/CyTable/hooks';

export interface AssociationInputProps {
  id?: string;
  path: string[];
  subtype: GeneralModel.JSONSchemaSubtype;
  schema: GeneralModel.JSONSchema;
  apiQuery: ApiModel.ApiValue['query'];
  disabled?: boolean;
  errorMessage?: string;
  value: ApiModel.ApiRecordAssociationList[];
  /** below are not used */
  label?: string;
  description?: string;
  required?: boolean;
  doClear?: boolean;
  doSet?: boolean;
  disableCreateNew?: boolean;
  disableLink?: boolean;
  disableUnlink?: boolean;
  autoFocus?: boolean;
  shouldSetRecord?: boolean;
  disabledType?: GeneralModel.DisabledType;
}

export const AssociationInput = memo(
  ({
    id,
    disabled,
    subtype,
    schema,
    path,
    apiQuery,
    errorMessage,
    value,
    disableCreateNew,
    disableLink,
    disableUnlink,
    disabledType,
    autoFocus,
    shouldSetRecord,
    doClear,
    doSet
  }: AssociationInputProps) => {
    const { useAction, useParsers } = useContext(CyWrapperContext);
    const { fullValue, setFullValue, temp, setTemp } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
    const [editionTab, setEditionTab] = useState<EditionTab>(null);
    const [isNewOpen, setNewOpen] = useState<boolean>(false);
    const ACC_INPUT_TEMP_KEY = id;
    const dispatchRefresh = useAction('dispatchRefresh');
    const onNavigateTo = useAction('dispatchNavigateTo');
    const { parseSchemaProperty } = useParsers();

    const isNewRecord = !apiQuery?.cursor?.id;
    const fullValueRef = useRef(fullValue);
    fullValueRef.current = fullValue;

    const associationKey = schema?.metadata?.association?.cursor?.associationKey;
    const key = path.join('.');
    const metaKey = `$$${key}`;
    const meta: ApiModel.ApiRecordAssociationMeta = useMemo(() => fullValue?.[metaKey] || { add: [], modify: [], remove: [] }, [fullValue, metaKey]);

    const { canLink } = getAssociationBoundaries(schema, meta);

    const safeSubtype = useMemo(() => {
      if (subtype === GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN && value?.length > 1) return GeneralModel.JSONSchemaSubtype.ASSOCIATION_LIST;
      if (
        ![
          GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN,
          GeneralModel.JSONSchemaSubtype.ASSOCIATION_KPI,
          GeneralModel.JSONSchemaSubtype.ASSOCIATION_LIST,
          GeneralModel.JSONSchemaSubtype.ASSOCIATION_TABLE,
          GeneralModel.JSONSchemaSubtype.ASSOCIATION_CARD,
          GeneralModel.JSONSchemaSubtype.ASSOCIATION_GRID
        ].includes(subtype)
      ) {
        return GeneralModel.JSONSchemaSubtype.ASSOCIATION_LIST;
      }

      return subtype;
    }, [subtype, value]);

    const onClickItem = useCallback(
      (event: ApiModel.ApiRecord) => onNavigateTo({ path: 'DETAIL', qs: { id: event?.id, collectionId: event?.collectionId }, openInNewTab: true }),
      [onNavigateTo]
    );

    const onCloseEdit = useCallback(() => {
      setEditionTab(null);
      return EMPTY;
    }, []);

    const onOpenUnlinked = useCallback(() => {
      setEditionTab('unlinked');
      return EMPTY;
    }, []);

    const onOpenLinked = useCallback(() => {
      setEditionTab('linked');
      return EMPTY;
    }, []);

    const onToggleNew = useCallback(() => {
      setNewOpen(p => !p);
      return EMPTY;
    }, []);

    const onChangeMeta = useCallback(
      (event: Partial<ApiModel.ApiRecordAssociationMeta>, record?: ApiModel.ApiRecord) => {
        const newFullValue = { ...fullValueRef.current, [metaKey]: { ...meta, ...event }, ...(shouldSetRecord ? { [key]: [record] } : {}) };
        setFullValue(newFullValue);
        fullValueRef.current = newFullValue;
      },
      [setFullValue, metaKey, meta, shouldSetRecord, key]
    );

    const onCreateNew = useCallback((): Observable<ApiModel.APIAction> => {
      dispatchRefresh({ componentNameList: [metaKey] }).subscribe();
      onChangeMeta({ totalCount: meta.totalCount + 1 });
      return onToggleNew();
    }, [dispatchRefresh, metaKey, onChangeMeta, meta.totalCount, onToggleNew]);

    const toggleFromList = useCallback(
      (listName: 'add' | 'remove', acc: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => {
        const isAlreadyQueued = meta?.[listName]?.some(queuedAcc => queuedAcc?.id === acc?.id);
        const list = isAlreadyQueued ? [...meta?.[listName]?.filter(queuedAcc => queuedAcc?.id !== acc?.id)] : [acc, ...meta?.[listName]];
        onChangeMeta({ ...meta, [listName]: list });
        /* istanbul ignore else */
        if (record) setTemp(p => ({ ...p, [ACC_INPUT_TEMP_KEY]: { ...p?.[ACC_INPUT_TEMP_KEY], [acc?.id]: record } }));
      },
      [meta, onChangeMeta, setTemp, ACC_INPUT_TEMP_KEY]
    );

    const onToggleLink = useCallback((acc: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => toggleFromList('add', acc, record), [toggleFromList]);
    const onToggleUnlink = useCallback(
      (acc: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => toggleFromList('remove', acc, record),
      [toggleFromList]
    );

    const isInitialRender = useRef<boolean>(true);

    const extraFilters = useMemo(
      () =>
        parseSchemaProperty(
          { fixedFilter: schema?.metadata?.association?.fixedFilter, defaultFilter: schema?.metadata?.association?.defaultFilter },
          { value: fullValue?.[associationKey], fullItem: fullValue, path: associationKey, definition: schema }
        ),
      [associationKey, parseSchemaProperty, fullValue, schema]
    );

    useEffect(() => {
      if (!isInitialRender.current) dispatchRefresh({ componentNameList: [metaKey] }).subscribe();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullValue?.updatedAt]);

    useEffect(() => {
      isInitialRender.current = false;
    }, []);

    return (
      <>
        <ErrorBoundary>
          {(() => {
            switch (safeSubtype) {
              case GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN:
                return (
                  <AssociationDropdown
                    doClear={doClear}
                    doSet={doSet}
                    value={value}
                    meta={meta}
                    apiQuery={apiQuery}
                    disabled={disabled}
                    disabledType={disabledType}
                    disableCreateNew={disableCreateNew || isNewRecord}
                    disableLink={disableLink}
                    disableUnlink={disableUnlink}
                    schema={schema}
                    fixedFilter={extraFilters.fixedFilter}
                    componentName={metaKey}
                    onChange={onChangeMeta}
                    autoFocus={autoFocus}
                    shouldPassRecord={shouldSetRecord}
                    onAdd={!isNewRecord && onToggleNew}
                  />
                );
              case GeneralModel.JSONSchemaSubtype.ASSOCIATION_KPI:
                return (
                  <AssociationKPI
                    apiQuery={apiQuery}
                    schema={schema}
                    meta={meta}
                    recordMap={temp?.[ACC_INPUT_TEMP_KEY]}
                    disabled={disabled}
                    disabledType={disabledType}
                    errorMessage={errorMessage}
                    disableCreateNew={disableCreateNew || isNewRecord}
                    disableLink={disableLink}
                    disableUnlink={disableUnlink}
                    onLink={onToggleLink}
                    onUnlink={onToggleUnlink}
                    onEdit={onOpenLinked}
                    onChangeMeta={onChangeMeta}
                  />
                );
              default:
                return (
                  <AssociationListBase
                    apiQuery={apiQuery}
                    disabled={disabled}
                    disabledType={disabledType}
                    disableCreateNew={disableCreateNew || isNewRecord}
                    disableLink={disableLink}
                    disableUnlink={disableUnlink}
                    componentName={metaKey}
                    schema={schema}
                    subtype={safeSubtype}
                    fixedFilter={extraFilters.fixedFilter}
                    defaultFilter={extraFilters.defaultFilter}
                    meta={meta}
                    errorMessage={errorMessage}
                    recordMap={temp?.[ACC_INPUT_TEMP_KEY]}
                    onLink={onToggleLink}
                    onUnlink={onToggleUnlink}
                    onClickItem={onClickItem}
                    onAdd={!isNewRecord && onToggleNew}
                    onOpenLinked={onOpenLinked}
                    onOpenUnlinked={onOpenUnlinked}
                    onChangeMeta={onChangeMeta}
                  />
                );
            }
          })()}
        </ErrorBoundary>
        {!!editionTab && (
          <ErrorBoundary>
            <AssociationModal
              schema={schema}
              initialTab={editionTab}
              meta={meta}
              apiQuery={apiQuery}
              disableLink={disableLink}
              disableUnlink={disableUnlink}
              recordMap={temp?.[ACC_INPUT_TEMP_KEY]}
              onLink={onToggleLink}
              onUnlink={onToggleUnlink}
              onClose={onCloseEdit}
              onClickItem={onClickItem}
              componentName={metaKey}
              record={fullValue}
              fixedFilter={extraFilters.fixedFilter}
              defaultFilter={extraFilters.defaultFilter}
              errorMessage={errorMessage}
              actionListChildren={[
                !isNewRecord &&
                  !disableCreateNew && {
                    label: TRANS.client.buttons.createNew,
                    icon: 'add' as any,
                    type: ViewModel.CTAType.SECONDARY,
                    size: ViewModel.CTASize.SMALL,
                    onClick: onToggleNew,
                    disabled: !canLink
                  },
                { label: 'OK', type: ViewModel.CTAType.PRIMARY, onClick: onCloseEdit }
              ].filter(Boolean)}
            />
          </ErrorBoundary>
        )}
        {!!isNewOpen && (
          <ErrorBoundary>
            <CreateAssociationModal associatedSchema={schema} apiQuery={apiQuery} onClose={onToggleNew} onAssociationCreated={onCreateNew} record={fullValue} />
          </ErrorBoundary>
        )}
      </>
    );
  }
);

AssociationInput.displayName = 'AssociationInput';

export const AssociationInputEditTable = ({ recordId, ...props }: AssociationInputProps & { recordId }) => {
  const { change, list, editedRecords } = useBulkEditContext();
  const [temp, setTemp] = useState();
  const { useParsers } = useContext(CyWrapperContext);

  const fullValue = useMemo(() => editedRecords[recordId] ?? list.find(({ id }) => id === recordId), [list, recordId, editedRecords]);
  const setFullValue = useCallback(
    value => {
      change({ [recordId]: value });
    },
    [change, recordId]
  );

  return (
    <SchemaFormContext.Provider value={{ fullValue, setFullValue, temp, setTemp, useParsers }}>
      <AssociationInput {...props} schema={{ ...props.schema, title: null }} />
    </SchemaFormContext.Provider>
  );
};
