import { COLOR, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    flex: 1;
  `,
  dropdownCombo: css`
    display: flex;
    align-items: center;
    flex: 1;
  `,
  valueInput: css`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${GAP.L};
  `,
  addBtn: css`
    background-color: ${COLOR.BRAND_1};
    color: ${COLOR.BASE_BACKGROUND};
    flex: 1;
    margin-left: ${GAP.S};
  `,
  dropdown: css`
    flex: 1;
  `,
  removeBtn: css`
    display: flex;
    justify-content: center;
    margin-left: ${GAP.S};
  `,
  clipboardBtn: css`
    border-radius: 100% !important;
    background-color: ${COLOR.BASE_BACKGROUND};
    border: 1px ${COLOR.BRAND_1} solid !important;
    height: 35px;
    width: 35px;
    margin-left: ${GAP.XS};
  `,
  flexibleObjectCtaContainer: css`
    margin-bottom: ${GAP.S};
  `
};
