import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EMPTY, catchError, take, takeUntil, tap } from 'rxjs';

import type { GeneralModel } from '@cyferd/client-engine';
import { ApiModel, ErrorBoundary, ViewModel, ofType, useUnmountObservable } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../smart/CyWrapper';
import { CTA, CTAType } from '../CTA';
import { Icon } from '../Icon';
import { Modal } from '../Modal';
import { OptionMenu } from '../OptionMenu';
import { Spinner } from '../Spinner';
import { ToolTip } from '../Tooltip';
import { FileViewer } from './components/FileViewer';
import { styles } from './styles';
import { PreventClickPropagation } from '../PreventClickPropagation';
import FileInfoDropdown from '../FileInfoDropdown/FileInfoDropdown';
import { MultiFileInputContext } from '../MultiFileInput/MultiFileInputContext';

export interface FileViewerModalProps {
  title: string;
  value: GeneralModel.FileValue;
  onClose: () => void;
  onDownload: (id?: string) => void;
}

export const FileViewerModal = ({ title, value, onClose, onDownload }: FileViewerModalProps) => {
  const { indexedValues } = useContext(MultiFileInputContext);
  const [fileUrl, setFileUrl] = useState<string>();
  const [currentValue, setCurrentValue] = useState(value);

  const { useAction } = useContext(CyWrapperContext);
  const fetchFile = useAction('fileGetUrl');
  const onDestroy$ = useUnmountObservable();

  const items = useMemo(() => indexedValues.filter(item => !!item?.uploadedAt), [indexedValues]);
  const currentIndex = useMemo(() => items.findIndex(item => item.id === currentValue.id), [currentValue.id, items]);

  useEffect(() => {
    setFileUrl(undefined);
    fetchFile({ id: currentValue.id })
      .pipe(
        takeUntil(onDestroy$),
        ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
        take(1),
        tap(payload => payload?.url && setFileUrl(payload.url)),
        catchError(() => {
          onClose();
          return EMPTY;
        })
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue.id]);

  const hasNext = currentIndex < items.length - 1;
  const hasPrev = currentIndex > 0;

  const handleNext = useCallback(() => setCurrentValue({ ...items[currentIndex + 1] }), [currentIndex, items]);
  const handlePrev = useCallback(() => setCurrentValue({ ...items[currentIndex - 1] }), [currentIndex, items]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (fileUrl) {
        if (e.key === 'ArrowLeft') hasPrev && handlePrev();
        if (e.key === 'ArrowRight') hasNext && handleNext();
      }
    },
    [hasNext, hasPrev, fileUrl, handleNext, handlePrev]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const renderInfoTrigger = ({ onClick, ref }) => (
    <PreventClickPropagation>
      <div onClick={onClick} ref={ref} css={styles.tooltipTrigger}>
        <span>File info</span> <Icon name="help" />
      </div>
    </PreventClickPropagation>
  );

  return (
    <ErrorBoundary>
      <Modal
        open={true}
        type={ViewModel.ModalType.FULL_SCREEN}
        icon="file_copy"
        title={title}
        description={
          <ToolTip
            text={
              <p css={styles.tooltipContent}>
                <strong>Name:</strong>
                <span>{currentValue?.name}</span>
                <strong>Uploaded by:</strong>
                <span>{currentValue?.uploadedBy}</span>
                <strong>Uploaded at:</strong>
                <span>{currentValue?.uploadedAt}</span>
              </p>
            }
          >
            <div css={styles.description}>
              <FileInfoDropdown renderTrigger={renderInfoTrigger} value={{ ...currentValue }} />
            </div>
          </ToolTip>
        }
        header={
          fileUrl ? (
            <>
              {hasPrev && (
                <div css={styles.btnLeft}>
                  <CTA
                    testid="cta-btn-left"
                    type={CTAType.ACTION_TERTIARY}
                    size={ViewModel.CTASize.SMALL}
                    color="BASE_FOREGROUND"
                    onClick={handlePrev}
                    icon="navigate_before"
                  />
                </div>
              )}
              {hasNext && (
                <div css={styles.btnRight}>
                  <CTA
                    testid="cta-btn-right"
                    type={CTAType.ACTION_TERTIARY}
                    size={ViewModel.CTASize.SMALL}
                    color="BASE_FOREGROUND"
                    onClick={handleNext}
                    icon="navigate_next"
                  />
                </div>
              )}
            </>
          ) : null
        }
        footer={
          <div css={styles.optionMenu}>
            <OptionMenu
              optionList={[
                { important: true, testid: 'download', type: CTAType.SECONDARY, label: 'Download', onClick: () => onDownload(currentValue.id) },
                { important: true, testid: 'close', type: CTAType.PRIMARY, label: 'OK', onClick: onClose }
              ]}
            />
          </div>
        }
        onClose={onClose}
      >
        <div data-testid="file-viewer-modal" css={styles.container}>
          {!fileUrl && (
            <div>
              <Spinner size="55px" />
            </div>
          )}
          {!!fileUrl && (
            <ErrorBoundary>
              <FileViewer mimeType={currentValue?.mimeType} title={currentValue?.name} fileUrl={fileUrl} />
            </ErrorBoundary>
          )}
        </div>
      </Modal>
    </ErrorBoundary>
  );
};
