import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { Evaluator, normalize, safeParse } from '@cyferd/client-engine';

const evaluate = (formula: any) => {
  try {
    return Evaluator.parse(formula).evaluate();
  } catch {
    /* istanbul ignore next */
    return undefined;
  }
};

export const csvToApiValue = (csv: string = ''): ApiModel.ApiValue => {
  const rows = csv
    .trim()
    .split('\n')
    .map(r => r.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/).map(i => safeParse<string>(i)));
  const headRows = rows[0] as string[];
  const bodyRows = rows.slice(1, rows.length);
  const schema: GeneralModel.JSONSchema = normalize.schema(
    {
      type: 'object',
      properties: headRows.reduce(
        (total, curr, index) => ({
          ...total,
          [index]: {
            type: 'string',
            ...evaluate({ $cyf_schemafromjson: [safeParse(bodyRows?.[0]?.[index])] }),
            title: curr,
            metadata: { detailOrder: index + 1 }
          }
        }),
        {}
      )
    },
    { avoidAlphabeticalSort: true }
  );
  const list = bodyRows.map(row => row.reduce<Record<string, any>>((total, curr, index) => ({ ...total, [index]: curr }), {}));

  return { query: { schema, cursor: {} }, totalCount: bodyRows.length, list };
};
