import type { PropsWithChildren, ReactNode } from 'react';
import React, { memo, useId, useMemo } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { Translate, ViewModel } from '@cyferd/client-engine';

import { COLOR, FONT_SIZE } from '@constants';
import { CTA, CTAType } from '../CTA';
import type { IconKeys } from '../Icon';
import { Icon } from '../Icon';
import { styles } from './styles';
import { getColorFromStatus } from '../CTA/styles';
import type { ModalContainerContextValue } from './ModalContainerContext';
import { ModalContainerContext } from './ModalContainerContext';

export type ModalProps = PropsWithChildren<{
  id?: string;
  open: boolean;
  onClose?: () => void;
  title?: ReactNode;
  icon?: IconKeys;
  status?: ViewModel.Status;
  color?: GeneralModel.Color.ThemeColor;
  description?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  testid?: string;
  type?: ViewModel.ModalType;
  placeOnTop?: boolean;
  avoidLimitingHeight?: boolean;
}>;

export const Modal = memo(
  ({
    id,
    children,
    open,
    title,
    description,
    header,
    icon,
    color,
    status,
    footer,
    onClose,
    type = ViewModel.ModalType.REGULAR,
    placeOnTop,
    avoidLimitingHeight,
    ...props
  }: ModalProps) => {
    const testid = props.testid || 'modal';
    const safeColor = COLOR[color] ? color : status ? getColorFromStatus(status) : 'NEUTRAL_1';
    const instance = useId();

    /** used to affect some compos only when contained inside a modal */
    const contextValue: ModalContainerContextValue = useMemo(() => ({ instance }), [instance]);
    const containerStyle = useMemo(() => styles.container(type), [type]);
    if (!open) return null;
    return (
      <ModalContainerContext.Provider value={contextValue}>
        <div>
          <div css={[styles.overlayContainer, open && styles.overlayContainerOpen, placeOnTop && styles.placeOnTop]}>
            <div css={styles.overlay} data-testid={`${testid}-backdrop`} />
            <div id={id} css={containerStyle} data-testid={testid}>
              <div css={[styles.innerContainer, !avoidLimitingHeight && styles.limitInnerContainerHeight]}>
                <div css={styles.headerContainer}>
                  <header css={styles.header}>
                    {!!(header || title || description || icon) && (
                      <>
                        {header}
                        {!!icon && (
                          <div css={styles.iconContainer}>
                            <Icon testid={`${testid}-main-icon`} name={icon} size={FONT_SIZE.XL} fill={COLOR[safeColor]} />
                          </div>
                        )}
                        <div css={styles.titleDescriptionContainer}>
                          {!!title && (
                            <h2 data-testid={`${testid}-title`}>
                              <Translate>{title}</Translate>
                            </h2>
                          )}
                          {!!description && (
                            <div css={styles.descriptionContainer}>
                              <p data-testid={`${testid}-description`}>
                                <Translate>{description}</Translate>
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </header>
                  {!!onClose && (
                    <div css={styles.navbar}>
                      <CTA testid={`${testid}-close-btn`} type={CTAType.SEEMLESS} onClick={onClose}>
                        <Icon name="close" size={FONT_SIZE.XXM} fill={COLOR.NEUTRAL_1} title="close" />
                      </CTA>
                    </div>
                  )}
                </div>
                <div css={styles.content} data-testid={`${testid}-content`}>
                  {children}
                </div>
                <footer css={styles.footer}>{footer}</footer>
              </div>
            </div>
          </div>
        </div>
      </ModalContainerContext.Provider>
    );
  }
);

Modal.displayName = 'Modal';
