import { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';
import { ENV } from './env';

export const MAPS_URL = 'https://www.google.com/maps/search/';

export const INPUT_HEIGHT = '32px';

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
  OS = 'os'
}

export const { COLOR, SECONDARY_COLOR, FOREGROUND_COLOR, HC_COLOR, HC_SECONDARY_COLOR, HC_FOREGROUND_COLOR } = GeneralModel.Color.colorNameList.reduce(
  (total, curr) => ({
    COLOR: { ...total.COLOR, [curr]: `var(--${curr})` },
    SECONDARY_COLOR: { ...total.SECONDARY_COLOR, [curr]: `var(--${curr}_SECONDARY)` },
    FOREGROUND_COLOR: { ...total.FOREGROUND_COLOR, [curr]: `var(--${curr}_FOREGROUND)` },
    HC_COLOR: { ...total.HC_COLOR, [curr]: `var(--${GeneralModel.Color.HCMap[curr]})` },
    HC_SECONDARY_COLOR: { ...total.HC_SECONDARY_COLOR, [curr]: `var(--${GeneralModel.Color.HCMap[curr]}_SECONDARY)` },
    HC_FOREGROUND_COLOR: { ...total.HC_FOREGROUND_COLOR, [curr]: `var(--${GeneralModel.Color.HCMap[curr]}_FOREGROUND)` }
  }),
  {
    COLOR: {} as Record<GeneralModel.Color.ThemeColor, string>,
    SECONDARY_COLOR: {} as Record<GeneralModel.Color.ThemeColor, string>,
    FOREGROUND_COLOR: {} as Record<GeneralModel.Color.ThemeColor, string>,
    HC_COLOR: {} as Record<GeneralModel.Color.ThemeColor, string>,
    HC_SECONDARY_COLOR: {} as Record<GeneralModel.Color.ThemeColor, string>,
    HC_FOREGROUND_COLOR: {} as Record<GeneralModel.Color.ThemeColor, string>
  }
);

export enum GAP {
  XXXS = '1px',
  XXS = '2px',
  XS = '4px',
  S = '8px',
  M = '12px',
  L = '20px',
  XL = '24px',
  XXL = '40px'
}

export enum RADIUS {
  XS = '3px',
  S = '4px',
  M = '8px',
  L = '12px'
}

export enum SHADOW {
  XS = '0px 0px 3px var(--shadow-color)',
  S = '1px 2px 10px var(--shadow-color)',
  M = '7px 7px 10px 10px var(--shadow-color)'
}

export enum FONT_WEIGHT {
  LIGHT = '300',
  NORMAL = '400',
  SEMI_BOLD = '500',
  BOLD = '600',
  EXTRA_BOLD = '700'
}

export enum FONT_SIZE {
  XXS = '12px',
  XS = '13px',
  S = '14px',
  M = '16px',
  XM = '18px',
  XXM = '20px',
  L = '24px',
  XL = '28px',
  XXL = '36px',
  XXXL = '42px'
}

export enum CONTAINER_WIDTH {
  MIN = 260,
  XS = 350,
  S = 600,
  M = 800,
  MAX = 3440
}

export enum EMPTY_CONTAINER_HEIGHT {
  XS = 300
}

export enum APP_WIDTH {
  S = 576,
  M = 1440
}

export const TRANSITION_SPEED = '0.3s';

export const GLOBAL_HEADER_HEIGHT = '40px';

export const MENU_WIDTH = '300px';

export const CLOSED_MENU_WIDTH = '50px';

export const APP_LOGGER_HEADER = ENV.APP_LOGGER_HEADER;

export const SCROLLBAR = css`
  @media (hover: hover) and (pointer: fine) {
    ::-webkit-scrollbar {
      display: block;
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${COLOR.NEUTRAL_3};
      border-radius: ${RADIUS.S};
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;
