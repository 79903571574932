import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, TRANSITION_SPEED } from '@constants';

export const styles = {
  listWrapper: css`
    overflow: hidden;
    position: relative;
    list-style: none;
    padding-right: ${GAP.XS};
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      border-radius: ${RADIUS.S};
      width: ${GAP.XS};
    }
  `,
  listItem: css`
    color: ${COLOR.NEUTRAL_1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${GAP.S} 0 ${GAP.S} ${GAP.S};
    transition: background-color ${TRANSITION_SPEED};
    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      bottom: 0px;
      left: 4px;
      height: 1px;
      background: ${COLOR.NEUTRAL_4};
    }
  `,
  firstChild: css`
    [data-selector='listItem'] {
      border-top: 1px solid ${COLOR.NEUTRAL_4};
    }
  `,
  borderColor: props => css`
    &::after {
      background-color: ${props.backgroundColor};
    }
  `,
  clickableListItem: css`
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
    }
  `,
  infoContainer: css`
    flex: 1;
    display: flex;
    gap: ${GAP.XS};
    align-items: center;
    overflow: hidden;
  `,
  textContainer: css`
    width: 100%;
    overflow: hidden;
  `,
  title: css`
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  subtitle: css`
    padding-top: ${GAP.XXS};
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    > span {
      -webkit-line-clamp: 2;
      word-break: break-word;
    }
  `
};
