/* istanbul ignore file */
import Rollbar from 'rollbar';

import { ENV } from '@constants';
import { EventTracker, GeneralModel, Logger } from '@cyferd/client-engine';

import enginePackageJson from '../../node_modules/@cyferd/client-engine/package.json';

const eventTracker = new EventTracker(Rollbar, {
  accessToken: ENV.EVENT_REPORT_TOKEN,
  verbose: false,
  environment: ENV.ENVIRONMENT,
  version: ENV.VERSION,
  codeVersion: ENV.VERSION,
  code_version: ENV.VERSION,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: false,
  payload: {
    client: { platform: GeneralModel.CyferdApp.WEB, appName: 'client-app', version: ENV.VERSION }
  }
});

export const logger = new Logger(console, {
  enabledLogLevelList: Object.values(ENV.ENABLED_LOG_LEVEL_LIST || {}),
  verbose: true,
  batchTime: 0,
  header: `[v${ENV.VERSION} - v${enginePackageJson.version}]`,
  eventTracker
});

if (!ENV.IS_DEV) logger.eventTracker.start();
