import { getFormulaKey, isObject } from '@cyferd/client-engine';
import { FormulaInputType } from './getFormulaInputType';

export type TypeToCast = 'string' | 'number' | 'boolean' | 'object' | 'array' | 'null' | FormulaInputType.FORMULA;

export const cast = (value: any, typeToCast: TypeToCast) => {
  if (typeToCast === 'null') return null;

  const currentType = getType(value);
  switch (currentType) {
    case 'array':
      return castFromArray(value, typeToCast);
    case 'object':
      return castFromObject(value, typeToCast);
    case FormulaInputType.FORMULA:
      return castFromFormula(value, typeToCast);
    default:
      return castFromPrimitive(value, typeToCast);
  }
};

export const getType = (value: any) => {
  if (isObject(value)) {
    const fxKey = getFormulaKey(value);
    if (Array.isArray(value[fxKey])) return FormulaInputType.FORMULA;
    else return 'object';
  }
  if (Array.isArray(value)) return 'array';
  if ([null, undefined].includes(value)) return 'null';
  return typeof value;
};

const castFromFormula = (value: object, typeToCast: TypeToCast) => {
  const key = getFormulaKey(value);
  switch (typeToCast) {
    case 'boolean':
    case 'object':
    case 'number':
    case 'string':
    case 'array':
      return castFromArray(value[key], typeToCast);
    default:
      return value;
  }
};

const castFromArray = (value: any[], typeToCast: TypeToCast) => {
  switch (typeToCast) {
    case 'object':
      return Object.fromEntries(Object.entries(value));
    case 'boolean':
      return !!value.length;
    case 'number':
      const index = value.findIndex(v => typeof cast(v, typeToCast) === typeToCast);
      if (index !== -1) return cast(value[index], typeToCast);
      else return 0;
    case 'string':
      return value.map(v => cast(v, 'string')).join(',');
    case FormulaInputType.FORMULA:
      return { $cyf_coalesce: value };
    case 'array':
    default:
      return value;
  }
};

const castFromObject = (value: object, typeToCast: TypeToCast) => {
  switch (typeToCast) {
    case 'array':
    case 'boolean':
    case 'number':
    case 'string':
      return castFromArray(Object.values(value), typeToCast);
    case FormulaInputType.FORMULA:
      return { $cyf_coalesce: Object.values(value) };
    case 'object':
    default:
      return value;
  }
};

const castFromPrimitive = (value: string | number | boolean, typeToCast: TypeToCast) => {
  switch (typeToCast) {
    case 'array':
      if (typeof value === 'string') return value.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);
      else return [value];
    case 'object':
      return { [String(value)]: value };
    case 'boolean':
      if (value === 1 || value === 'true') return true;
      if (value === 0 || value === 'false') return false;
      return !!value;
    case 'number':
      if (typeof value === 'string') return Number(value.split('').reduce((t, c) => (/[0-9]/.test(c) ? `${t}${c}` : t), '0'));
      return Number(value) || 0;
    case 'string':
      return String(value);
    case FormulaInputType.FORMULA:
      return { $cyf_coalesce: [value] };
    /* istanbul ignore next | not reachable. keeping just in case */
    default:
      return value;
  }
};
