import React, { useCallback, useMemo, useState } from 'react';

import { GeneralModel, getAddressValue } from '@cyferd/client-engine';

import { CTAType } from '../CTA';
import { Input } from '../Input';
import type { IOptionListItem, IOptionMenu } from '../OptionMenu';
import { AddressModal } from './components/AddressModal';
import { useOpenMapOption } from './useOpenMapOption';
import { TRANS } from '@constants';

export interface AddressInputProps {
  id?: string;
  label?: string;
  value?: GeneralModel.AddressValue;
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  color?: GeneralModel.Color.ThemeColor;
  icon?: GeneralModel.IconName;
  required?: boolean;
  optionList?: IOptionMenu['optionList'];
  schema?: GeneralModel.JSONSchema;
  delay?: number;
  onChange: (value: GeneralModel.AddressValue) => void;
  info?: string;
  disabledType?: GeneralModel.DisabledType;
}

export const AddressInput = ({
  id,
  value,
  label,
  disabled,
  icon,
  optionList = [],
  description,
  color,
  required,
  errorMessage,
  schema,
  delay = 500,
  onChange,
  info,
  disabledType
}: AddressInputProps) => {
  const safeValue = useMemo(() => getAddressValue(value), [value]);
  const openMapOption = useOpenMapOption(safeValue);
  const [open, setOpen] = useState(false);

  const onToggleModal = useCallback(() => setOpen(prev => !prev), []);

  const baseOptions: IOptionListItem[] = useMemo(
    () => [
      {
        image: 'location_on',
        important: false,
        testid: `open-address-btn`,
        tooltip: 'Open address',
        onClick: onToggleModal,
        type: CTAType.LINK
      },
      openMapOption,
      {
        disabled: !safeValue?.summary,
        image: 'content_copy',
        important: false,
        testid: `copy-address-btn`,
        tooltip: TRANS.client.buttons.copyAddress,
        onClick: () => navigator.clipboard.writeText(safeValue?.summary),
        type: CTAType.LINK
      }
    ],
    [onToggleModal, openMapOption, safeValue?.summary]
  );

  const completeOptionList = useMemo(() => [...optionList, ...baseOptions], [baseOptions, optionList]);

  return (
    <>
      <Input
        id={id}
        testid={id}
        label={label}
        value={safeValue?.summary}
        description={description}
        color={color}
        required={required}
        errorMessage={errorMessage}
        type={GeneralModel.JSONSchemaFormat.TEXT}
        optionList={completeOptionList}
        disabled={disabled}
        onChange={onChange}
        onFocus={onToggleModal}
        disableFocusable={true}
        showClear={true}
        info={info}
        disabledType={disabledType}
      />

      {!!open && (
        <AddressModal
          delay={delay}
          title={label}
          description={description}
          icon={icon}
          value={safeValue}
          disabled={disabled}
          schema={schema}
          onChange={onChange}
          onClose={onToggleModal}
        />
      )}
    </>
  );
};
