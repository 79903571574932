import { Translate } from '@cyferd/client-engine';
import { styles } from './styles';
import { ToolTip } from '../Tooltip';

export interface InputDescriptionProps {
  description?: string;
  showPadding?: boolean;
  testId?: string;
  active?: boolean;
}

export const InputDescription = ({ description, showPadding = true, active, testId }: InputDescriptionProps) => {
  return (
    !!description && (
      <p data-testid={testId} css={[styles.description, showPadding && styles.padding, !!active && styles.active]}>
        <ToolTip text={description}>
          <Translate>{description}</Translate>
        </ToolTip>
      </p>
    )
  );
};
