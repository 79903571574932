import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XL};
    align-items: center;
  `,
  title: (isEdition: boolean) => css`
    font-size: ${isEdition ? FONT_SIZE.XXM : FONT_SIZE.L};
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
    align-items: center;
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.M};
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.NORMAL};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${GAP.XXS};
  `,
  orContainer: css`
    position: relative;
    width: 100%;
    height: 14px;
  `,
  or: css`
    font-size: ${FONT_SIZE.XS};
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.NORMAL};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    position: absolute;
    z-index: 1;
    width: 40px;
    left: calc(50% - 20px);
  `,
  orDivision: css`
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(${FONT_SIZE.XS} / 2);
    z-index: 0;
    border-top: 1px ${COLOR.NEUTRAL_3} solid;
  `
};
