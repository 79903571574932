import { useState } from 'react';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import type { CollectionModel, GeneralModel, ParsedList } from '@cyferd/client-engine';
import { ViewModel, isDeepEqual, isObject, removeKeyList, safeParse, swallowError, useFinalizeWhileMounted } from '@cyferd/client-engine';

import { CTA } from '../CTA';
import { CTAType } from '../CTA/types';
import { KanbanCard } from '../KanbanCard';
import { styles } from './styles';
import { IconImage } from '../Icon/renderIcon';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { useDragHorizontalScroll } from '@utils';

export type KanbanProps = {
  value: ParsedList;
  isLoading?: boolean;
  recordActions?: CollectionModel.Collection['recordActions'];
} & Pick<
  ViewModel.CyKanbanProps,
  'id' | 'children' | 'optionListField' | 'onClickItem' | 'actionListChildren' | 'onChangeItem' | 'onColumnAction' | 'moveDisabled'
>;

export const Kanban = ({
  id,
  value,
  optionListField,
  actionListChildren,
  recordActions,
  isLoading,
  moveDisabled,
  onClickItem,
  onChangeItem,
  onColumnAction
}: KanbanProps) => {
  const testid = `kanban-${id}`;
  const [disabled, setDisabled] = useState<boolean>(false);

  const finalize = useFinalizeWhileMounted();

  const fieldDefinition = value?.definitionMap[optionListField] && value?.definitionMap[optionListField];
  const required = fieldDefinition?.required;
  const optionList = fieldDefinition?.property?.metadata?.optionList || [];
  const listGroupedByOption: Record<string | number, ParsedList['items'][0][]> = (value?.items || [])
    .filter(i => isObject(i.fullItem))
    .reduce(
      (total, curr) => {
        const key = ![null, undefined].includes(curr.fullItem[optionListField]) ? JSON.stringify(curr.fullItem[optionListField]) : curr.fullItem['undefined'];
        return { ...total, [key]: [...(total[key] || []), curr] };
      },
      optionList.reduce((t, c) => ({ ...t, [JSON.stringify(c.value)]: [] }), required ? {} : { undefined: [] })
    );
  const optionMap: Record<string, GeneralModel.JSONSchemaMetadata['optionList'][number]> = optionList.reduce(
    (total, option) => ({ ...total, [JSON.stringify(option.value)]: option }),
    { undefined: { value: undefined, label: 'Not selected', color: 'NEUTRAL_5' } }
  );

  /* istanbul ignore next line | @todo */
  const onDragEnd = (result: DropResult) => {
    if (!result?.source || !result?.destination || !onChangeItem) return;

    const originalItem = listGroupedByOption[result.source.droppableId][result.source.index].raw;
    const changedItem = [undefined, 'undefined'].includes(result.destination.droppableId)
      ? removeKeyList(originalItem, [optionListField])
      : { ...originalItem, [optionListField]: safeParse(result.destination.droppableId) };

    /** don't fire when there are no changes */
    if (isDeepEqual(changedItem?.[optionListField], originalItem?.[optionListField])) return;

    setDisabled(true);

    onChangeItem(changedItem)
      .pipe(
        finalize(() => setDisabled(false)),
        swallowError()
      )
      .subscribe();
  };

  const { onMouseDown, onMouseMove, onMouseUp, hScrollingActive, ref: containerRef, stopPropagation } = useDragHorizontalScroll(1);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        data-testid={testid}
        css={[styles.container, hScrollingActive && styles.grabbingCursor]}
        ref={containerRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
      >
        {Object.entries(listGroupedByOption)
          /** unselected rows go first */
          .sort(([a], [b]) => {
            if (a === 'undefined') return -1;
            /* istanbul ignore next line */
            if (b === 'undefined') return 1;
            return 0;
          })
          .map(([optionValue, list]) => (
            <div key={optionValue} data-testid="column" css={styles.column}>
              <h4 data-testid="column-title" css={styles.columnTitle} style={{ backgroundColor: COLOR[optionMap[optionValue]?.color] }}>
                <span css={styles.columnTitleText} style={{ color: FOREGROUND_COLOR[optionMap[optionValue]?.color] }}>
                  <IconImage
                    title=""
                    icon={optionMap[optionValue]?.image}
                    iconProps={{ fill: FOREGROUND_COLOR[optionMap[optionValue]?.color] || COLOR.NEUTRAL_1, size: FONT_SIZE.M }}
                    imageProps={{ css: { width: 24, height: 24, objectFit: 'cover', borderRadius: '100%' } }}
                  />
                  {optionMap[optionValue]?.label || optionValue}
                </span>
                {typeof onColumnAction === 'function' && (
                  <div onMouseDown={stopPropagation} onMouseUp={stopPropagation}>
                    <CTA
                      type={CTAType.ACTION_SECONDARY}
                      icon="add"
                      testid="column-action"
                      size={ViewModel.CTASize.SMALL}
                      disabled={isLoading}
                      onClick={event => {
                        onColumnAction({ [optionListField]: optionValue === 'undefined' ? undefined : safeParse(optionValue) }, { metaKey: event?.metaKey });
                      }}
                    />
                  </div>
                )}
              </h4>
              <Droppable droppableId={optionValue} isDropDisabled={isLoading || disabled}>
                {(droppableProvided, droppableProps) => (
                  <div
                    css={[
                      styles.listContainer,
                      droppableProps.isDraggingOver && /* istanbul ignore next | part of the missing drag&drop testing */ styles.columnDraggingOver
                    ]}
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    <div css={[styles.list, isLoading || (disabled && /* istanbul ignore next | part of the missing drag&drop testing */ styles.disabledList)]}>
                      {list.map((item, index) => (
                        <div key={`${optionValue}-${index}-${item.raw?.id}`} onMouseDown={stopPropagation} onMouseUp={stopPropagation}>
                          <Draggable
                            draggableId={`${optionValue}-${index}`}
                            index={index}
                            key={`${optionValue}-${index}-${item.raw?.id}`}
                            isDragDisabled={moveDisabled || isLoading || disabled}
                          >
                            {draggableProvided => (
                              <div
                                {...draggableProvided.dragHandleProps}
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                data-testid="item"
                              >
                                <KanbanCard
                                  title={item.title}
                                  onClick={
                                    /* istanbul ignore next */ event =>
                                      typeof onClickItem === 'function' && onClickItem(item?.raw, event)?.pipe(swallowError()).subscribe()
                                  }
                                  actionListChildren={actionListChildren}
                                  recordActions={recordActions}
                                  imageUrl={item?.image}
                                  color={item?.color as GeneralModel.Color.ThemeColor}
                                  fullItem={item?.raw}
                                  subtitle={item.description}
                                  index={index}
                                  disabled={isLoading}
                                />
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          ))}
      </div>
    </DragDropContext>
  );
};
