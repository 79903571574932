import { useContext, useMemo } from 'react';

import { MAPS_URL } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../smart/CyWrapper';
import { CTAType } from '../CTA';
import type { IOptionListItem } from '../OptionMenu';

export const useOpenMapOption = (address: GeneralModel.AddressValue) => {
  const { useOnOpenExternalUrl } = useContext(CyWrapperContext);
  const onOpenExternalUrl = useOnOpenExternalUrl();

  return useMemo(
    () =>
      ({
        disabled: !address?.lat || !address?.lng,
        image: 'map',
        important: false,
        testid: 'open-map-btn',
        tooltip: 'Open map',
        onClick: () => onOpenExternalUrl(`${MAPS_URL}${address?.lat},${address?.lng}`),
        type: CTAType.LINK
      }) as IOptionListItem,
    [address?.lat, address?.lng, onOpenExternalUrl]
  );
};
