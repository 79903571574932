import type { ComponentProps } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { Observable } from 'rxjs';
import { concat, delay, map, mergeMap, of, shareReplay, takeLast } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import type { TokenSet } from '@cyferd/client-engine';
import {
  ClientEngineContext,
  ErrorBoundary,
  GeneralModel,
  ViewModel,
  createUUID,
  getParsedActionChildren,
  isDeepEqual,
  safeParse,
  swallowError,
  useFinalizeWhileMounted,
  useOnEntityChange,
  useOnRefresh,
  usePrevious,
  useRecordActionsParser
} from '@cyferd/client-engine';

import { ErrorSummary } from '@components/elements/ErrorSummary';
import type { BaseFormProps } from './components/BaseForm';
import { BaseForm } from './components/BaseForm';
import { CyWrapperContext } from '../CyWrapper';
import { useSmartUtils } from '@utils/useSmartUtils';
import { getFormErrors } from './getFormErrors';
import { styles } from './styles';
import { useFormModel } from './useFormModel';
import { TabList, TabListType, VerticalTabList } from '@components/elements/TabList';
import { CTA, CTAType } from '@components/elements/CTA';
import { Spinner } from '@components/elements/Spinner';
import { Stepper } from '@components/elements/Stepper';
import { Layout } from '@components/elements/Layout';
import { SemaphoreProvider } from './SemaphoreProvider';
import { BBContainer } from '@components/elements/BBContainer';
import type { IconKeys } from '@components/elements/Icon';
import { OptionMenu } from '@components/elements/OptionMenu';
import { Fieldset } from '@components/elements/Fieldset';

export type CyFormBaseProps = {
  showErrorsBeforeSave?: boolean;
  errorSummaryHidden?: boolean;
  tabsIOptionMenu?: ComponentProps<typeof TabList>['optionMenuProps'];
} & ViewModel.CyFormProps &
  Pick<
    BaseFormProps,
    | 'componentRecord'
    | 'getComponentRecord'
    | 'getOptionMenu'
    | 'wrapDetailGroups'
    | 'getType'
    | 'value'
    | 'ignoredPropertyReg'
    | 'shouldValidate'
    | 'rootItemName'
    | 'supportedSpecialFormatList'
    | 'unsuportedSpecialFormatList'
    | 'addDefaults'
    | 'avoidAlphabeticalSort'
    | 'avoidInitialSync'
    | 'delayTime'
    | 'allowFormula'
    | 'inputList'
  >;

export const CyFormBase = ({
  id,
  componentName,
  value,
  ctaLabel,
  type,
  actionListChildren,
  performanceModeDisabled,
  autofocusDisabled,
  actionPosition: originalActionPosition,
  submitHidden,
  avoidFetch,
  disabled: formDisabled,
  model: modelOverride,
  collectionId,
  recordId,
  maxColumns,
  onChange,
  onSave,
  onFetch,
  onValidate,
  /** extended cyform props */
  delayTime: defaultDelay = 500,
  shouldValidate = true,
  addDefaults = true,
  showErrorsBeforeSave = false,
  errorSummaryHidden,
  effectChildren,
  fitToPage,
  tabColor,
  tabsIOptionMenu,
  recordActionsHidden,
  ...baseFormProps
}: CyFormBaseProps) => {
  const { useOnDownloadFile, useParsers, useAction } = useContext(CyWrapperContext);
  const valueInstanceId = value?.record?.id;
  const { platformConfig } = useContext(ClientEngineContext);
  const parsedOnFetch = !avoidFetch ? onFetch : undefined;
  const valueRef = useRef<ViewModel.CyFormProps['value']>(value);
  valueRef.current = value; // saving this outside of the state life cycle to use in mid rxjs flows (file upload)
  const testid = 'cyForm';
  const delayTime = performanceModeDisabled ? 0 : defaultDelay;
  const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(showErrorsBeforeSave);
  const [isCyFormSaving, setIsCyFormSaving] = useState<boolean>(false);
  const [isCyFormUploading, setIsCyFormUploading] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepNumber] = useState<number>(0);
  const dispatchRefresh = useAction('dispatchRefresh');
  const finalize = useFinalizeWhileMounted();
  const ungroupedKey = useMemo(createUUID, []);
  const actionPosition = [ViewModel.ActionPosition.TOP, ViewModel.ActionPosition.BOTTOM, ViewModel.ActionPosition.BOTH].includes(originalActionPosition)
    ? originalActionPosition
    : ViewModel.ActionPosition.BOTTOM;

  const { parseData, parseDetailGroupList } = useParsers(value?.query);

  const parseRecordActions = useRecordActionsParser(value?.query?.recordActions);
  const parsedRecordActions = useMemo(
    () => (recordActionsHidden ? [] : parseRecordActions({ item: value?.record, index: 0 })),
    [parseRecordActions, recordActionsHidden, value?.record]
  );
  const completeActionListChildren = useMemo(
    () => [...parsedRecordActions, ...(Array.isArray(actionListChildren) ? actionListChildren : [])],
    [actionListChildren, parsedRecordActions]
  );

  const safeSubmitHidden = (!!formDisabled && baseFormProps.disabledType === GeneralModel.DisabledType.VIEW_ONLY) || submitHidden;

  const isNewRecord = !value?.record?.createdAt;

  const { model, groupListId, parsedDetailGroupMap } = useFormModel({
    value,
    model: modelOverride,
    ungroupedKey,
    parseDetailGroupList,
    avoidAlphabeticalSort: baseFormProps.avoidAlphabeticalSort
  });

  const showUngroupedStep = useMemo(() => groupListId.includes(ungroupedKey), [groupListId, ungroupedKey]);

  const steps = useMemo(
    () => [...groupListId.map(groupId => parsedDetailGroupMap[groupId]), showUngroupedStep && { id: ungroupedKey, name: '' }].filter(Boolean),
    [parsedDetailGroupMap, groupListId, showUngroupedStep, ungroupedKey]
  );

  const currentStep = steps[currentStepIndex];
  const stepsCount = useMemo(() => steps?.length - 1, [steps]);
  const asStepper = useMemo(() => [ViewModel.CyFormType.STEPPER, ViewModel.CyFormType.TABS, ViewModel.CyFormType.VERTICAL_TABS].includes(type), [type]);
  const shouldRender = !!model?.schema;
  const { onInternalFetch, isLoading } = useSmartUtils({ value, onFetch: parsedOnFetch });
  const saveRequirementListRef = useRef<Observable<any>[]>([of(null)]);

  const { formErrors, groupErrors, formRawErrors, groupRawErrors } = useMemo(
    () => getFormErrors({ model, value, parseData, currentStep, shouldValidate }),
    [model, value, parseData, currentStep, shouldValidate]
  );

  const formHasErrors = useMemo(() => !!Object.keys(formErrors).length, [formErrors]);
  const groupHasErrors = useMemo(() => !!Object.keys(groupErrors).length, [groupErrors]);

  const canNavigate = !isNewRecord && !isCyFormSaving;
  const cantSave = (formHasErrors && shouldShowErrors) || isCyFormUploading || isCyFormSaving || formDisabled;
  const cantNext = groupHasErrors && shouldShowErrors && !canNavigate;

  const onFormChange = useCallback((val: any) => onChange && onChange({ ...value, record: val }), [onChange, value]);

  const onFormChangeStep = useCallback(
    (newStep: number) => {
      if (newStep < currentStepIndex || canNavigate) return setCurrentStepNumber(newStep);
      if (groupHasErrors) return setShouldShowErrors(true);

      setShouldShowErrors(false);
      setCurrentStepNumber(newStep);
    },
    [groupHasErrors, canNavigate, currentStepIndex]
  );

  const onFormSave = useCallback(() => {
    setShouldShowErrors(true);
    if (!onSave || !!formHasErrors) return;
    setIsCyFormSaving(true);

    return concat(
      ...(saveRequirementListRef.current.length ? saveRequirementListRef.current : /* istanbul ignore next */ [of(null)]).map(o$ => o$.pipe(swallowError()))
    ).pipe(
      takeLast(1),
      delay(delayTime), // delay for the form's on change throttle (pending files)
      delay(0), // tick to wait for the new value's reference to be set
      mergeMap(() => onSave(valueRef.current?.record)),
      swallowError(),
      finalize(() => {
        const componentNameList = Object.entries(value?.query?.schema?.properties || /* istanbul ignore next */ {})
          .filter(([_, field]) => (field as GeneralModel.JSONSchema).format === GeneralModel.JSONSchemaFormat.ASSOCIATION)
          .map(([key]) => `$$${key}`);
        if (componentNameList.length) dispatchRefresh({ componentNameList }).subscribe();
        setIsCyFormSaving(false);
      })
    );
  }, [onSave, formHasErrors, dispatchRefresh, value?.query?.schema?.properties, delayTime, finalize]);

  const onGetFileRequest = useCallback(
    (file: File) => {
      const formData = new FormData();
      const headers = {
        'Content-Disposition': 'form-data; name=file',
        enctype: 'multipart/form-data',
        Authorization: `Bearer ${safeParse<TokenSet>(localStorage.getItem('token-set'))?.access}`
      };
      formData.append('file', file);
      setIsCyFormUploading(true);
      const request$ = ajax({ url: platformConfig?.fileUploadUrl, body: formData, method: 'POST', headers }).pipe(
        map(r => r.response),
        shareReplay()
      );

      saveRequirementListRef.current = [...saveRequirementListRef.current, request$];

      return request$.pipe(
        finalize(() => {
          saveRequirementListRef.current = saveRequirementListRef.current.filter(r => r !== request$);
        })
      );
    },
    [finalize, platformConfig?.fileUploadUrl]
  );

  useEffect(() => {
    /* istanbul ignore next else */
    if (saveRequirementListRef.current.length === 1) setIsCyFormUploading(false);
  }, [saveRequirementListRef.current.length]);

  const onDownloadFile = useOnDownloadFile();
  /* istanbul ignore next line */
  const onConfiguredDownloadFile = useCallback((fileId: string) => onDownloadFile({ id: fileId }), [onDownloadFile]);

  const formProps: BaseFormProps = {
    id,
    valueInstanceId,
    delayTime,
    errorMap: shouldShowErrors ? formErrors : undefined,
    addDefaults,
    shouldValidate: false,
    schema: model?.schema,
    value: value?.record,
    maxColumns: Math.max(1, Math.floor(maxColumns)),
    detailGroupList: model?.detailGroupList,
    disabled: isCyFormSaving || formDisabled,
    apiQuery: value?.query,
    ungroupedKey,
    currentStepId: currentStep?.id,
    asStepper,
    onGetFileRequest,
    onDownloadFile: onConfiguredDownloadFile,
    onChange: onFormChange,
    ...baseFormProps
  };

  const formContainerRef = useRef();
  useEffect(() => {
    if (!!isLoading || !!autofocusDisabled || !formContainerRef.current) return;
    const element = (formContainerRef.current as HTMLElement).querySelector('[data-focusable="true"]') as HTMLElement;
    if (typeof element?.focus === 'function') element.focus();
  }, [isLoading, currentStepIndex, autofocusDisabled, id, formContainerRef]);

  const prevValueInstanceId = usePrevious(valueInstanceId);

  useEffect(() => {
    setShouldShowErrors(prev => (valueInstanceId === prevValueInstanceId ? prev : false) || !isNewRecord);
  }, [isNewRecord, prevValueInstanceId, valueInstanceId]);

  const prevFormErrors = usePrevious(formRawErrors);

  useEffect(() => {
    if (onValidate && !isDeepEqual(formRawErrors, prevFormErrors)) onValidate(formRawErrors).pipe(swallowError()).subscribe();
  }, [formRawErrors, prevFormErrors, onValidate]);

  const onRefresh = useMemo(() => {
    if (parsedOnFetch) return () => onInternalFetch();
  }, [onInternalFetch, parsedOnFetch]);

  useOnRefresh({ id, componentName }, onRefresh);

  useOnEntityChange({ id: recordId, elementType: collectionId }, value?.query?.cursor, onInternalFetch);

  if (isLoading) {
    return (
      <div id={id} data-testid={testid} css={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  if (!shouldRender) return null;

  const safeCtaLabel = ctaLabel || 'Submit';

  const errorSummary = !!shouldShowErrors && !errorSummaryHidden && !!formRawErrors?.length && <ErrorSummary errorList={formRawErrors} />;
  const groupErrorSummary = !!shouldShowErrors && !errorSummaryHidden && <ErrorSummary errorList={groupRawErrors} />;

  const optionList = [
    ...getParsedActionChildren(completeActionListChildren, { item: value?.record }).map((action, index) => ({
      label: action.label,
      onClick: event => action.onClick(value?.record, { metaKey: event?.metaKey }),
      image: action.icon,
      type: action.type || CTAType.PRIMARY,
      disabled: !!(action.disabled || ((action as any).disabledWhenFormIsInvalid && cantSave)),
      testid: `actionListChild-${index}`,
      status: (action as any).status,
      tooltip: action.helperText,
      color: action.color,
      important: action.important
    })),
    !(type === ViewModel.CyFormType.STEPPER && stepsCount > 0) &&
      !!onSave &&
      !safeSubmitHidden && {
        important: true,
        label: safeCtaLabel,
        type: CTAType.PRIMARY,
        testid: `${testid}-save-button`,
        onClick: onFormSave,
        disabled: cantSave,
        allowAutofocus: true
      }
  ].filter(Boolean);

  const showActions = (!!onSave && !safeSubmitHidden) || !!optionList?.length || !!errorSummary;

  const actionList = (
    <div data-selector="action-list" css={styles.actionList}>
      <OptionMenu defaultBtnType={CTAType.LINK} optionList={optionList} />
    </div>
  );

  if (type === ViewModel.CyFormType.STEPPER && stepsCount > 0) {
    return (
      <ErrorBoundary>
        <BBContainer fitToPage={fitToPage}>
          <SemaphoreProvider>
            <div css={styles.mainStepperContainer}>
              <div data-testid="effects">{effectChildren}</div>
              <div id={id} css={[styles.stepper.wrapper, styles.fullHeight]} data-testid={testid} ref={formContainerRef}>
                <div css={[styles.stepper.mainView, styles.fullHeight]}>
                  <Stepper
                    currentStep={currentStepIndex + 1}
                    stepCount={steps.length}
                    title={currentStep.name}
                    description={currentStep.description}
                    icon={currentStep.image as IconKeys}
                  />
                  <div data-testid="form-stepper-container" css={styles.stepperContent}>
                    <div data-selector="form-content" css={styles.formContent}>
                      <BaseForm {...formProps} />
                    </div>

                    <Fieldset isDetailGroup={true} maxColumns={1}>
                      <div css={styles.stepper.stepperControlsContainer}>
                        {actionList}
                        <div css={styles.stepper.rightControlsContainer}>
                          {currentStepIndex > 0 && (
                            <div>
                              <CTA
                                label="Previous"
                                type={CTAType.SECONDARY}
                                testid={`${testid}-stepper-previous-button`}
                                onClick={() => onFormChangeStep(currentStepIndex - 1)}
                                icon={{ name: 'keyboard_arrow_left', title: 'Previous' }}
                                disabled={isCyFormSaving}
                              />
                            </div>
                          )}
                          {currentStepIndex < stepsCount && (
                            <div>
                              <CTA
                                label="Next"
                                type={CTAType.PRIMARY}
                                testid={`${testid}-stepper-next-button`}
                                icon={{ position: 'end', name: 'keyboard_arrow_right', title: 'Next' }}
                                onClick={() => onFormChangeStep(currentStepIndex + 1)}
                                disabled={cantNext}
                                allowAutofocus={true}
                              />
                            </div>
                          )}
                          {currentStepIndex === stepsCount && !!onSave && !safeSubmitHidden && (
                            <div>
                              <CTA
                                label={safeCtaLabel}
                                type={CTAType.PRIMARY}
                                testid={`${testid}-save-button`}
                                onClick={onFormSave}
                                disabled={cantSave}
                                allowAutofocus={true}
                              />
                            </div>
                          )}
                          {groupErrorSummary}
                        </div>
                      </div>
                    </Fieldset>
                  </div>
                </div>
              </div>
            </div>
          </SemaphoreProvider>
        </BBContainer>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <BBContainer fitToPage={fitToPage}>
        <SemaphoreProvider>
          <div css={[styles.fullHeight, styles.mainContainer]}>
            <div data-testid="effects">{effectChildren}</div>
            <div css={styles.mainStepperContainer}>
              {showActions && [ViewModel.ActionPosition.BOTH, ViewModel.ActionPosition.TOP].includes(actionPosition) && (
                <Fieldset isDetailGroup={true} maxColumns={1}>
                  <div css={styles.controlsContainer} data-testid={`${testid}-top-actions-container`}>
                    {actionList}
                    {errorSummary}
                  </div>
                </Fieldset>
              )}
              <div data-selector="form-content" css={[!asStepper && styles.formContent]}>
                {asStepper && type === ViewModel.CyFormType.TABS && (
                  <TabList
                    type={TabListType.FORM}
                    tabColor={tabColor}
                    tabList={steps.map(step => ({ title: step.id, displayName: step.id === ungroupedKey ? 'Ungrouped' : step.name, icon: step.image as any }))}
                    activeTab={currentStep?.id}
                    onChangeTab={event => setCurrentStepNumber(Object.values(steps).findIndex(step => step.id === event)) as any}
                    optionMenuProps={tabsIOptionMenu}
                  />
                )}
                <div data-selector="form-body" css={styles.formBody}>
                  {asStepper && type === ViewModel.CyFormType.VERTICAL_TABS && (
                    <VerticalTabList
                      type={TabListType.FORM}
                      tabColor={tabColor}
                      tabList={steps.map(step => ({
                        title: step.id,
                        displayName: step.id === ungroupedKey ? 'Ungrouped' : step.name,
                        icon: step.image as any
                      }))}
                      activeTab={currentStep?.id}
                      optionMenuProps={tabsIOptionMenu}
                      onChangeTab={event => setCurrentStepNumber(Object.values(steps).findIndex(step => step.id === event)) as any}
                    />
                  )}
                  <div css={styles.expand}>
                    <Layout id={id} testid={testid} type={ViewModel.LayoutType.FULL}>
                      <div css={styles.inputsContainerStyles}>
                        <div css={styles.formContainer} ref={formContainerRef}>
                          <BaseForm {...formProps} />
                        </div>
                      </div>
                    </Layout>
                  </div>
                </div>
              </div>
              {showActions && [ViewModel.ActionPosition.BOTH, ViewModel.ActionPosition.BOTTOM].includes(actionPosition) && (
                <Fieldset isDetailGroup={true} maxColumns={1}>
                  <div css={styles.controlsContainer} data-testid={`${testid}-bottom-actions-container`}>
                    {actionList}
                    {errorSummary}
                  </div>
                </Fieldset>
              )}
            </div>
          </div>
        </SemaphoreProvider>
      </BBContainer>
    </ErrorBoundary>
  );
};

CyFormBase.displayName = 'CyFormBase';
