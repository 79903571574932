import { COLOR, GAP, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    gap: ${GAP.S};
    background-color: ${COLOR.BASE_BACKGROUND};
    padding-left: ${GAP.S};
  `,
  tree: css`
    height: 100%;
    overflow: auto;
    flex: 1;
    padding: ${GAP.S} 0;
    scroll-behavior: smooth;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  `,
  emptyStateContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  emptyState: css`
    padding: ${GAP.L};
    align-items: center;
  `,
  fieldContainer: css`
    overflow: auto;
    border-left: 1px ${COLOR.NEUTRAL_2} solid;
    padding: ${GAP.M} 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    transition: width calc(${TRANSITION_SPEED} / 2);
  `,
  formulaInputContainer: css`
    padding-left: ${GAP.S};
    width: 100%;
    height: 100%;
  `,
  closeContainer: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  hidden: css`
    display: none;
  `
};
