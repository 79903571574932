import { COLOR, FONT_SIZE } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  spinnerContainer: css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  altRowTitle: css`
    font-size: ${FONT_SIZE.S};
    color: ${COLOR.NEUTRAL_2};
    margin-top: 9.5px;

    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  altRow: css`
    display: grid;
    grid-template-columns: 200px 1fr;
  `
};
