import { COLOR, FONT_SIZE, GAP, RADIUS, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

const selected = `
  border-color: ${COLOR.BRAND_1};
  background-color: ${COLOR.NEUTRAL_5};

  > span {
    color: ${COLOR.BRAND_1};
  }

  > div {
    color: ${COLOR.BRAND_1};
  }
`;

const iconButton = `
  transition: all calc(${TRANSITION_SPEED} / 2);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${RADIUS.M};
  overflow: hidden;
  width: 100%;
  padding: ${GAP.S};
  flex-direction: column;
  overflow: hidden;
  background-color: ${COLOR.NEUTRAL_5};
  border: 2px solid ${COLOR.NEUTRAL_5};
  &:not(:active):not(:disabled):hover,
  &:active {
    ${selected}
  }

  > span {
    font-size: ${FONT_SIZE.XL};
    color: ${COLOR.NEUTRAL_2};
  }

  > div {
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XS};
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  content-visibility: auto;
`;

export const styles = {
  inputContainer: css`
    margin-top: ${GAP.XXS};
  `,
  iconPickerWrapper: css`
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fill, 85px);
    grid-gap: ${GAP.XS};
    max-height: 380px;
    overflow: auto;
    margin-top: ${GAP.S};
  `,
  iconButton: css`
    ${iconButton}
  `,
  selectedIconButton: css`
    ${iconButton}
    ${selected}
  `,
  footer: css`
    display: flex;
    > *:not(:last-child) {
      margin-right: ${GAP.M};
    }
  `,
  oops: css`
    margin-top: ${GAP.XL};
  `,
  selectedImage: css`
    background-color: ${COLOR.NEUTRAL_5};
    margin-top: ${GAP.L};

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      margin: 0;
      max-height: 350px;
    }
  `,
  imageNotFound: css`
    display: flex;
    flex-direction: column;
    padding: ${GAP.XL} ${GAP.L};

    > span:first-of-type {
      color: ${COLOR.NEUTRAL_2};
    }

    h3 {
      margin: ${GAP.XXS} 0;
      color: ${COLOR.BASE_FOREGROUND};
      font-size: ${FONT_SIZE.XXM};
    }

    > span:not(:first-of-type) {
      font-size: ${FONT_SIZE.S};
    }
  `,
  searchContainer: css`
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-gap: ${GAP.S};
  `,
  searchInput: css`
    display: flex;
    align-items: flex-end;
  `
};
