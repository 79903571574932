import { isObject } from '@cyferd/client-engine';
import { CTA, CTAType } from '../CTA';
import { styles } from './styles';
import { memo, useCallback, useState } from 'react';
import type { ChipProps } from '../Chip';
import { Chip } from '../Chip';

export const ChipList = memo(
  ({ list, limited, collapsible, testid = 'chip-list' }: { list: ChipProps[]; limited?: boolean; collapsible?: boolean; testid?: string }) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const cleanList = list?.filter?.(isObject);

    const onToggle = useCallback(() => setOpen(p => !p), []);

    if (!cleanList?.length) return null;

    return (
      <div
        data-testid={testid}
        data-selector="chip-list"
        css={[styles.chipList, !!limited && styles.chipListLimited, !!collapsible && !isOpen && styles.collapsed]}
      >
        {!!collapsible && (
          <CTA testid="toggle-chip-collapse" type={CTAType.SEEMLESS} icon={isOpen ? 'keyboard_arrow_down' : 'chevron_right'} onClick={onToggle} />
        )}
        {list.map((props, index) => (
          <Chip key={`${props.id}-${index}`} {...props} />
        ))}
      </div>
    );
  }
);
