import React, { useMemo, useState } from 'react';
import { ErrorBoundary, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import { BaseForm } from '../../smart/CyForm/components/BaseForm';
import { Modal } from '../Modal';
import { OptionMenu } from '../OptionMenu';
import { CTAType } from '../CTA';
import { TRANS } from '@constants';

export type EditInformationModalProps = {
  onClose: () => void;
  onEdit: (value: any) => void;
  value: GeneralModel.FileValue;
  disabled?: boolean;
  schema?: GeneralModel.JSONSchema;
};

export const EditInformationModal = ({ onClose, onEdit, value, disabled, schema }: EditInformationModalProps) => {
  const [internalValue, setInternalValue] = useState({ name: value?.name, notes: value?.notes });

  const editInformationSchema = useMemo(() => {
    switch (schema?.format) {
      case GeneralModel.JSONSchemaFormat.FILE_LIST:
        return {
          type: 'object' as const,
          required: schema?.items?.required,
          properties: { name: schema?.items?.properties?.name, ...(!!value?.uploadedAt && { notes: schema?.items?.properties?.notes }) }
        };
      case GeneralModel.JSONSchemaFormat.FILE:
      default:
        return {
          type: 'object' as const,
          required: schema?.required,
          properties: { name: schema?.properties?.name, ...(!!value?.uploadedAt && { notes: schema?.properties?.notes }) }
        };
    }
  }, [
    schema?.format,
    schema?.items?.properties?.name,
    schema?.items?.properties?.notes,
    schema?.items?.required,
    schema?.properties?.name,
    schema?.properties?.notes,
    schema?.required,
    value?.uploadedAt
  ]);

  return (
    <ErrorBoundary>
      <Modal
        open={true}
        type={ViewModel.ModalType.REGULAR}
        onClose={onClose}
        icon="edit"
        title={TRANS.client.buttons.edit}
        description="Modify the information of your file"
        footer={
          <div css={styles.optionMenu}>
            <OptionMenu
              optionList={[
                { important: true, label: TRANS.client.buttons.cancel, testid: 'cancel', type: CTAType.SECONDARY, onClick: onClose },
                { important: true, label: TRANS.client.buttons.apply, disabled, testid: 'apply', type: CTAType.PRIMARY, onClick: () => onEdit(internalValue) }
              ]}
            />
          </div>
        }
      >
        <BaseForm
          disabled={disabled}
          value={internalValue}
          schema={{ ...editInformationSchema }}
          maxColumns={1}
          avoidAlphabeticalSort={true}
          shouldValidate={true}
          onChange={setInternalValue}
        />
      </Modal>
    </ErrorBoundary>
  );
};
