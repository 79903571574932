import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  loading: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${GAP.L};
  `,
  img: css`
    width: 200px;
    height: auto;
    margin: auto;
  `,
  txt: css`
    font-size: ${FONT_SIZE.XM};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.NEUTRAL_1};
    font-style: italic;
  `
};
