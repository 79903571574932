import { memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { createPortal } from 'react-dom';

import type { CollectionModel } from '@cyferd/client-engine';
import {
  ErrorBoundary,
  GeneralModel,
  ViewModel,
  createUUID,
  getClassnames,
  isObject,
  listToMap,
  mergeTruthy,
  normalize,
  prepareTermForReg,
  removeKeyList
} from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { COLOR, FONT_SIZE, GENERAL, TRANS, getDataTypeIcon, getDataTypeLabel } from '@constants';
import { EntityListItem } from '../../../EntityListItem';
import { SeemlessButton } from '@components/elements/SeemlessButton';
import type { TemplateName } from '../../schemas';
import { getTypeDefaultProps } from '../../schemas';
import { EditIdModal } from './components/EditIdModal';
import { styles } from './styles';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { OptionMenu } from '@components/elements/OptionMenu';
import { Icon } from '@components/elements/Icon';
import { Collapsible } from '@components/elements/Collapsible';
import { CTA, CTAType } from '@components/elements/CTA';
import { SchemaAdvancedForm } from '../SchemaAdvancedForm';

export const getType = (itemName: string, properties: GeneralModel.JSONSchema): GeneralModel.JSONSchema['type'] => {
  const isEmpty = !properties?.type && isObject(properties) && !Object.keys(properties).length;
  if (isEmpty || (properties?.type === 'object' && !properties?.properties) || (properties?.type === 'array' && !properties?.items) || properties?.allOf) {
    return 'any';
  }
  if ((properties?.anyOf || properties?.oneOf) && !properties.properties && !properties.items) {
    return getType(itemName, (properties?.anyOf || properties?.oneOf)[0] as GeneralModel.JSONSchema);
  }
  if (Array.isArray(properties?.type)) return getType(itemName, { ...properties, type: properties?.type[0] });
  if (properties?.type === 'integer') return 'number';
  return properties?.type;
};

export interface SchemaCreatorContentProps {
  itemName?: string;
  properties: GeneralModel.JSONSchema;
  required?: boolean;
  isTopLevel?: boolean;
  parent?: GeneralModel.JSONSchema;
  detailGroupList: CollectionModel.Collection['detailGroupList'];
  activePropertyId: string;
  nestedLevel?: number;
  searchCriteria?: string;
  allowApiFormats: boolean;
  onChange: (value: GeneralModel.JSONSchema, key?: string) => void;
  onChangeParent: (parent: GeneralModel.JSONSchema) => void;
  setActivePropertyId: (id: string, fieldPath?: string[]) => void;
  onChangeDetailGroupList: (value: CollectionModel.Collection['detailGroupList']) => void;
  onRemoveDetailGroup: (groupId: string) => void;
  onAddFieldInGroup: (groupId: string) => void;
  onAddAssociation: (groupId?: string) => void;
  onAddInfoBlock: (groupId?: string) => void;
  isParentDragging?: boolean;
  inputList: FormulaInputRow[];
  interactionHidden?: boolean;
  fieldPath?: string[];
  overrideIdList?: string[];
}

export const SchemaCreatorContent = memo(
  ({
    properties,
    itemName,
    required,
    nestedLevel = 0,
    parent,
    isTopLevel,
    detailGroupList,
    activePropertyId,
    searchCriteria,
    allowApiFormats,
    interactionHidden,
    onChange,
    onChangeParent,
    setActivePropertyId,
    onChangeDetailGroupList,
    onRemoveDetailGroup,
    onAddFieldInGroup,
    onAddAssociation,
    onAddInfoBlock,
    inputList,
    isParentDragging,
    fieldPath = [],
    overrideIdList
  }: SchemaCreatorContentProps) => {
    const { getTestIdProps } = useTestingHelper('schema-creator');

    const [detailContainer, setDetailContainer] = useState<HTMLElement>();

    const [displayEditIdModal, setDisplayEditIdModal] = useState(false);

    const isActive = properties.$id === activePropertyId;

    const type = useMemo(() => getType(itemName, properties), [itemName, properties]);
    const parentType = useMemo(() => getType(itemName, parent), [itemName, parent]);
    const parentIsArray = parentType === 'array';
    const shouldWrap = !!parentType;
    const title = !parentIsArray ? properties.title : `${parent?.title ?? 'list'} item`;

    const detailGroupMap = useMemo(() => listToMap(detailGroupList, {}, 'id'), [detailGroupList]);
    const icon = getDataTypeIcon(properties?.format as TemplateName);

    const renderId = createUUID(); // used to fix an issue with the drag & drop library

    const ungroupedKey = useMemo(() => createUUID(), []);

    const shouldShowObjectChildren = [undefined, null, GeneralModel.JSONSchemaFormat.OBJECT].includes(properties?.format as any);
    const shouldShowArrayChildren = [
      undefined,
      null,
      GeneralModel.JSONSchemaFormat.ARRAY,
      GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
      GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT
    ].includes(properties?.format as any);

    const shouldHighlight = useMemo(
      () => !!searchCriteria?.trim?.() && [title, itemName, properties?.$id].some(i => new RegExp(prepareTermForReg(searchCriteria), 'i').test(i)),
      [itemName, properties?.$id, searchCriteria, title]
    );

    const onChangeChild = useCallback(
      (value: GeneralModel.JSONSchema, key: string) => {
        if (type === 'object') onChange({ ...properties, properties: { ...properties.properties, [key]: value } }, itemName);
        if (type === 'array') onChange({ ...properties, items: value }, itemName);
      },
      [properties, itemName, type, onChange]
    );

    const onChildChangeParent = useCallback((value: GeneralModel.JSONSchema) => onChange(value, itemName), [itemName, onChange]);

    const onChangeRequired = (isRequired: boolean) => {
      if (isRequired) onChangeParent({ ...parent, required: Array.from(new Set([...(parent.required || []), itemName])) });
      else onChangeParent({ ...parent, required: parent.required?.filter(i => i !== itemName) });
    };

    const onAddItem = () => {
      const $id = createUUID();
      const defaultTypeProps = getTypeDefaultProps('string', GeneralModel.JSONSchemaFormat.TEXT);
      onChange(
        normalize.schema(
          {
            ...properties,
            properties: {
              ...properties.properties,
              [$id]: { ...defaultTypeProps, type: 'string', format: GeneralModel.JSONSchemaFormat.TEXT, $id, title: 'New field', metadata: {} }
            }
          },
          { validDetailGroupIdList: detailGroupList?.map(d => d.id) }
        ),
        itemName
      );
      setActivePropertyId($id, fieldPath);
    };

    const onRemove = () => {
      onChangeParent({ ...parent, properties: removeKeyList(parent.properties, [itemName]), required: parent.required?.filter(i => i !== itemName) });
    };

    const onGenericChange = useCallback(
      (value: GeneralModel.JSONSchema) => {
        const hasObjectFormatChanged = value.format !== properties?.format;

        if (!hasObjectFormatChanged) return onChange(value, itemName);

        const newValue: GeneralModel.JSONSchema = mergeTruthy(
          {
            $id: properties.$id,
            label: properties.title,
            title: properties.title,
            type: value.type,
            format: value.format,
            description: value.description,
            metadata: {
              hidden: value.metadata?.hidden,
              detailPriority: value.metadata?.detailPriority,
              disabled: value.metadata?.disabled,
              detailGroupId: value.metadata?.detailGroupId,
              detailOrder: value.metadata?.detailOrder
            }
          },
          getTypeDefaultProps(value.type, value.format)
        );

        return onChange(newValue, itemName);
      },
      [itemName, onChange, properties]
    );

    /* istanbul ignore next line | @todo */
    const onFieldDragEnd = useCallback(
      (propId: string, groupId: string, newOrder: number) => {
        const prop = properties.properties[propId];

        const propList = Object.entries(properties.properties)
          .filter(([key, thisProp]) => {
            if (key === propId) return false;
            if (!prop.metadata?.detailGroupId && !thisProp.metadata?.detailGroupId) return true;
            return thisProp.metadata?.detailGroupId === groupId || (!thisProp.metadata?.detailGroupId && groupId === ungroupedKey);
          })
          .sort(([_, a], [_2, b]) => ((a.metadata?.detailOrder || Infinity) > (b.metadata?.detailOrder || Number.MAX_VALUE) ? 1 : -1));
        const reorderedList: [string, GeneralModel.JSONSchema][] = [
          ...propList.slice(0, newOrder),
          [propId, { ...prop, metadata: { ...(prop.metadata || {}), detailGroupId: detailGroupMap[groupId] ? groupId : undefined } }],
          ...propList.slice(newOrder, propList.length)
        ];
        const newPropMap = Object.fromEntries(
          reorderedList.map(([key, p], index) => [key, { ...p, metadata: { ...(p.metadata || {}), detailOrder: index + 1 } }])
        );

        onChange({ ...properties, properties: { ...properties.properties, ...newPropMap } }, itemName);
      },
      [detailGroupMap, itemName, onChange, properties, ungroupedKey]
    );

    const existUngroupedFields = useMemo(
      () => Object.values(isObject(properties?.properties) ? properties?.properties : {}).some(prop => !detailGroupMap[prop.metadata?.detailGroupId]),
      [detailGroupMap, properties.properties]
    );

    /* istanbul ignore next line | @todo */
    const onGroupDragEnd = useCallback(
      (groupId: string, newOrder: number) => {
        const group = detailGroupList.find(g => g.id === groupId);
        const order = existUngroupedFields ? newOrder - 1 : newOrder; /** accounts for ungrouped group */
        const cleanOrder = group?.order < order ? order - 1 : order;

        const groupListWithoutDragged: CollectionModel.Collection['detailGroupList'] = detailGroupList.filter(g => g.id !== groupId);

        const reorderedList: CollectionModel.Collection['detailGroupList'] = [
          ...groupListWithoutDragged.slice(0, cleanOrder),
          group,
          ...groupListWithoutDragged.slice(cleanOrder, groupListWithoutDragged.length)
        ].map((g, i) => ({ ...g, order: i + 1 }));

        onChangeDetailGroupList(reorderedList);
      },
      [detailGroupList, existUngroupedFields, onChangeDetailGroupList]
    );

    /* istanbul ignore next line | @todo */
    const onDragEnd = useCallback(
      (result: DropResult) => {
        if (!result?.destination) return;
        const { droppableId, index: newOrder } = result.destination;
        const destinationId = droppableId?.replace(renderId, '');
        const draggableId = result.draggableId;
        const isGroup = detailGroupList.some(g => g.id === draggableId);
        const isDestinationGroup = detailGroupList.some(g => g.id === destinationId);

        if (isGroup && (isDestinationGroup || (ungroupedKey === destinationId && newOrder !== 0))) return onGroupDragEnd(draggableId, newOrder);
        if (!isGroup) return onFieldDragEnd(draggableId, destinationId, newOrder);
      },
      [detailGroupList, onFieldDragEnd, onGroupDragEnd, renderId, ungroupedKey]
    );

    const onOpenEditIdModal = () => setDisplayEditIdModal(true);

    const onCloseEditIdModal = () => setDisplayEditIdModal(false);

    useLayoutEffect(() => {
      setDetailContainer(document.getElementById(GENERAL.PROPERTY_DETAIL_ID));
    }, []);

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          {!!shouldWrap && (
            <EntityListItem
              shouldHighlight={shouldHighlight}
              testid="schema-creator"
              title={title}
              description={getDataTypeLabel(properties.format as TemplateName)}
              color={properties.metadata?.color}
              isActive={isActive}
              icon={icon}
              hiddenIndicator={![false, null, undefined].includes(properties.metadata?.hidden)}
              nestedLevel={nestedLevel}
              onClick={properties?.$id && (() => setActivePropertyId(properties.$id, fieldPath))}
              isOverriden={overrideIdList?.includes(fieldPath.join('.'))}
            >
              {!interactionHidden && (
                <PreventClickPropagation>
                  <OptionMenu
                    defaultBtnType={ViewModel.CTAType.LINK}
                    defaultBtnColor={isActive ? 'BASE_BACKGROUND' : 'BASE_FOREGROUND'}
                    defaultBtnTestid="field-dropdown-btn"
                    defaultBtnSize={ViewModel.CTASize.SMALL}
                    optionList={[
                      {
                        testid: 'copy-id-btn',
                        image: 'content_copy',
                        label: TRANS.client.buttons.copyId,
                        onClick: () => navigator.clipboard.writeText(properties.$id),
                        color: 'BASE_FOREGROUND'
                      },
                      !properties?.metadata?.core &&
                        properties?.format !== GeneralModel.JSONSchemaFormat.ASSOCIATION && {
                          testid: 'edit-id-btn',
                          image: 'edit',
                          label: 'Edit ID (not recommended)',
                          onClick: onOpenEditIdModal,
                          color: 'BASE_FOREGROUND'
                        },
                      !properties?.metadata?.core && {
                        testid: 'remove-field-btn',
                        image: 'delete',
                        label: 'Remove field',
                        onClick: onRemove,
                        color: 'RD_4'
                      }
                    ]}
                  />
                </PreventClickPropagation>
              )}
            </EntityListItem>
          )}

          {(() => {
            switch (type) {
              case 'object':
                const sortedProperties = Object.entries(properties.properties).sort(([_, a], [_2, b]) =>
                  (a.metadata?.detailOrder || Infinity) > (b.metadata?.detailOrder || Number.MAX_VALUE) ? 1 : -1
                );
                const groupList = Object.entries<[string, GeneralModel.JSONSchema][]>(
                  sortedProperties.reduce(
                    (total, [key, property]) => {
                      /** grouping properties in arrays either by existing detail group or "ungrouped" group */
                      const groupKey = detailGroupMap[property.metadata?.detailGroupId] ? property.metadata?.detailGroupId : ungroupedKey;
                      return { ...total, [groupKey]: [...(total[groupKey] ?? []), [key, property]] };
                    },
                    !itemName
                      ? [...detailGroupList]
                          .sort((a, b) => ((a?.order || Infinity) > (b?.order || Number.MAX_VALUE) ? 1 : -1))
                          .reduce((t, c) => ({ ...t, [c.id]: [] }), {})
                      : {}
                  )
                ).sort(([groupKeyA], [groupKeyB]) => {
                  /** sorting groups by order, "ungrouped" group always goes first */
                  if (groupKeyA === ungroupedKey) return -1;
                  /* istanbul ignore if */
                  if (groupKeyB === ungroupedKey) return 1;
                  return (detailGroupMap[groupKeyA]?.order || Infinity) > (detailGroupMap[groupKeyB]?.order || Number.MAX_VALUE) ? 1 : -1;
                });

                return groupList.map(([groupKey, propertyEntryList], groupIndex) => {
                  const isGroupActive = activePropertyId === groupKey && groupKey !== ungroupedKey;
                  return (
                    <Droppable type="group" droppableId={`${groupKey}${renderId}`} key={groupKey} isDropDisabled={interactionHidden || isParentDragging}>
                      {(droppableGroupP, droppableGroupS) => (
                        <div
                          {...droppableGroupP.droppableProps}
                          ref={droppableGroupP.innerRef}
                          {...getTestIdProps('group-container')}
                          className={getClassnames(/* istanbul ignore next | @todo */ droppableGroupS.isDraggingOver && styles.isDraggingOver)}
                        >
                          <Draggable
                            key={groupKey}
                            draggableId={groupKey}
                            index={groupIndex}
                            isDragDisabled={interactionHidden || groupKey === ungroupedKey || isParentDragging}
                          >
                            {(draggableGroupP, groupSnap) => (
                              <div {...draggableGroupP.dragHandleProps} {...draggableGroupP.draggableProps} ref={draggableGroupP.innerRef}>
                                <Collapsible
                                  open={true}
                                  renderHeader={(onToggle, _, isOpen) =>
                                    (groupList.length > 1 || groupKey !== ungroupedKey) && ( // avoids showing a group label when the only group is "ungrouped"
                                      <EntityListItem
                                        title={groupKey === ungroupedKey ? 'Ungrouped fields' : detailGroupMap[groupKey]?.name}
                                        icon="folder"
                                        hiddenIndicator={![false, null, undefined].includes(detailGroupMap[groupKey]?.hidden)}
                                        nestedLevel={0}
                                        isActive={isGroupActive}
                                        testid="detail-group-btn"
                                        strongTitle={true}
                                        onClick={() => setActivePropertyId(groupKey, fieldPath)}
                                        isOverriden={overrideIdList?.includes(groupKey)}
                                        toggleButton={
                                          <SeemlessButton onClick={onToggle}>
                                            <Icon
                                              fill={isGroupActive ? COLOR.BASE_BACKGROUND : COLOR.NEUTRAL_1}
                                              size={FONT_SIZE.M}
                                              name={isOpen ? 'keyboard_arrow_down' : /* istanbul ignore next */ 'chevron_right'}
                                            />
                                          </SeemlessButton>
                                        }
                                      >
                                        <div className={styles.groupMenu}>
                                          {groupKey !== ungroupedKey && !interactionHidden && (
                                            <PreventClickPropagation>
                                              <OptionMenu
                                                defaultBtnType={ViewModel.CTAType.LINK}
                                                defaultBtnColor={isGroupActive ? 'BASE_BACKGROUND' : 'BASE_FOREGROUND'}
                                                defaultBtnTestid="group-dropdown-btn"
                                                defaultBtnSize={ViewModel.CTASize.SMALL}
                                                optionList={[
                                                  {
                                                    testid: 'add-field-in-group-btn',
                                                    image: 'note_add',
                                                    label: 'Add new field',
                                                    onClick: () => onAddFieldInGroup(groupKey)
                                                  },
                                                  {
                                                    testid: 'add-acc-in-group-btn',
                                                    image: 'share',
                                                    label: 'Add new association',
                                                    onClick: () => onAddAssociation(groupKey)
                                                  },
                                                  {
                                                    testid: 'add-info-btn',
                                                    image: getDataTypeIcon(GeneralModel.JSONSchemaFormat.INFO_BLOCK),
                                                    label: 'Add new text block',
                                                    onClick: () => onAddInfoBlock(groupKey)
                                                  },
                                                  {
                                                    testid: 'copy-group-id-btn',
                                                    image: 'content_copy',
                                                    label: TRANS.client.buttons.copyGroupId,
                                                    onClick: () => navigator.clipboard.writeText(groupKey)
                                                  },
                                                  {
                                                    testid: 'remove-group-btn',
                                                    image: 'delete',
                                                    label: 'Remove detail group',
                                                    onClick: () => onRemoveDetailGroup(groupKey),
                                                    color: 'RD_4'
                                                  }
                                                ]}
                                              />
                                            </PreventClickPropagation>
                                          )}
                                        </div>
                                      </EntityListItem>
                                    )
                                  }
                                >
                                  {shouldShowObjectChildren && (
                                    <Droppable type="field" droppableId={`${groupKey}${renderId}`} key={groupKey} isDropDisabled={isParentDragging}>
                                      {droppableFieldP => (
                                        <div {...droppableFieldP.droppableProps} ref={droppableFieldP.innerRef}>
                                          {propertyEntryList.map(([key, child], index) => (
                                            <Draggable key={child.$id} draggableId={key} index={index} isDragDisabled={isParentDragging}>
                                              {(draggableFieldP, fieldSnap) => (
                                                <div
                                                  {...draggableFieldP.dragHandleProps}
                                                  {...draggableFieldP.draggableProps}
                                                  ref={draggableFieldP.innerRef}
                                                  className={styles.propContainer}
                                                >
                                                  <ErrorBoundary>
                                                    <SchemaCreatorContent
                                                      isTopLevel={!itemName}
                                                      detailGroupList={detailGroupList}
                                                      parent={properties}
                                                      itemName={key}
                                                      properties={child}
                                                      nestedLevel={nestedLevel + 1}
                                                      allowApiFormats={allowApiFormats}
                                                      onChange={onChangeChild}
                                                      onChangeParent={onChildChangeParent}
                                                      onChangeDetailGroupList={onChangeDetailGroupList}
                                                      required={properties.required?.includes(key)}
                                                      activePropertyId={activePropertyId}
                                                      setActivePropertyId={setActivePropertyId}
                                                      onRemoveDetailGroup={onRemoveDetailGroup}
                                                      onAddFieldInGroup={onAddFieldInGroup}
                                                      onAddAssociation={onAddAssociation}
                                                      onAddInfoBlock={onAddInfoBlock}
                                                      searchCriteria={searchCriteria}
                                                      inputList={inputList}
                                                      isParentDragging={isParentDragging || groupSnap?.isDragging || fieldSnap?.isDragging}
                                                      interactionHidden={interactionHidden}
                                                      fieldPath={[...fieldPath, 'properties', key]}
                                                      overrideIdList={overrideIdList}
                                                    />
                                                  </ErrorBoundary>
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                          {droppableFieldP.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  )}
                                </Collapsible>
                              </div>
                            )}
                          </Draggable>
                          {droppableGroupP.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                });
              case 'array':
                if (!shouldShowArrayChildren) return null;
                return (
                  <div key={properties.$id}>
                    <ErrorBoundary>
                      <SchemaCreatorContent
                        isTopLevel={!itemName}
                        detailGroupList={detailGroupList}
                        parent={properties}
                        itemName={itemName}
                        properties={properties.items}
                        nestedLevel={nestedLevel + 1}
                        allowApiFormats={allowApiFormats}
                        onChange={onChangeChild}
                        onChangeParent={onChildChangeParent}
                        onChangeDetailGroupList={onChangeDetailGroupList}
                        required={required}
                        activePropertyId={activePropertyId}
                        setActivePropertyId={setActivePropertyId}
                        onRemoveDetailGroup={onRemoveDetailGroup}
                        onAddFieldInGroup={onAddFieldInGroup}
                        onAddAssociation={onAddAssociation}
                        onAddInfoBlock={onAddInfoBlock}
                        searchCriteria={searchCriteria}
                        inputList={inputList}
                        isParentDragging={isParentDragging}
                        interactionHidden={interactionHidden}
                        fieldPath={[...fieldPath, 'items']}
                        overrideIdList={overrideIdList}
                      />
                    </ErrorBoundary>
                  </div>
                );
              default:
                return null;
            }
          })()}
          {isActive &&
            !!detailContainer &&
            createPortal(
              <div className={styles.detailContainer}>
                {shouldWrap && (
                  <SchemaAdvancedForm
                    parentType={parent?.type}
                    parentFormat={parent?.format}
                    isTopLevel={isTopLevel}
                    payload={properties}
                    required={required}
                    allowApiFormats={allowApiFormats}
                    onChange={onGenericChange}
                    onChangeRequired={onChangeRequired}
                    inputList={inputList}
                  />
                )}
              </div>,
              detailContainer
            )}
          {itemName && type === 'object' && shouldShowObjectChildren && !interactionHidden && (
            <EntityListItem testid="schema-creator-add-btn" title="Add field" isActive={false} nestedLevel={nestedLevel + 1} onClick={onAddItem}>
              <CTA type={CTAType.ACTION_TERTIARY} icon="add" size={ViewModel.CTASize.SMALL} tooltip="Add field" />
            </EntityListItem>
          )}
          <EditIdModal
            itemName={itemName}
            onChangeParent={onChangeParent}
            open={displayEditIdModal}
            parent={parent}
            onClose={onCloseEditIdModal}
            setActivePropertyId={setActivePropertyId}
          />
        </div>
      </DragDropContext>
    );
  }
);
