// istanbul ignore file
import { GeneralModel, Translate, getTimeFromNow, mergeTruthy, noop, normalize } from '@cyferd/client-engine';
import { styles } from './styles';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';
import { get } from 'lodash';

export const AssociationChanges = ({ field, change }) => {
  const schema = normalize.schema({
    type: 'object',
    properties: {
      ...Object.fromEntries(
        [
          { title: 'Added', key: 'add' },
          { title: 'Removed', key: 'remove' },
          { title: 'Set', description: 'Cleared all and set only these', key: 'set' },
          { title: 'Modified', key: 'modify' }
        ].map(({ title, description, key }, i) => [
          key,
          {
            title,
            description,
            type: 'array',
            items: {
              title: ' ',
              type: 'object',
              properties: {
                id: {
                  title: 'Related record',
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
                  metadata: {
                    collectionId: field.metadata.association.cursor.collectionId,
                    detailOrder: 1
                  }
                },
                $r: {
                  title: 'Relationship',
                  type: 'object',
                  format: GeneralModel.JSONSchemaFormat.JSON
                }
              }
            },
            metadata: {
              detailOrder: i + 1,
              hidden: {
                $not: true,
                $cyf_hasitems: ['{{event.value}}']
              }
            }
          }
        ])
      ),
      clear: {
        title: 'Cleared all',
        type: 'boolean',
        metadata: {
          hidden: {
            $cyf_not: ['{{event.value}}']
          }
        }
      },
      totalCount: {
        title: 'Total count',
        type: 'number'
      }
    }
  });
  return (
    <div key={change?.key} css={[styles.propRow, styles.bodyRow]} data-testid="diff-row">
      {/** field name  */}
      <div css={[styles.propCellBase, styles.propCellCenter, styles.propCellLabel]}>
        <p css={styles.propTitle}>
          <Translate>{field?.label}</Translate>
        </p>
      </div>
      {/** prev value empty  */}
      <div css={[styles.propCellBase]} />
      {/** value  */}
      <div css={[styles.propCellBase]}>
        <SchemaForm
          shouldValidate={false}
          onChange={noop}
          disabled={true}
          disabledType={GeneralModel.DisabledType.VIEW_ONLY}
          schema={schema}
          model={{ schema: { properties: { prevValue: { metadata: { hidden: true } } } } }}
          value={change}
        />
      </div>
    </div>
  );
};
export const RegularChanges = ({ change, field }) => {
  const rawField = mergeTruthy(
    field,
    { label: ' ', title: ' ', description: '', info: '', metadata: { calculation: null, hidden: false } },
    arg => arg !== undefined
  );

  const schema = normalize.schema({ type: 'object', properties: { value: rawField, prevValue: rawField } });
  return (
    <div key={change?.key} css={[styles.propRow, styles.bodyRow]} data-testid="diff-row">
      {/** field name  */}
      <div css={[styles.propCellBase, styles.propCellCenter, styles.propCellLabel]}>
        <p css={styles.propTitle}>
          <Translate>{field?.label}</Translate>
        </p>
      </div>
      {/** prev value  */}
      <div css={[styles.propCellBase]}>
        <SchemaForm
          shouldValidate={false}
          onChange={noop}
          disabled={true}
          disabledType={GeneralModel.DisabledType.VIEW_ONLY}
          schema={schema}
          model={{ schema: { properties: { value: { metadata: { hidden: true } } } } }}
          value={change}
        />
      </div>
      {/** value  */}
      <div css={[styles.propCellBase]}>
        <SchemaForm
          shouldValidate={false}
          onChange={noop}
          disabled={true}
          disabledType={GeneralModel.DisabledType.VIEW_ONLY}
          schema={schema}
          model={{ schema: { properties: { prevValue: { metadata: { hidden: true } } } } }}
          value={change}
        />
      </div>
    </div>
  );
};

export const ActivityLogDetail = ({ activeRow, collection }) => {
  if (!activeRow) return <></>;
  return (
    <div data-testid="active-row">
      <div css={[styles.propRow, styles.tableHeader]}>
        <div css={styles.propTitle} />
        <div css={styles.propTitle}>Previous value</div>
        <div css={styles.propTitle}>{getTimeFromNow(activeRow.createdAt)}</div>
      </div>
      {activeRow.changeLog?.map?.(change => {
        const field = get(collection?.schema?.properties, change?.key) || { type: 'any', format: GeneralModel.JSONSchemaFormat.EVALUATION };

        if (field.format === GeneralModel.JSONSchemaFormat.ASSOCIATION) {
          return <AssociationChanges field={field} change={change} key={change?.key} />;
        }

        return <RegularChanges field={field} change={change} key={change?.key} />;
      })}
    </div>
  );
};
