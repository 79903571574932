import { useMemo } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { Translate, ViewModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';
import * as RadixSwitch from '@radix-ui/react-switch';

import { COLOR, SECONDARY_COLOR } from '@constants';
import { CTAType } from '../CTA';
import { InfoForLabel } from '../InfoForLabel';
import { InputDescription } from '../InputDescription';
import { InputErrorMesssage } from '../InputErrorMessage';
import type { IOptionMenu } from '../OptionMenu';
import { OptionMenu } from '../OptionMenu';
import { styles } from './styles';

export interface SwitchProps {
  value?: boolean;
  labelPosition?: 'right' | 'left';
  label: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  name?: string;
  onChange?: (value: boolean) => void;
  description?: string;
  required?: boolean;
  color?: GeneralModel.Color.ThemeColor;
  errorMessage?: string;
  testid?: string;
  optionList?: IOptionMenu['optionList'];
  info?: string;
}

export const Switch = ({
  labelPosition = 'right',
  disabled,
  label,
  id,
  onChange,
  value,
  autoFocus,
  description,
  color,
  name,
  testid = 'switch',
  errorMessage,
  required,
  optionList,
  info
}: SwitchProps) => {
  const mainColor: GeneralModel.Color.ThemeColor = useMemo(() => (COLOR[color] ? color : 'BRAND_1'), [color]);
  const stateColor = useMemo(() => (!disabled ? COLOR[mainColor] : SECONDARY_COLOR[mainColor]), [disabled, mainColor]);
  const isIndeterminate = value === undefined || value === null;

  const onInputChange = () => {
    onChange?.(!value);
  };

  const labelElement = (
    <div css={[styles.label, labelPosition === 'left' && styles.labelLeft]} data-testid={`${testid}-label`}>
      <label htmlFor={id} css={styles.labelElement}>
        <div className="label-text">
          <Translate>{label}</Translate> <InfoForLabel label={label} value={info} testId={testid} />
          {required ? '*' : ''}
        </div>
      </label>
      <InputDescription description={description} showPadding={false} />
    </div>
  );

  return (
    <div>
      <div css={styles.inputContainer}>
        <div css={styles.switchElement} data-testid={`${testid}-container`}>
          {labelPosition === 'left' && labelElement}
          <RadixSwitch.Root
            autoFocus={autoFocus}
            checked={!!value}
            name={name}
            css={styles.root}
            style={{ backgroundColor: !!value && stateColor, borderColor: stateColor }}
            id={id}
            disabled={disabled}
            aria-errormessage={errorMessage ? `${id}-error` : undefined}
            aria-invalid={errorMessage ? 'true' : 'false'}
            onCheckedChange={onInputChange}
            data-testid={testid}
            data-focusable={!disabled}
          >
            {isIndeterminate ? (
              <div
                css={[
                  styles.flexRow,
                  styles.flexRowIndeterminate,
                  css`
                    span {
                      background-color: ${stateColor};
                    }
                  `
                ]}
                data-testid={`${testid}-indeterminate`}
              >
                <RadixSwitch.Thumb css={styles.thumbIndeterminate} />
              </div>
            ) : (
              <div
                css={[
                  styles.flexRow,
                  css`
                    span {
                      background-color: ${stateColor};
                    }
                  `
                ]}
              >
                <RadixSwitch.Thumb />
              </div>
            )}
          </RadixSwitch.Root>
          {labelPosition === 'right' && labelElement}
        </div>
        <OptionMenu defaultBtnType={CTAType.LINK} optionList={optionList} defaultBtnSize={ViewModel.CTASize.MEDIUM} />
      </div>
      <InputErrorMesssage id={id} message={errorMessage} testid={testid} showLine={true} />
    </div>
  );
};

Switch.displayName = 'Switch';
