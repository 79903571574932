import { memo, useCallback, useMemo, useState } from 'react';
import { finalize, Observable, takeUntil } from 'rxjs';

import { GeneralModel, swallowError, Translate, useUnmountObservable } from '@cyferd/client-engine';

import { COLOR, FOREGROUND_COLOR, GAP, HC_COLOR } from '@constants';
import { useHover } from '@utils';
import { CTA, CTAType } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import { ToolTip } from '../Tooltip';
import { TooltipInfo } from '../TooltipInfo';
import { Icon } from '../Icon';
import { DropdownMenu } from '../DropdownMenu';
import { Spinner } from '../Spinner';
import { getColors, styles } from './styles';
import { CheckboxMenu } from '../CheckboxMenu';
import type { ChipProps } from './types';
import { ChipType } from './types';

export const Chip = memo(
  ({
    id,
    testid = 'chip',
    type = ChipType.DEFAULT,
    title,
    description,
    color,
    icon,
    active,
    activeValues = [],
    disabled,
    disabledType,
    showCheck,
    compact,
    onClick,
    options
  }: ChipProps) => {
    const isDropdown = type === ChipType.DRODPOWN;
    const onDestroy$ = useUnmountObservable();
    const [isLoading, setIsLoading] = useState(false);
    const { ref, hovered } = useHover();

    const safeDisabled = !!disabled && disabledType === GeneralModel.DisabledType.DISABLED;

    const safeColorName: GeneralModel.Color.ThemeColor = useMemo(() => {
      if (safeDisabled) return 'NEUTRAL_3_5';
      return COLOR[color] ? color : 'BRAND_3';
    }, [color, safeDisabled]);

    const iconColor = useMemo(() => {
      if (safeDisabled) return COLOR.NEUTRAL_1_5;
      return active ? FOREGROUND_COLOR[safeColorName] : HC_COLOR[safeColorName];
    }, [active, safeColorName, safeDisabled]);

    const onSelect = useCallback(
      opt => {
        const result$ = opt.onClick?.();
        if (!(result$ instanceof Observable)) return;
        setIsLoading(true);
        result$
          .pipe(
            takeUntil(onDestroy$),
            swallowError(),
            finalize(() => setIsLoading(false))
          )
          .subscribe();
      },
      [onDestroy$]
    );

    const renderChip = useCallback(
      (isDropdownOpen?: boolean) => {
        const textColor = getColors({ safeDisabled, color: safeColorName, active }).text;
        return (
          <ToolTip text={<TooltipInfo title={title} description={description} />}>
            <div id={id} css={styles.container({ color: safeColorName, active, compact, safeDisabled, disabled, isDropdownOpen, type })} ref={ref}>
              <CTA
                testid={id || testid}
                type={CTAType.SEEMLESS}
                onClick={!isDropdown ? onClick : undefined}
                disabled={disabled || isLoading}
                hideLoading={true}
              >
                <div css={[styles.content, (disabled || isLoading) && styles.disabled]}>
                  {!!icon && (
                    <IconImage
                      title=""
                      icon={icon}
                      iconProps={{
                        outlined: true,
                        fill: iconColor,
                        size: compact ? '15px' : '19px',
                        css: { lineHeight: 0 }
                      }}
                      imageProps={{ css: { width: 21, height: 21, objectFit: 'cover', borderRadius: '100%', margin: `-${GAP.XS} 0 -${GAP.XS} -${GAP.S}` } }}
                    />
                  )}
                  <span css={styles.text({ color: safeColorName, active, safeDisabled })}>
                    <Translate>{title}</Translate>
                  </span>
                  {!!isDropdown && activeValues?.length > 0 && (
                    <div data-testid={`${testid}-counter`} css={styles.activeValuesCounter}>
                      {activeValues.length}
                    </div>
                  )}
                  {!!isDropdown && isLoading && <Spinner size={GAP.M} />}
                  {!!isDropdown && !isLoading && (!active || hovered) && <Icon name="keyboard_arrow_down" size="16px" fill={textColor} />}
                  {((!isDropdown && showCheck && active) || (isDropdown && active && !hovered && !isLoading)) && (
                    <Icon testid={`${testid}-check-icon`} name="check" size="16px" fill={textColor} />
                  )}
                </div>
              </CTA>
            </div>
          </ToolTip>
        );
      },
      [
        active,
        activeValues.length,
        compact,
        description,
        disabled,
        hovered,
        icon,
        iconColor,
        id,
        isDropdown,
        isLoading,
        onClick,
        ref,
        safeColorName,
        safeDisabled,
        showCheck,
        testid,
        title,
        type
      ]
    );

    if (!isDropdown) return renderChip();

    return (
      <DropdownMenu
        horizontalResponsive={false}
        renderTrigger={({ onClick, ref, testid, isOpen }) => (
          <div onClick={!isLoading ? onClick : undefined} ref={ref} data-testid={testid}>
            {renderChip(isOpen)}
          </div>
        )}
        vertical={4}
      >
        <CheckboxMenu optionList={options} hideSearch={true} selectedOptions={activeValues} onSelect={onSelect} />
      </DropdownMenu>
    );
  }
);
