import type { ComponentProps } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { EMPTY } from 'rxjs';

import type { FormComponentRecord, GeneralModel, SchemaFormBaseProps } from '@cyferd/client-engine';
import type { CyFormBaseProps } from '@components/smart/CyForm';
import { CyFormBase } from '@components/smart/CyForm';
import { getLabel } from '@utils';

import { DATA_TYPE_FORMAT, ENV, TRIGGER_FORMAT } from '@constants';
import { DataTypeInput } from '../SchemaEditor/components/DataTypeInput';
import { TriggerForm } from '@components/elements/TriggerForm';

export type Props = {
  schema?: SchemaFormBaseProps['schema'];
  value?: SchemaFormBaseProps['value'];
  detailGroupList?: SchemaFormBaseProps['detailGroupList'];
  getComponentRecord?: (base: FormComponentRecord) => FormComponentRecord;
  cursor?: GeneralModel.FetchCriteria;
  onChange?: SchemaFormBaseProps['onChange'];
} & Omit<CyFormBaseProps, 'value' | 'onChange'>;

export const SchemaForm = memo(
  ({
    id = '',
    schema,
    value,
    detailGroupList,
    shouldValidate = true,
    avoidAlphabeticalSort = true,
    avoidInitialSync = true,
    delayTime = ENV.INPUT_DEBOUNCE_TIME,
    getOptionMenu = () => [],
    onChange,
    getComponentRecord = c => c,
    wrapDetailGroups = false,
    showErrorsBeforeSave = true,
    errorSummaryHidden = true,
    cursor = {},
    ...props
  }: Props) => {
    const renderAny: FormComponentRecord['renderAny'] = useCallback(
      anyProps => {
        switch (anyProps.format) {
          case TRIGGER_FORMAT:
            return (
              <div data-testid="trigger-form">
                <TriggerForm
                  listener={anyProps.value}
                  onChange={anyProps.onChange}
                  label={getLabel(anyProps.displayNamePath)}
                  description={anyProps.description}
                  eventType={anyProps.schema.metadata?.eventType}
                />
              </div>
            );
          case DATA_TYPE_FORMAT:
            return (
              <DataTypeInput
                id={anyProps.id}
                testid={anyProps.id}
                description={anyProps.description}
                disabled={anyProps.disabled}
                label={getLabel(anyProps.displayNamePath)}
                required={anyProps.required}
                value={anyProps.value}
                optionList={getOptionMenu(anyProps)}
                onChange={anyProps.onChange}
                errorMessage={anyProps.error}
              />
            );
          default:
            return null;
        }
      },
      [getOptionMenu]
    );

    const query = useMemo(() => ({ schema, detailGroupList: detailGroupList, cursor }) as CyFormBaseProps['value']['query'], [cursor, detailGroupList, schema]);

    const internalValue = useMemo(() => ({ record: value, query }) as CyFormBaseProps['value'], [value, query]);
    const internalGetComponentRecord: ComponentProps<typeof CyFormBase>['getComponentRecord'] = useCallback(
      r => getComponentRecord({ ...r, renderAny }),
      [getComponentRecord, renderAny]
    );
    const onInternalChange = useCallback(
      (event: Parameters<CyFormBaseProps['onChange']>[0]) => {
        onChange(event?.record);
        return EMPTY;
      },
      [onChange]
    );

    return (
      <CyFormBase
        key={id}
        {...props}
        id={id}
        value={internalValue}
        getComponentRecord={internalGetComponentRecord}
        shouldValidate={shouldValidate}
        avoidAlphabeticalSort={avoidAlphabeticalSort}
        avoidInitialSync={avoidInitialSync}
        delayTime={delayTime}
        wrapDetailGroups={wrapDetailGroups}
        showErrorsBeforeSave={showErrorsBeforeSave}
        errorSummaryHidden={errorSummaryHidden}
        submitHidden={true}
        onChange={onInternalChange}
        getOptionMenu={getOptionMenu}
      />
    );
  }
);
