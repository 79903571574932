import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  listContainer: css`
    height: calc(90vh - 220px);
  `
};
