import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, RADIUS, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const getStyles = (color: string, toastWidth: string) => ({
  container: css`
    cursor: pointer;
    width: ${toastWidth};
    background-color: ${COLOR[color]};
    border: 1px solid ${COLOR[color]};
    border-radius: ${RADIUS.M};
    overflow: hidden;
    box-shadow: ${SHADOW.M};
    padding: ${GAP.L} ${GAP.S};
  `,
  content: css`
    display: grid;
    gap: ${GAP.M};
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  title: css`
    color: ${FOREGROUND_COLOR[color]};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    white-space: pre-line;
    display: flex;
    align-items: center;
  `,
  iconTitle: css`
    margin-right: ${GAP.XS};
    display: inline-block;
    line-height: 0;
  `,
  message: css`
    color: ${FOREGROUND_COLOR[color]};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `
});
