// istanbul ignore file
import type { ComponentProps } from 'react';
import type { ParsedListHeadItem } from '@cyferd/client-engine';
import { GeneralModel } from '@cyferd/client-engine';
import type { IconKeys } from '@components/elements/Icon';
import { Icon } from '@components/elements/Icon';
import { InfoForLabel } from '@components/elements/InfoForLabel';
import type { Table } from '@components/elements/Table';
import { ToolTip } from '@components/elements/Tooltip';
import { COLOR, FONT_SIZE } from '@constants';
import { styles } from './styles';
import { useColumnClick } from './useColumnClick';

type UseTableHeader = (params: {
  head: ParsedListHeadItem[];
  hasActions?: boolean;
  cursor?: any;
  definitionMap: any;
  hideSorting?: boolean;
  disableSorting?: boolean;
  showCalculation?: boolean;
  updateCursor?: any;
  isLoading?: boolean;
}) => ComponentProps<typeof Table>['head'];

export const useTableHeader: UseTableHeader = ({
  head,
  hasActions,
  cursor,
  definitionMap,
  hideSorting,
  disableSorting,
  showCalculation,
  updateCursor,
  isLoading
}) => {
  const onColumnClick = useColumnClick(updateCursor, cursor);
  return [
    ...head.map(col => {
      const isSorting: boolean = cursor?.options?.orderBy === col.definitionId;
      const iconName: IconKeys = !cursor?.options?.descending ? 'arrow_upward' : 'arrow_downward';
      const definition = definitionMap[col.definitionId];
      const info = definition?.property?.info;
      const shouldShowSort = !hideSorting && !!GeneralModel.formatIsSortableMap[definition?.property?.format];
      const shouldShowCalculation = showCalculation && definition?.property?.metadata?.calculation;
      const sortable = !!shouldShowSort && !disableSorting;

      return {
        id: `${col.definitionId}`,
        item: (
          <button
            data-testid="column-head"
            onClick={sortable ? () => onColumnClick(col, isSorting) : undefined}
            disabled={isLoading}
            css={[styles.columnHeaderButtonStyles, shouldShowSort && (!disableSorting ? styles.clickable : styles.nonClickable)]}
          >
            {!!shouldShowSort && (
              <Icon
                testid="table-header-sorting-icon-container"
                name={isSorting ? iconName : 'swap_vert'}
                fill={isSorting ? COLOR.HC_15 : COLOR.NEUTRAL_2}
                size={FONT_SIZE.S}
              />
            )}
            <ToolTip text={String(col.value)}>
              <div css={styles.titleContainerStyles}>
                <span style={{ color: isSorting ? COLOR.HC_15 : COLOR.NEUTRAL_1 }}>{String(col.value)}</span>
                {!!info && <InfoForLabel label={col.value} value={info} />}
              </div>
            </ToolTip>
            {!!shouldShowCalculation && <Icon name="function" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.S} />}
          </button>
        )
      };
    }),
    hasActions && {
      id: 'action-list-header-col',
      item: (
        <button css={styles.columnHeaderButtonStyles}>
          <div css={[styles.titleContainerStyles, styles.actionColumnTitle]}>A</div>
        </button>
      )
    }
  ].filter(Boolean) as ComponentProps<typeof Table>['head'];
};
