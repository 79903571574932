export const arrayToTsv = (data: string[][]) => {
  return data
    .map(
      row =>
        row
          ?.map(val => (val ? val : '')) // convert valid values to strings
          .map(v => v.replaceAll('"', '""')) // escape double quotes
          .map(v => `"${v}"`) // quote it
          .join('\t') // tab-delimited
    )
    .join('\r\n'); // rows starting on new lines
};
