import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/css';

export const iconSize = FONT_SIZE.M;

export const activeColor = 'BRAND_1';

export const styles = {
  getGroupList: (columnWidth = '250px') => css`
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, ${columnWidth}), 1fr));
    grid-gap: ${GAP.L} ${GAP.M};
  `,
  groupTitle: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.BOLD};
    padding-bottom: ${GAP.M};
  `,
  templateNameActive: css`
    padding: ${GAP.XXS} ${GAP.S} ${GAP.XXS} ${GAP.XXS};
    background-color: ${SECONDARY_COLOR[activeColor]};
  `,
  templateNameTextActive: css`
    font-weight: ${FONT_WEIGHT.BOLD}!important;
  `,
  templateNameTextInactive: css`
    opacity: 0.4;
  `,
  templateName: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${RADIUS.S};
    transition: all ${TRANSITION_SPEED};
    :hover {
      opacity: 0.6;
    }
  `,
  listContainer: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${GAP.S};
  `,
  buttonContent: css`
    padding: ${GAP.XXS} ${GAP.M} ${GAP.XXS} 0;
    border-radius: ${RADIUS.S};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 130px;
    p {
      text-align: left;
    }
  `,
  buttonInnerContainer: css`
    display: flex;
    gap: ${GAP.XS};
    align-items: flex-start;
    justify-content: flex-start;
  `,
  templateNameText: css`
    padding: ${GAP.XXS} 0;
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    transition: all calc(${TRANSITION_SPEED} / 4);
  `,
  description: css`
    font-size: ${FONT_SIZE.XS};
    color: ${COLOR.NEUTRAL_2};
    font-weight: ${FONT_WEIGHT.NORMAL};
    text-align: left;
  `,
  searchContainer: css`
    margin-bottom: ${GAP.M};
    display: flex;
    justify-content: flex-start;
  `,
  searchInnerContainer: css`
    flex: 1;
    max-width: 300px;
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${GAP.XXS};
    border-radius: ${RADIUS.S};
    div {
      height: ${iconSize};
      width: ${iconSize};
      display: flex;
      align-self: center;
      justify-content: center;
    }
  `
};
