import { THEME } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';
import { type SerializedStyles, css } from '@emotion/react';

export const themeMap = { [THEME.LIGHT]: GeneralModel.Color.LightTheme, [THEME.DARK]: GeneralModel.Color.DarkTheme };

export const themeStyleMap = Object.entries({
  [THEME.LIGHT]: GeneralModel.Color.LightTheme,
  [THEME.DARK]: GeneralModel.Color.DarkTheme
}).reduce(
  (total, [themeName, config]) => {
    const cssRules = Object.entries(config)
      .flatMap(([key, item]) => [`--${key}: ${item.value};`, `--${key}_SECONDARY: ${item.secondary};`, `--${key}_FOREGROUND: var(--${item.foreground});`])
      .join('\n');

    const shadowColor = config.ABSOLUTE_BLACK.value + (themeName === THEME.DARK ? '90' : '20');

    total[themeName] = css`
      :root {
        ${cssRules}
        --shadow-color: ${shadowColor};
      }
    `;

    return total;
  },
  {} as Record<GeneralModel.Color.ThemeColor, SerializedStyles>
);
