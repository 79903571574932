import { css } from '@emotion/react';

import { COLOR } from '@constants';

export const styles = {
  toggleContainer: css`
    position: relative;
  `,
  dot: css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 0px;
    transition: background-color 0.2s ease-in-out;
    background-color: ${COLOR.RD_1};
    right: 0px;
  `
};
