import { useCallback, useContext, useMemo } from 'react';

import { UIContext } from '@components/providers/UIprovider';
import { ENV } from '@constants';
import { ClientEngineContext, Evaluator, GeneralModel, ViewModel } from '@cyferd/client-engine';

import { logger } from './logger';
import { useUrlBuilder } from './useUrlBuilder';

export const useEvaluationPayload = () => {
  const urlData = useUrlBuilder();
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const { runtimeTheme } = useContext(UIContext);

  return useMemo(
    () => ({
      [ViewModel.InputKey.USER]: user,
      [ViewModel.InputKey.URL]: urlData,
      [ViewModel.InputKey.PLATFORM]: {
        app: GeneralModel.CyferdApp.BUILDER,
        env: ENV.ENVIRONMENT,
        builderUrl: `${ENV.ADMIN_URL}/`,
        clientAppUrl: `${ENV.CLIENT_APP_URL}/`,
        theme: runtimeTheme
      } as GeneralModel.PlatformConfig
    }),
    [runtimeTheme, urlData, user]
  );
};

/* istanbul ignore next line */
export const useDataParser = () => {
  const evaluationPayload = useEvaluationPayload();
  return useCallback(
    (value: any, event: any) => {
      const completeDataMap = { ...evaluationPayload, [ViewModel.InputKey.EVENT]: event } as GeneralModel.PlatformConfig;
      try {
        return Evaluator.parse(value).evaluate(completeDataMap);
      } catch (error) {
        logger.warn(ENV.APP_LOGGER_HEADER, 'Error evaluating', { value, completeDataMap, error });
        return undefined;
      }
    },
    [evaluationPayload]
  );
};
