import { GAP, INPUT_HEIGHT } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  createNewOption: css`
    width: 100%;
    height: calc(${INPUT_HEIGHT} - 2px);
    padding: 0 ${GAP.S};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  `
};
