/* istanbul ignore file */

import type { ComponentProps } from 'react';

import type { Filters } from '@components/elements/Filters';

import { SchemaForm } from '../builder/views/shared/SchemaForm';

export const getRenderSchemaForm =
  (schemaFormPros?: Partial<ComponentProps<typeof SchemaForm>>): ComponentProps<typeof Filters>['renderSchemaForm'] =>
  props => {
    return (
      <div data-testid="schema-form-container">
        <SchemaForm allowFormula={true} {...schemaFormPros} {...props} />
      </div>
    );
  };
