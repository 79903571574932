import type { SelectDropdownProps } from '../SelectDropdown';
import { styles } from './styles';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { GeneralModel, Translate } from '@cyferd/client-engine';
import { ToolTip } from '../Tooltip';
import { IconImage } from '../Icon/renderIcon';
import { TooltipInfo } from '../TooltipInfo';
import { InputWrapper } from '../InputWrapper';
import { useCallback } from 'react';

export const InlineDropdown = ({
  id,
  testid = 'inline-dropdown',
  label,
  value,
  onChange,
  description,
  options,
  disabled,
  errorMessage,
  optionList,
  info,
  minWidth,
  required
}: SelectDropdownProps & { minWidth?: number }) => {
  const onClick = (event: string) => !disabled && onChange(event === value && !required ? null : event);

  const getForegroundColor = useCallback(
    (color: GeneralModel.Color.ThemeColor, isActive: boolean) => {
      if (isActive) return FOREGROUND_COLOR[color];
      if (disabled) return COLOR.NEUTRAL_1_5;
      return COLOR.NEUTRAL_1;
    },
    [disabled]
  );

  return (
    <InputWrapper
      unframed={true}
      description={description}
      errorMessage={errorMessage}
      id={id}
      label={label}
      optionList={optionList}
      required={required}
      testid={testid}
      info={info}
      unlimitedHeight={true}
    >
      <div css={[styles.optionList, disabled && styles.disabledOptionList]} data-testid={GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST}>
        {(options || []).map((option, idx) => {
          const safeColor = (COLOR[option.color] ? option.color : 'BRAND_1') as GeneralModel.Color.ThemeColor;
          const isActive = option.value === value;
          const foregroundColor = getForegroundColor(safeColor, isActive);
          return (
            <div key={`${option.value}-${option.label}-${idx}`}>
              <ToolTip text={<TooltipInfo title={option.label} description={option.description} />}>
                <div
                  data-testid={`option-${idx}`}
                  css={[
                    styles.option(safeColor, minWidth),
                    isActive && styles.activeOption(safeColor),
                    disabled && styles.disabledOption,
                    isActive && !!errorMessage && styles.optionError
                  ]}
                  onClick={() => onClick(option.value as string)}
                >
                  <IconImage
                    icon={option.image}
                    title={option.label}
                    iconProps={{ size: FONT_SIZE.M, fill: foregroundColor }}
                    imageProps={{ css: { width: 16, height: 16, objectFit: 'cover', borderRadius: '100%' } }}
                  />
                  <p css={[styles.optionLabel, isActive && styles.activeOptionLabel]} style={{ color: foregroundColor }}>
                    <Translate>{option.label}</Translate>
                  </p>
                </div>
              </ToolTip>
            </div>
          );
        })}
      </div>
    </InputWrapper>
  );
};
