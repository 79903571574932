import { COLOR, EMPTY_CONTAINER_HEIGHT, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  headerContainer: css`
    margin-bottom: ${GAP.M};
  `,
  container: css`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
  `,
  tableContainer: css`
    display: flex;
  `,
  content: css`
    display: flow;
    flex: 1;
    overflow: auto;
  `,
  headerActionList: css`
    [data-selector='option-menu-container'] {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  `,
  headerSearchContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: ${GAP.XS};
  `,
  searchInputContainer: css`
    > div {
      align-items: flex-end;
    }
  `,
  titleControls: css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: ${GAP.XS};
  `,
  controls: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
  `,
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `,
  titleContainerStyles: css`
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
  `,
  columnHeaderButtonStyles: css`
    background: none;
    display: flex;
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.NORMAL};
    width: 100%;
    justify-content: flex-start;
    gap: ${GAP.XXS};
    align-items: center;
  `,
  clickable: css`
    cursor: pointer;
  `,
  actionColumnTitle: css`
    visibility: hidden;
  `,
  actionsContainer: css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `
};
