import { TRANS, defaultFormDetailGroupMap } from '@constants';
import { GeneralModel, ViewModel, capitalize } from '@cyferd/client-engine';

const alertModalSchemaProps: GeneralModel.JSONSchema['properties'] = {
  title: { label: TRANS.client.fields.titles.title, type: 'string' },
  description: { label: TRANS.client.fields.titles.message, type: 'string', format: GeneralModel.JSONSchemaFormat.RICH_TEXT },
  icon: { label: TRANS.client.fields.titles.icon, type: 'string', format: GeneralModel.JSONSchemaFormat.ICON },
  status: {
    label: TRANS.client.fields.titles.status,
    type: 'string',
    metadata: { hidden: true, optionList: Object.values(ViewModel.Status).map(s => ({ value: s, label: capitalize(s) })) }
  },
  skip: { label: TRANS.client.triggerForm.titles.skip, description: TRANS.client.triggerForm.descriptions.skip, type: 'boolean' },
  confirmLabel: { label: TRANS.client.triggerForm.titles.confirmLabel, type: 'string' }
};

export const complexListenerSchema: GeneralModel.JSONSchema = {
  type: 'object',
  properties: {
    trigger: {
      label: ' ',
      description: TRANS.client.triggerForm.descriptions.trigger,
      type: 'object',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id, allowFormula: false },
      properties: {
        type: {
          type: 'string',
          label: TRANS.client.fields.titles.actionType,
          format: GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST,
          metadata: {}
        }
      }
    },
    id: {
      type: 'string',
      metadata: { hidden: true, calculation: { $cyf_coalesce: ['{{event.value}}', { $cyf_guid: [] }] } }
    },
    label: {
      label: TRANS.client.fields.titles.actionName,
      type: 'string',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id, allowFormula: false }
    },
    description: {
      label: TRANS.client.triggerForm.titles.developerNotes,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id, allowFormula: false }
    },
    delay: {
      type: 'number',
      label: TRANS.client.triggerForm.titles.delay,
      description: TRANS.client.triggerForm.descriptions.delay,
      metadata: { detailGroupId: defaultFormDetailGroupMap.advanced.id, allowFormula: true }
    },
    condition: {
      type: 'object',
      label: TRANS.client.fields.titles.condition,
      description: TRANS.client.triggerForm.descriptions.condition,
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      metadata: { detailGroupId: defaultFormDetailGroupMap.advanced.id, allowFormula: true }
    },
    parameters: {
      type: 'object',
      label: TRANS.client.triggerForm.titles.parameters,
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      metadata: { detailGroupId: defaultFormDetailGroupMap.advanced.id, allowFormula: true }
    },
    concurrent: {
      type: 'boolean',
      label: TRANS.client.triggerForm.titles.concurrent,
      description: TRANS.client.triggerForm.descriptions.concurrent,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { detailGroupId: defaultFormDetailGroupMap.advanced.id, allowFormula: true }
    },
    userConfirmation: {
      type: 'object',
      label: TRANS.client.fields.titles.confirmationMessage,
      description: TRANS.client.triggerForm.descriptions.confirmationMessage,
      format: GeneralModel.JSONSchemaFormat.OBJECT,
      properties: { ...alertModalSchemaProps, cancelLabel: { label: TRANS.client.triggerForm.titles.cancelBtnLabel, type: 'string' } },
      metadata: {
        fieldSize: GeneralModel.FieldSize.FULL,
        detailGroupId: defaultFormDetailGroupMap.advanced.id,
        color: 'OE_3',
        allowFormula: true,
        subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
        startsCollapsed: true
      }
    },
    userAlert: {
      type: 'object',
      label: TRANS.client.triggerForm.titles.userAlert,
      description: TRANS.client.triggerForm.descriptions.userAlert,
      format: GeneralModel.JSONSchemaFormat.OBJECT,
      properties: { ...alertModalSchemaProps },
      metadata: {
        fieldSize: GeneralModel.FieldSize.FULL,
        detailGroupId: defaultFormDetailGroupMap.advanced.id,
        color: 'GN_2',
        allowFormula: true,
        subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
        startsCollapsed: true,
        hidden: { $cyf_sw: ['{{event.item.trigger.type}}', 'DISPATCH'] }
      }
    },
    userError: {
      type: 'object',
      label: TRANS.client.triggerForm.titles.userError,
      description: TRANS.client.triggerForm.descriptions.userError,
      format: GeneralModel.JSONSchemaFormat.OBJECT,
      properties: { ...alertModalSchemaProps },
      metadata: {
        fieldSize: GeneralModel.FieldSize.FULL,
        detailGroupId: defaultFormDetailGroupMap.advanced.id,
        color: 'RD_4',
        allowFormula: true,
        subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
        startsCollapsed: true,
        hidden: { $cyf_sw: ['{{event.item.trigger.type}}', 'DISPATCH'] }
      }
    },
    payload: {
      format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
      type: 'object',
      title: ' ',
      properties: {},
      metadata: {
        detailOrder: 2,
        collectionId: 'Flow',
        recordId: '{{event.item.trigger.type}}',
        isCollapsible: false,
        detailGroupId: 'payload',
        fieldSize: GeneralModel.FieldSize.FULL,
        subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
        allowFormula: true
      }
    }
  }
} as GeneralModel.JSONSchema;
