import { useCallback } from 'react';
import { mergeMap, of, takeUntil } from 'rxjs';

import { actions, tapOnSuccess, useFinalizeWhileMounted, useIsMounted, useUnmountObservable } from '@cyferd/client-engine';

import type { Request } from '../client-app/state-mgmt/WSProvider';

export const useSaveBond = (request: Request, onSuccess?: () => void, onFinalize?: () => void) => {
  const onDestroy$ = useUnmountObservable();
  const isMounted = useIsMounted();
  const finalize = useFinalizeWhileMounted();

  const saveBond = useCallback(
    (itemId, itemCollectionId, bondId, data, associationKey) => {
      return of(null).pipe(
        mergeMap(() =>
          request(
            actions.coreUpsert({
              $cyf_escape: [
                {
                  query: { cursor: { id: itemId, collectionId: itemCollectionId } },
                  record: {
                    [`$$${associationKey}`]: {
                      modify: [{ $r: { id: bondId, ...data } }]
                    }
                  },
                  options: { reset: false }
                }
              ]
            } as any)
          ).pipe(
            takeUntil(onDestroy$),
            finalize(() => isMounted.current && onFinalize && onFinalize()),
            tapOnSuccess(() => isMounted.current && onSuccess && onSuccess())
          )
        )
      );
    },
    [request, finalize, onDestroy$, isMounted, onFinalize, onSuccess]
  );

  return { saveBond };
};
