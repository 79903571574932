import { useCallback, useContext } from 'react';

/* istanbul ignore file */
import { ApiModel } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../smart/CyWrapper';

export const useNavigateToRecord = () => {
  const { useAction } = useContext(CyWrapperContext);
  const navigateTo = useAction('dispatchNavigateTo');

  return useCallback(
    (collectionId: string, recordId: string) => {
      switch (collectionId) {
        case ApiModel.ApiEntity.FLOW:
          return navigateTo({ path: '/FLOW', qs: { id: recordId }, openInNewTab: true });
        default:
          return navigateTo({ path: '/DETAIL', qs: { collectionId, id: recordId }, openInNewTab: true });
      }
    },
    [navigateTo]
  );
};
