import { COLOR, GAP, SECONDARY_COLOR } from '@constants';
import { css } from '@emotion/react';

const animation = css`
  @keyframes skeleton {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `,
  avatar: props => css`
    min-width: ${props.size || 30}px;
    min-height: ${props.size || 30}px;
    border-radius: 50%;
    background: linear-gradient(90deg, ${COLOR.NEUTRAL_9} 25%, ${SECONDARY_COLOR.NEUTRAL_9} 37%, ${COLOR.NEUTRAL_9} 63%);
    background-size: 400% 100%;
    animation: skeleton 1.5s ease infinite;
    ${animation};
  `,
  content: css`
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, ${COLOR.NEUTRAL_9} 25%, ${SECONDARY_COLOR.NEUTRAL_9} 37%, ${COLOR.NEUTRAL_9} 63%);
    background-size: 400% 100%;
    animation: skeleton 1s ease infinite;
    ${animation};
  `,
  round: css`
    border-radius: ${GAP.XS};
  `
};
