// istanbul ignore file
import type { CollectionModel, UserModel } from '@cyferd/client-engine';
import {
  ApiModel,
  GeneralModel,
  ViewModel,
  applyMask,
  normalize,
  ofTypeSetData,
  swallowError,
  useFinalizeWhileMounted,
  useUnmountObservable
} from '@cyferd/client-engine';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { EMPTY, takeUntil, tap } from 'rxjs';
import { BBContainer } from '@components/elements/BBContainer';
import { CTA } from '@components/elements/CTA';
import { Layout } from '@components/elements/Layout';
import { isValidCollectionId } from '@utils';
import { styles } from './styles';
import { ActivityLogDetail } from './ActivityLogDetail';
import type { CyListContextValue } from '../CyList';
import { CyList, CyListContext } from '../CyList';
import { CyWrapperContext } from '../CyWrapper';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';

interface ActivityRow {
  action: string;
  changeLog: { key: string; prevValue: any; value: any }[];
  createdAt: string;
  user: UserModel.User;
}

export const CyActivityLog = ({
  id,
  componentName,
  title,
  collectionId,
  recordId,
  fixedFilter,
  fitToPage,
  actionListChildren,
  effectChildren
}: ViewModel.CyActivityLogProps) => {
  const { useAction, useOnRefresh } = useContext(CyWrapperContext);
  const [value, setValue] = useState();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [collection, setCollection] = useState<CollectionModel.Collection>(undefined);
  const onDestroy$ = useUnmountObservable();
  const finalize = useFinalizeWhileMounted();
  const onCoreDescribe = useAction('coreDescribe');
  const onCoreList = useAction('coreList');
  const controlsRef = useRef<HTMLDivElement>();
  const [controlsContainer, setControlsContainer] = useState<HTMLDivElement>();
  const [selectedField, setSelectedField] = useState<string>();
  const [activeRow, setActiveRow] = useState<ActivityRow>(null);

  const hasValidIds = useMemo(() => isValidCollectionId(collectionId) && isValidCollectionId(recordId), [collectionId, recordId]);

  const cyListContextValue: CyListContextValue = useMemo(
    () => ({ controlsPortalRef: controlsContainer, controlCTASize: ViewModel.CTASize.LARGE }),
    [controlsContainer]
  );

  const initialFetchCriteria = useMemo(
    () => ({
      collectionId: ApiModel.ApiEntity.ACTIVITY,
      searchString: undefined,
      options: { limit: undefined, orderBy: 'createdAt', descending: true }
    }),
    []
  );

  const completeFixedFilter = useMemo(
    () => ({
      $and: [
        { 'cursor.collectionId': collectionId },
        { 'cursor.id': recordId },
        { action: { $ne: 'get' } },
        { action: { $ne: 'list' } },
        !!selectedField && { 'changeLog.key': selectedField },
        fixedFilter
      ].filter(Boolean)
    }),
    [collectionId, fixedFilter, recordId, selectedField]
  );

  const fieldSelectorSchema = useMemo(
    () =>
      normalize.schema({
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
        metadata: { collection, disabled: isLoading }
      }),
    [collection, isLoading]
  );

  const onGetCollection = useCallback(() => {
    if (!hasValidIds) return EMPTY;
    setLoading(true);
    return onCoreDescribe({ query: { cursor: { collectionId } }, pointer: GeneralModel.IGNORED_POINTER_ID }).pipe(
      takeUntil(onDestroy$),
      ofTypeSetData(),
      tap(value => setCollection(normalize.collection(value?.query))),
      swallowError(),
      finalize(() => setLoading(false))
    );
  }, [collectionId, finalize, hasValidIds, onCoreDescribe, onDestroy$]);

  const onFetch = useCallback(
    (event: GeneralModel.FetchCriteria) => {
      return onCoreList({ pointer: GeneralModel.IGNORED_POINTER_ID, query: { cursor: event } }).pipe(
        takeUntil(onDestroy$),
        tap(response => {
          if (response?.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA) setValue(response.payload?.value);
        })
      );
    },
    [onDestroy$, onCoreList]
  );

  const onClickItem: ViewModel.CyListProps['onClickItem'] = useCallback(event => {
    setActiveRow(event as any);
    return EMPTY;
  }, []);

  useOnRefresh({ id, componentName }, onGetCollection);

  useEffect(() => {
    onGetCollection().subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setControlsContainer(controlsRef.current);
  }, []);

  return (
    <BBContainer framed={true} fitToPage={!!activeRow && fitToPage}>
      <div data-testid="effects">{effectChildren}</div>
      <div data-testid="CyActivityLog">
        <header css={styles.header}>
          {!!activeRow && (
            <CTA type={ViewModel.CTAType.LINK} size={ViewModel.CTASize.SMALL} icon="keyboard_arrow_left" testid="back" onClick={() => setActiveRow(null)} />
          )}
          <div css={styles.titleContainer}>
            <Layout title={activeRow ? applyMask(activeRow.createdAt, 'YYYY-MM-DD HH:mm:ss') : title} actionListChildren={actionListChildren as any} />
          </div>
        </header>
        <div css={[!!activeRow && styles.hidden]}>
          <div css={styles.controls}>
            <div>
              <SchemaForm schema={fieldSelectorSchema} value={selectedField} onChange={setSelectedField} />
            </div>
            <div data-testid="activity-log-controls-portal-ref" ref={controlsRef} />
          </div>
          <CyListContext.Provider value={cyListContextValue}>
            <CyList
              id={componentName}
              componentName={componentName}
              fitToPage={fitToPage}
              initialFetchCriteria={initialFetchCriteria}
              onFetch={onFetch}
              onClickItem={onClickItem}
              value={value}
              fixedFilter={completeFixedFilter}
              framed={false}
              searchStringHidden={true}
              typeSelectorHidden={true}
              quickFiltersHidden={true}
              recordActionsHidden={true}
              avoidFetch={!hasValidIds}
            />
          </CyListContext.Provider>
        </div>
        <ActivityLogDetail activeRow={activeRow} collection={collection} />
      </div>
    </BBContainer>
  );
};

CyActivityLog.displayName = ViewModel.DisplayName.CY_ACTIVITY_LOG;
