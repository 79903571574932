import { GeneralModel, TranslationModel, ViewModel } from '@cyferd/client-engine';

export const translation = {
  ...TranslationModel.en.translation,
  client: {
    schemaEditor: {
      groups: {
        titles: {
          basicInformation: 'Basic Information',
          advancedConfig: 'General',
          associationSettings: 'Association settings',
          advancedFormatting: 'Appearance',
          actions: 'Actions',
          validation: 'Validation',
          advancedValidation: 'Advanced validation',
          options: 'Options',
          recordAttrs: 'Record attributes',
          defaultFilters: 'Default filters',
          search: 'Search',
          defaultDisplay: 'Default display'
        },
        descriptions: {
          hidden: 'Controls whether the group is shown',
          shouldCleanHidden: 'When group is hidden on save or exiting the form, the values will be lost',
          disabled: 'Controls whether the group is disabled'
        }
      },
      fields: {
        titles: {
          fixedValue: 'Content',
          option: 'Option',
          required: 'Field is required',
          title: 'Field name',
          label: 'Field title',
          type: 'Type',
          description: 'Short description',
          info: 'Long description',
          detailPriority: { title: 'Where to display', important: 'Show everywhere', other: 'Show only in form' },
          minProperties: 'Minimum properties',
          color: 'Color',
          pattern: 'Regular expression',
          calculation: 'Calculation',
          subtype: 'Subtype',
          mask: 'Mask',
          booleanLabelTrue: 'Label for "true" state',
          booleanLabelFalse: 'Label for "false" state',
          hidden: 'Hidden',
          allowFileInput: 'Allow file upload',
          default: 'Initial value',
          format: 'Data type',
          maxLength: 'Max length',
          minLength: 'Min length',
          multipleOf: 'Multiples of',
          maximum: 'Maximum',
          minimum: 'Minimum',
          optionList: { title: 'Dropdown options', cta: 'Add option' },
          maxItems: 'Max items',
          minItems: 'Min items',
          uniqueItems: 'Unique items',
          unlimitedHeight: 'Full height',
          disabledType: { title: 'Appearance if disabled', disabled: 'Greyed out', readonly: 'View-only' },
          disabled: 'Disabled',
          icon: 'Icon',
          shouldCleanHidden: 'Clean value when hidden',
          invalid: 'Custom error message',
          secondaryColor: 'Secondary color',
          fieldSize: { title: 'Field width', regular: 'Regular', full: 'Full' },
          startsCollapsed: 'Starts collapsed',
          expanded: 'Expanded',
          ctaConfig: {
            ctaConfig: 'CTA settings',
            label: 'Add button label',
            image: 'Add button icon',
            color: 'Add button color',
            type: 'Add button type'
          },
          iconImage: 'Icon',
          suggestionList: { title: 'Suggestions', cta: 'Add suggestion' },
          collectionId: 'Collection',
          recodId: 'Record',
          fetchCriteria: 'Fetch criteria',
          disableFreeText: 'Disable free text',
          additionalOptions: 'Additional options',
          excludeFields: 'Exclude fields',
          model: 'Model',
          expandedImg: 'Maximize images in the form',
          sliderType: 'Slider type',
          value: 'Value',
          selectedColor: 'Selected color',
          display: 'Display',
          framed: 'Framed',
          unframed: 'Unframed',
          allowedFileTypes: 'Allowed file types',
          assType: { title: 'Association type', list: 'List', kpi: 'KPI', table: 'Table', card: 'Cards', grid: 'Grid', dropdown: 'Dropdown' },
          disableCreateNew: 'Hide "create new" action',
          disableLink: 'Hide "add" action',
          disableUnlink: 'Hide "remove" action',
          limit: 'Limit',
          orderBy: 'Order by',
          descending: 'Descending',
          defaultFilter: 'Default filter',
          select: 'Select fields to be part of the association',
          omit: 'Omit fields from the association',
          fixedFilter: 'Fixed filter',
          preventLoad: 'Prevent load',
          recordTitle: 'Record title',
          recordDescription: 'Record description',
          recordImage: 'Record image',
          recordColor: 'Record color',
          defaultLimit: 'Default table limit',
          defaultKanbanField: 'Default kanban (optional)',
          defaultOrderBy: 'Default table sorting',
          defaultDescending: 'Descending',
          address: {
            road: 'Road',
            houseNumber: 'House Number',
            city: 'City',
            postcode: 'Postcode',
            state: 'State',
            country: 'Country',
            lat: 'Latitude',
            lng: 'Longitude',
            county: 'County',
            category: 'Category',
            continent: 'Continent',
            countryCode: 'Country code',
            stateCode: 'State code',
            suburb: 'Suburb',
            stateDistrict: 'State district',
            required: 'Required fields'
          },
          file: {
            id: 'ID',
            name: 'Name',
            encoding: 'Encoding',
            mimeType: 'Mime type',
            uploadedAt: 'Uploaded at',
            uploadedBy: 'Uploaded by'
          }
        },
        descriptions: {
          disabledType: {
            disabled: 'Grey out the field. Recommended for fields that are temporarily disabled, that the user should ignore.',
            readonly: 'Recommended for fields with content that is relevant to the user, but they are unable to edit it directly.'
          },
          address: {
            required: 'Select the fields that are required for this address'
          },
          collectionLookup: {
            collectionId: 'ID of the collection used to fetch values for the dropdown',
            fetchCriteria: 'Manually input a query used to fetch values for the dropdown',
            disableFreeText: 'Disable manual input',
            optionList: 'Fixed option list to be displayed along with collection records'
          },
          collectionField: {
            condition: 'Optional formula to evaluate each field. When it returns true, field will be excluded'
          },
          collectionFilter: {
            collectionId: 'ID of the collection used to fetch the entity'
          },
          rating: { maximum: 'Select value from 1 to 10', emptyColor: 'Empty color' },
          object: {
            subtype: {
              framed: 'Default data set that only shows fields inside',
              unframed: 'Visible data set that can show title, use color, etc.'
            }
          },
          file: {
            allowedFileTypes: 'If none selected, all file types are allowed'
          },
          association: {
            subtype: {
              dropdown: 'Dropdown is limited to a maximum of 1 associated records. If more than 1 record is associated, it will display as List type.'
            },
            defaultFilter: 'Apply a default filter to the association field, that the end user can edit or remove',
            select: '<p>Select which fields will be part of the association.</br>By default, all fields in the collection are part of the association.</p>',
            omit: '<p>Select which fields will be omitted from the association.</br>By default, all fields in the collection are part of the association.</p>',
            fixedFilter: 'Apply a fixed filter to the association field, that the end user cannot edit or remove'
          },
          description: 'Used as help text in forms',
          info: 'Extra information that pops up when a user interacts with the help icon',
          format: 'Defines the type of field which affects data visualization, validation and storage',
          pattern: `Regular expressions (regex) allow you to set simple or complex rules about what values are valid.<br/><br/>For example, using regex, "$" means that the input text must end with the stated characters. If the regular expression was ".com$" then the input would only be valid if the value ends with ".com".<br/><br/>Suggestions for basic regular expressions are provided, but more complex patterns can be created.<br/>`,
          booleanLabelTrue: 'If left empty, a tick icon will show by default',
          booleanLabelFalse: 'If left empty, a cross icon will show by default',
          color: 'Color for this field',
          fieldSize: { description: 'Field size in forms', regular: 'Default width', full: 'Field will use the full width available' },

          secondaryColor: 'Secondary color for this field',
          invalid: `A formula that returns either:<br/><br/><ul> <li>a boolean indicating when a the field is invalid (true)</li><li>or a string to use as error message.</li></ul><br/>It is useful for custom validations like you would have when using the value of more than one field to validate another one; for example, the field "to date" should be greater than the field "from date"`,
          detailPriority: {
            description: 'Controls where the field is shown',
            important: 'Important',
            other: 'Other'
          },
          mask: {
            description: 'Formula to be used when displaying value outside of a form',
            mask_1: '123 | 123K | 123M',
            mask_2: '0.12 becomes 12%',
            mask_3: '0.75 becomes 3/4, 5.75 becomes 5 3/4',
            mask_4: '$ 123 | $ 123K | $ 123M',
            mask_5: '£ 123 | £ 123K | £ 123M',
            mask_6: '123 € | 123K € | 123M €'
          },
          hidden: 'Controls whether the field is shown',
          icon: 'Icon related to this field',
          iconImage: 'Icon or image related to this field',
          default: 'Set the initial value the first time the field shows in a form',
          uniqueItems: 'Validates whether items should be unique',
          unlimitedHeight: 'The field has no height limit, and will grow to accommodate content.',
          optionList: {
            description: 'Fixed list of option to be presented in a form',
            hidden: 'Controls whether the option is shown in forms',
            color: 'Color for this option'
          },
          suggestionList: 'Suggested values',
          disabled: `<p>A non-editable field will display any field content, but it cannot be directly edited by the user.</p><p>&nbsp;</p><p>However, non-editable fields can be indirectly edited if they are calculation fields.</p>`,
          shouldCleanHidden: `When set to true, the value will be cleared when the field is hidden.<br/><br/>This can be useful, for example, to remove the value from an "Expense amount" field, when it gets hidden because "Has expenses" is set to "false".`,
          allowFileInput: 'Allow file content to be loaded into the field'
        },
        info: {
          info: 'This pop up is how long description looks',
          association: {
            disableCreateNew: 'Remove the option to create new records from this field',
            disableLink: 'Remove the option to link existing records to this association',
            disableUnlink: 'Remove the option to unlink records that are in the association',
            preventLoad: 'Backend optimization aimed at reducing loading times. Fields with this enabled are excluded from calculations'
          }
        }
      },
      infoBlocks: {
        general: {
          visibility: '<h5>Visibility</h5>',
          disabled: '<h5>Disabled</h5>'
        },
        appearance: {
          size: '<h5>Size</h5>'
        },
        association: {
          listOptions: '<h5>List options</h5>',
          validation: '<h5>Association validation</h5>',
          selectionFilters: '<h5>Selection filters</h5>',
          pickFields: '<h5>Fields</h5>',
          performance: '<h5>Performance</h5>'
        }
      }
    },
    triggerForm: {
      titles: {
        confirmLabel: 'Confirmation button label',
        developerNotes: 'Developer notes',
        concurrent: 'Concurrent',
        skip: 'Skip',
        delay: 'Delay',
        parameters: 'Parameters',
        cancelBtnLabel: 'Cancel button label',
        userAlert: 'Success message',
        userError: 'Error message'
      },
      descriptions: {
        skip: 'If true, the confirmation message will not be shown',
        trigger: 'Can be used to create an inline action if none was selected or override an existing one',
        delay: 'Delay in milliseconds to wait before running the action',
        condition: 'If this field is either true or left empty, the action will run',
        concurrent: 'Run without waiting for the previous action on the list to finish',
        confirmationMessage: 'Set up a confirmation message that the user will see before running the action ',
        userAlert: 'Feedback after the action finished',
        userError: 'Feedback after the action had an error'
      }
    },
    fields: {
      titles: {
        name: 'Name',
        publicName: 'Public name',
        description: 'Description',
        color: 'Color',
        icon: 'Icon',
        adminNotes: 'Admin notes',
        globalSearch: 'Include in search results',
        hideApp: 'Hide app',
        appHomeView: 'App home view',
        id: 'ID',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdBy: 'Created by',
        updatedBy: 'Updated by',
        hideInApps: 'Hide from app home',
        enabled: 'Enabled',
        verboseLog: 'Verbose log',
        status: 'Status',
        maxStepRuns: 'Max step runs',
        schedules: 'Schedules',
        schedule: 'Schedule',
        nextRun: 'Next run',
        payload: 'Payload',
        whenToRun: 'When to run',
        type: 'Type',
        customPattern: 'Custom pattern',
        dayOfMonth: 'Day of the month',
        dayOWeek: 'Day of the week',
        time: 'Time',
        startDate: 'Start date',
        endDate: 'End date',
        timezone: 'Timezone',
        lastExecutedAt: 'Last executed at',
        lastFinishedAt: 'Last finished at',
        nextScheduledAt: 'Next scheduled at',
        consecutiveErrorCount: 'Consecutive errors',
        tag: 'Tag',
        input: 'Input',
        fullName: 'Full name',
        email: 'Email',
        roles: 'Roles',
        blockMobileAccess: 'Block mobile access',
        stepName: 'Step name',
        debug: 'Debug',
        actionType: 'Action type',
        actionName: 'Action name',
        stateChanges: 'State changes',
        nextStepItem: 'Next step to run when condition is met',
        goTo: "Next step's name",
        condition: 'Condition',
        lineName: 'Line name',
        stepErrorTypeList: 'Add a step list for an error type',
        value: 'Value',
        key: 'Key',
        image: 'Image',
        hidden: 'Hidden',
        groupOrder: 'Group order',
        collapsible: 'Collapsible',
        startsCollapsed: 'Starts collapsed',
        title: 'Title',
        label: 'Label',
        message: 'Message',
        confirmationMessage: 'Confirmation message',
        above: 'Above',
        left: 'Left',
        right: 'Right',
        customView: 'Custom view',
        keepDraftVer: 'Keep a draft version',
        adminName: 'Admin name',
        buttonlabel: 'Button label',
        modalTitle: 'Modal title'
      },
      descriptions: {
        optional: 'optional'
      },
      info: {
        top: 'The custom view will be displayed in a top row, above the page content.',
        right: 'The custom view will be displayed in a right column, next to the page content.',
        left: 'The custom view will be displayed in a left column, next to the page content.',
        color: `<p>This color will be displayed:</p><ul><li>In the page header of the collection core view</li><li>With the collection when it's included in a List</li></ul>`,
        image: `<p>This icon or image will be displayed:</p><ul><li>In the page header of the collection core view</li><li>With the collection when it's included in a List</li></ul>`,
        adminName: 'This name is only visible to admin users.',
        adminNotes: 'This description is only visible to admin users.',
        buttonLabel: 'This is the label for the action button.',
        modalTitle:
          '<p>The public name of the flow will be used as the modal title by default.<br/><br/>You can customize the modal title here if you prefer another title.</p>'
      }
    },
    groups: {
      titles: {
        basic: 'Basic information',
        naming: 'Naming',
        date: 'Activity',
        configuration: 'Configuration',
        advanced: 'Advanced configuration',
        defaultDisplay: 'Default display',
        coreViewsConfig: 'Add to the {{view}} view',
        customizeCoreViews: 'Customize the core views',
        replaceCoreViews: 'Replace the core views'
      },
      descriptions: {
        customizeCoreViews:
          'As a core behavior, collections will show in a core view. You can customize these pages by inserting custom views around the core content.',
        replaceCoreViews: 'Completely replace the core views with custom views'
      }
    },
    emptyStates: {
      unlabeledPieItem: 'No label',
      noMobileApp: 'Mobile app is not available in this platform.',
      redirectingToMobileApp: 'Opening in app...',
      cyList: `<h2 style="text-align: center;">No data</h2><p style="text-align: center;"><span style="font-size: 14px; font-weight: 400; color: var(--NEUTRAL_2);">There is no information to display</span></p>`,
      cyListWithFilters: `<h2 style="text-align: center;">No data found</h2><p style="text-align: center;"><span style="font-size: 14px; font-weight: 400; color: var(--NEUTRAL_2);">There are no items that match your search criteria</span></p>`,
      collectionOverride: `<h2 style="text-align: center;">No data</h2><p style="text-align: center;"><span style="font-size: 14px; font-weight: 400; color: var(--NEUTRAL_2);">If a user has access to a collection through multiple apps, only the collection override from the first app applies</span></p>`
    },
    buttons: {
      select: 'Select',
      filters: 'Advanced filters',
      refresh: 'Refresh',
      quickFilters: 'Quick filters',
      actions: 'Actions',
      listType: 'Choose a type of list',
      cancel: 'Cancel',
      clearAll: 'Clear all',
      clear: 'Clear',
      confirm: 'Confirm',
      save: 'Save',
      apply: 'Apply',
      createNew: 'Create new',
      sandbox: 'Sandbox',
      sortBy: 'Sort by',
      removeGroup: 'Remove group',
      duplicate: 'Duplicate',
      remove: 'Remove',
      addField: 'Add field',
      addGroup: 'Add group',
      delete: 'Delete',
      publish: 'Publish',
      copy: 'Copy',
      deletePermanently: 'Delete permanently',
      saveAndExit: 'Save and exit',
      goBack: 'Go back',
      goHome: 'Go home',
      copyAddress: 'Copy address',
      copyImage: 'Copy image URL',
      copyReq: 'Copy request',
      copyReqRaw: 'Copy request raw',
      copyName: 'Copy name',
      copyId: 'Copy ID',
      copyGroupId: 'Copy group ID',
      open: 'Open',
      edit: 'Edit',
      close: 'Close',
      create: 'Create',
      smartGenerate: 'Smart generate',
      generateSampleData: 'Regenerate sample data',
      openInApp: 'Open in app',
      dontShowMeAgain: "Don't show me again.",
      reset: 'Reset',
      paste: 'Paste'
    },
    pagination: {
      display: 'Display',
      items: '{{i}} items',
      page: 'Page {{pageNumber}}',
      next: 'next page',
      prev: 'previous page'
    },
    nav: {
      builder: {
        adminMenu: 'Admin menu',
        home: 'Home',
        builder: 'Builder home',
        apps: 'Apps',
        data: 'Data collections',
        flows: 'Flows',
        views: 'Views',
        integrations: 'Integrations',
        devices: 'Devices',
        platform: 'Platform Settings',
        invitations: 'Invitations',
        sandbox: 'Sandbox',
        users: 'Users',
        playground: 'Playground tools',
        guide: 'Cyferd guide',
        pin: 'Pin menu',
        unpin: 'Unpin menu',
        tabs: {
          details: 'Details',
          linkedCollections: 'Linked collections',
          linkedFlows: 'Linked flows',
          linkedUsers: 'Linked users',
          linkedViews: 'Linked views',
          linkedIntegrations: 'Linked integrations',
          linkedApps: 'Linked apps',
          linkedApp: 'Linked app',
          coreViewConfig: 'View settings',
          fields: 'Fields',
          settings: 'Settings',
          filters: 'Filters',
          preview: 'Preview',
          definition: 'Definition',
          logs: 'Logs',
          layout: 'Layout',
          vars: 'Variables',
          mainView: 'Main view',
          modals: 'Modals',
          modalsCount: 'Modals ({{count}})',
          flows: {
            inputForm: 'Input form',
            inputChanges: 'Input changes',
            savedState: 'Saved state',
            output: 'Output',
            start: 'Start',
            steps: 'Steps',
            stepDetail: 'Detail',
            stepAction: 'Action',
            stepNext: 'Next steps',
            stepError: 'Error steps'
          }
        },
        newNames: {
          apps: 'New Application',
          data: 'New Collection',
          createData: 'Create collection',
          publishData: 'Publish collection',
          flows: 'New Flow',
          views: 'New View',
          integrations: 'New Integration',
          invitations: 'Invite for: {{email}}'
        }
      },
      share: 'Copy short URL',
      print: 'Print page',
      favs: 'My favorites',
      apps: 'My Apps',
      notifs: 'Notifications',
      dev: 'Dev tools',
      account: 'My account',
      notifSettings: 'Notification settings',
      notifAll: 'View all',
      notifMark: 'Mark all as read',
      accountMenu: {
        settings: 'Settings',
        devices: 'Devices',
        lang: 'Language',
        theme: 'Theme',
        light: 'Light',
        dark: 'Dark',
        os: 'System settings',
        terms: 'Terms & policies',
        priv: 'Privacy policy',
        third: '3rd party software',
        logout: 'Sign out',
        notifPreferences: 'Notification settings'
      }
    },
    viewTabs: {
      basic: 'Basic',
      tabs: 'Tabs',
      input: 'Input',
      calendarList: 'Calendars',
      advanced: 'Advanced',
      headerActions: 'Buttons',
      mainActions: 'Button actions',
      itemActions: 'Item buttons',
      navigation: 'Navigation bar',
      page: 'Page background',
      custom: 'Custom',
      core: 'Core'
    },
    navSubtitle: {
      apps: 'Group resources and assign users to them',
      data: 'Manage your data structures and the associations between them',
      flows: 'Define custom processes to run manually, on schedule or triggered by other actions in Cyferd',
      views: 'Design custom views to get even more value from your data',
      integrations: 'Manage integrations to 3rd party applications such as databases, REST API, MS Graph...',
      devices: 'Manage the access to Cyferd mobile app by your users',
      invitations: 'Invite new users and set their permissions and roles',
      users: 'Assign roles, permissions and manage users',
      platform: 'Manage the configuration specific to your Cyferd tenant'
    },
    placeholder: {
      search: 'Search',
      globalSearch: 'Search for anything',
      calendarSearch: 'Search events',
      assAdd: 'Search in table',
      addressSearch: 'Search for an address',
      pickColor: 'Pick a color'
    },
    dataTypes: {
      modal: {
        title: 'Field type',
        description: 'Select a data type'
      },
      categories: {
        text: 'Text',
        number: 'Number',
        dates: 'Date & time',
        options: 'Options',
        controls: 'Pickers & controls',
        contact: 'Contact info',
        share: 'Share files & links',
        identifier: 'Identifier',
        raw: 'Raw',
        data: 'Data driven',
        misc: 'Misc'
      },
      names: {
        [GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST]: 'Text dropdown',
        [GeneralModel.JSONSchemaFormat.ICON_IMAGE]: 'Icon or image',
        [GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST]: 'Numeric dropdown',
        [GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST]: 'Horizontal options',
        [GeneralModel.JSONSchemaFormat.RANGE]: 'Numeric slider',
        [GeneralModel.JSONSchemaFormat.NUMERIC_RANGE]: 'Numeric range',
        [GeneralModel.JSONSchemaFormat.CHECKBOX]: 'Checkbox',
        [GeneralModel.JSONSchemaFormat.SWITCH]: 'Switch',
        [GeneralModel.JSONSchemaFormat.OBJECT]: 'Data set',
        [GeneralModel.JSONSchemaFormat.ARRAY]: 'List',
        [GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST]: 'Multiple choice checkboxes',
        [GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT]: 'Multiple choice dropdown',
        [GeneralModel.JSONSchemaFormat.DATE_TIME]: 'Date time',
        [GeneralModel.JSONSchemaFormat.DATE_TIME_U]: 'Date time',
        [GeneralModel.JSONSchemaFormat.PASSWORD]: 'Masked text',
        [GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP]: 'Foreign key lookup',
        [GeneralModel.JSONSchemaFormat.URL]: 'URL',
        [GeneralModel.JSONSchemaFormat.QR]: 'QR',
        [GeneralModel.JSONSchemaFormat.SQL]: 'SQL',
        [GeneralModel.JSONSchemaFormat.YAML]: 'YML',
        [GeneralModel.JSONSchemaFormat.XML]: 'XML',
        [GeneralModel.JSONSchemaFormat.GRAPHQL]: 'GraphQL',
        [GeneralModel.JSONSchemaFormat.JSON]: 'JSON',
        [GeneralModel.JSONSchemaFormat.ADDRESS]: 'Address',
        [GeneralModel.JSONSchemaFormat.TEXT]: 'Text',
        [GeneralModel.JSONSchemaFormat.COLOR]: 'Color',
        [GeneralModel.JSONSchemaFormat.DATE]: 'Date',
        [GeneralModel.JSONSchemaFormat.EMAIL]: 'Email',
        [GeneralModel.JSONSchemaFormat.MONTH]: 'Month',
        [GeneralModel.JSONSchemaFormat.WEEK]: 'Week',
        [GeneralModel.JSONSchemaFormat.TIME]: 'Time',
        [GeneralModel.JSONSchemaFormat.MULTILINE]: 'Multiline',
        [GeneralModel.JSONSchemaFormat.ICON]: 'Icon',
        [GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST]: 'Action type option list',
        [GeneralModel.JSONSchemaFormat.CREDIT_CARD_NUMBER]: 'Credit card number',
        [GeneralModel.JSONSchemaFormat.IBAN]: 'IBAN',
        [GeneralModel.JSONSchemaFormat.ISIN]: 'ISIN',
        [GeneralModel.JSONSchemaFormat.DUNS]: 'DUNS',
        [GeneralModel.JSONSchemaFormat.ISBN]: 'ISBN',
        [GeneralModel.JSONSchemaFormat.BARCODE]: 'Barcode',
        [GeneralModel.JSONSchemaFormat.PHONE_NUMBER]: 'Phone number',
        [GeneralModel.JSONSchemaFormat.COLLECTION_FIELD]: 'Collection field',
        [GeneralModel.JSONSchemaFormat.RICH_TEXT]: 'Rich text',
        [GeneralModel.JSONSchemaFormat.SEARCH]: 'Search',
        [GeneralModel.JSONSchemaFormat.NUMBER]: 'Number',
        [GeneralModel.JSONSchemaFormat.CURRENCY]: 'Currency',
        [GeneralModel.JSONSchemaFormat.ADVANCED_CURRENCY]: 'Advanced currency',
        [GeneralModel.JSONSchemaFormat.RATING]: 'Rating',
        [GeneralModel.JSONSchemaFormat.EVALUATION]: 'Evaluation',
        [GeneralModel.JSONSchemaFormat.FILE]: 'File',
        [GeneralModel.JSONSchemaFormat.COLLECTION_FILTER]: 'Collection filter',
        [GeneralModel.JSONSchemaFormat.COLLECTION_LITE]: 'Collection lite',
        [GeneralModel.JSONSchemaFormat.COLLECTION_RECORD]: 'Collection record',
        [GeneralModel.JSONSchemaFormat.ASSOCIATION]: 'Association',
        [GeneralModel.JSONSchemaFormat.FILE_LIST]: 'File list'
      }
    },
    ViewCompoNames: {
      [ViewModel.DisplayName.CY_TABLE]: 'Edit table',
      [ViewModel.DisplayName.CY_LIST]: 'CyList',
      [ViewModel.DisplayName.CY_FORM]: 'CyForm',
      [ViewModel.DisplayName.CY_MODAL]: 'CyModal',
      [ViewModel.DisplayName.CY_INFO]: 'CyInfo',
      [ViewModel.DisplayName.CY_CHART]: 'CyChart',
      [ViewModel.DisplayName.CY_KANBAN]: 'CyKanban board',
      [ViewModel.DisplayName.CY_LAYOUT]: 'CyLayout',
      [ViewModel.DisplayName.CY_BLANK]: 'Blank',
      [ViewModel.DisplayName.CY_SEARCH]: 'CySearch',
      [ViewModel.DisplayName.CY_ACTION]: 'CyButtons',
      [ViewModel.DisplayName.CY_TEXT]: 'CyText',
      [ViewModel.DisplayName.CY_IMAGE]: 'CyImage',
      [ViewModel.DisplayName.CY_CALENDAR]: 'CyCalendar',
      [ViewModel.DisplayName.CY_ACTIVITY_LOG]: 'Activity log',
      [ViewModel.DisplayName.CY_VIEW]: 'CyView',
      [ViewModel.DisplayName.CY_VIEW_CONTENT]: "Parent view's content",
      [ViewModel.DisplayName.CY_DATA_EFFECT]: 'Data or URL change',
      [ViewModel.DisplayName.CY_ACTION_EFFECT]: 'Incoming action event',
      [ViewModel.DisplayName.CY_LOAD_EFFECT]: 'Load',
      [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: 'Unload',
      [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: 'Timer',
      [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]: 'Reusable action',
      [ViewModel.DisplayName.VIEW_HEADER]: 'Header',
      [ViewModel.DisplayName.GLOBAL_HEADER]: 'Page settings',
      [ViewModel.DisplayName.NOT_FOUND]: 'Not found'
    },
    inputCompo: {
      [GeneralModel.JSONSchemaFormat.COLOR]: {
        recent: 'Recent',
        palette: 'Palette'
      }
    },
    buldingBlocks: {
      [ViewModel.DisplayName.CY_KANBAN]: {
        paginationInfoMessage: 'A maximum of {{limit}} cards are being shown in this board. There may be more cards hidden by pagination.'
      }
    },
    viewCompoCategories: {
      LAYOUT: 'Layouts',
      INFORMATION: 'Interaction',
      DATA: 'Data',
      OTHER: 'Other',
      EFFECT: 'Actions based on events',
      layoutBlocks: 'Layout blocks'
    },
    SubHeader: {
      modalTitle: 'Delete item?',
      modalDescription: `You're trying to delete this item. Are you sure you want to continue?`
    },
    ComponentAdder: {
      [ViewModel.DisplayName.CY_VIEW]: 'Loads an existing custom view',
      [ViewModel.DisplayName.CY_VIEW_CONTENT]: 'When placed inside a View component, this component will show content from the parent view',
      [ViewModel.DisplayName.CY_DATA_EFFECT]: 'Listen for changes to specified data points or parameters',
      [ViewModel.DisplayName.CY_ACTION_EFFECT]: 'Set up actions to trigger when all other actions are performed',
      [ViewModel.DisplayName.CY_LOAD_EFFECT]: 'Trigger action when shown e.g. when the event is in a modal, and it is opened',
      [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: 'Trigger action when no longer shown e.g. when the event is in a layout, and it changes to hidden',
      [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: 'Triggers an action in a given time interval',
      [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]:
        'Configure actions to be reusable across multiple components, triggered through a "use action" action in a separate component or through a refresh action',
      [ViewModel.DisplayName.CY_BLANK]: 'Insert in columns to keep the column empty',
      [ViewModel.DisplayName.CY_ACTION]: 'Insert buttons and links',
      [ViewModel.DisplayName.CY_TEXT]: 'Create text areas, with the option of buttons',
      [ViewModel.DisplayName.CY_IMAGE]: 'Create image blocks that can hold images, text and buttons',
      [`${ViewModel.DisplayName.CY_LAYOUT}-${ViewModel.LayoutType.RESPONSIVE}`]: 'Efficiently display multiple KPIs with minimal spacing',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.LIST}`]: 'Simple, compact summary of records in a vertical list',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.TABLE}`]: 'Important details of the records in a data table',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.CARD}`]: 'Summary of records in stacked cards',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.GRID}`]: 'Visual display of records showing title and image or color',
      [`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.DEFAULT}`]: 'Display the entire form in one area',
      [`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.STEPPER}`]: 'Display each group of the data collection as a step',
      dynamicLayout: {
        title: 'Dynamic',
        longDesc: 'A layout that will adapt its format, based on the content'
      }
    }
  }
};
