import { TRANS } from '@constants';
import type { CollectionModel } from '@cyferd/client-engine';
import { GeneralModel, createUUID } from '@cyferd/client-engine';

const groupDetailGroupMap = {
  basicInformation: { id: createUUID(), name: 'Detail group', order: 1 } as CollectionModel.DetailGroup
};

export const groupDetailGroupList = Object.values(groupDetailGroupMap);

export const groupSchema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name'],
  properties: {
    id: { type: 'string', label: TRANS.client.fields.titles.id, metadata: { hidden: true, disabled: true } },
    order: { type: 'number', label: TRANS.client.fields.titles.groupOrder, minimum: -1, metadata: { hidden: true, disabled: true } },
    name: { type: 'string', label: TRANS.client.fields.titles.name, minLength: 1, metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id } },
    color: {
      label: TRANS.client.schemaEditor.fields.titles.color,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.COLOR,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id, allowFormula: false }
    },
    collapsible: {
      type: 'boolean',
      label: TRANS.client.fields.titles.collapsible,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id, allowFormula: true }
    },
    startsCollapsed: {
      type: 'boolean',
      label: TRANS.client.fields.titles.startsCollapsed,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id, allowFormula: true }
    },
    hidden: {
      type: 'boolean',
      label: TRANS.client.fields.titles.hidden,
      info: TRANS.client.schemaEditor.groups.descriptions.hidden,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { allowFormula: true, detailGroupId: groupDetailGroupMap.basicInformation.id }
    },
    shouldCleanHidden: {
      $id: createUUID(),
      type: 'boolean',
      label: TRANS.client.schemaEditor.fields.titles.shouldCleanHidden,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      info: TRANS.client.schemaEditor.groups.descriptions.shouldCleanHidden,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id }
    },
    disabled: {
      type: 'boolean',
      label: TRANS.client.schemaEditor.fields.titles.disabled,
      info: TRANS.client.schemaEditor.groups.descriptions.disabled,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { allowFormula: true, detailGroupId: groupDetailGroupMap.basicInformation.id }
    },
    image: {
      type: 'string',
      label: TRANS.client.fields.titles.image,
      format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id }
    },
    info: {
      $id: createUUID(),
      label: TRANS.client.schemaEditor.fields.titles.info,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.RICH_TEXT,
      description: TRANS.client.schemaEditor.fields.descriptions.info,
      info: TRANS.client.schemaEditor.fields.info.info,
      metadata: { detailGroupId: groupDetailGroupMap.basicInformation.id }
    }
  }
};
