import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  actionListContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: ${GAP.S};
  `
};
