import type { ComponentProps } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Subject, takeUntil, tap } from 'rxjs';
import { GeneralModel, ViewModel, noop, safeParse, useFinalizeWhileMounted } from '@cyferd/client-engine';
import { Document, Page, pdfjs } from 'react-pdf';

import { Image } from '../../Image';
import { styles } from './styles';
import { Spinner } from '../../Spinner';
import { OptionMenu } from '../../OptionMenu';
import { CTAType } from '../../CTA';
import { ajax as rxjsAjax } from 'rxjs/ajax';
import { JSONSyntaxEditor } from '../../JSONSyntaxEditor';
import { Input } from '../../Input';
import { SyntaxEditor } from '../..//SyntaxEditor';
import { CyList } from '../../../smart/CyList';
import { csvToApiValue } from './csvToApiValue';
import { FileViewerZoom } from './FileViewerZoom';
import { isCsv, isImage, isJson, isPdf, isText, isVideo, syntaxReg, textReg } from '@utils';

/**
 * using oficial way see github 👀: https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#use-external-cdn
 * another source is this one: `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`
 */

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export interface FileViewerProps {
  title: string;
  mimeType: string;
  fileUrl: string;
  ajax?: typeof rxjsAjax;
}

export const FileViewer = ({ title, mimeType, fileUrl, ajax = rxjsAjax }: FileViewerProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileContent, setFileContent] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();

  const onLoadSuccess: ComponentProps<typeof Document>['onLoadSuccess'] = useCallback(
    response => setNumPages(response?.numPages || /* istanbul ignore next */ 0),
    []
  );

  useEffect(() => {
    if ([textReg, syntaxReg].every(r => !r.test(mimeType))) return;
    const sync$ = new Subject<void>();
    setLoading(true);
    ajax({ url: fileUrl, responseType: 'text', withCredentials: true, crossDomain: true })
      .pipe(
        takeUntil(sync$),
        tap(({ response }) => setFileContent(String(response))),
        finalize(() => setLoading(false))
      )
      .subscribe();
    return () => sync$.next();
  }, [fileUrl, ajax, mimeType, finalize]);

  return (
    <div data-testid="file-viewer" css={[styles.container, mimeType !== 'application/pdf' && styles.center]}>
      {(() => {
        if (loading)
          return (
            <div css={styles.center}>
              <Spinner size="55px" />
            </div>
          );

        if (isImage(mimeType))
          return (
            <div css={styles.zoomContainer}>
              <FileViewerZoom>
                <Image testid="image" src={fileUrl} alt={title} />
              </FileViewerZoom>
            </div>
          );
        if (isVideo(mimeType)) return <video data-testid="video" css={styles.video} src={fileUrl} width="100%" controls={true} />;
        if (isJson(mimeType)) {
          return (
            <div css={styles.center} data-testid="json">
              <JSONSyntaxEditor
                width="100%"
                height={500}
                expanded={true}
                avoidExpandOption={true}
                value={safeParse(fileContent)}
                disabled={true}
                label=""
                onChange={noop}
              />
            </div>
          );
        }
        if (syntaxReg.test(mimeType)) {
          return (
            <div css={styles.center} data-testid="syntax">
              <SyntaxEditor
                language={mimeType.split('/')[1]}
                width="100%"
                height={400}
                expanded={true}
                value={fileContent}
                disabled={true}
                label=""
                onChange={noop}
              />
            </div>
          );
        }
        if (isCsv(mimeType)) {
          return (
            <div css={styles.csvContainer} data-testid="csv">
              <CyList
                value={csvToApiValue(fileContent)}
                type={ViewModel.CyListType.TABLE}
                avoidFetch={true}
                searchStringHidden={true}
                paginationHidden={true}
                typeSelectorHidden={true}
                advancedFiltersHidden={true}
                orderHidden={true}
                quickFiltersHidden={true}
                recordActionsHidden={true}
              />
            </div>
          );
        }
        if (isText(mimeType)) {
          return (
            <div css={[styles.center, styles.textContainer]} data-testid="text">
              <Input type={GeneralModel.JSONSchemaFormat.MULTILINE} rows={40} value={fileContent} disabled={true} onChange={noop} />
            </div>
          );
        }
        if (isPdf(mimeType)) {
          return (
            <FileViewerZoom
              offsideContent={
                numPages > 1 && (
                  <div css={styles.pdfControls}>
                    <span css={styles.pageCount}>
                      {pageNumber} of {numPages}
                    </span>
                    <OptionMenu
                      optionList={[
                        {
                          important: true,
                          testid: 'prev',
                          label: 'Previous',
                          type: CTAType.LINK,
                          disabled: pageNumber <= 1,
                          onClick: () => setPageNumber(p => p - 1)
                        },
                        {
                          important: true,
                          testid: 'next',
                          label: 'Next',
                          type: CTAType.LINK,
                          disabled: pageNumber >= numPages,
                          onClick: () => setPageNumber(p => p + 1)
                        }
                      ]}
                    />
                  </div>
                )
              }
            >
              <div css={styles.pdfContainer} data-testid="pdf">
                <div css={styles.pdfContent}>
                  <Document file={fileUrl} loading={<Spinner size="55px" />} onLoadSuccess={onLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              </div>
            </FileViewerZoom>
          );
        }
        return <div data-testid="unsupported">{"This file type can't be previewed"}</div>;
      })()}
    </div>
  );
};
