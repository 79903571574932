import { Translate } from '@cyferd/client-engine';
import { styles } from './styles';

export interface TooltipInfoProps {
  title?: string;
  description?: string;
}

export const TooltipInfo = ({ title, description }: TooltipInfoProps) => {
  const safeTitle = title?.trim();
  const safeDescription = description?.trim();

  if (!safeTitle && !safeDescription) return null;

  return (
    <div data-testid="tooltip-info-container" css={styles.tooltip}>
      {!!safeTitle && (
        <p data-testid="tooltip-info-title" css={styles.tooltipTitle}>
          <Translate>{safeTitle}</Translate>
        </p>
      )}
      {!!safeDescription && (
        <p data-testid="tooltip-info-description" css={styles.tooltipDescription}>
          <Translate>{safeDescription}</Translate>
        </p>
      )}
    </div>
  );
};
