import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  `,
  toolbar: css`
    display: flex;
    flex-align: center;
    justify-content: flex-end;
  `,
  toolbarItem: css`
    display: grid;
    grid-gap: ${GAP.XS};
    grid-auto-flow: column;
    margin-left: ${GAP.XS};
  `,
  titleText: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  title: css`
    align-items: center;
    gap: ${GAP.XXS};
    display: grid;
    grid-template-columns: auto auto 1fr auto;
  `,
  counter: css`
    white-space: nowrap;
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.NEUTRAL_1};
  `,
  disabled: css`
    color: ${COLOR.NEUTRAL_1_5};
  `,
  spinnerContainer: css`
    margin-left: ${GAP.XS};
  `
};
