import { safeParse } from '@cyferd/client-engine';

export class Storage {
  storageDep?: globalThis.Storage;
  constructor(storageDep?: globalThis.Storage) {
    this.storageDep = storageDep || localStorage;
  }

  get = <T = any>(key: string) => safeParse<T>(this.storageDep.getItem(key));

  set = (key: string, item: any) => this.storageDep.setItem(key, JSON.stringify(item));

  remove = (key: string) => this.storageDep.removeItem(key);
}
