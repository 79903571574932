import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SHADOW, TRANSITION_SPEED } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';

export const styles = {
  cardItem: css`
    box-shadow: ${SHADOW.S};
    display: flex;
    flex-direction: column;
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    border-radius: ${RADIUS.S};
    color: ${COLOR.NEUTRAL_1};
    width: 100%;
    height: auto;
    min-height: 38px;
    position: relative;
    overflow: hidden;
    transition: background-color ${TRANSITION_SPEED};

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      border-radius: ${RADIUS.S};
      width: ${GAP.XS};
    }

    [data-testid='actions-btn'] {
      opacity: 0;
      transition: opacity ${TRANSITION_SPEED};
    }

    :hover {
      [data-testid='actions-btn'] {
        opacity: 1;
      }
    }
  `,
  actionContainer: css`
    position: absolute;
    top: 8px;
    right: 9px;
  `,
  borderColor: (color: GeneralModel.Color.ThemeColor) => css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &::before {
      background-color: ${COLOR[color]};
    }
  `,
  cardItemClickable: css`
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
      top: -1px;
    }
  `,
  title: css`
    word-break: break-word;
    overflow: hidden;
    color: ${COLOR.BASE_FOREGROUND};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: ${FONT_SIZE.S};
    line-height: ${FONT_SIZE.M};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  subtitle: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_2};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    > span {
      word-break: break-word;
    }
  `,
  imageContainer: css`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `,
  iconContainer: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    max-height: 120px;
    overflow: hidden;
    width: 100%;
    padding-top: ${GAP.M};
    padding-bottom: 6px;
    background-color: ${COLOR.NEUTRAL_5};
    span {
      font-size: ${FONT_SIZE.L};
    }
    svg {
      width: 32px;
      height: 32px;
    }
  `,
  iconContent: css`
    min-height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  image: css`
    max-width: 100%;
  `,
  content: props => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: ${GAP.M};
    padding-left: ${props.color ? GAP.S : '14px'};
    gap: ${GAP.S};
  `,
  textContent: css`
    align-self: flex-start;
  `
};
