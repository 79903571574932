import { useCallback, useEffect, useRef, useState } from 'react';

export const hoverDebounceDelay = 100;

const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

export const useHover = () => {
  const [hovered, setHovered] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkHover = useCallback(
    debounce(event => {
      if (!ref.current) return;
      const { left, right, top, bottom } = ref.current.getBoundingClientRect();
      const { pageY, pageX, clientX, clientY } = event;
      const x = pageX || clientX;
      const y = pageY || clientY;
      setHovered(left <= x && x <= right && top <= y && y <= bottom);
    }, hoverDebounceDelay),
    []
  );

  useEffect(() => {
    document.addEventListener('mousemove', checkHover);
    return () => document.removeEventListener('mousemove', checkHover);
  }, [checkHover]);

  return { ref, hovered };
};
