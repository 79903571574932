import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: (e?: MouseEvent) => void, eventType: string = 'click') => {
  const ref = useRef();

  useEffect(() => {
    const onClick = event => {
      if (ref.current && !(ref.current as any).contains(event.target)) callback(event);
    };

    document.addEventListener(eventType, onClick);
    return () => {
      document.removeEventListener(eventType, onClick);
    };
  }, [callback, eventType, ref]);

  return ref;
};
